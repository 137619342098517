export const internationalizedValues = {
  COMPANY_TITLE: 'Company',
  FILTER_PLACEHOLDER: 'Company',
  DELETE_TITLE: 'Delete Confirmation',
  DELETE_MESSAGE: 'Are you sure you want to delete this item?',
  EDIT_TITLE: 'Defect',
  DELETE_REFLECT_MESSAGE:
    'This item is referenced by others, do you want to delete to all of them or just for this item?',
  EDIT_REFLECT_MESSAGE:
    'This item is referenced by others, do you want to make the change to all of them or just for this item?',
  REFLECT_TITLE: 'Reflect confirmation',
  REFLECT_REORDERING_TITLE: 'Reflect Reordering Confirmation',
  REFLECT_REORDERING_MESSAGE:
    'This item is referenced by others; do you want to change the order of all of them, or just for this item?',
  SAVE_CONFIGURATION_SUCCESS_MESSAGE: 'Configurations have been saved',
  SAVE_CONFIGURATION_FAILED_MESSAGE: 'Configurations could not be saved',
  PUBLISH_TITLE: 'Are you sure you want to publish the current configurations?',
  PUBLISH_CONFIGURATION_SUCCESS_MESSAGE: 'Configurations were deployed',
  PUBLISH_CONFIGURATION_FAILED_MESSAGE: 'Configurations could not be deployed',
  DUPLICATE_LOCATION_MESSAGE:
    'Multiple tags in this location. Filter by Zone group above for individual details.',
  CONFIGURATION_ERROR_TITLE: 'Configuration errors',
  EDIT_CONFIGURATION_TITLE: 'Edit configuration',
  EDIT_ZONE_TITLE: 'Edit zone',
  EDIT_COMPONENT_TITLE: 'Edit component',
  EDIT_CONDITION_TITLE: 'Edit condition',
  TEST_TITLE: 'Are you sure you want to test the current configurations?',
  TEST_CONFIGURATION_FAILED_MESSAGE: 'Test configuration failed.',
  TEST_CONFIGURATION_SUCCESS_MESSAGE: 'Configurations were deployed as drafts',
  REMOVE_ASSET_VIEW_IMAGE_TITLE: 'Are you sure you want to remove the current image?',
  REMOVE_ASSET_VIEW_IMAGE_MESSAGE:
    'Removing this image will also remove the associated grid size and tag location information.',
  CHANGE_ASSET_LOCATION_TITLE:
    'Are you sure you want to change the grid size coordinates?',
  CHANGE_ASSET_LOCATION_MESSAGE:
    'Changing these coordinates will impact the location of the current zone tags in the Asset map view.',
  UPLOAD_ASSET_VIEW_MAP_FAILED: 'The image failed to upload - please try again',
  DELETE_PIECE_FAILED_MESSAGE: 'Deleting template library piece failed',
  ADD_INSPECTION_TYPE_SUCCESS_MESSAGE: 'Additional inspection type has been added',
  WARNING_TITLE: 'Warning',
}

const translationFactory = (
  en: string,
  enGB: string,
  it: string,
  es: string,
  fr: string,
  de: string
) => {
  return { en, 'en-GB': enGB, it, es, fr, de }
}

const concatStrTranslation = translationFactory('and', 'and', 'e', 'y', 'et', 'und')
const ownStrTranslation = translationFactory('has', 'has', 'ha', 'tiene', 'a', 'hat')
const ownsStrTranslation = translationFactory(
  'have',
  'have',
  'hanno',
  'tienen',
  'ont',
  'haben'
)
const configSubjectTranslation = translationFactory(
  'this config',
  'this config',
  'queste configurazione',
  'este config',
  'cette config',
  'dieser Konfiguration'
)
const configSubjectsTranslation = translationFactory(
  'these configs',
  'these configs',
  'queste configurazioni',
  'estos configs',
  'ces configs',
  'diesen Konfigurationen'
)
const inspectionTypeTranslation = translationFactory(
  'inspection type',
  'inspection type',
  'tipo di ispezione',
  'el tipo de inspección',
  "type d'inspection",
  'Inspektionstyp'
)
const inspectionTypesTranslation = translationFactory(
  'inspection types',
  'inspection types',
  'tipologia di ispezioni',
  'tipos de inspecciones',
  "types d'inspection",
  'Kontrolltyp(en)'
)

export enum MENU_OPTION_KEY {
  DELETE = 'DELETE',
  COPY = 'COPY',
  EDIT = 'EDIT',
  CLONE_BELOW = 'CLONE_BELOW',
  EXPORT_ALL_JSON = 'EXPORT_ALL_JSON',
  EXPORT_CONFIG_JSON = 'EXPORT_CONFIG_JSON',
  EXPORT_ALL_FULL = 'EXPORT_ALL_FULL',
  EXPORT_ALL_INSTALL = 'EXPORT_ALL_INSTALL',
  EXPORT_CONFIG_FULL = 'EXPORT_CONFIG_FULL',
  EXPORT_CONFIG_INSTALL = 'EXPORT_CONFIG_INSTALL',
  PASTE = 'PASTE',
  IMPORT_LEGACY_CONFIG = 'IMPORT_LEGACY',
  ADD_TO_LIBRARY = 'ADD_TO_LIBRARY',
  ADD_FROM_LIBRARY = 'ADD_FROM_LIBRARY',
  CREATE_NEW = 'CREATE_NEW',
  IMPORT_JSON = 'IMPORT_JSON',
}

export enum CANCEL_OPTIONS {
  NO = 'No',
  CANCEL = 'Cancel',
}

export enum ACCEPT_OPTIONS {
  YES = 'Yes',
  APPLY = 'Apply',
  OK = 'OK',
  APPLY_ALL = 'Apply All',
  APPLY_JUST_THIS_ONE = 'Apply Just This One',
  APPLY_TO_ONE = 'Apply to one',
  APPLY_TO_ALL = 'Apply to all',
}

export enum NODE_LEVELS {
  ALL_CONFIG_LEVEL = -1,
  CONFIG_LEVEL = 0,
  ZONE_LEVEL = 1,
  COMPONENT_LEVEL = 2,
  CONDITION_LEVEL = 3,
}

export enum CHIP_TYPE {
  STRING = 'string',
  NUMBER = 'number',
}

export const MAX_ASSET_TYPE = 3
export const MIN_ASSET_TYPE = 1
export const MAX_ASSET_VIEW_GRID = 2
export const MIN_ASSET_VIEW_GRID = 2
export const MIN_ASSET_VIEW_LOCATION_LENGTH = 2
export const VALID_ASSET_VIEW_GRID_LENGTH = 2
export const NAME_REGEX = /^[A-Za-z0-9 '\%\&\(\)\_\-\+\=\|\:\/\,\.\[\]\<\>\#]*$/
// angular escape work not match with this regex so change to regex string to fix it
/* eslint-disable */
export const NUMBER_REGEX = '^-?[0-9]\\d*$'
export const POSITIVE_NUMBER_REGEX = '[0-9]*'

export const ERROR_MESSAGES = {
  NAME_REQUIRED: 'Name must be required.',
  GENERIC_FIELD_REQUIRED: 'This field is required.',
  INVALID_CHARACTERS: 'Only letters, numbers, and some special characters are allowed.',
  ASSET_TYPES_REQUIRED: 'Asset type(s) must be required.',
  ASSET_TYPES_DUPLICATED: 'Asset type(s) already exists ',
  ASSET_MAP_VIEW_COORDINATES_REQUIRED: 'Asset map view grid size must be required.',
  ASSET_MAP_VIEW_COORDINATES_LENGTH: 'Asset map view coordinates must be 2 coordinates.',
  INVALID_ASSET_MAP_VIEW_COORDINATES: 'Only numbers greater than zero are allowed.',
  INVALID_NUMBER: 'Only positive integers are allowed.',
  TAG_TYPE_REQUIRED: 'Tag type is required.',
  TAG_TYPE_LIMIT: 'Tag type is less than a milion.',
  TAG_NUMBER_REQUIRED: 'Tag number is required.',
  TAG_NUMBER_LIMIT: 'Tag number is less than a hundred.',
  INSPECTION_TYPE_REQUIRED: 'Inspection Types(s) is required.',
  ASSET_VIEW_LOCATION_REQUIRED: 'Asset view locations are required.',
  ASSET_VIEW_LOCATION_LENGTH: 'Asset view locations must be 2 coordinates.',
  INVALID_POSITIVE_INTEGER: 'Only positive integers are allowed.',
  NUMBER_LIMIT_8BIT: 'The value must in range [0-255].',
  ERROR_ASSET_BACKGROUND: 'No image currently available',
  ERROR_ASSET_CONFIGURATION: 'No configuration data currently available for this company',
  NO_TEMPLATE_LIBRARY_DATA:
    'No template library data currently available for this template type',
  DUPLICATE_PIECE_NAME: 'Duplicate names not allowed',
  NO_PREMADE_FIELD_DATA: 'No premade field data currently available',
  DUPLICATE_INSPECTION_TYPE_NAME: 'Duplicate names not allowed',
  ASSET_TYPE_REQUIRED_MESSAGE: 'Asset type must be required.',
  ASSET_TYPE_DUPLICATED_MESSAGE: `Asset type cannot be "Vehicle" or "Standard".`,
}

export enum NODE_LEVELS_INDEX {
  CONFIG_LEVEL = 1,
  ZONE_LEVEL = 2,
  COMPONENT_LEVEL = 3,
  CONDITION_LEVEL = 4,
}

export enum CONFIRM_DIALOG_TYPE {
  REFECLT_DIALOG = 1,
  CONFIRM_DIALOG = 2,
}

export enum CONFIRM_REFLECT_ACTION {
  CANCEL = 0,
  APPLY_ALL = 1,
  APPLY_JUST_THIS_ONE = 2,
}

export enum ERROR_STATUS_CODES {
  NO_PERMISSION = 401,
  NOT_FOUND_ERROR = 404,
  SERVER_ERROR = 500,
  MAINTENANCE_ERROR = 503,
}

export const defaultErrorConstants = {
  ERROR_HEADER: 'ERROR_DEFAULT.HEADER',
  ERROR_SUBHEADER: 'ERROR_DEFAULT.SUBHEADER',
  ERROR_CONTACT_US: 'ERROR_DEFAULT.CONTACT_US',
  ERROR_ACTION_BUTTON_TEXT: 'RETURN_HOME',
  SUPPORT_PHONE: '877-843-347',
  SUPPORT_EMAIL: 'customercare@zonarsystems.com',
}

export const httpNoPermissionConstants = {
  ERROR_HEADER: 'ERROR_NO_PERMISSION.HEADER',
  ERROR_SUBHEADER: 'ERROR_NO_PERMISSION.SUBHEADER',
  ERROR_CONTACT_US: 'ERROR_NO_PERMISSION.CONTACT_US',
  ERROR_ACTION_BUTTON_TEXT: 'RETURN_HOME',
}

export const httpPageNotFoundConstants = {
  ERROR_HEADER: 'ERROR_PAGE_NOT_FOUND.HEADER',
  ERROR_SUBHEADER: 'ERROR_PAGE_NOT_FOUND.SUBHEADER',
  ERROR_CONTACT_US: '',
  ERROR_ACTION_BUTTON_TEXT: 'RETURN_HOME',
}

export const httpServerErrorConstants = {
  ERROR_HEADER: 'ERROR_DEFAULT.HEADER',
  ERROR_SUBHEADER: 'ERROR_DEFAULT.SUBHEADER',
  ERROR_CONTACT_US: 'ERROR_DEFAULT.CONTACT_US',
  ERROR_ACTION_BUTTON_TEXT: 'RETURN_HOME',
}

export const httpMaintenanceConstants = {
  ERROR_HEADER: 'ERROR_MAINTENANCE.HEADER',
  ERROR_SUBHEADER: 'ERROR_MAINTENANCE.SUBHEADER',
  ERROR_CONTACT_US: 'ERROR_MAINTENANCE.CONTACT_US',
  ERROR_ACTION_BUTTON_TEXT: 'TRY_AGAIN',
}

export enum BUTTON_TYPES_KEY {
  UNDO = 'UNDO',
  SAVE = 'SAVE',
  TEST = 'TEST',
  DEPLOY = 'DEPLOY',
}

export enum INSTALLER_EXPORT_TYPE {
  INSTALLER_EXPORT_LARGE = 0,
  INSTALLER_EXPORT_SHORT = 1,
}

export enum INSTALLER_EXPORT_NAMES {
  FULL_DETAIL = 'Full Export Config Details',
  ZONES_DETAIL = 'Zone installation locations',
}

export enum EXPANDED_STATES_OPTIONS {
  CLONE_BELOW = 0,
  DELETE = 1,
  DRAG_DROP = 2,
  COPY_PASTE = 3,
  IMPORT_LEGACY = 4,
}

export enum GRID_VIEW_POSITION_INDEX {
  POSITION_X = 0,
  POSITION_Y = 1,
}

export const MIN_NUMBER_ZONES = 1
export const MAX_TOTAL_STANDARD_ZONES = 15
export const MIN_NUMBER_COMPONENTS = 1
export const MAX_NUMBER_COMPONENTS = 50
export const MIN_NUMBER_CONDITIONS = 1
export const MAX_NUMBER_CONDITIONS = 50

export const STANDARD_ZONE_TAG_TYPES = ['zone', 'dolly', 'trailer']

export enum CONFIGURATION_ERROR_TITLES {
  LIMIT_NUMBER_OF_ZONES = 'ZONE_MINIMUM_ERROR_TITLE',
  TOTAL_OF_STANDARD_ZONES = 'ZONE_MAXIMUM_ERROR_TITLE',
  LIMIT_NUMBER_OF_COMPONENTS = 'COMPONENTS_RANGE_ERROR_TITLE',
  LIMIT_NUMBER_OF_CONDITIONS = 'CONDITIONS_RANGE_ERROR_TITLE',
  REQUIRED_FIELDS = 'CONFIG_REQUIRED_ERROR_TITLE',
  ITEM_NUMBER = 'CONFIG_OUT_OF_RANGE_ERROR_TITLE',
  INVALID_NUMBER_VALUE = 'CONFIG_VALID_NUMBER_ERROR_TITLE',
  INVALID_FORMAT = 'CONFIG_VALID_FORMAT_ERROR_TITLE',
  DUPLICATE_ASSET_TYPE = 'DUPLICATE_ASSET_TYPE_TITLE',
}

export enum CONFIGURATION_ERROR_TYPES {
  LIMIT_NUMBER_OF_ZONES = 'LIMIT_NUMBER_OF_ZONES',
  TOTAL_OF_STANDARD_ZONES = 'TOTAL_OF_STANDARD_ZONES',
  LIMIT_NUMBER_OF_COMPONENTS = 'LIMIT_NUMBER_OF_COMPONENTS',
  LIMIT_NUMBER_OF_CONDITIONS = 'LIMIT_NUMBER_OF_CONDITIONS',
  REQUIRED_FIELDS = 'REQUIRED_FIELDS',
  ITEM_NUMBER = 'ITEM_NUMBER',
  INVALID_NUMBER_VALUE = 'INVALID_NUMBER_VALUE',
  INVALID_FORMAT = 'INVALID_FORMAT',
  DUPLICATE_ASSET_TYPE = 'DUPLICATE_ASSET_TYPE',
}

export const CONFIGURATION_ERROR_MESSAGES = {
  LIMIT_NUMBER_OF_ZONES: 'ZONE_MINIMUM_ERROR_MSG',
  TOTAL_OF_STANDARD_ZONES: `ZONE_MAXIMUM_ERROR_MSG`,
  LIMIT_NUMBER_OF_COMPONENTS: `COMPONENTS_RANGE_ERROR_MSG`,
  LIMIT_NUMBER_OF_CONDITIONS: `CONDITIONS_RANGE_ERROR_MSG`,
  REQUIRED_FIELDS: `CONFIG_REQUIRED_ERROR_MSG`,
  ITEM_NUMBER: `CONFIG_OUT_OF_RANGE_ERROR_MSG`,
  INVALID_NUMBER_VALUE: `CONFIG_VALID_NUMBER_ERROR_MSG`,
  INVALID_FORMAT: `CONFIG_VALID_FORMAT_ERROR_MSG`,
  DUPLICATE_ASSET_TYPE: 'DUPLICATE_ASSET_TYPE',
}

export enum TAG_TYPE_OPTION_VALUE {
  ZONE = 'zone',
  TRAILER = 'trailer',
  DOLLY = 'dolly',
}

const capitalizeFirstLetter = ([first, ...rest]: string) =>
  first.toUpperCase() + rest.join('')
export const TAG_TYPE_OPTIONS = [
  {
    id: TAG_TYPE_OPTION_VALUE.ZONE,
    displayName: 'TAG_TYPE_OPTIONS.ZONE',
  },
  {
    id: TAG_TYPE_OPTION_VALUE.TRAILER,
    displayName: 'TAG_TYPE_OPTIONS.TRAILER',
  },
  {
    id: TAG_TYPE_OPTION_VALUE.DOLLY,
    displayName: 'TAG_TYPE_OPTIONS.DOLLY',
  },
]

export const DEFAULT_WIDTH_SVG = 276
export enum PLACEHOLDER {
  ZONE_GROUP = 'Choose an option',
}

export enum NOTIFICATION_TYPES {
  FAILED = 0,
  SUCCESS = 1,
}

export const LIMIT_CLIENT_ERROR_STATUS_CODE = 499
export enum CONFIRM_DIALOG_TYPE {
  TEST = 'TEST',
  PUBLISH = 'PUBLISH',
}

export const REMOVE_CURRENT = 'remove-current'

export enum TEMPLATE_LIBRARY_SORT_OPTIONS {
  SORT_BY_DATE_ADDED = 'SORT_BY_DATE_ADDED',
  SORT_BY_ASCENDING_ALPHABET = 'SORT_BY_ASCENDING_ALPHABET',
  SORT_BY_DESCENDING_ALPHABET = 'SORT_BY_DESCENDING_ALPHABET',
}

export const TEMPLATE_LIBRARY_NAMEKEY_LIST = [
  {
    id: 'configuration',
    nameKey: 'zoneLayoutLanguageKey',
  },
  {
    id: 'zone',
    nameKey: 'tagNameKey',
  },
  {
    id: 'component',
    nameKey: 'componentNameKey',
  },
  {
    id: 'condition',
    nameKey: 'name',
  },
]
export enum TEMPLATE_TYPES_VALUE {
  CONFIGURATION = 'configuration',
  ZONE = 'zone',
  COMPONENT = 'component',
  CONDITION = 'condition',
  FORM = 'form',
  INSPECTION_TYPE = 'inspectionType',
}

export const TEMPLATE_TYPE_OPTIONS = [
  TEMPLATE_TYPES_VALUE.CONFIGURATION,
  TEMPLATE_TYPES_VALUE.ZONE,
  TEMPLATE_TYPES_VALUE.COMPONENT,
  TEMPLATE_TYPES_VALUE.CONDITION,
]

export enum TEMPLATE_NAME_KEY {
  CONFIGURATION = 'zoneLayoutLanguageKey',
  ZONE = 'tagNameKey',
  COMPONENT = 'componentNameKey',
  CONDITION = 'name',
}

export enum TEMPLATE_PIECE_KEY {
  CONFIGURATION = 'configZones',
  ZONE = 'tagComponents',
  COMPONENT = 'suggestedConditions',
  CONDITION = '',
}

export const TEMPLATE_NAME_KEY_LIST = {
  [TEMPLATE_TYPES_VALUE.CONFIGURATION]: TEMPLATE_NAME_KEY.CONFIGURATION,
  [TEMPLATE_TYPES_VALUE.ZONE]: TEMPLATE_NAME_KEY.ZONE,
  [TEMPLATE_TYPES_VALUE.COMPONENT]: TEMPLATE_NAME_KEY.COMPONENT,
  [TEMPLATE_TYPES_VALUE.CONDITION]: TEMPLATE_NAME_KEY.CONDITION,
}

export const TemplateLibraryKey = {
  [TEMPLATE_TYPES_VALUE.CONFIGURATION]: {
    pieceKey: TEMPLATE_PIECE_KEY.CONFIGURATION,
    nameKey: TEMPLATE_NAME_KEY.CONFIGURATION,
    nextKey: TEMPLATE_TYPES_VALUE.ZONE,
    startIndex: '0',
  },
  [TEMPLATE_TYPES_VALUE.ZONE]: {
    pieceKey: TEMPLATE_PIECE_KEY.ZONE,
    nameKey: TEMPLATE_NAME_KEY.ZONE,
    nextKey: TEMPLATE_TYPES_VALUE.COMPONENT,
    startIndex: '0/0',
  },
  [TEMPLATE_TYPES_VALUE.COMPONENT]: {
    pieceKey: TEMPLATE_PIECE_KEY.COMPONENT,
    nameKey: TEMPLATE_NAME_KEY.COMPONENT,
    nextKey: TEMPLATE_TYPES_VALUE.CONDITION,
    startIndex: '0/0/0',
  },
  [TEMPLATE_TYPES_VALUE.CONDITION]: {
    pieceKey: TEMPLATE_PIECE_KEY.CONDITION,
    nameKey: TEMPLATE_NAME_KEY.CONDITION,
    nextKey: null,
    startIndex: '0/0/0/0',
  },
}

export const TEMPLATE_TYPE_BY_NODE_LEVEL = {
  [NODE_LEVELS.CONFIG_LEVEL]: TEMPLATE_TYPES_VALUE.CONFIGURATION,
  [NODE_LEVELS.ZONE_LEVEL]: TEMPLATE_TYPES_VALUE.ZONE,
  [NODE_LEVELS.COMPONENT_LEVEL]: TEMPLATE_TYPES_VALUE.COMPONENT,
  [NODE_LEVELS.CONDITION_LEVEL]: TEMPLATE_TYPES_VALUE.CONDITION,
}

export const MIN_NUMBER_OF_CHOICES = 2

export enum INSPECTION_TYPE_MODAL_TYPE {
  EDIT = 'Edit',
  CREATE = 'Create',
}

export enum FORM_LIBRARY_DROP_TYPE {
  CUSTOM_FIELD = 'Custom field',
  PREMADE_FIELD = 'Premade field',
}

export const REGION_BRAND = {
  NA: 'default',
  EU: 'conti',
}

export const Constants: Record<string, any> = {
  snackbar: {
    duration: 3000,
  },
}

export enum LANGUAGE_CONFIG_TYPES {
  CONFIGURATION = 'zoneLayout',
  ZONE = 'configZone',
  COMPONENT = 'component',
  CONDITION = 'condition',
  INSPECTION_TYPE = 'inspectionType',
  FORM_DATA = 'formData',
}

export const CONFIG_TYPE_BY_NODE_LEVEL = {
  [NODE_LEVELS.CONFIG_LEVEL]: LANGUAGE_CONFIG_TYPES.CONFIGURATION,
  [NODE_LEVELS.ZONE_LEVEL]: LANGUAGE_CONFIG_TYPES.ZONE,
  [NODE_LEVELS.COMPONENT_LEVEL]: LANGUAGE_CONFIG_TYPES.COMPONENT,
  [NODE_LEVELS.CONDITION_LEVEL]: LANGUAGE_CONFIG_TYPES.CONDITION,
}

/** ECT2-806: We will temporarily remove view grid input on config edit dialog by flag.
 * It has ability to re-display in the future
 */
export const IS_GRID_VIEW_HIDDEN = true

const renderMessage = (
  message: string,
  configName: string,
  configLength: number,
  configIndex: number,
  languageCode: string
) => {
  if (configLength === 1) {
    return `${message} [${configName}]`
  } else {
    if (configIndex === configLength - 1 && configIndex > 0) {
      return `${message} ${concatStrTranslation[languageCode]} ${configName}]`
    } else {
      return `${message}${configIndex === 0 ? ' [' : ', '}${configName}`
    }
  }
}

export const i18nParams = {
  FORMAT_PIECE_NAME: (pieceName) => ({ name: capitalizeFirstLetter(pieceName) }),

  CONFIGURATION_ERROR_DIALOG: (errorData: any[], languageCode: string) => {
    const joinedNames = errorData.join(` ${concatStrTranslation[languageCode]} `)
    const verb =
      errorData.length > 1
        ? ownsStrTranslation[languageCode]
        : ownStrTranslation[languageCode]

    return {
      names: joinedNames,
      verb,
    }
  },

  DELETE_INSPECTION_ERROR: (errorData: any[], languageCode: string) => {
    const names = errorData.reduce(
      (accumulator: string, name: string, index: number) =>
        renderMessage(accumulator, name, errorData.length, index, languageCode),
      ''
    )

    const subjects =
      errorData.length > 1
        ? configSubjectsTranslation[languageCode]
        : configSubjectTranslation[languageCode]

    return {
      names,
      subjects,
    }
  },
}

export const INSPECTION_TYPE_CATEGORY_LIST = [
  {
    name: 'USDOT_PRE_TRIP',
    description: 'USDOT_PRE_TRIP_DESCRIPTION',
    value: 'Pre-Trip (Compliance - USDOT)',
  },
  {
    name: 'USDOT_POST_TRIP',
    description: 'USDOT_POST_TRIP_DESCRIPTION',
    value: 'Post-Trip (Compliance - USDOT)',
  },
  {
    name: 'USDOT_DAILY',
    description: 'USDOT_DAILY_DESCRIPTION',
    value: 'Daily (Compliance - CDOT)',
  },
  { name: 'PRE_TRIP', description: 'PRE_TRIP_DESCRIPTION', value: 'Pre-Trip' },
  { name: 'POST_TRIP', description: 'POST_TRIP_DESCRIPTION', value: 'Post-Trip' },
  { name: 'CHILD_CHECK', description: 'CHILD_CHECK_DESCRIPTION', value: 'Child Check' },
  { name: 'TIME_CARD', description: 'TIME_CARD_DESCRIPTION', value: 'Time Card' },
  {
    name: 'LOAD_SECUREMENT',
    description: 'LOAD_SECUREMENT_DESCRIPTION',
    value: 'Load Securement',
  },
  { name: 'GENERAL', description: 'GENERAL_DESCRIPTION', value: 'General' },
]

export const LANGUAGE_IDS = {
  ENGLISH: 'en',
  ENGLISH_UK: 'en-GB',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  SPANISH: 'es',
}

const LANGUAGE_CODES = {
  ENGLISH: 'en-us',
  FRENCH: 'fr-fr',
  GERMAN: 'de-de',
  ITALIAN: 'it-it',
  SPANISH: 'es-es',
}

export const LANGUAGE_CODE_BY_LANGUAGE_ID = {
  [LANGUAGE_IDS.ENGLISH]: LANGUAGE_CODES.ENGLISH,
  [LANGUAGE_IDS.ENGLISH_UK]: LANGUAGE_CODES.ENGLISH,
  [LANGUAGE_IDS.FRENCH]: LANGUAGE_CODES.FRENCH,
  [LANGUAGE_IDS.GERMAN]: LANGUAGE_CODES.GERMAN,
  [LANGUAGE_IDS.ITALIAN]: LANGUAGE_CODES.ITALIAN,
  [LANGUAGE_IDS.SPANISH]: LANGUAGE_CODES.SPANISH,
}

export const DEFAULT_MAX_IMAGE_COUNT = 3
