import { CommonModule } from '@angular/common'
import { Injector, ModuleWithProviders, NgModule } from '@angular/core'
import { NGXS_PLUGINS } from '@ngxs/store'
import { TrackConfigurationEditHistoryPlugin } from './plugins/track-edit-history.plugin'
import { HistoryStackService } from './services/history-stack.service'

export function createTrackConfigurationEditHistoryPlugin(stackService: HistoryStackService, injector: Injector) {
  return new TrackConfigurationEditHistoryPlugin(stackService, injector)
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class NgxsHistoryPluginModule {
  static forRoot(config?: any): ModuleWithProviders<NgxsHistoryPluginModule> {
    return {
      ngModule: NgxsHistoryPluginModule,
      providers: [
        HistoryStackService,
        {
          provide: NGXS_PLUGINS,
          useFactory: createTrackConfigurationEditHistoryPlugin,
          deps: [HistoryStackService, Injector],
          multi: true,
        },
        // {
        //   provide: NGXS_LOGGER_PLUGIN_OPTIONS,
        //   useValue: config,
        // }
      ],
    }
  }
}
