import { unescape } from 'lodash'
import { validate as uuidValidate } from 'uuid'

import { legacyConfigurationId } from '../app.state'
import { EvirLanguageDictionary } from '../components/tree/models/tree.model'
import { LANGUAGE_CODE_BY_LANGUAGE_ID, LANGUAGE_IDS } from '../constants/internationalized-constants-en'

export function convertLangKeyToString(
  rawLangKey: string, languageDictionary: EvirLanguageDictionary,
  languageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
): string {
  if (!rawLangKey || !(languageDictionary && languageDictionary.languageStrings)) {
    return ''
  }

  const langKey = getLanguageKey(rawLangKey, languageDictionary)
  return languageDictionary.languageStrings[langKey]
    ? unescape(languageDictionary.languageStrings[langKey].translations[languageCode])
    : ''
}

export function getLanguageKey(rawLanguageKey: string, languageDictionary: EvirLanguageDictionary): string {
  if (uuidValidate(rawLanguageKey)) {
    return rawLanguageKey
  }

  if (!languageDictionary.legacyLanguageStrings) {
    return ''
  }

  return (languageDictionary.legacyLanguageStrings[rawLanguageKey] || {})[legacyConfigurationId] || ''
}

/**
 * Trim all the part of the string.
 *
 * @param rawString input string
 * @return trimed string that remove all whitespace at the head, tail, and keep only one whitespace on body of string
 * For example: '  User      Input   ' ---> 'User Input'
 */
export function trimAll(rawString: string): string {
  return (rawString || '').replace(/^\s+|\s+$|\s+(?=\s)/g, '')
}

/**
 * Transform string to format that have able to compare NOT case-sensitive. 
 * We will treat 'User Input' and ' User   Input   ' is the same string.
 *
 * @param rawString input string
 * @return transformed string
 * For example: ' User   Input   ' ---> 'user input'
 */
export function toIgnoreCase(rawString: string): string {
  return trimAll(rawString).toLowerCase()
}

/**
 * Compare strings to find out if they are equal, ignoring case differences:
 * @param target array or string used to compare
 * @param item string to used to compare
 * @returns Is thre differences or not
 * For example: ['A', 'B', 'C'] compare with 'c' should be treated as a equation 
 * For example: 'USER INPUT' compare with 'user input' should be treated as a equation 
 */
export function equalsIgnoreCase(target: string[] | string, item: string) {
  const ignoreCaseTarget = Array.isArray(target)
    ? target.map(value => toIgnoreCase(value))
    : toIgnoreCase(target)

  return ignoreCaseTarget.includes(toIgnoreCase(item))
}

/**
 * Check if target is Array type and have at least one item.
 * @param target array used to checked
 * @returns target is Array type or not
 */
export function isValidArray(target: any[]) {
  return Array.isArray(target) && target.length > 0
}

/**
 * Generate a coordinate for assetViewLocation based on assetViewGrid
 * @param index the order of coordinate in assetViewLocation
 * @param target the value of coordinate at index in assetViewLocation
 * @param limit the value of assetViewGrid
 * @returns a coordinate for assetViewLocation in range
 */
export function getLocationWithLimit(index: number, target: number, limit: number[]): number {
  return target >= limit[index] ? limit[index] - 1 : target
}
