import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  MAX_ASSET_TYPE,
  MAX_ASSET_VIEW_GRID,
  MIN_ASSET_TYPE,
  MIN_ASSET_VIEW_GRID,
  NAME_REGEX,
} from 'src/app/constants/internationalized-constants-en'
import { convertLangKeyToString } from 'src/app/utils/utils'
import { EvirLanguageDictionary, ZoneLayoutModel } from '../../../models/tree.model'
import { lastAssetTypeValidator } from '../validators/asset-type'
import { assetViewGridValidator } from '../validators/asset-view'
import { maxLengthValidator, minLengthValidator } from '../validators/number'
import { FormGroupGenerator, FormType } from './interface'

export class ConfigFormGroup implements FormGroupGenerator {
  generateFor: FormType = 'configuration'
  errorKey = {
    MIN: 'invalidMinLengthChips',
    MAX: 'invalidMaxLengthChips',
  }

  generateFormGroup(formBuilder: FormBuilder, forModal: boolean): FormGroup {
    let controlConfigs: Record<string, any> = {}

    controlConfigs = {
      ...controlConfigs,
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(100),
      ]),
    }

    controlConfigs = {
      ...controlConfigs,
      assetType: new FormControl('', [
        Validators.required,
        minLengthValidator(MIN_ASSET_TYPE, this.errorKey.MIN),
        maxLengthValidator(MAX_ASSET_TYPE, this.errorKey.MAX),
        lastAssetTypeValidator(),
      ]),
      assetViewGrid: new FormControl(''),
      assetViewId: new FormControl(''),
    }

    return formBuilder.group(controlConfigs, {
      validators: [assetViewGridValidator(MIN_ASSET_VIEW_GRID)]
    })
  }

  transformToValidateableFormat(rawConfig: ZoneLayoutModel, dictionary: EvirLanguageDictionary) {
    return {
      assetType: rawConfig.assetType,
      assetViewGrid: rawConfig.assetViewGrid,
      name: convertLangKeyToString(rawConfig.zoneLayoutLangKey, dictionary),
      assetViewId: rawConfig.assetViewId,
    }
  }
}
