<div class="dialog-wrapper">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start">
    <div class="dialog-title">{{'EDIT_FIELD_TITLE' | translate}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close" mat-dialog-close>close</mat-icon>
  </div>

  <mat-dialog-content class="node-edit-content">
    <ng-container *ngTemplateOutlet="formSection; context: { $implicit: editForm }"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <ng-container *ngTemplateOutlet="actionSection"></ng-container>
  </mat-dialog-actions>
</div>

<ng-template #formSection let-form>
  <form class="inspection-type-row-edit-form" fxLayout="column" gdAlignColumns="start stretch" [formGroup]="form">

    <mat-form-field appearance="outline">
      <mat-label>{{'LABEL_LABEL' | translate}}</mat-label>
      <input matInput class="field-name" placeholder="{{'NAME_PLACEHOLDER' | translate}}" maxlength="20" formControlName="label"
        [errorStateMatcher]="matcher" />
      <mat-error *ngIf="editForm.controls.label.hasError('required')">
        {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
      </mat-error>
      <mat-error *ngIf="editForm.controls.label.hasError('pattern')">
        {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'HELPER_TEXT_LABEL' | translate}}</mat-label>
      <input matInput class="field-name" maxlength="40" formControlName="helper" [errorStateMatcher]="matcher" />
      <mat-error *ngIf="editForm.controls.helper.hasError('required')">
        {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
      </mat-error>
      <mat-error *ngIf="editForm.controls.helper.hasError('pattern')">
        {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'FIELD_TYPE_LABEL' | translate}}</mat-label>
      <mat-select formControlName="inspectionDetailType">
        <mat-option *ngFor="let option of inspectionDetailTypeOptions" [value]="option.value">{{option.displayName}}
          <span class="decimal-value" *ngIf="option.subName">({{option.subName}})</span>
        </mat-option>
        <mat-select-trigger>
          {{ getInspectionDetailTypeDisplayValue()}} <span class="decimal-value"
            *ngIf="editForm.controls.inspectionDetailType.value === 'float'">({{getInspectionDetailTypeSubName()}})</span>
        </mat-select-trigger>
      </mat-select>
    </mat-form-field>

    <div class="sub-form" fxLayout="column">
      <ng-container [ngSwitch]="editForm.value.inspectionDetailType">

        <ng-container *ngSwitchCase="'text'">
          <app-inspection-row-text formControlName="text"></app-inspection-row-text>
        </ng-container>

        <ng-container *ngSwitchCase="'int'">
          <app-inspection-row-numberic formControlName="int"></app-inspection-row-numberic>
        </ng-container>
        <ng-container *ngSwitchCase="'float'">
          <app-inspection-row-numberic formControlName="float" [isDecimal]="true"></app-inspection-row-numberic>
        </ng-container>

        <p *ngSwitchDefault>
          TBD
        </p>
      </ng-container>
    </div>
  </form>
</ng-template>

<ng-template #actionSection>
  <button mat-button class="primary-button" mat-dialog-close>{{'CANCEL' | translate}}</button>
  <button mat-button class="special-button" [disabled]="editForm.invalid"
    (click)="handleApply()">{{'APPLY' | translate}}</button>
</ng-template>
