<div class="dialog-wrapper" data-testid="dialog-wrapper">
  <div mat-dialog-title>
    <div class="dialog-title">{{inspectionTypeEditTitle}}</div>
    <mat-icon class="close-icon"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form [formGroup]="inspectionTypeEditForm">
      <mat-form-field class="inspection-type-label-field"
        appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'LABEL_LABEL' | translate}}</mat-label>
        <input matInput
          placeholder="{{'LABEL_LABEL' | translate}}"
          formControlName="label"
          maxlength="32"
          [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="inspectionTypeEditForm.controls.label.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="inspectionTypeEditForm.controls.label.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="inspectionTypeEditForm.controls.label.hasError('duplicateInspectionTypeName')">
          {{ 'NAME_DUPLICATED_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="inspection-type-description-field"
        appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'DESCRIPTION_LABEL' | translate}}</mat-label>
        <textarea matInput
          placeholder="{{'DESCRIPTION_LABEL' | translate}}"
          formControlName="description"
          maxlength="128"
          [errorStateMatcher]="matcher"></textarea>
        <mat-hint>{{ 'DESCRIPTION_MAXIMUM_CHARS' | translate: { number: 128 } }}</mat-hint>
        <mat-error *ngIf="inspectionTypeEditForm.controls.description.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inspectionTypeEditForm.controls.description.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
      <div class="inspection-type-category-field">
        <mat-form-field appearance="outline">
          <mat-label>{{'INSPECTION_TYPE' | translate}}</mat-label>
          <mat-select focused="false" [required]="true"
            [placeholder]="('INSPECTION_TYPE' | translate)"
            [formControl]="inspectionTypeEditForm.controls.category"
            [errorStateMatcher]="matcher">
            <mat-option *ngFor="let option of categoryOptions" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inspectionTypeEditForm.controls.category.hasError('required')">
            {{'FIELD_REQUIRED_ERROR_MSG' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-icon class="info-description-icon" (click)="openCategoryDescriptionDialog()">info</mat-icon>
      </div>
      <div class="inspection-type-zoneless-checkbox" *ngIf="!editMode || data.inspectionType.zoneless">
        <mat-checkbox [disableRipple]="true" [disabled]="editMode" data-testid="zoneless-checkbox"
          formControlName="zoneless">
          {{'ZONELESS_CHECKBOX_NAME' | translate}}
        </mat-checkbox>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!inspectionTypeEditForm.valid"
      (click)="handleApply()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</div>
