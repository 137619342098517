import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { AuthConfig, AuthHttpInterceptor, AuthModule, HttpInterceptorRouteConfig } from '@auth0/auth0-angular'
import { PermissionsService } from '@zonar-ui/auth'

import { environment } from 'src/environments/environment'
import { permissions } from 'src/environments/shared'

// Any request URL match one of this item will be intercepted by
// Auth0 module, mostly just to register the Bearer token
const interceptUrls = [
  'https://api-dev.zonarsystems.net/*',
  'https://api-qa.zonarsystems.net/*',
  'https://api.zonarsystems.net/*',
  'https://zonar-eu-nonprod-qa.apigee.net/*',
  'https://company-api.zonarsystems.net/*',
  'https://ib-api.production.eu.zonarsystems.net/*',
  'https://evir-api.production.eu.zonarsystems.net/*',
  'https://evir-api.staging.eu.zonarsystems.net/*',
  'https://evir-api.qa.zonarsystems.net/*',
  'https://evir-api.qa.eu.zonarsystems.net/*',
  'https://api.dev.continental-mobility-services.com/*',
  'https://api.qa.continental-mobility-services.com/*',
  'https://api.production.continental-mobility-services.com/*',
  'https://ib-api.qa.continental-mobility-services.com/*',
  'https://ib-api.production.continental-mobility-services.com/*'
]

const interceptRoutes: HttpInterceptorRouteConfig[] = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].reduce((acc, currentMethod) => {
  return [
    ...acc,
    ...interceptUrls.map(uri => ({
      uri: uri,
      httpMethod: currentMethod,
    })),
  ]
}, [])

const authConfig: AuthConfig = {

  clientId: environment.clientID,
  domain: environment.domain,
  audience: environment.audience,
  redirectUri: window.location.origin,
  scope: 'openid profile email offline_access',

  useRefreshTokens: true,
  maxAge: 36000,

  cacheLocation: 'localstorage',

  httpInterceptor: {
    allowedList: interceptRoutes,
  }
}

export function mapAppEnvToEntityEnv(appEnvironment: string, region: string): string {
  const mapper = {
    NA: {
      dev: 'development',
      qa: 'qa',
      staging: 'qa',
      prod: 'production',
    },
    EU: {
      dev: 'qa',
      qa: 'qa',
      staging: 'qa',
      prod: 'production',
    }
  }

  return mapper[region][appEnvironment]
}

/**
 * Wrap module including the default Auth0 AuthModule.forRoot(...) alone
 * with other providers required for pattern-library. Usage: just invoke
 * EctAuthModule.forRoot() to have the AuthModule ready with providers.
 */
@NgModule({
  declarations: [],
})
export class EctAuthModule {

  /**
   * Create a module of AuthModule, with neccessary providers
   */
  static forRoot(): ModuleWithProviders<AuthModule> {
    const returnModule = AuthModule.forRoot(authConfig)
    returnModule.providers = [

      // Providers created by Auth0Module.forRoot
      // Should include auth0 config, auth0 client
      ...returnModule.providers,

      // Url tokens
      { provide: 'appUrl', useValue: environment.appUrl },
      { provide: 'redirectUrl', useValue: environment.redirectUrl },
      { provide: 'logoutReturnToUrl', useValue: window.location.origin },
      { provide: 'p', useValue: window.location.origin },

      // Use to get the correct user profile, each application in Zonar
      // has a unique ID assigned to it
      { provide: 'applicationId', useValue: environment.applicationId },


      // Token used for permission service
      { provide: 'routePermissions', useValue: permissions.routes },
      { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
      { provide: 'defaultPermPrefix', useValue: environment.defaultPermPrefix },
      { provide: 'defaultZonarRole', useValue: environment.defaultZonarRole },

      // Utility interceptor to support auto assigned Bearer token to the requests
      // match interceptUrls list
      { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },

      { provide: 'appEnvironment', useValue: environment.environment },

      // This value should be used by zonar-ui/auth to determine the entity endpoint.
      // For ex: development -> https://<endpoint>/development/...
      { provide: 'environment', useFactory: mapAppEnvToEntityEnv, deps: ['appEnvironment', 'region'] },
      { provide: 'region', useValue: environment.region },
    ]

    return returnModule
  }
}
