import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, noop, Observable, Subject } from 'rxjs'
import { filter, startWith, take, takeUntil, tap } from 'rxjs/operators'

import {
  GetConfiguration,
  GetLegacyAccountCode,
  PublishConfiguration,
  SaveConfiguration,
  SetDraftStatus,
  SetWorkInProgressStatus,
  DeleteBorderDataConfigurationImport,
  SetConfigurationChangedStatus,
  TestConfiguration,
  GetAssetViewList,
  GetTemplateLibraryPieces,
  GetBlankPieces,
  CompanyContext,
} from 'src/app/app.state'
import { DialogComponent } from 'src/app/components/dialog/dialog.component'
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component'
import { AppState } from './../../app.state'
import { CompanyModel, TabLink } from './model'
import {
  internationalizedValues,
  NOTIFICATION_TYPES,
  LIMIT_CLIENT_ERROR_STATUS_CODE,
  CONFIRM_DIALOG_TYPE,
  Constants,
} from '../../constants/internationalized-constants-en'
import {
  ConfigurationErrorModel,
  EvirLanguageDictionary,
  ZoneLayoutModel,
} from 'src/app/components/tree/models/tree.model'
import {
  DisableUndoFeature,
  UndoConfigurationChange,
} from 'src/app/modules/ngxs-history-plugin/actions'
import { DataConfigurationValidationService } from 'src/app/components/tree/services/data-configuration-validation/data-configuration-validation.service'
import { NotificationSnackbarService } from 'src/app/components/notification-snackbar/services/notification-snackbar.service'

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  @Select(AppState.getDataConfiguration) dataConfiguration$: Observable<ZoneLayoutModel[]>
  @Select(AppState.getCompanyContext) companyContext$: Observable<CompanyContext>
  @Select(AppState.getLanguageDictionary)
  dataLanguageDictionary$: Observable<EvirLanguageDictionary>

  tabLinks: TabLink[] = [
    { label: 'CONFIGURATIONS_TITLE', link: '/companies/configurations' },
    { label: 'INSPECTIONS_TYPE_TITLE', link: '/companies/inspection-types' },
  ]
  activeTabIndex: number
  options: CompanyModel[]
  selectedCompany: CompanyModel
  companyTitle = internationalizedValues.COMPANY_TITLE
  placeholder = internationalizedValues.FILTER_PLACEHOLDER
  isUpdatingConfiguration: boolean = false
  isSaving: boolean = false
  isTesting: boolean = false
  isDeploying: boolean = false
  isValidatedConfiguration: boolean = false

  configurations: ZoneLayoutModel[]
  languageDictionary: EvirLanguageDictionary

  currentCompanyId = ''
  indexTabLinks: number

  CONFIRM_DIALOG_TYPE = CONFIRM_DIALOG_TYPE

  public shouldLoading$: Observable<any[]>
  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private validationService: DataConfigurationValidationService,
    private snackbarService: NotificationSnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activeTabIndex = 0

    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        tap((params) => {
          this.currentCompanyId = params.get('companyId')
          this.tabLinks = this.generateTabLinks(this.currentCompanyId)
          if (this.currentCompanyId) {
            this.store.dispatch(new GetLegacyAccountCode(this.currentCompanyId))
            this.shouldLoading$ = this.store.dispatch([
              new GetConfiguration(this.currentCompanyId),
              new GetTemplateLibraryPieces(),
              new GetBlankPieces(),
            ])
            this.store.dispatch(new GetAssetViewList(this.currentCompanyId))
          }
        })
      )
      .subscribe()

    combineLatest([this.dataConfiguration$, this.dataLanguageDictionary$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([dataConfiguration, dataLanguageDictionary]) => {
        this.configurations = dataConfiguration
        this.languageDictionary = dataLanguageDictionary
      })
  }

  generateTabLinks(companyId: string): TabLink[] {
    this.tabLinks = companyId
      ? [
          {
            label: 'CONFIGURATIONS_TITLE',
            link: `/companies/${companyId}/configurations`,
          },
          {
            label: 'INSPECTIONS_TYPE_TITLE',
            link: `/companies/${companyId}/inspection-types`,
          },
        ]
      : this.tabLinks

    return this.tabLinks.map((tabLink) => ({
      ...tabLink,
      label: this.translateService.instant(tabLink.label),
    }))
  }

  switchTab(event) {
    this.indexTabLinks = event
    // this.activeTabIndex = event
    // if (event === 0) {
    //   this.store.dispatch(new GetCompanies());
    // }
    if (event && event.link) {
      // this.router.navigate(event.link);
    }
  }

  handleUpdateConfiguration(isDeploy: boolean) {
    const updateConfigurationAction = isDeploy ? PublishConfiguration : SaveConfiguration

    this.store
      .dispatch(new updateConfigurationAction())
      .pipe(
        startWith(
          (this.isUpdatingConfiguration = true),
          (this.isSaving = !isDeploy),
          (this.isDeploying = isDeploy)
        )
      )
      .subscribe(
        (value) => {
          if (isDeploy) {
            console.log('Published configuration:', value)
          } else {
            console.log('Saved configuration:', value)
          }
        },
        (error) => {
          const displayMessage = isDeploy
            ? this.translateService.instant('CONFIG_DEPLOY_FAIL_MSG')
            : this.translateService.instant('CONFIG_SAVE_FAIL_MSG')
          const detailedError = this.getDetailedError(error)
          this.isUpdatingConfiguration = false
          if (!isDeploy) {
            this.isSaving = false
          }
          this.isDeploying = false
          this.snackbarService.openNotificationSnackbar(
            displayMessage,
            NOTIFICATION_TYPES.FAILED,
            detailedError
          )
        },
        () => {
          const displayMessage = isDeploy
            ? this.translateService.instant('CONFIG_DEPLOY_SUCCESS_MSG')
            : this.translateService.instant('CONFIG_SAVE_SUCCESS_MSG')
          this.isUpdatingConfiguration = false
          if (!isDeploy) {
            this.isSaving = false
          }
          this.isDeploying = false
          this.setStatusAfterUpdateConfiguration(isDeploy)
          this.snackbarService.openNotificationSnackbar(
            displayMessage,
            NOTIFICATION_TYPES.SUCCESS,
            null,
            Constants.snackbar.duration
          )
        }
      )
  }

  openConfirmDialog(dialogType: CONFIRM_DIALOG_TYPE) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => {
          dialogRef.close()
        },
        onAccept: () => {
          if (dialogType === CONFIRM_DIALOG_TYPE.PUBLISH) {
            this.handleUpdateConfiguration(true)
          }
          if (dialogType === CONFIRM_DIALOG_TYPE.TEST) {
            this.handleTestAction()
          }
          dialogRef.close()
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('OK'),
        title:
          dialogType === CONFIRM_DIALOG_TYPE.PUBLISH
            ? this.translateService.instant('CONFIG_DEPLOY_CONFIRM_MSG')
            : this.translateService.instant('CONFIG_TEST_CONFIRM_MSG'),
        message: '',
      },
    })
  }

  handleTestAction() {
    this.store
      .dispatch(new TestConfiguration())
      .pipe(startWith((this.isUpdatingConfiguration = true), (this.isTesting = true)))
      .subscribe(
        (value) => {
          console.log('Tested configuration:', value)
        },
        (error) => {
          console.log('Error detail:', error)
          this.isUpdatingConfiguration = false
          this.isTesting = false
          this.snackbarService.openNotificationSnackbar(
            this.translateService.instant('CONFIG_TEST_FAIL_MSG'),
            NOTIFICATION_TYPES.FAILED
          )
        },
        () => {
          this.isUpdatingConfiguration = false
          this.isTesting = false
          this.setStatusAfterUpdateConfiguration(false)
          this.snackbarService.openNotificationSnackbar(
            this.translateService.instant('CONFIG_TEST_SUCCESS_MSG'),
            NOTIFICATION_TYPES.SUCCESS,
            null,
            Constants.snackbar.duration
          )
        }
      )
  }

  undoConfigurationChanged() {
    this.store.dispatch(new UndoConfigurationChange())
  }

  setStatusAfterUpdateConfiguration(isDeploy: boolean) {
    if (isDeploy) {
      this.store.dispatch(new SetWorkInProgressStatus(false))
      this.store.dispatch(new SetDraftStatus(false))
    } else {
      this.store.dispatch(new SetConfigurationChangedStatus(false))
      this.store.dispatch(new DisableUndoFeature())
      this.store.dispatch(new SetWorkInProgressStatus(true))
    }
  }

  validateDataConfigurationOnSave() {
    const dataConfigurationError: ConfigurationErrorModel[] =
      this.validationService.validateDataConfigurationError(
        this.configurations,
        this.languageDictionary
      )
    this.store.dispatch(new DeleteBorderDataConfigurationImport())
    if (dataConfigurationError && dataConfigurationError.length) {
      this.openConfigurationErrorDialog(dataConfigurationError)
    } else {
      this.handleUpdateConfiguration(false)
    }
  }

  openConfigurationErrorDialog(dataConfigurationError: ConfigurationErrorModel[]) {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        errors: dataConfigurationError,
      },
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-backdrop-showing',
      position: {
        top: '160px',
        right: '30px',
      },
    })
    this.isValidatedConfiguration = true
    const subscription = dialogRef.afterClosed().subscribe(() => {
      this.isValidatedConfiguration = false
      subscription.unsubscribe()
    })
  }

  getDetailedError(rawError: HttpErrorResponse): Record<string, string[]> {
    if (rawError.status > LIMIT_CLIENT_ERROR_STATUS_CODE) {
      return {
        message: [`${rawError.status} ${rawError.error.message}`],
      }
    }

    return rawError.error && rawError.error.text && rawError.error.text.errors
      ? rawError.error.text.errors
      : null
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
