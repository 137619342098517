import { Component, EventEmitter, Input, Output } from '@angular/core'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatChipInputEvent } from '@angular/material/chips'
import { cloneDeep } from 'lodash'

import { CHIP_TYPE, MAX_ASSET_TYPE, MIN_ASSET_TYPE } from 'src/app/constants/internationalized-constants-en'

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {

  @Input() chipTitle: string = 'Title'
  @Input() placeholder: string = 'Placeholder'
  @Input() maxChips: number = MAX_ASSET_TYPE
  @Input() minChips: number = MIN_ASSET_TYPE
  @Input() chipType: CHIP_TYPE = CHIP_TYPE.STRING
  @Input() chipList: string[] | number[] = []
  // angular escape work not match with this regex so change to regex string to fix it
  /* eslint-disable */
  @Input() regex: string | RegExp = "^[a-zA-Z0-9_ %&+=:,.\\/()\\[\\]|-]*$"
  @Input() chipsStatus: boolean = true
  @Input() maxLengthNumber: number = 2
  @Input() disabledChipList: boolean = false
  @Output() valueChange = new EventEmitter()
  @Output() inputStatusChange = new EventEmitter()

  CHIP_TYPE = CHIP_TYPE
  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  inputChipValid = true
  chipInputValue = ''

  readonly separatorKeysCodes: number[] = [COMMA, ENTER]

  constructor() { }

  add(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    if (input && input.validity && !input.validity.valid) {
      return
    }
    const list = cloneDeep(this.chipList)
    if (this.chipType === CHIP_TYPE.STRING && (value || '').trim()) {
      // Fix bug ECT2-233 
      if ((list as string[]).includes(value.trim())) {
        return
      } else {
        (list as string[]).push(value.trim())
      }
    } else if (this.chipType === CHIP_TYPE.NUMBER && value) {
      (list as number[]).push(Number(value))
    }
    this.valueChange.emit(list)

    if (input) {
      this.chipInputValue = ''
      input.value = ''
    }
  }

  remove(index: number): void {
    const value = cloneDeep(this.chipList)
    if (index >= 0) {
      value.splice(index, 1)
      this.valueChange.emit(value)
    }
  }

  handleStatusChange(chip: HTMLInputElement) {
    setTimeout(() => {
      const status = chip && chip.validity && chip.validity.valid
      this.chipInputValue = chip.value
      this.inputChipValid = status
      this.inputStatusChange.emit(status)
    }, 100)
  }
}

