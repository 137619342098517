import { Component, Inject, OnInit } from '@angular/core'
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

import { NOTIFICATION_TYPES } from 'src/app/constants/internationalized-constants-en'

export class NotificationSnackbarDataModel {
  notificationMessage: string
  notificationType: NOTIFICATION_TYPES
  detailedError?: Record<string, string[]>
}

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent implements OnInit {
  notificationMessage: string
  detailedErrorMessages: string[] = []
  notificationType: NOTIFICATION_TYPES
  notificationTypes = NOTIFICATION_TYPES

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) data: NotificationSnackbarDataModel,
    private snackbarRef: MatSnackBarRef<NotificationSnackbarComponent>
  ) {
    if (data) {
      this.notificationMessage = data.notificationMessage
      this.notificationType = data.notificationType
      if (data.detailedError) {
        this.collectDetailedErrorMessages(data.detailedError)
      }
    }
  }

  ngOnInit(): void {
  }

  collectDetailedErrorMessages(detailedError: Record<string, string[]>) {
    Object.keys(detailedError).map(key => {
      this.detailedErrorMessages = [
        ...this.detailedErrorMessages,
        ...detailedError[key]
      ]
    })
  }

  dismissNotificationSnackbar() {
    this.snackbarRef.dismiss()
  }
}
