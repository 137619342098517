import { SelectionModel } from '@angular/cdk/collections'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { Store, Select } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { cloneDeep, intersection } from 'lodash'
import { Observable, of as observableOf, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

import {
  UpdateDataConfiguration,
  AppState,
  CreateNewDataConfiguration,
  CreateDataNewForDeleteDataConfiguration,
  ReorderDataConfiguration,
  InstallerExportDataConfiguration,
  CloneDataConfiguration,
  SetExpandedStates,
  SelectConfiguration,
  Ect2Data,
  CreateLanguageDictionaryUndoable,
  DeleteDataConfigurationUndoable,
  CopyConfiguration,
  PasteConfiguration,
  ImportLegacyConfig,
  GetAssetZoneMapBackground,
  UploadAssetMapView,
  AddTemplateLibraryPiece,
  GetTemplateLibraryPieces,
  PasteFromTemplateLibrary,
} from 'src/app/app.state'
import {
  MENU_OPTION_KEY,
  NODE_LEVELS,
  CONFIRM_DIALOG_TYPE,
  INSTALLER_EXPORT_TYPE,
  EXPANDED_STATES_OPTIONS,
  ERROR_MESSAGES,
  NODE_LEVELS_INDEX,
  GRID_VIEW_POSITION_INDEX,
  REMOVE_CURRENT,
  NOTIFICATION_TYPES,
  TEMPLATE_TYPE_BY_NODE_LEVEL,
  TEMPLATE_TYPES_VALUE,
  Constants,
  CONFIG_TYPE_BY_NODE_LEVEL,
  i18nParams,
} from 'src/app/constants/internationalized-constants-en'
import { ZoneBoxModel } from 'src/app/models'
import {
  TreeFlatNode,
  TreeNode,
  ZoneLayoutModel,
  SelectedConfigurationModel,
  InspectionDetailModel,
  ZoneNameModel,
  TreeDataType,
  EvirLanguageDictionary,
} from './models/tree.model'
import { DialogComponent } from '../dialog/dialog.component'
import { ConditionEditDialogComponent } from '../condition-edit-dialog/condition-edit-dialog.component'
import { ComponentEditDialogComponent } from '../component-edit-dialog/component-edit-dialog.component'
import { InformDialogComponent } from '../inform-dialog/inform-dialog.component'
import { ConfigLevelEditDialogComponent } from '../config-level-edit-dialog/config-level-edit-dialog.component'
import { ConfirmReflectDialogComponent } from '../confirm-reflect-dialog/confirm-reflect-dialog.component'
import { ZoneLevelEditDialogComponent } from '../zone-level-edit-dialog/zone-level-edit-dialog.component'
import { TreeBuildingService } from './services/tree-building/tree-building.service'
import { DataConfigurationHandlingService } from './services/data-configuration-handling/data-configuration-handling.service'
import { UpdateDataConfigurationUndoable } from '../../app.state'
import { DisableUndoConfigurationChange } from 'src/app/modules/ngxs-history-plugin/actions'
import { NotificationSnackbarService } from '../notification-snackbar/services/notification-snackbar.service'
import { AddPieceDialogComponent } from '../add-piece-dialog/add-piece-dialog.component'
import { LanguageDictionaryHandlingService } from './services/language-dictionary-handling/language-dictionary-handling.service'
import { trimAll } from 'src/app/utils/utils'
import { MenuOption } from '../menu/menu.component'

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeBuildingService, DataConfigurationHandlingService]
})
export class TreeComponent implements OnDestroy {

  @Select(AppState.getDataConfiguration) dataConfiguration$: Observable<ZoneLayoutModel[]>
  @Select(AppState.getLanguageDictionary) languageDictionary$: Observable<EvirLanguageDictionary>
  @Select(AppState.getInspectionDetails) dataInspectionDetails$: Observable<InspectionDetailModel[]>
  @Select(AppState.getCompanyId) companyId$: Observable<string>
  @Select(AppState.getGlobalEct2Data) globalEct2Data$: Observable<Ect2Data>
  @Select(AppState.getGlobalConfigType) globalConfigType$: Observable<string>
  @Select(AppState.getExpandedStates) expandedStates$: Observable<string[]>
  @Select(AppState.getSelectedConfiguration) selectedConfiguration$: Observable<SelectedConfigurationModel>
  @Select(AppState.getHoveringAssetZoneBox) hoveringAssetZoneBox$: Observable<ZoneBoxModel>
  @Select(AppState.getActiveUndo) isActiveUndo$: Observable<boolean>
  @Select(AppState.getCopyNodeId) copyNodeId$: Observable<string>
  @Select(AppState.getFilterInspectionTypes) filterInspectionTypes$: Observable<string>
  @Select(AppState.getDraftStatus) isDraft$: Observable<boolean>
  @Select(AppState.getFilterPieceType) filterPieceType$: Observable<string>
  @Select(AppState.getAccessStatus) isAccessGranted$: Observable<boolean>

  @ViewChild('fileInput') fileInput: ElementRef
  treeControl: FlatTreeControl<TreeFlatNode>
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true)
  dragging = false
  expandTimeout: any
  expandDelay = 1000
  subscription: Subscription
  languageDictionary: EvirLanguageDictionary
  inspectionDetails: InspectionDetailModel[]
  companyId: string
  globalEct2Data: Ect2Data
  globalConfigType: string
  dataConfiguration: ZoneLayoutModel[]
  isErrorData: boolean = false
  expandedStates: string[]
  selectedConfiguration: SelectedConfigurationModel
  errorMessage = ERROR_MESSAGES.ERROR_ASSET_CONFIGURATION
  hoveringAssetZoneBox: ZoneBoxModel = null
  statusUndo: boolean = false
  copyNodeId: string = null
  xmlList: string[] = []
  filterInspectionTypes = []
  isDraft: false
  zoneNameList: ZoneNameModel[] = []
  tooltipDisabledStatus: boolean = true
  isAccessGranted: boolean = false
  tooltipDelayTime = 3000
  filterPieceType = ''

  menuOptions: MenuOption[] = [
    { text: MENU_OPTION_KEY.IMPORT_LEGACY_CONFIG },
    { text: MENU_OPTION_KEY.IMPORT_JSON },
    { text: MENU_OPTION_KEY.EDIT },
    { text: MENU_OPTION_KEY.COPY },
    { text: MENU_OPTION_KEY.PASTE },
    { text: MENU_OPTION_KEY.CLONE_BELOW },
    { text: MENU_OPTION_KEY.EXPORT_ALL_JSON },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_JSON },
    { text: MENU_OPTION_KEY.EXPORT_ALL_FULL },
    { text: MENU_OPTION_KEY.EXPORT_ALL_INSTALL },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_FULL },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL },
    // ECT2-1326: Set disable true to temporarily disable this option
    { text: MENU_OPTION_KEY.ADD_TO_LIBRARY, disable: true },
    { text: MENU_OPTION_KEY.DELETE }
  ]

  NODE_LEVELS = NODE_LEVELS

  constructor(
    public treeBuildingService: TreeBuildingService,
    public dataConfigurationHandlingService: DataConfigurationHandlingService,
    private dialog: MatDialog,
    public store: Store,
    private snackbarService: NotificationSnackbarService,
    public langDictionaryService: LanguageDictionaryHandlingService,
    public translateService: TranslateService,
  ) {
    this.subscription = combineLatest(
      this.dataConfiguration$,
      this.languageDictionary$,
      this.dataInspectionDetails$,
      this.companyId$,
      this.globalEct2Data$,
      this.globalConfigType$,
      this.expandedStates$,
      this.selectedConfiguration$,
      this.hoveringAssetZoneBox$,
      this.isActiveUndo$,
      this.copyNodeId$,
      this.filterInspectionTypes$,
      this.isDraft$,
      this.filterPieceType$,
      this.isAccessGranted$
    ).subscribe(([dataConfiguration, languageDictionary, dataInspectionDetails, id, ect2Data, configType, expandedNodeStates,
      selectedConfiguration, hoveringAssetZoneBox, statusUndo, copyNodeId, filterInspectionTypes, isDraft, filterPieceType, isAccessGranted]) => {
      this.filterPieceType = filterPieceType
      this.filterInspectionTypes = filterInspectionTypes
      this.hoveringAssetZoneBox = hoveringAssetZoneBox
      this.copyNodeId = copyNodeId
      this.isDraft = isDraft
      this.isAccessGranted = isAccessGranted
      if (
        dataConfiguration && !dataConfiguration.length ||
        languageDictionary && !Object.keys(languageDictionary).length ||
        dataInspectionDetails && !dataInspectionDetails.length ||
        id === '') {
        this.isErrorData = true
        return
      } else if (!dataConfiguration || !languageDictionary || !dataInspectionDetails || !id) {
        return
      }

      const isNeedSetDefaultValue = this.companyId !== id

      this.isErrorData = false
      this.languageDictionary = languageDictionary
      this.inspectionDetails = dataInspectionDetails
      this.dataConfiguration = dataConfiguration
      this.companyId = id
      this.globalEct2Data = ect2Data
      this.globalConfigType = configType
      this.expandedStates = expandedNodeStates
      this.statusUndo = statusUndo

      if (this.statusUndo) {
        this.updateExpandedStatesUndo(this.expandedStates)
        this.store.dispatch(new DisableUndoConfigurationChange())
        // Reload the Asset Map View column after clicking Undo button
        if (this.dataSource && selectedConfiguration) {
          this.store.dispatch(new SelectConfiguration(this.dataSource.data[selectedConfiguration.index]))
        }
      }

      this.createResourceAndRebuildTree(dataConfiguration, languageDictionary)

      if (isNeedSetDefaultValue) {
        const selectedNode = this.treeControl.dataNodes.length ? this.treeControl.dataNodes[0] : null
        if (selectedNode) {
          this.handleSelectNode(selectedNode, true)
        }
      }

      if (selectedConfiguration) {
        this.selectedConfiguration = selectedConfiguration
      }

      this.zoneNameList = this.treeControl.dataNodes.filter(node => node.level === NODE_LEVELS.ZONE_LEVEL)
        .map(zoneNode => {
          const nodeIndexes = zoneNode.id.split('/').map(value => Number(value))
          return {
            parentIndex: nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL],
            zoneName: zoneNode.name
          }
        })
    })
  }

  updateExpandedStatesUndo(expandedStates: string[]) {
    this.expansionModel.clear()
    expandedStates.forEach(expanded => {
      this.expansionModel.toggle(expanded)
    })
  }

  createResourceAndRebuildTree(dataConfiguration: ZoneLayoutModel[], languageDictionary: EvirLanguageDictionary) {
    const treeData = this.treeBuildingService.getConfigurations(dataConfiguration, languageDictionary)
    const data = this.treeBuildingService.buildTree(treeData, 0)
    const transformer = (node: TreeNode, level: number) => {
      return new TreeFlatNode(!!node.children, node.name, level, node.value, node.id, node.nodeType, node.reflectCount)
    }
    const getLevel = (node: TreeFlatNode) => node.level
    const isExpandable = (node: TreeFlatNode) => node.expandable
    const getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children)
    this.treeFlattener = new MatTreeFlattener(transformer, getLevel, isExpandable, getChildren)
    this.treeControl = new FlatTreeControl<TreeFlatNode>(getLevel, isExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

    this.rebuildTreeForData(data)
  }
  hasChild = (_index: number, _nodeData: TreeFlatNode) => {
    const nodeSiblings = this.treeBuildingService.findNodeSiblings(this.dataSource.data, _nodeData.id)
    let node = nodeSiblings.find(x => x.id === _nodeData.id)
    if (!node || !node.children || !node.children.length) {
      _nodeData.expandable = false
      return false
    }
    return _nodeData.expandable
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): TreeNode[] {
    const result = []

    function addExpandedChildren(node: TreeNode, expanded: string[]) {
      result.push(node)
      if (expanded.includes(node.id) && node.children) {
        node.children.map((child) => addExpandedChildren(child, expanded))
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected)
    })
    return result
  }

  getLevel(key: string) {
    const obj = {
      [TEMPLATE_TYPES_VALUE.CONFIGURATION]: 1,
      [TEMPLATE_TYPES_VALUE.ZONE]: 2,
      [TEMPLATE_TYPES_VALUE.COMPONENT]: 3,
      [TEMPLATE_TYPES_VALUE.CONDITION]: 4
    }
    return obj[key]
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // console.log('origin/destination', event.previousIndex, event.currentIndex);

    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) {
      return
    }


    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes()

    // deep clone the data source so we can mutate it
    const changedData = cloneDeep(this.dataSource.data)
    const isDropBelow = event.currentIndex > event.previousIndex

    let nodeAtDest = visibleNodes[event.currentIndex]
    let newSiblings, node
    if (event && event.item) {
      node = event.item.data
    }


    if (nodeAtDest) {
      newSiblings = this.treeBuildingService.findNodeSiblings(changedData, nodeAtDest.id)
    }

    if (!newSiblings && event.currentIndex === visibleNodes.length) {
      const insertLevel = NODE_LEVELS_INDEX.CONFIG_LEVEL
      const dropLevel = this.getLevel(this.filterPieceType) - 1
      nodeAtDest = {
        id: `0/${event.currentIndex}`
      } as TreeNode
      this.handleConfirmAfterDropFromTemplateLibrary(insertLevel, node, nodeAtDest, event.currentIndex, dropLevel)
      return
    }

    // determine where to insert the node
    if (!newSiblings || !this.filterPieceType) {
      return
    }

    /* Dont insert drag&drop item has templateType difference with current filter */
    if (TEMPLATE_TYPE_BY_NODE_LEVEL[this.dataConfigurationHandlingService.getNodeLevel(node.id)] !== this.filterPieceType
      && (event.item.data && event.item.data.nodeType === TreeDataType.TEMPLATE_LIBRARY)) {
      return
    }

    const insertIndex = newSiblings.findIndex(s => s.id === nodeAtDest.id)

    if (event.item.data && event.item.data.nodeType === TreeDataType.TEMPLATE_LIBRARY) {
      const insertParentsIndex: number[] = nodeAtDest.id.split('/').map(x => Number(x))
      const insertLevel = insertParentsIndex.length - 1
      const insertParentsDropNodeIndex: number[] = node.id.split('/').map(x => Number(x))
      const insertDropNodeLevel = this.dataConfigurationHandlingService.getNodeLevel(node.id)
      this.handleConfirmAfterDropFromTemplateLibrary(insertLevel, node, nodeAtDest, event.currentIndex, insertDropNodeLevel)
      return
    }

    const siblings = this.treeBuildingService.findNodeSiblings(changedData, node.id)
    const siblingIndex = siblings.findIndex(n => n.id === node.id)

    const nodeToInsert = siblings[siblingIndex]

    if (nodeAtDest.id === nodeToInsert.id) {
      return
    }

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find((n) => nodeAtDest.id === n.id)
    const parentNodeAtDestFlatNode = this.getParentNode(nodeAtDestFlatNode)
    const parentCurrentNode = this.getParentNode(node)

    // Handle exception for bug ECT2-61, ECT2-62
    if (nodeAtDestFlatNode.level !== node.level) {
      const newDropIndex = this.handleReorderException(node.id, event.currentIndex, isDropBelow)

      if (newDropIndex !== -1) {
        this.handleReorderNode(node, newDropIndex)
        this.updateExpandedStates(node, EXPANDED_STATES_OPTIONS.DRAG_DROP, parentNodeAtDestFlatNode)
        this.handleDragDropSelectConfiguration(node.level, this.selectedConfiguration.index, this.dataSource.data, siblingIndex, newDropIndex)
      }
      return

    }
    const isNodeAtDestExpanded = this.expansionModel.selected.find(x => x === nodeAtDest.id)
    const nextNode = visibleNodes[event.currentIndex + 1]
    const nodeLevel = this.dataConfigurationHandlingService.getNodeLevel(node.id)
    const isSameParent = nodeAtDest.id.split('/', nodeLevel + 1).join('/') === node.id.split('/', nodeLevel + 1).join('/')
    if (isDropBelow &&
      nextNode &&
      isNodeAtDestExpanded &&
      nodeAtDest.children.length &&
      this.dataConfigurationHandlingService.getNodeLevel(node.id) !== this.dataConfigurationHandlingService.getNodeLevel(nextNode.id) ||
      (!isSameParent && nodeLevel !== NODE_LEVELS.CONFIG_LEVEL)) {
      return
    }
    if (!isSameParent) {
      return
    }

    this.handleReorderNode(node, insertIndex)
    this.updateExpandedStates(node, EXPANDED_STATES_OPTIONS.DRAG_DROP, nodeAtDestFlatNode)
    this.handleDragDropSelectConfiguration(node.level, this.selectedConfiguration.index, this.dataSource.data, siblingIndex, insertIndex)
  }

  handleAfterDrop(node: TreeNode, nodeAtDest: TreeNode, includeSubLayers: boolean = true, insertAtLast: boolean = false) {
    this.store.dispatch(new PasteFromTemplateLibrary(node, nodeAtDest.id, includeSubLayers, insertAtLast)).subscribe(
      () => {
        this.updateExpandedStatesWhenDropFromTemplateLibrary(nodeAtDest, insertAtLast)
      }
    )
  }

  handleConfirmAfterDropFromTemplateLibrary(insertLevel: NODE_LEVELS_INDEX, node: TreeNode, nodeAtDest: TreeNode, dropIndex: number, insertDropNodeLevel: NODE_LEVELS) {

    let insertNode = cloneDeep(nodeAtDest)
    let insertAtLast = false
    if (this.getLevel(this.filterPieceType) !== insertLevel) {
      insertNode = this.handleExceptionDropFromTemplateLibrary(dropIndex, insertLevel, insertDropNodeLevel)
      insertAtLast = true
      if (!insertNode) {
        return
      }
    }

    // ECT2-1184: No need to open dialog confirm Include all sublayers
    this.handleAfterDrop(node, insertNode, true, insertAtLast)
    /**
    if (this.getLevel(this.filterPieceType) - 1 === NODE_LEVELS.CONDITION_LEVEL) {
      this.handleAfterDrop(node, insertNode, false, insertAtLast)
    } else {
      this.dialog.open<DialogComponent, GenericDialogData>(DialogComponent, {
        data: {
          onCancel: () => {
            this.handleAfterDrop(node, insertNode, false, insertAtLast)
          },
          onAccept: () => {
            this.handleAfterDrop(node, insertNode, true, insertAtLast)
          },
          cancelText: CANCEL_OPTIONS.NO,
          acceptText: ACCEPT_OPTIONS.YES,
          title: 'Would you like to include all sublayers?'
        },
      })
    }
    */
  }

  handleReorderNode(node: TreeNode, newIndex: number) {
    // open confirm dialog when reodering condition node (has reflect data) on the tree
    if (node.reflectCount && node.reflectCount.count > 1 && node.id.split('/').length === 5) {
      const dialogRef = this.dialog.open(ConfirmReflectDialogComponent, {
        data: {
          onClose: () => {
            dialogRef.close()
          },
          onApplyJustThisOne: () => {
            this.store.dispatch(new ReorderDataConfiguration(node, newIndex, false))
            dialogRef.close()
          },
          onApplyAll: () => {
            this.store.dispatch(new ReorderDataConfiguration(node, newIndex, true))
            dialogRef.close()
          },
          cancelText: this.translateService.instant('CANCEL'),
          applyJustThisOneText: this.translateService.instant('APPLY_ONE'),
          applyAllText: this.translateService.instant('APPLY_ALL'),
          title: this.translateService.instant('REFLECT_REORDERING_TITLE'),
          message: this.translateService.instant('REFLECT_REORDERING_CONFIRM_MSG'),
        },
        maxWidth: '594px'
      })
    } else {
      this.store.dispatch(new ReorderDataConfiguration(node, newIndex, true))
    }
  }

  // Update position select configuration when drag drop
  handleDragDropSelectConfiguration(nodeLevel: number, positionSelectedConfiguration: number, nodes: TreeNode[], positionDrag: number, positionDrop: number) {
    if (nodeLevel !== NODE_LEVELS.CONFIG_LEVEL) {
      return
    }
    let newPosition: number

    if (positionDrag > positionSelectedConfiguration && positionDrop <= positionSelectedConfiguration) {
      newPosition = positionSelectedConfiguration + 1
    } else if (positionDrag < positionSelectedConfiguration && positionDrop >= positionSelectedConfiguration) {
      newPosition = positionSelectedConfiguration - 1
    } else {
      if (positionDrag === positionSelectedConfiguration) {
        newPosition = positionDrop
      } else {
        return
      }
    }

    // const flatNode = new TreeFlatNode(false, nodes[newPosition].name, nodes[newPosition]., node.value, node.id, node.reflectCount)
    // this.store.dispatch(new CopyConfiguration(flatNode))
    this.store.dispatch(new SelectConfiguration(nodes[newPosition]))
  }

  rebuildTreeForData(data: any) {
    this.dataSource.data = data
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.id === id)
      this.treeControl.expand(node)
    })
  }

  private getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = node.level
    if (currentLevel < 1) {
      return null
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i]
      if (currentNode.level < currentLevel) {
        return currentNode
      }
    }
    return null
  }

  isActiveNode(node: TreeFlatNode) {
    if (!this.selectedConfiguration) {
      return false
    }
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    return configIndex === this.selectedConfiguration.index
  }

  handleSelectOption(option, node: TreeFlatNode) {
    switch (option) {
      case MENU_OPTION_KEY.DELETE:
        if (node.reflectCount && node.reflectCount.count > 1 && node.level === NODE_LEVELS.CONDITION_LEVEL) {
          this.openConfirmRemoveReflectDialog(node)
        } else {
          this.openConfirmDialog(node)
        }
        break
      case MENU_OPTION_KEY.EDIT:
        console.log(node)

        this.openEditDialog(node)
        break
      case MENU_OPTION_KEY.EXPORT_CONFIG_JSON:
        const nodeSiblings = this.treeBuildingService.findNodeSiblings(this.dataSource.data, node.id)
        const selectedNode = nodeSiblings.find(x => x.id === node.id)
        const result = this.dataConfigurationHandlingService.convertTreeDataToJson(selectedNode, this.languageDictionary.languageStrings)
        this.openInformDialog(JSON.stringify(result, null, 2))
        break
      case MENU_OPTION_KEY.EXPORT_ALL_JSON:
        const rawLanguageDictionary = this.store.selectSnapshot(AppState.getRawLanguageDictionary)
        const languageCodes = this.store.selectSnapshot(AppState.getLanguageCodes)
        const configPackageVersion = this.store.selectSnapshot(AppState.getConfigPackageVersion)
        const createdDate = this.store.selectSnapshot(AppState.getConfigPackageCreatedDate)
        const allConfig = this.dataConfigurationHandlingService.convertAllTreeDataToJson(
          this.dataSource.data, this.inspectionDetails, rawLanguageDictionary, this.companyId, this.globalEct2Data,
          this.globalConfigType, this.isDraft, this.languageDictionary.languageStrings, languageCodes, configPackageVersion, createdDate
        )
        this.openInformDialog(JSON.stringify(allConfig, null, 2))
        break
      case MENU_OPTION_KEY.EXPORT_ALL_FULL:
      case MENU_OPTION_KEY.EXPORT_CONFIG_FULL:
        if (node) {
          this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_LARGE, false, node))
        } else {
          this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_LARGE, false))
        }
        break
      case MENU_OPTION_KEY.EXPORT_ALL_INSTALL:
      case MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL:
        if (node) {
          this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_SHORT, false, node))
        } else {
          this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_SHORT, false))
        }
        break
      case MENU_OPTION_KEY.CLONE_BELOW:
        this.store.dispatch(new CloneDataConfiguration(node)).subscribe(
          () => {
            this.updateExpandedStates(node, EXPANDED_STATES_OPTIONS.CLONE_BELOW)
          }
        )
        break
      case MENU_OPTION_KEY.COPY:
        this.store.dispatch(new CopyConfiguration(node))
        break
      case MENU_OPTION_KEY.PASTE:
        this.store.dispatch(new PasteConfiguration(node)).subscribe(
          () => {
            this.updateExpandedStates(node, EXPANDED_STATES_OPTIONS.COPY_PASTE)
          }
        )
        break
      case MENU_OPTION_KEY.IMPORT_LEGACY_CONFIG:
        this.openFileUploadDialog()
        break
      case MENU_OPTION_KEY.ADD_TO_LIBRARY:
        this.openAddPieceDialog(node)
        break
      default:
        break
    }
  }

  openFileUploadDialog() {
    const element: HTMLElement = this.fileInput.nativeElement
    element.click()
  }

  chooseFile(fileInputEvent: any) {
    const listDataFileChosen = Object.values(fileInputEvent.target.files)

    listDataFileChosen.forEach((item: File) => {
      this.uploadXMLFile(item)
    })
    /**
    * File will have content the latest selected file
    * When user click Cancel in dialog reference won't be changed and still contains the previously selected file.
    *
    */
    if (fileInputEvent.target.files[0]) {
      setTimeout(() => {
        if (this.xmlList && this.xmlList.length) {
          this.handleInputXMLFile(this.xmlList)
        }
      }, 100)
    }
    // clear input file and reset array
    this.fileInput.nativeElement.value = ''
    this.xmlList.length = 0
  }

  handleInputXMLFile(xmlList: string[]) {
    const oldConfigLength = this.dataConfiguration.length
    this.store.dispatch(new ImportLegacyConfig(xmlList)).subscribe(
      () => {
        const numberNewConfig = this.dataConfiguration.length - oldConfigLength
        this.updateExpandedStates(null, EXPANDED_STATES_OPTIONS.IMPORT_LEGACY,
          null, numberNewConfig)
      }
    )
  }

  uploadXMLFile(file: File) {
    let fileReader = new FileReader()
    fileReader.onload = (e) => {
      const contentXML: string = fileReader.result as string
      this.xmlList.push(contentXML)
    }
    fileReader.readAsText(file)
  }

  removeNodeInTree(node: TreeFlatNode, confirmType: CONFIRM_DIALOG_TYPE) {
    const changedData = cloneDeep(this.dataSource.data)
    const nodeSiblings = this.treeBuildingService.findNodeSiblings(changedData, node.id)
    const nodeDelete = nodeSiblings.find(x => x.id === node.id)
    const nodeIndexDelete = nodeSiblings.findIndex(x => x.id === node.id)
    if (confirmType === CONFIRM_DIALOG_TYPE.CONFIRM_DIALOG) {
      this.store.dispatch(new CreateDataNewForDeleteDataConfiguration(nodeDelete)).subscribe(
        () => {
          this.updateExpandedStates(node, EXPANDED_STATES_OPTIONS.DELETE)
          this.updateSetConfigurationDeleteNode(node, nodeIndexDelete)
        }
      )
    } else {
      this.store.dispatch(new DeleteDataConfigurationUndoable(nodeDelete))
    }
  }

  updateSetConfigurationDeleteNode(node: TreeFlatNode, nodeIndexDelete: number) {
    if (node.level !== NODE_LEVELS.CONFIG_LEVEL) {
      return
    }
    let indexSetConfiguration: number
    if (this.dataSource.data.length === this.selectedConfiguration.index && this.selectedConfiguration.index === nodeIndexDelete) {
      indexSetConfiguration = 0
    } else if (this.selectedConfiguration.index > nodeIndexDelete) {
      indexSetConfiguration = this.selectedConfiguration.index - 1
    } else if (this.dataSource.data.length === 1 && this.selectedConfiguration.index === nodeIndexDelete) {
      return
    } else if (this.selectedConfiguration.index < nodeIndexDelete) {
      return
    } else {
      indexSetConfiguration = 0
    }
    this.store.dispatch(new SelectConfiguration(this.dataSource.data[indexSetConfiguration]))
  }

  openInformDialog(message: string) {
    this.dialog.open(InformDialogComponent, {
      data: {
        cancelText: this.translateService.instant('OK'),
        acceptText: this.translateService.instant('COPY'),
        toastMessage: this.translateService.instant('COPIED_TO_CLIPBOARD'),
        title: '',
        message: message
      },
    })
  }

  editNodeInTree(node: TreeFlatNode, newValue: any, isReflect: boolean = true, isUndoable: boolean = true) {
    const changedData = cloneDeep(this.dataSource.data)
    const nodeSiblings = this.treeBuildingService.findNodeSiblings(changedData, node.id)
    const currentNode = nodeSiblings.find(x => x.id === node.id)
    newValue.name = trimAll(newValue.name)
    currentNode.name = newValue.name
    const shouldUpdateAssetViewLocation = node.level === NODE_LEVELS.CONFIG_LEVEL && newValue.assetViewId &&
      !newValue.assetViewGrid.every((value, index) => value === (node.value.assetViewGrid || [])[index])
    const shouldRemovedAssetViewLocation = node.level === NODE_LEVELS.CONFIG_LEVEL && newValue.assetViewId === REMOVE_CURRENT
    const isUpdatedAssetViewBackground = this.checkUpdatedAssetViewBackground(node, newValue.assetViewId)
    this.handleUpdateDataTree(node.level, currentNode, newValue)
    const configType = CONFIG_TYPE_BY_NODE_LEVEL[node.level]
    const newLangKey = this.langDictionaryService.getLangKeyByString(newValue.name, this.languageDictionary.languageStrings, configType)
    if (newLangKey) {
      currentNode.value.id = newLangKey
    }

    if (isReflect) {
      const updateAction = isUndoable ? new UpdateDataConfigurationUndoable(currentNode) : new UpdateDataConfiguration(currentNode)
      const dispatchAction = newLangKey ? updateAction : new CreateLanguageDictionaryUndoable(currentNode, newValue.name, configType)
      this.store.dispatch(dispatchAction).subscribe(
        () => {
          this._handlePostTreeUpdate(currentNode, shouldUpdateAssetViewLocation, shouldRemovedAssetViewLocation)
          if (isUpdatedAssetViewBackground) {
            this.store.dispatch(new GetAssetZoneMapBackground(this.companyId, currentNode.value.assetViewId))
          }
        }
      )
    } else {
      this.store.dispatch(new CreateNewDataConfiguration(currentNode, isReflect, newValue.name, configType))
    }
  }

  handleUpdateDataTree(level: number, currentValue: TreeNode, newValue) {
    switch (level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        currentValue.value.assetType = newValue.assetType.map(value => trimAll(value))
        currentValue.value.assetViewGrid = newValue.assetViewId !== REMOVE_CURRENT
          ? newValue.assetViewGrid
          : [1, 1]
        currentValue.value.assetViewId = newValue.assetViewId !== REMOVE_CURRENT
          ? newValue.assetViewId
          : ''
        return
      case NODE_LEVELS.ZONE_LEVEL:
        currentValue.value.tagNumber = parseInt(newValue.tagNumber, 10)
        currentValue.value.tagType = newValue.tagType
        currentValue.value.zoneInspectionTypes = newValue.zoneInspectionTypes
        currentValue.value.assetViewLocation = newValue.assetViewLocation
        return
      case NODE_LEVELS.COMPONENT_LEVEL:
        currentValue.value.severityMax = newValue.severityMax
        currentValue.value.severityMin = newValue.severityMin
        return
      case NODE_LEVELS.CONDITION_LEVEL:
        return
      default:
        return
    }
  }

  openConfirmDialog(node: TreeFlatNode) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => { dialogRef.close() },
        onAccept: () => {
          this.removeNodeInTree(node, CONFIRM_DIALOG_TYPE.CONFIRM_DIALOG)
          dialogRef.close()
        },
        cancelText: this.translateService.instant('NO'),
        acceptText: this.translateService.instant('YES'),
        title: this.translateService.instant('DELETE_TITLE'),
        message: this.translateService.instant('CONFIG_DELETE_CONFIRM_MSG')
      },
    })
  }

  openConfirmRemoveReflectDialog(node: TreeFlatNode) {
    const dialogRef = this.dialog.open(ConfirmReflectDialogComponent, {
      data: {
        onClose: () => {
          dialogRef.close()
        },
        onApplyJustThisOne: () => {
          this.removeNodeInTree(node, CONFIRM_DIALOG_TYPE.CONFIRM_DIALOG)
          dialogRef.close()
        },
        onApplyAll: () => {
          this.removeNodeInTree(node, CONFIRM_DIALOG_TYPE.REFECLT_DIALOG)
          dialogRef.close()
        },
        cancelText: this.translateService.instant('CANCEL'),
        applyJustThisOneText: this.translateService.instant('APPLY_ONE'),
        applyAllText: this.translateService.instant('APPLY_ALL'),
        title: this.translateService.instant('DELETE_TITLE'),
        message: this.translateService.instant('CONFIG_DELETE_REFERENCED_CONFIRM_MSG')
      },
      maxWidth: '594px'
    })
  }

  getEditDialogByLevel(level: number) {
    switch (level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        return ConfigLevelEditDialogComponent
      case NODE_LEVELS.ZONE_LEVEL:
        return ZoneLevelEditDialogComponent
      case NODE_LEVELS.COMPONENT_LEVEL:
        return ComponentEditDialogComponent
      case NODE_LEVELS.CONDITION_LEVEL:
        return ConditionEditDialogComponent
      default:
        return
    }
  }

  openEditDialog(node: TreeFlatNode) {

    const editDialog = this.getEditDialogByLevel(node.level)
    if (!editDialog) {
      return
    }

    let dialogData: any = {
      node: node
    }
    if (node.level === NODE_LEVELS.ZONE_LEVEL) {
      const parentNode = this.getParentNode(node)
      dialogData = {
        ...dialogData,
        assetViewGrid: parentNode.value.assetViewGrid,
        assetViewId: parentNode.value.assetViewId,
      }
    }
    const dialogRef = this.dialog.open(editDialog, {
      data: dialogData,
    })

    const subscription = dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return
      }
      const { data, isReflect, fileUploaded } = result
      if (data && (!fileUploaded || data.assetViewId !== 'default-selected-id')) {
        this.editNodeInTree(node, data, isReflect)
      } else if (data && fileUploaded && data.assetViewId === 'default-selected-id') {
        fileUploaded.companyId = this.companyId
        this.store.dispatch(new UploadAssetMapView(fileUploaded)).subscribe(
          res => {
            const { app } = res
            const { updateAssetViewMapResponse } = app
            data.assetViewId = res && app && updateAssetViewMapResponse ? updateAssetViewMapResponse.assetViewId : ''
          },
          rawError => {
            const detailedError = typeof (rawError.error) === 'string' ? { message: [rawError.error] } : null
            this.snackbarService.openNotificationSnackbar(
              this.translateService.instant('UPLOAD_ASSET_VIEW_MAP_FAILED'),
              NOTIFICATION_TYPES.FAILED,
              detailedError,
            )
          },
          () => {
            this.editNodeInTree(node, data, isReflect)
          })
      }
      subscription.unsubscribe()
    })
  }

  getMenuOption(level: NODE_LEVELS, node?: TreeFlatNode) {
    const copyNodeLevel = this.copyNodeId ? this.dataConfigurationHandlingService.getNodeLevel(this.copyNodeId) : null
    // const isCopyable = copyNodeLevel === null || (copyNodeLevel !== null && this.copyNodeId !== nodeId)
    const isPasteable = copyNodeLevel !== null && (level === copyNodeLevel - 1)

    // Handle to display an options in option menu by level
    switch (level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        return this.menuOptions.filter(menuOption =>
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_JSON &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_FULL &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_INSTALL &&
          menuOption.text !== MENU_OPTION_KEY.IMPORT_LEGACY_CONFIG &&
          menuOption.text !== MENU_OPTION_KEY.IMPORT_JSON &&
          menuOption.text !== MENU_OPTION_KEY.PASTE &&
          menuOption.text !== MENU_OPTION_KEY.ADD_TO_LIBRARY ||
          menuOption.text === MENU_OPTION_KEY.PASTE && isPasteable ||
          menuOption.text === MENU_OPTION_KEY.ADD_TO_LIBRARY && this.isAccessGranted
        )

      case NODE_LEVELS.ZONE_LEVEL:
      case NODE_LEVELS.COMPONENT_LEVEL:
      case NODE_LEVELS.CONDITION_LEVEL:
        const result = this.menuOptions.filter(menuOption =>
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_JSON &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_CONFIG_JSON &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_FULL &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_ALL_INSTALL &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_CONFIG_FULL &&
          menuOption.text !== MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL &&
          menuOption.text !== MENU_OPTION_KEY.IMPORT_LEGACY_CONFIG &&
          menuOption.text !== MENU_OPTION_KEY.IMPORT_JSON &&
          menuOption.text !== MENU_OPTION_KEY.ADD_TO_LIBRARY &&
          menuOption.text !== MENU_OPTION_KEY.PASTE ||
          menuOption.text === MENU_OPTION_KEY.PASTE && isPasteable
        )

        /** 
         * ECT2-1201: Disable Delete option on last condition in a component.
         * Just a short-term solution. This will be removed in the future.
         */
        if (this.dataConfigurationHandlingService.isLastConditionNode(this.dataSource.data, node)) {
          const deleteOptionIndex = result.findIndex(menuOption => menuOption.text === MENU_OPTION_KEY.DELETE)
          if (deleteOptionIndex > -1) {
            const clonedDeleteOption = cloneDeep(result[deleteOptionIndex])
            clonedDeleteOption.disable = true
            clonedDeleteOption.tooltipMessage = this.translateService.instant('AT_LEAST_ONE_CONDITION_TOOLTIP')
            result[deleteOptionIndex] = clonedDeleteOption
          }
        }

        return result
      default:
        return this.menuOptions.filter(menuOption =>
          menuOption.text === MENU_OPTION_KEY.EXPORT_ALL_JSON ||
          menuOption.text === MENU_OPTION_KEY.EXPORT_ALL_FULL ||
          menuOption.text === MENU_OPTION_KEY.EXPORT_ALL_INSTALL ||
          menuOption.text === MENU_OPTION_KEY.IMPORT_LEGACY_CONFIG && this.isAccessGranted ||
          menuOption.text === MENU_OPTION_KEY.IMPORT_JSON ||
          menuOption.text === MENU_OPTION_KEY.PASTE && isPasteable
        )
    }
  }

  setExpandedStatesToStore() {
    const expandedStates = this.expansionModel.selected
    this.store.dispatch(new SetExpandedStates(expandedStates))
  }

  filterChangeExpandedStatesWithOption(node: TreeFlatNode, option: EXPANDED_STATES_OPTIONS, destinationNode?: TreeFlatNode): string[] {
    const nodeLevelIndex = option === EXPANDED_STATES_OPTIONS.COPY_PASTE ? node.level + 2 : node.level + 1
    const nodeIndexes: number[] = node.id.split('/').map(x => Number(x))
    const expandedStatesWithLevel = this.dataConfigurationHandlingService.filterExpandedStatesWithLevel(node.level, nodeIndexes, this.expandedStates)

    switch (option) {
      case EXPANDED_STATES_OPTIONS.CLONE_BELOW:
        return expandedStatesWithLevel.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          return nodeIndexes[nodeLevelIndex] < expandedStateIndexes[nodeLevelIndex]
        })

      case EXPANDED_STATES_OPTIONS.COPY_PASTE:
        return expandedStatesWithLevel.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          return nodeIndexes[nodeLevelIndex - 1] === expandedStateIndexes[nodeLevelIndex - 1]
        })
      case EXPANDED_STATES_OPTIONS.DELETE:
        return expandedStatesWithLevel.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          if (expandedState.includes(node.id) && nodeIndexes[nodeLevelIndex] === expandedStateIndexes[nodeLevelIndex]) {
            this.expansionModel.deselect(expandedState)
          }
          return nodeIndexes[nodeLevelIndex] < expandedStateIndexes[nodeLevelIndex]
        })

      case EXPANDED_STATES_OPTIONS.DRAG_DROP:
        const destinationNodeIndexes: number[] = destinationNode.id.split('/').map(x => Number(x))
        const getExpandedStateWithLimit = (value: number, lowerLimit: number, upperLimit: number) => {
          return value >= lowerLimit && value <= upperLimit
        }

        return expandedStatesWithLevel.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          return nodeIndexes[nodeLevelIndex] < destinationNodeIndexes[nodeLevelIndex]
            ? getExpandedStateWithLimit(expandedStateIndexes[nodeLevelIndex], nodeIndexes[nodeLevelIndex], destinationNodeIndexes[nodeLevelIndex])
            : getExpandedStateWithLimit(expandedStateIndexes[nodeLevelIndex], destinationNodeIndexes[nodeLevelIndex], nodeIndexes[nodeLevelIndex])
        })

      default:
        return []
    }
  }

  updateExpandedStatesWhenDropFromTemplateLibrary(node: TreeNode, insertAtLast: boolean = false) {
    const expandedStates = this.expansionModel.selected
    const parentsIndex = node.id.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 1
    let newExpandedState

    expandedStates.forEach(expandedState => {
      const parentId = expandedState.split('/').map(x => Number(x))

      if (parentsIndex[nodeLevel] <= parentId[nodeLevel]) {
        newExpandedState = this.dataConfigurationHandlingService.updateChildNodeId(expandedState, insertAtLast ? parentId[nodeLevel] : parentId[nodeLevel] + 1, nodeLevel - 1)
        this.expansionModel.toggle(expandedState)
        this.expansionModel.toggle(newExpandedState)
      }
    })
    this.setExpandedStatesToStore()
  }

  updateExpandedStates(
    node: TreeFlatNode, option: EXPANDED_STATES_OPTIONS,
    destinationNode?: TreeFlatNode, numberNewConfig?: number) {

    // Don't update expanded states if node level is condition level
    if (node && node.level === NODE_LEVELS.CONDITION_LEVEL) {
      return
    }

    let expandedStates: string[],
      newExpandedState: string

    if (option === EXPANDED_STATES_OPTIONS.COPY_PASTE && node === null) {
      expandedStates = this.expansionModel.selected
      expandedStates.forEach(expandedState => {
        const parentId = expandedState.split('/').map(x => Number(x))
        newExpandedState = this.dataConfigurationHandlingService.updateChildNodeId(expandedState, parentId[NODE_LEVELS_INDEX.CONFIG_LEVEL] + 1, NODE_LEVELS.CONFIG_LEVEL)
        this.expansionModel.toggle(expandedState)
        this.expansionModel.toggle(newExpandedState)
      })
    } else {
      expandedStates = option !== EXPANDED_STATES_OPTIONS.IMPORT_LEGACY
        ? this.filterChangeExpandedStatesWithOption(node, option, destinationNode)
        : this.expandedStates
      expandedStates.forEach(expandedState => {
        newExpandedState = this.dataConfigurationHandlingService
          .getNewExpandedStateWithOption(expandedState, node, option, destinationNode, numberNewConfig)
        this.expansionModel.toggle(expandedState)
        this.expansionModel.toggle(newExpandedState)
      })
    }
    this.setExpandedStatesToStore()
  }


  updateAssetViewLocation(parentNode: TreeNode) {
    parentNode.children.forEach(childrenNode => {
      const updateNode: TreeFlatNode = this.treeControl.dataNodes.find(n => childrenNode.id === n.id)
      const newAssetViewLocation: number[] = parentNode.value.assetViewId
        ? this.dataConfigurationHandlingService.getNewAssetViewLocation(updateNode.value.assetViewLocation, parentNode.value.assetViewGrid)
        : []
      if (newAssetViewLocation) {
        const updateData = {
          name: updateNode.name,
          tagType: updateNode.value.tagType,
          tagNumber: updateNode.value.tagNumber,
          zoneInspectionTypes: updateNode.value.zoneInspectionTypes,
          assetViewLocation: newAssetViewLocation
        }
        this.editNodeInTree(updateNode, updateData, true, false)
      }
    })
  }

  private _handlePostTreeUpdate(node: TreeNode, shouldUpdateAssetViewLocation: boolean, shouldRemoveAssetViewLocation: boolean) {
    if (!shouldUpdateAssetViewLocation && !shouldRemoveAssetViewLocation) {
      return
    }

    if (shouldUpdateAssetViewLocation || shouldRemoveAssetViewLocation) {
      this.updateAssetViewLocation(node)
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new SelectConfiguration(null))
    this.store.dispatch(new SetExpandedStates([]))
    // this.store.dispatch(new CopyConfiguration(null))
  }

  handleSelectNode(node, isSetDefault: boolean = false) {
    if (node.level === NODE_LEVELS.CONFIG_LEVEL) {
      const isNodeExpanded = this.expandedStates.findIndex(state => state === node.id) !== -1
      if (!isNodeExpanded && !isSetDefault) {
        this.expansionModel.toggle(node.id)
        this.setExpandedStatesToStore()
      }
      this.store.dispatch(new SelectConfiguration(node))
    }
  }

  isHoveringAssetZoneMapBox(node) {
    if (node.level === NODE_LEVELS.ZONE_LEVEL && this.hoveringAssetZoneBox) {
      const position = node.value.assetViewLocation
      if (!position) {
        return false
      }
      const positionX = position[GRID_VIEW_POSITION_INDEX.POSITION_X]
      const positionY = position[GRID_VIEW_POSITION_INDEX.POSITION_Y]

      const inspectionTypes = node.value.zoneInspectionTypes as string[]
      const isSelectedInspectionTypes = intersection(inspectionTypes, this.filterInspectionTypes)
      return positionX === this.hoveringAssetZoneBox.positionX && positionY === this.hoveringAssetZoneBox.positionY && isSelectedInspectionTypes.length
    }
    return false
  }

  checkUpdatedAssetViewBackground(node: TreeFlatNode, newAssetViewId: string): boolean {
    if (node.level !== NODE_LEVELS.CONFIG_LEVEL) {
      return false
    }

    const nodeIndexes = node.id.split('/').map(value => Number(value))
    return nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] === this.selectedConfiguration.index &&
      node.value.assetViewId !== newAssetViewId
  }

  isDisabledTooltip(nodeId: string): boolean {
    if (!nodeId) {
      return true
    }

    const selectors = [`name ${nodeId}`, `zone-tag ${nodeId}`, `zone-inspection-types ${nodeId}`]
    const nodeContentWidth = selectors
      .map(document.getElementById.bind(document))
      .filter(item => !!item)
      .map((item: HTMLElement) => item.offsetWidth)
      .reduce((acc, current) => acc += current, 0)
    const maxNodeContentWidth = document.getElementById(nodeId)
      ? document.getElementById(nodeId).offsetWidth
      : 0
    return nodeContentWidth < maxNodeContentWidth
  }

  openAddPieceDialog(node: TreeFlatNode) {
    this.dialog.open(AddPieceDialogComponent, {
      data: {
        name: node.name,
        hasNodeChildren: this.hasChild(null, node),
        isPremadeField: false,
        title: this.translateService.instant('CONFIG_PIECE_ADD_TITLE'),
      },
      panelClass: 'add-piece-dialog'
    }).afterClosed().pipe(filter(data => !!data))
      .subscribe(result => {
        const { name, isIncludedAllSublayers, isAdminViewOnly } = result
        this.handleAddPieceToTemplateLibrary(node, name, isIncludedAllSublayers, isAdminViewOnly)
      })
  }

  handleAddPieceToTemplateLibrary(node: TreeFlatNode, pieceName: string, isIncludedAllSublayers: boolean, isAdminViewOnly: boolean) {
    this.store.dispatch(new AddTemplateLibraryPiece(node, pieceName, isIncludedAllSublayers, isAdminViewOnly)).subscribe(
      value => { },
      error => {
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('CONFIG_PIECE_ADD_FAIL_MSG', { name: pieceName }),
          NOTIFICATION_TYPES.FAILED
        )
      },
      () => {
        this.store.dispatch(new GetTemplateLibraryPieces())
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('CONFIG_PIECE_ADD_SUCCESS_MSG', { name: pieceName }),
          NOTIFICATION_TYPES.SUCCESS, null, Constants.snackbar.duration
        )
      }
    )
  }

  renderId(parentIndex: number[], nodeLevel: NODE_LEVELS) {
    return parentIndex.reduce((acc, current, index) => index <= nodeLevel + 1 ? `${acc}${index === 0 ? '' : '/'}${current}` : acc, '')
  }

  handleReorderException(nodeId: string, dropIndex: number, isDropBelow: boolean = false) {
    const visibleNodes = this.visibleNodes()
    const nodeAtDest = visibleNodes[dropIndex]
    const nextNode = visibleNodes[dropIndex + 1]
    const parentIndexNodeAtDest = nodeAtDest.id.split('/').map(x => Number(x))
    const nodeLevel = this.dataConfigurationHandlingService.getNodeLevel(nodeId)
    const nextNodeLevel = nextNode ? this.dataConfigurationHandlingService.getNodeLevel(nextNode.id) : ''
    const isSameParent = nodeAtDest.id.split('/', nodeLevel + 1).join('/') === nodeId.split('/', nodeLevel + 1).join('/')
    let newIndex: number = -1
    if (!isDropBelow) {
      return newIndex
    }

    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        if (nextNodeLevel === NODE_LEVELS.CONFIG_LEVEL || !nextNode) {
          newIndex = parentIndexNodeAtDest[NODE_LEVELS_INDEX.CONFIG_LEVEL]
        }

        break
      case NODE_LEVELS.ZONE_LEVEL:
      case NODE_LEVELS.COMPONENT_LEVEL:
      case NODE_LEVELS.CONDITION_LEVEL:
        if ((nextNodeLevel <= nodeLevel || !nextNode) && isSameParent) {
          newIndex = parentIndexNodeAtDest[nodeLevel + 1]
        }
        break

      default:

        break
    }
    return newIndex
  }

  handleExceptionDropFromTemplateLibrary(dropIndex: number, dropLevel: NODE_LEVELS_INDEX, dropNodeLevel: NODE_LEVELS) {
    const visibleNodes = this.visibleNodes()
    const nodeAtDest = visibleNodes[dropIndex - 1]
    const nextNode = visibleNodes[dropIndex]
    const parentIndexNodeAtDest = nodeAtDest.id.split('/').map(x => Number(x))
    const nodeAtDestLevel = this.dataConfigurationHandlingService.getNodeLevel(nodeAtDest.id)
    const nextNodeLevel = nextNode ? this.dataConfigurationHandlingService.getNodeLevel(nextNode.id) : ''

    let newId
    let result

    switch (dropNodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        break
      case NODE_LEVELS.ZONE_LEVEL:
        if ((nextNodeLevel === NODE_LEVELS.CONFIG_LEVEL || !nextNodeLevel) && nodeAtDestLevel >= dropNodeLevel) {
          newId = this.renderId(parentIndexNodeAtDest, dropNodeLevel)
          result = visibleNodes.find(x => x.id === newId)
          break
        }

        if ((nextNodeLevel === NODE_LEVELS.CONFIG_LEVEL || !nextNodeLevel) && !nodeAtDest.children.length) {
          result = {
            ...nodeAtDest,
            id: `${nodeAtDest.id}/0`
          }
          break
        }
        break
      case NODE_LEVELS.COMPONENT_LEVEL:
        if ((nextNodeLevel === NODE_LEVELS.CONFIG_LEVEL || nextNodeLevel === NODE_LEVELS.ZONE_LEVEL || !nextNodeLevel) && (nodeAtDestLevel > NODE_LEVELS.ZONE_LEVEL)) {
          newId = this.renderId(parentIndexNodeAtDest, dropNodeLevel)
          result = visibleNodes.find(x => x.id === newId)
          break
        }

        if ((nextNodeLevel === NODE_LEVELS.CONFIG_LEVEL || nextNodeLevel === NODE_LEVELS.ZONE_LEVEL || !nextNodeLevel) && !nodeAtDest.children.length && nodeAtDestLevel === NODE_LEVELS.ZONE_LEVEL) {
          result = {
            ...nodeAtDest,
            id: `${nodeAtDest.id}/0`
          }
          break
        }
        break
      case NODE_LEVELS.CONDITION_LEVEL:
        if (nodeAtDestLevel >= dropNodeLevel) {
          newId = this.renderId(parentIndexNodeAtDest, dropNodeLevel)
          result = visibleNodes.find(x => x.id === newId)
          break
        }

        if (nextNodeLevel <= NODE_LEVELS.COMPONENT_LEVEL && !nodeAtDest.children.length && nodeAtDestLevel === NODE_LEVELS.COMPONENT_LEVEL) {
          result = {
            ...nodeAtDest,
            id: `${nodeAtDest.id}/0`
          }
          break
        }
        break
      default:

        break
    }
    return result
  }
}
