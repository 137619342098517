import { Component, Inject, OnInit } from '@angular/core'
import { FormGroup, FormControl, ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'

import { DropToInspectionTypeDetail, UpdateInspectionFormData } from 'src/app/app.state'
import { ERROR_MESSAGES, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { InspectionTypeFormData } from '../../views/inspection-type/inspection-type.component'
import { InspectionRowEditDialogData } from '../inspection-type-detail/inspection-type-detail.component'

const subFormValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const activeSubFormControl = control.get('inspectionDetailType')
  if (activeSubFormControl.value === '') { return null }
  const subFormValueControl = control.get(activeSubFormControl.value as string)
  if (!subFormValueControl) { return null }
  const hasError = subFormValueControl.value === null || Object.keys(subFormValueControl.value).length === 0
  return hasError ? { subFormError: true } : null
}

export class LabelErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

@Component({
  selector: 'app-inspection-detail-row-edit-dialog',
  templateUrl: './inspection-detail-row-edit-dialog.component.html',
  styleUrls: [
    '../base-edit-dialog/base-edit-dialog.component.scss',
    './inspection-detail-row-edit-dialog.component.scss',
  ],
})
export class InspectionDetailRowEditDialogComponent implements OnInit {
  editForm = new FormGroup({
    label: new FormControl('', {
      validators: [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(20),
      ]
    }),
    helper: new FormControl('', {
      validators: [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(40),
      ]
    }),
    inspectionDetailType: new FormControl(''),
    fieldType: new FormControl(''),
    text: new FormControl({}),
    int: new FormControl({}),
    float: new FormControl({}),
  }, {
    validators: [
      subFormValid,
    ],
  })

  inspectionDetailTypeOptions = [
    {
      value: 'text',
      displayName: 'SIMPLE_TEXT_OPTION',
      subName: ''
    }, {
      value: 'int',
      displayName: 'NUMERIC_OPTION',
      subName: ''
    }, {
      value: 'float',
      displayName: 'NUMERIC_OPTION',
      subName: 'NUMERIC_SUBNAME'
    }
  ]

  measurementTypeByDisplayType = {
    'fluid volume': 'fluidVolume',
  }

  categoryByMeasurementType = {
    'distance': 'odometer',
    'fluidVolume': 'fuelVolume',
    'time': 'engineHours',
    'mass': 'weight',
  }

  commonErrorMessages = {
    required: ERROR_MESSAGES.GENERIC_FIELD_REQUIRED,
    namePattern: ERROR_MESSAGES.INVALID_CHARACTERS
  }

  matcher = new LabelErrorStateMatcher()

  private inspectionTypeIndex: number
  private formDataIndex: number
  private isUpdate: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InspectionRowEditDialogData,
    private store: Store,
    private dialogRef: MatDialogRef<InspectionDetailRowEditDialogComponent>,
    private translateService: TranslateService) {
    if (data && data.row) {
      this.editForm.patchValue(this.convertFormDataToFormModel(data.row))
      this.inspectionTypeIndex = data.inspectionTypeIndex
      this.formDataIndex = data.formDataIndex
      this.isUpdate = data.isUpdate

      this.inspectionDetailTypeOptions = this.inspectionDetailTypeOptions.map(option => ({
        ...option,
        displayName: this.translateService.instant(option.displayName),
        subName: option.subName ? this.translateService.instant(option.subName) : '',
      }))
    }
  }

  ngOnInit(): void { }

  handleApply(): void {
    if (this.editForm.invalid) {
      return
    }

    const formValue = this.editForm.value
    let updateValue = {
      inspectionDetailType: formValue.inspectionDetailType,
      inspectionDetailCategory: 'other',
      ...formValue[formValue.inspectionDetailType],
      fieldNameLabel: formValue.label,
      hint: formValue.helper,
    }

    if (formValue.inspectionDetailType !== 'text') {
      const rawMeasurementType = formValue[formValue.inspectionDetailType].inspectionDetailMeasurementType
      if (rawMeasurementType) {
        const measurementType = this.measurementTypeByDisplayType[rawMeasurementType] || rawMeasurementType
        updateValue = {
          ...updateValue,
          inspectionDetailMeasurementType: measurementType,
          inspectionDetailCategory: this.categoryByMeasurementType[measurementType] || 'other',
        }
      }
    }

    const action = this.isUpdate ? UpdateInspectionFormData : DropToInspectionTypeDetail
    this.store.dispatch(new action(
      this.inspectionTypeIndex,
      this.formDataIndex,
      updateValue,
    ))
    this.dialogRef.close()

  }

  private convertFormDataToFormModel(formData: InspectionTypeFormData): any {
    const fieldType = formData.inspectionDetailType
    const result = {
      label: formData.fieldNameLabel,
      helper: formData.hint,
      inspectionDetailType: formData.inspectionDetailType,
      fieldType: fieldType,
    }

    if (fieldType) {
      result[fieldType] = { ...formData }
    }

    return result
  }

  getInspectionDetailTypeDisplayValue() {
    const fieldType = this.editForm.controls.inspectionDetailType.value
    const selectedItem = this.inspectionDetailTypeOptions.find(x => x.value === fieldType)
    return selectedItem.displayName
  }

  getInspectionDetailTypeSubName() {
    const fieldType = this.editForm.controls.inspectionDetailType.value
    const selectedItem = this.inspectionDetailTypeOptions.find(x => x.value === fieldType)
    return selectedItem.subName
  }
}
