<div mat-dialog-title
     fxLayout='row'
     fxLayoutAlign='space-between start'>
  <div class="dialog-title">{{title}}</div>
</div>
<mat-dialog-content>
  <div class="dialog-content">{{confirmMessage}}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button
          color="accent"
          mat-dialog-close
          (click)="handleCancel()">{{cancelText}}</button>
  <button mat-raised-button
          color="accent"
          mat-dialog-close
          (click)="handleApplyAll()">{{applyAllText}}</button>
  <button mat-raised-button
          color="accent"
          mat-dialog-close
          (click)="handleApplyJustThisOne()">{{applyJustThisOneText}}</button>
</mat-dialog-actions>