<ng-container>
  <div mat-dialog-title
       fxLayout='row'
       fxLayoutAlign='space-between start'>
    <div class="dialog-title">{{'CONDITION_EDIT_DIALOG_TITLE' | translate}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
              (click)="handleCancel()">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="node-edit-content">
    <form class="condition-edit-form"
          [formGroup]="inputForm">
      <mat-form-field appearance="outline"
                      [style.width.%]="100">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input matInput
               class="field-name"
               placeholder="{{'NAME_PLACEHOLDER' | translate}}"
               formControlName="name"
               [errorStateMatcher]="matcher"
               maxlength="100">
        <mat-error *ngIf="inputForm.controls.name.hasError('pattern')">
          {{'INVALID_CHARS_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          {{'NAME_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
            class="primary-button"
            (click)="handleCancel()">{{'CANCEL' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid || !(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="primary-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_ALL)">{{'APPLY_ALL' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid"
            *ngIf="(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="special-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_JUST_THIS_ONE)">{{'APPLY' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid"
            *ngIf="!(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="special-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_ALL)">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</ng-container>
