import {
  ComponentFactoryResolver,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatProgressSpinner } from '@angular/material/progress-spinner'

@Directive({
  selector: '[appLoadingSpinner]',
})
export class SpinnerDirective {
  // Config properties for Spinner.
  private color: ThemePalette = 'accent'
  private diameter: number = 22
  private spinner: MatProgressSpinner = null

  // Should public status property of spinner is loading or not.
  public isSpinning = null

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set appLoadingSpinnerColor(color: ThemePalette) {
    this.color = color
    if (this.spinner) {
      this.spinner.color = color
    }
  }

  @Input()
  set appLoadingSpinnerDiameter(diameter: number) {
    this.diameter = diameter
    if (this.spinner) {
      this.spinner.diameter = diameter
    }
  }

  @Input() set appLoadingSpinner(isShowSpinner: boolean) {
    if (isShowSpinner !== this.isSpinning) {
      // Make sure only one Element is displayed (Spinner or viewContainer).
      this.spinner = null
      this.viewContainer.clear()

      // Set status of Spinner.
      this.isSpinning = isShowSpinner
      if (isShowSpinner) {
        this.addLoadingSpinnerDynamic()
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef)
      }
    }
  }

  private addLoadingSpinnerDynamic() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner)
    const { instance } =
      this.viewContainer.createComponent<MatProgressSpinner>(componentFactory)
    instance.diameter = this.diameter
    instance.color = this.color
    instance.mode = 'indeterminate'
    instance._elementRef.nativeElement.style = 'margin:0 auto;'
    instance._elementRef.nativeElement.classList.add('spin-directive')
    this.spinner = instance
  }
}
