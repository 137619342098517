import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { MenuOption } from '../menu/menu.component'
import { MENU_OPTION_KEY } from 'src/app/constants/internationalized-constants-en'

export const MENU_INSPECTION_TYPE_ITEM = {
  Edit: { text: MENU_OPTION_KEY.EDIT },
  Copy: { text: MENU_OPTION_KEY.COPY },
  'Clone below': { text: MENU_OPTION_KEY.CLONE_BELOW },
  Delete: { text: MENU_OPTION_KEY.DELETE },
}

@Component({
  selector: 'app-inspection-type-card',
  templateUrl: './inspection-type-card.component.html',
  styleUrls: ['./inspection-type-card.component.scss'],
})
export class InspectionTypeCardComponent implements OnInit {
  @Input() inspection: any = {}
  @Input() isActive: boolean = false
  @Input() inspectionTypeIndex: number = null
  @Input() isAccessGranted: boolean = false

  @Output() click = new EventEmitter<string>()

  private adminOnlyOptions: MenuOption[] = [
    MENU_INSPECTION_TYPE_ITEM.Copy
  ]

  get menuItems(): MenuOption[] {
    return Object.values(MENU_INSPECTION_TYPE_ITEM).filter(menuItem => this.isAccessGranted || !this.adminOnlyOptions.map(item => item.text).includes(menuItem.text))
  }

  constructor() { }

  ngOnInit(): void { }

  handleClickContextMenuItem(item) {
    this.click.emit(item)
  }
}
