import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSelect } from '@angular/material/select'
import { Select } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import { AppState } from 'src/app/app.state'
import {
  CHIP_TYPE,
  MAX_ASSET_VIEW_GRID,
  MIN_ASSET_VIEW_GRID,
  ERROR_MESSAGES,
  POSITIVE_NUMBER_REGEX,
  internationalizedValues,
  CANCEL_OPTIONS,
  ACCEPT_OPTIONS,
  REMOVE_CURRENT,
  IS_GRID_VIEW_HIDDEN
} from 'src/app/constants/internationalized-constants-en'
import { isValidArray } from 'src/app/utils/utils'
import { EditDialogDataModel } from 'src/app/views/companies/model'
import { BaseEditDialogComponent } from '../base-edit-dialog/base-edit-dialog.component'
import { ChipsComponent } from '../chips/chips.component'
import { DialogComponent } from '../dialog/dialog.component'
import { AssetViewModel, AssetViewUploadModel } from '../tree/models/tree.model'
import { FormGeneratorService, FormType } from '../tree/services/form-generator/form-generator.service'
import { SvgHandlingService } from '../tree/services/svg-handling/svg-handling.service'

@Component({
  selector: 'app-config-level-edit-dialog',
  templateUrl: './config-level-edit-dialog.component.html',
  styleUrls: [
    '../base-edit-dialog/base-edit-dialog.component.scss',
    './config-level-edit-dialog.component.scss'
  ]
})
export class ConfigLevelEditDialogComponent extends BaseEditDialogComponent {

  @Select(AppState.getAccessStatus) isAccessGranted$: Observable<boolean>
  @Select(AppState.getAssetViewList) assetViewList$: Observable<AssetViewModel[]>
  @ViewChild('fileUpload') fileUpload: ElementRef
  @ViewChild('assetViewIdSelectBox') assetViewIdSelectBox: MatSelect

  minAssetViewGrid = MIN_ASSET_VIEW_GRID
  maxAssetViewGrid = MAX_ASSET_VIEW_GRID
  regexNumber = POSITIVE_NUMBER_REGEX
  CHIP_TYPE = CHIP_TYPE
  ASSET_MAP_VIEW_COORDINATES_LENGTH_MESSAGE = ERROR_MESSAGES.ASSET_MAP_VIEW_COORDINATES_LENGTH
  ASSET_MAP_VIEW_COORDINATES_REQUIRED_MESSAGE = ERROR_MESSAGES.ASSET_MAP_VIEW_COORDINATES_REQUIRED
  INVALID_NUMBER_MESSAGE = ERROR_MESSAGES.INVALID_NUMBER
  INVALID_ASSET_MAP_VIEW_COORDINATES_MESSAGE = ERROR_MESSAGES.INVALID_ASSET_MAP_VIEW_COORDINATES
  assetViewOptions$: Observable<AssetViewModel[]>
  defaultOptions = [
    {
      id: '',
      name: 'NONE_IMAGE'
    },
    {
      id: 'remove-current',
      name: 'REMOVE_CURRENT_OPTION'
    },
  ]
  removeCurrent: string = REMOVE_CURRENT
  defaultSelect: string = 'default-selected-id'
  assetViewList: AssetViewModel[] = []

  isDisplayEditConfigurationDialog = true

  formType: FormType = 'configuration'
  fileUploaded: any = null

  private originalAssetViewGridValue: [number, number]

  /** ECT2-806: Currently the grid being used (behind the map view image) is based on what numbers have been entered within the edit modal for that config.
   *  We remove those input fields and make the grid size more fixed.
   *  Fixed width, dynamic rows based on image loaded. */
  get isAssetViewGridHidden(): boolean {
    return IS_GRID_VIEW_HIDDEN
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditDialogDataModel,
    public dialogRef: MatDialogRef<ConfigLevelEditDialogComponent>,
    formGenerator: FormGeneratorService,
    private svgHandlingService: SvgHandlingService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService) {
    super(data, dialogRef, formGenerator)
    this.assetViewOptions$ = this.assetViewList$.pipe(
      filter(assetViewList => !!(assetViewList && assetViewList.length))
    )

    this.subscriptions.push(this.assetViewOptions$.subscribe((assetViewList) => {
      this.assetViewList = assetViewList
    }))
  }

  handlePostInit(): void {
    if (this.inputForm.value.assetViewGrid && Array.isArray(this.inputForm.value.assetViewGrid)) {
      this.originalAssetViewGridValue = this.inputForm.value.assetViewGrid.slice()
    } else {
      this.inputForm.controls.assetViewGrid.setValue([])
      this.originalAssetViewGridValue = [null, null]
    }
  }


  handleChangeChip(valueChange: string[] | number[], field: string) {
    if (this.inputForm.controls[field]) {
      this.inputForm.controls[field].setValue(valueChange)
    }
  }

  handleChipAssetTypeStatusChange(assetTypeChip: Record<string, boolean | string>) {
    if (!assetTypeChip) {
      return
    }

    if (!assetTypeChip.status) {
      this.setErrorControl('assetType', 'invalidInput')
    } else if (!this.inputForm.controls.assetType.value.length && !assetTypeChip.chipInputValue) {
      this.setErrorControl('assetType', 'required')
    } else if (this.inputForm.controls.assetType.value.includes(((assetTypeChip.chipInputValue as string) || '').trim())) {
      this.setErrorControl('assetType', 'duplicated')
    } else {
      this.inputForm.controls.assetType.setErrors(null)
    }
  }

  handleChipAssetViewGridTypeStatusChange(status: boolean, chips: ChipsComponent) {
    // Handle to set an error status of Asset View Grid when input changes
    if (!status) {
      // Set an invalid input error when Asset View Grid isn't a positive integers
      this.setErrorControl('assetViewGrid', 'invalidInput')
    } else if (!this.inputForm.controls.assetViewGrid.value.length && !chips.chipInputValue) {
      // Set a required error when Asset View Grid's length is 0 and input value is empty
      this.setErrorControl('assetViewGrid', 'required')
    } else if (
      !chips.chipInputValue &&
      this.inputForm.controls.assetViewGrid.value.length < this.maxAssetViewGrid) {
      // Set an invalid length error when Asset View Grid's length is less than 2 and input value is empty
      this.setErrorControl('assetViewGrid', 'invalidMinLengthChips')
    } else if (!chips.chipInputValue &&
      this.inputForm.controls.assetViewGrid.value.length === this.maxAssetViewGrid &&
      !this.inputForm.controls.assetViewGrid.value.every((value) => value > 0)) {
      // Set an invalid Asset View Grid error when Asset View Grid has zero value
      this.setErrorControl('assetViewGrid', 'invalidAssetViewGrid')
    } else {
      this.inputForm.controls.assetViewGrid.setErrors(null)
    }
  }

  setErrorControl(field: string, errorName: string) {
    this.inputForm.controls[field].setErrors({ [errorName]: true })
  }

  openRemoveImageDialog() {
    this.isDisplayEditConfigurationDialog = false
    const removeImageDialogRef = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => {
          this.handleCancel()
          removeImageDialogRef.close()
        },
        onAccept: () => {
          this.handleAccept()
          removeImageDialogRef.close()
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('YES'),
        title: this.translateService.instant('REMOVE_ASSET_VIEW_IMAGE_TITLE'),
        message: this.translateService.instant('REMOVE_ASSET_VIEW_IMAGE_MSG'),
      },
      panelClass: 'remove-asset-view-image-dialog'
    })

    const subscription = removeImageDialogRef.backdropClick().subscribe(result => {
      this.handleCancel()
      subscription.unsubscribe()
    })
  }

  openUploadDialog() {
    const element: HTMLElement = this.fileUpload.nativeElement
    element.click()
  }

  async onChange($event) {
    this.inputForm.controls.assetViewId.setValue($event)

    if ($event === this.removeCurrent) {
      return
    }

    const selectedAssetView = $event === this.defaultSelect
      ? this.assetViewList.find(option => this.fileUploaded.assetViewName === option.assetViewName)
      : this.assetViewList.find(option => $event === option.assetViewId)

    if (!selectedAssetView || !selectedAssetView.assetViewData) {
      return
    }

    /**
     * ECT2-1213: When change other image:
     * - Using the default assetViewGrid supplied by AssetViewList from Evir API.
     * - But sometimes, there will some SVG images (old image uploaded by user before) have no default assetViewGrid, force we have to calculate view grid
     */
    const assetViewGrid = selectedAssetView.assetViewDefaults?.assetViewGrid || await this.svgHandlingService.getGridSizeFromSVGsource(selectedAssetView.assetViewData)
    this.inputForm.controls.assetViewGrid.setValue(assetViewGrid)
  }

  chooseFile(file) {
    const fileContent = file.target.files[0]
    let fileReader = new FileReader()
    fileReader.onload = async (e) => {
      const assetViewData = fileReader.result.toString()
      if (this.svgHandlingService.isSvgFile(assetViewData)) {
        /**
         * ECT2-1213: When upload new image:
         * - With `non-empty asset image config`: The `assetViewGrid` will be get from config. Admin users will make sure new image's size have match exactly with current config's assetViewGrid.
         * - With `empty asset image config`: The `assetViewGrid` will be calculated from IB web's algorithms.
         */
        const assetViewGrid = isValidArray(this.inputForm.value.assetViewGrid)
          ? this.inputForm.value.assetViewGrid
          : await this.svgHandlingService.getGridSizeFromSVGsource(assetViewData)

        this.fileUploaded = {
          assetViewName: this.svgHandlingService.getFileName(fileContent.name),
          proprietary: false,
          assetViewData: assetViewData,
          /** ECT2-1213: Add new `assetViewDefaults` that include `assetViewGrid` to body when POST new image to Evir API. */
          assetViewDefaults: {
            assetViewGrid: assetViewGrid,
            assetViewLocations: [[0, 0]] /** Workaround: To bypass `required key assetViewLocations` API validation only */
          }
        } as AssetViewUploadModel
        this.inputForm.controls.assetViewId.setValue(this.defaultSelect)
        this.fileUpload.nativeElement.value = ''
        this.cdRef.detectChanges()

        this.inputForm.controls.assetViewGrid.setValue(assetViewGrid)
      }
      this.assetViewIdSelectBox.close()
    }
    fileReader.readAsText(fileContent)
  }

  handleAccept() {
    if (this.inputForm.invalid) {
      return
    }

    this.dialogRef.close({ data: this.inputForm.value, isReflect: this.isReflect, fileUploaded: this.fileUploaded })
  }

  handleAcceptWithConfimation() {
    if (!this.isAssetViewGridChanged()) {
      this.handleAccept()
      return
    }

    this.dialogRef.addPanelClass('hidden-dialog')
    const confirmDialog = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => {
          this.dialogRef.removePanelClass('hidden-dialog')
          confirmDialog.close()
        },
        onAccept: () => {
          this.handleAccept()
          confirmDialog.close()
        },
        cancelText: CANCEL_OPTIONS.CANCEL,
        acceptText: ACCEPT_OPTIONS.OK,
        title: internationalizedValues.CHANGE_ASSET_LOCATION_TITLE,
        message: internationalizedValues.CHANGE_ASSET_LOCATION_MESSAGE,
      },
      panelClass: 'asset-coordination-confirm-dialog',
    })
  }

  getValidAssetTypeStatus() {
    const isValidAssetTypeValue = !this.inputForm.controls.assetType.hasError('required')
      && !this.inputForm.controls.assetType.hasError('invalidInput')
      && !this.inputForm.controls.assetType.hasError('duplicated')
    const isValidLastAssetType = !this.inputForm.controls.assetType.hasError('duplicateLastAssetType')
      && !this.inputForm.controls.assetType.hasError('emptyLastAssetType')
      && !this.inputForm.controls.assetType.hasError('invalidLastAssetType')
    return {
      isValidAssetTypeValue: isValidAssetTypeValue,
      isValidLastAssetType: isValidLastAssetType,
    }
  }

  private isAssetViewGridChanged(): boolean {
    const currentValue: [number, number] = this.inputForm.value.assetViewGrid || [null, null]
    return currentValue.some((value, index) => value !== this.originalAssetViewGridValue[index])
  }
}
