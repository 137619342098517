<app-spinner id="loading-companies">
  <div class="companies">
    <div class="title-content">
      <div class="title">
        <h1>Inspection Builder</h1>
      </div>
      <app-update-config-group-button
        [isSaving]="isSaving"
        [isTesting]="isTesting"
        [isDeploying]="isDeploying"
        [isUpdatingConfiguration]="isUpdatingConfiguration"
        [isValidatedConfiguration]="isValidatedConfiguration"
        (shouldSaveConfiguration)="validateDataConfigurationOnSave()"
        (shouldPublishConfiguration)="openConfirmDialog(CONFIRM_DIALOG_TYPE.PUBLISH)"
        (shouldUndoConfiguration)="undoConfigurationChanged()"
        (shouldTestConfiguration)="openConfirmDialog(CONFIRM_DIALOG_TYPE.TEST)"
      ></app-update-config-group-button>
    </div>

    <div class="filter-divider"></div>
    <app-spinner [shouldLoading]="shouldLoading$">
      <div *ngIf="shouldLoading$ | async">
        <app-navigation-tab
          [tabLinks]="tabLinks"
          (tabIndex)="switchTab($event)"
        ></app-navigation-tab>
        <app-configuration-layout *ngIf="currentCompanyId"></app-configuration-layout>
      </div>
    </app-spinner>
  </div>
</app-spinner>
