import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { internationalizedValues, CANCEL_OPTIONS, ACCEPT_OPTIONS, CONFIRM_REFLECT_ACTION } from 'src/app/constants/internationalized-constants-en'
import { EditControlModel, EditDataModel, EditDialogDataModel } from 'src/app/views/companies/model'
import { cloneDeep } from 'lodash'
import { TreeFlatNode } from '../tree/models/tree.model'
import { FormGeneratorService, FormType } from '../tree/services/form-generator/form-generator.service'


export class InvalidNameMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && (control.touched || control.dirty) && control.invalid)
  }
}

@Component({
  selector: 'app-base-edit-dialog',
  template: ''
})
export class BaseEditDialogComponent implements OnInit, OnDestroy {

  editNode: TreeFlatNode
  title: string = internationalizedValues.EDIT_TITLE
  cancelText = CANCEL_OPTIONS.CANCEL
  acceptText = ACCEPT_OPTIONS.APPLY
  applyToOneText = ACCEPT_OPTIONS.APPLY_TO_ONE
  applyToAllText = ACCEPT_OPTIONS.APPLY_TO_ALL
  editData: EditDataModel = { name: '' }
  controls: EditControlModel[] = []
  inputForm: FormGroup = new FormBuilder().group({})
  subscriptions: Subscription[] = []
  matcher = new InvalidNameMatcher()
  isReflect = true
  CONFIRM_REFLECT_ACTION = CONFIRM_REFLECT_ACTION

  formType: FormType = null
  customDataKey: string[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditDialogDataModel,
    public dialogRef: MatDialogRef<BaseEditDialogComponent>,
    private formGenerator: FormGeneratorService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.editNode = cloneDeep(this.data.node)
      const customDataDict = this.customDataKey.reduce((acc, value) => ({
        ...acc,
        [value]: this.data[value],
      }), {})
      this.editData = {
        ...this.editNode.value,
        ...customDataDict,
        name: this.editNode.name,
      }
    }

    this.inputForm = this.formGenerator.generateFormGroup(this.formType, true)
    this.inputForm.patchValue(this.editData)
    this.inputForm.updateValueAndValidity()
    this.handlePostInit()
  }

  handlePostInit(): void {
    // intent for child class to override
    return
  }

  handleCancel() {
    this.dialogRef.close()
  }

  handleAccept() {
    if (this.inputForm.invalid) {
      return
    }

    this.dialogRef.close({ data: this.inputForm.value, isReflect: this.isReflect })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  protected getControlValueByName<T>(controlName: string): T {
    const control = this.getControlByName(controlName)
    return control && control.value
  }

  protected getControlByName(controlName: string): AbstractControl {
    return this.inputForm.get(controlName)
  }

  protected setControlValueByName<T>(controlName: string, value: T) {
    const control = this.getControlByName(controlName)
    if (control) {
      control.setValue(value)
    }
  }

  beforeAccept(value: CONFIRM_REFLECT_ACTION) {
    if (value === CONFIRM_REFLECT_ACTION.CANCEL) {
      return
    } else {
      this.isReflect = value === CONFIRM_REFLECT_ACTION.APPLY_ALL
      this.handleAccept()
    }
  }

}
