import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ERROR_MESSAGES, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { CheckDuplicatePieceNameService, checkDuplicatePieceNameValidator } from '../template-library/services/check-duplicate-piece-name/check-duplicate-piece-name.service'

export class PieceNameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && control.invalid
  }
}

export class AddPieceDialogDataModel {
  name: string
  hasNodeChildren: boolean
  isPremadeField: boolean
  title: string
}

@Component({
  selector: 'app-add-piece-dialog',
  templateUrl: './add-piece-dialog.component.html',
  styleUrls: ['./add-piece-dialog.component.scss']
})
export class AddPieceDialogComponent implements OnInit {
  addPieceTitle: string
  hasNodeChildren: boolean
  addPieceForm: FormGroup
  isPremadeField: boolean

  NAME_REQUIRED_MESSAGE = ERROR_MESSAGES.NAME_REQUIRED
  INVALID_CHARACTERS_MESSAGE = ERROR_MESSAGES.INVALID_CHARACTERS
  DUPLICATE_PIECE_NAME_MESSAGE = ERROR_MESSAGES.DUPLICATE_PIECE_NAME

  matcher = new PieceNameErrorStateMatcher()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddPieceDialogDataModel,
    public dialogRef: MatDialogRef<AddPieceDialogComponent>,
    private formBuilder: FormBuilder,
    private pieceNameService: CheckDuplicatePieceNameService,
  ) {
    if (data) {
      this.addPieceTitle = data.title
      this.hasNodeChildren = data.hasNodeChildren
      this.isPremadeField = data.isPremadeField
      this.addPieceForm = this.formBuilder.group({
        name: new FormControl(data.name, [
          Validators.required,
          Validators.pattern(NAME_REGEX),
        ], checkDuplicatePieceNameValidator(this.pieceNameService)),
        isIncludedAllSublayers: new FormControl(this.hasNodeChildren),
        isAdminViewOnly: new FormControl(false)
      })
    }
  }

  ngOnInit(): void { }

  handleApply() {
    if (this.addPieceForm.invalid) {
      return
    }

    this.dialogRef.close(this.addPieceForm.value)
  }
}
