import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'

import { CONFIGURATION_ERROR_MESSAGES, CONFIGURATION_ERROR_TITLES, i18nParams } from 'src/app/constants/internationalized-constants-en'
import { ConfigurationErrorModel } from '../tree/models/tree.model'

export class ErrorDialogDataModel {
  errorDialogTitle?: string
  errorItemTitles?: Record<string, string>
  errors: ConfigurationErrorModel[]
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  errorItemTitles: Record<string, string> = CONFIGURATION_ERROR_TITLES
  errorList: ConfigurationErrorModel[] = []
  isMaximizedErrorDialog: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogDataModel,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    public translateService: TranslateService,
  ) {
    if (this.data && this.data.errors) {
      this.errorList = this.data.errors
    }
  }

  ngOnInit(): void { }

  handleErrorItemTitle(errorType: string): string {
    return this.translateService.instant(this.errorItemTitles[errorType])
  }

  handleErrorMessage(errorType: string, errorData: string[]): string {
    if (!errorType || !(errorData && errorData.length)) {
      return ''
    }

    const errorTypeKey = CONFIGURATION_ERROR_MESSAGES[errorType] || ''
    return this.translateService.instant(errorTypeKey, i18nParams.CONFIGURATION_ERROR_DIALOG(errorData, this.translateService.currentLang))
  }

  maximizeErrorDialog() {
    this.isMaximizedErrorDialog = true
  }

  closeErrorDialog() {
    this.dialogRef.close()
  }

}
