import { Injectable } from '@angular/core'
import {
  FormBuilder, FormGroup
} from '@angular/forms'

import { DEFAULT_GENERATORS, getFormGroupFactory } from './form-groups'
import { FormGroupGenerator } from './form-groups/interface'

export type FormType = 'configuration' | 'zone' | 'component' | 'condition'

/**
 * Service to generate form group base on the form type.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class FormGeneratorService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Generate form group base on form type.
   *
   * @param formType Target form type.
   * @param isModalFormGroup True if generate for modal usage, otherwise false.
   * @return FormGroup instance.
   */
  generateFormGroup(formType: FormType, isModalFormGroup: boolean): FormGroup {
    const generator = this.getFormGenerator(formType)
    return generator.generateFormGroup(this.formBuilder, isModalFormGroup)
  }

  /**
   * Get generator for a target form type.
   *
   * @param formType Target form type.
   * @return FormGroupGenerator instance
   */
  getFormGenerator(formType: FormType): FormGroupGenerator {
    return getFormGroupFactory()[formType] || DEFAULT_GENERATORS
  }

}
