import { Pipe, PipeTransform } from '@angular/core'

import { TreeFlatNode, ZoneNameModel } from 'src/app/components/tree/models/tree.model'
import { NODE_LEVELS, NODE_LEVELS_INDEX } from 'src/app/constants/internationalized-constants-en'

@Pipe({
  name: 'addZoneInspectionTypes'
})
export class AddZoneInspectionTypesPipe implements PipeTransform {

  transform(node: TreeFlatNode, zoneNameList: ZoneNameModel[]): string {
    if (node.level !== NODE_LEVELS.ZONE_LEVEL || !zoneNameList.length ||
      !(node.value && node.value.zoneInspectionTypes && node.value.zoneInspectionTypes.length)) {
      return ''
    }

    const nodeIndexes = node.id.split('/').map(value => Number(value))
    const duplicatedZoneNameList = zoneNameList.filter(zoneNameItem =>
      zoneNameItem.parentIndex === nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] && zoneNameItem.zoneName === node.name)
    return duplicatedZoneNameList.length > 1
      ? ` - ${node.value.zoneInspectionTypes.join(', ')}`
      : ''
  }

}
