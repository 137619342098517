import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'

import { firstVisitUrl, resetFirstVisitUrl } from 'src/app/app.component'

@Injectable({
  providedIn: 'root'
})
export class NoPermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {

          if (firstVisitUrl === '/no-permission') {
            resetFirstVisitUrl()
            this.router.navigate(['/'])
            return of(false)
          }

          return of(true)
        }

        return this.authService.loginWithRedirect().pipe(map(() => false))
      })
    )
  }
}
