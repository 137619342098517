import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { NOTIFICATION_TYPES } from 'src/app/constants/internationalized-constants-en'
import { NotificationSnackbarComponent } from '../notification-snackbar.component'

@Injectable({
  providedIn: 'root'
})
export class NotificationSnackbarService {

  constructor(private snackbar: MatSnackBar) { }

  openNotificationSnackbar(
    message: string, notificationType: NOTIFICATION_TYPES,
    detailedError?: Record<string, string[]>, duration?: number) {
    this.snackbar.openFromComponent(NotificationSnackbarComponent, {
      duration: notificationType === NOTIFICATION_TYPES.SUCCESS ? duration : undefined,
      panelClass: notificationType === NOTIFICATION_TYPES.SUCCESS
        ? 'success-notification-snackbar'
        : 'failed-notification-snackbar',
      data: {
        notificationMessage: message,
        detailedError: detailedError,
        notificationType: notificationType
      }
    })
  }
}
