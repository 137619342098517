<div 
  class="unit-switcher-container"
  fxLayout="column"
>
  <div 
    class="unit-item"
    [class.active]="unit === selected"
    *ngFor="let unit of units; let i=index" >
    {{ unit }}
  </div>
</div>
