import { Inject, Injectable } from '@angular/core'
import { datadogRum } from '@datadog/browser-rum'

import { APP_VERSION_STRING } from 'src/app/version'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class DataDogRumService {
  constructor(
    @Inject(APP_VERSION_STRING) private appVersion: string,
  ) {
    if (environment.datadog.enabled) {
      console.log('Datadog initialized')

      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        env: environment.production ? 'prod' : 'staging',
        sampleRate: 100,
        version: this.appVersion,
        trackUserInteractions: environment.datadog.trackUserInteractions,
        defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel as 'mask-user-input' | 'mask',
        service: environment.datadog.service,
        excludedActivityUrls: [
          url => environment.datadog.excludedActivityUrls.includes(new URL(url).hostname),
        ],
      })

      datadogRum.startSessionReplayRecording()
    }
  }
}
