<div mat-dialog-title
  fxLayout='row'
  fxLayoutAlign='space-between start'>
  <div class="dialog-title">{{title}}</div>
</div>
<mat-dialog-content>
  <div class="dialog-content">{{confirmMessage}}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
    class="primary-button"
    mat-dialog-close
    appThrottleClick
    (throttledClick)="handleCancel()"
    >{{cancelText}}</button>
  <button mat-button
    class="special-button"
    mat-dialog-close
    appThrottleClick
    (throttledClick)="handleAccept()"
    >{{acceptText}}</button>
</mat-dialog-actions>
