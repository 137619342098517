<div mat-dialog-content
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [ngClass]="{'maximize-height-error-dialog': isMaximizedErrorDialog}">
  <div fxLayout="row"
    fxLayoutAlign="space-between start">
    <div class="error-dialog-title">{{ 'CONFIGURATION_ERROR_TITLE' | translate }}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close error-dialog-close-icon"
      (click)="closeErrorDialog()">close</mat-icon>
  </div>
  <div class="error-dialog-content thin-scrollbar"
    [ngClass]="{'maximize-height-error-dialog-content': isMaximizedErrorDialog}">
    <ng-container *ngFor="let error of errorList; last as isLastError;">
      <mat-expansion-panel [@.disabled]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{error.configurationName}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let errorItem of error.configurationErrorList | keyvalue; last as isLastErrorItem">
        <div class="error-item"
          [ngClass]="{'last-error-item': isLastErrorItem}">
          <div class="error-item-title">
            {{handleErrorItemTitle(errorItem.key) | uppercase}}
          </div>
          <div class="error-item-content">
            {{handleErrorMessage(errorItem.key, errorItem.value) | capitalizeFirstLetter: true }}
          </div>
        </div>
        <mat-divider class="error-item-divider" *ngIf="!isLastErrorItem"></mat-divider>
      </ng-container>
    </mat-expansion-panel>
    <mat-divider *ngIf="!isLastError"></mat-divider>
    </ng-container>
  </div>
  <div class="error-dialog-action">
    <button class="maximize-button" (click)="maximizeErrorDialog()">
      <div class="maximize-icon"></div>
    </button>
  </div>
</div>
