import { NgModule } from '@angular/core'
import { FeatureFlagDirective } from './feature-flags.directive'

@NgModule({
  declarations: [
    FeatureFlagDirective,
  ],
  imports: [],
  providers: [],
})
export class FeatureFlagsModule { }
