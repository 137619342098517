import { Injectable } from '@angular/core'

import { ComponentModel, ConfigZoneModel, EvirLanguageDictionary, TreeFlatNode, ZoneLayoutModel } from 'src/app/components/tree/models/tree.model'
import { LanguageDictionaryHandlingService, currentLanguageCode } from 'src/app/components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import { LANGUAGE_CODE_BY_LANGUAGE_ID, LANGUAGE_CONFIG_TYPES, LANGUAGE_IDS, NODE_LEVELS, NODE_LEVELS_INDEX, TEMPLATE_TYPES_VALUE } from 'src/app/constants/internationalized-constants-en'
import { isValidArray } from 'src/app/utils/utils'
import { ComponentPieceModel, ConditionPieceModel, ConfigurationPieceModel, FormDataPieceModel, InspectionDetailPieceModel, TemplateLibraryPieceModel, ZonePieceModel } from '../../models/template-library.model'

@Injectable({
  providedIn: 'root'
})
export class TemplateLibraryPieceConvertingService {

  constructor(public langDictionaryService: LanguageDictionaryHandlingService) { }

  formatInspectionTypePieces(inspectionTypePieceList: TemplateLibraryPieceModel[], langDict: EvirLanguageDictionary): TemplateLibraryPieceModel[] {
    return inspectionTypePieceList.map(inspectionPiece => {
      const { inspectionDetailLanguageKey, inspectionDescriptionLanguageKey, formData, ...rest } = inspectionPiece.piece as InspectionDetailPieceModel

      const inspectionDetailLangKey = this.langDictionaryService.getLangKeyByString(inspectionDetailLanguageKey, langDict.languageStrings, LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
      const inspectionDescriptionLangKey = this.langDictionaryService.getLangKeyByString(inspectionDescriptionLanguageKey, langDict.languageStrings, LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])

      const displayName = this.langDictionaryService.convertLangKeyToString(inspectionDetailLangKey, langDict, currentLanguageCode) ||
        this.langDictionaryService.convertLangKeyToString(inspectionDetailLangKey, langDict, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]) ||
        inspectionDetailLanguageKey

      return {
        ...inspectionPiece,
        name: displayName,
        piece: {
          ...rest,
          inspectionDetailLangKey,
          inspectionDetailLanguageKey,
          inspectionDescriptionLangKey,
          inspectionDescriptionLanguageKey,
          ...(isValidArray(formData) && { formData: formData.map(formDataPiece => this.formatFormDataPieceHelper(formDataPiece, langDict)) }),
        }
      }
    })
  }

  formatPremadeFieldPieces(premadePieceList: TemplateLibraryPieceModel[], langDict: EvirLanguageDictionary): TemplateLibraryPieceModel[] {
    return premadePieceList.map(premadePiece => {
      const pieceData = premadePiece.piece as FormDataPieceModel
      const displayName = this.langDictionaryService.convertLangKeyToString(pieceData.fieldNameLangKey, langDict, currentLanguageCode) ||
        this.langDictionaryService.convertLangKeyToString(pieceData.fieldNameLangKey, langDict, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]) ||
        pieceData.fieldNameLabelKey

      return {
        ...premadePiece,
        piece: {
          ...pieceData,
          fieldNameLabelKey: displayName,
        }
      }
    })
  }

  convertToTemplateLibraryPiece(
    configurationList: ZoneLayoutModel[], languageDictionary: EvirLanguageDictionary,
    node: TreeFlatNode, isIncludedAllSublayers: boolean
  ) {
    const nodeIndexList = node.id.split('/').map(value => Number(value))
    const configuration = configurationList[nodeIndexList[NODE_LEVELS_INDEX.CONFIG_LEVEL]]
    const prepareTemplateLibraryPiece = (pieceType, pieceData) => ({ pieceType: pieceType, piece: pieceData })
    let tagComponent: number

    switch (node.level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        return prepareTemplateLibraryPiece(
          TEMPLATE_TYPES_VALUE.CONFIGURATION,
          this.convertToConfigurationPieceData(configuration, languageDictionary, isIncludedAllSublayers)
        )
      case NODE_LEVELS.ZONE_LEVEL:
        return prepareTemplateLibraryPiece(
          TEMPLATE_TYPES_VALUE.ZONE,
          this.convertToZonePieceData(configuration.configZones[nodeIndexList[NODE_LEVELS_INDEX.ZONE_LEVEL]],
            configuration.components, languageDictionary, isIncludedAllSublayers)
        )
      case NODE_LEVELS.COMPONENT_LEVEL:
        tagComponent = configuration.configZones[nodeIndexList[NODE_LEVELS_INDEX.ZONE_LEVEL]].tagComponents[nodeIndexList[NODE_LEVELS_INDEX.COMPONENT_LEVEL]]
        return prepareTemplateLibraryPiece(
          TEMPLATE_TYPES_VALUE.COMPONENT,
          this.convertToComponentPieceData(configuration.components[tagComponent], languageDictionary, isIncludedAllSublayers)
        )
      case NODE_LEVELS.CONDITION_LEVEL:
        tagComponent = configuration.configZones[nodeIndexList[NODE_LEVELS_INDEX.ZONE_LEVEL]].tagComponents[nodeIndexList[NODE_LEVELS_INDEX.COMPONENT_LEVEL]]
        return prepareTemplateLibraryPiece(
          TEMPLATE_TYPES_VALUE.CONDITION,
          this.convertToConditionPieceData(configuration.components[tagComponent], nodeIndexList[NODE_LEVELS_INDEX.CONDITION_LEVEL], languageDictionary)
        )
      default:
        return
    }
  }

  private convertToConfigurationPieceData(
    configuration: ZoneLayoutModel, languageDictionary: EvirLanguageDictionary,
    isIncludedAllSublayers: boolean
  ): ConfigurationPieceModel {
    return {
      assetType: configuration.assetType,
      assetViewGrid: configuration.assetViewGrid,
      assetViewId: configuration.assetViewId,
      zoneLayoutLanguageKey: this.langDictionaryService.convertLangKeyToString(configuration.zoneLayoutLangKey, languageDictionary),
      configZones: isIncludedAllSublayers && !!(configuration.configZones && configuration.configZones.length)
        ? configuration.configZones.map(configZone => this.convertToZonePieceData(configZone, configuration.components, languageDictionary, isIncludedAllSublayers))
        : []
    } as ConfigurationPieceModel
  }

  private convertToZonePieceData(
    zone: ConfigZoneModel, components: ComponentModel[],
    languageDictionary: EvirLanguageDictionary, isIncludedAllSublayers: boolean
  ): ZonePieceModel {
    return {
      assetViewLocation: zone.assetViewLocation,
      tagNameKey: this.langDictionaryService.convertLangKeyToString(zone.tagLangKey, languageDictionary),
      tagNumber: zone.tagNumber,
      tagType: this.langDictionaryService.convertLangKeyToString(zone.tagTypeLangKey, languageDictionary),
      zoneInspectionTypes: zone.zoneInspectionTypes,
      tagComponents: isIncludedAllSublayers && !!(zone.tagComponents && zone.tagComponents.length)
        ? zone.tagComponents.map(tagComponent => this.convertToComponentPieceData(components[tagComponent], languageDictionary, isIncludedAllSublayers))
        : []
    } as ZonePieceModel
  }

  private convertToComponentPieceData(component: ComponentModel, languageDictionary: EvirLanguageDictionary, isIncludedAllSublayers: boolean): ComponentPieceModel {
    return {
      maxSeverity: component.maxSeverity,
      minSeverity: component.minSeverity,
      componentNameKey: this.langDictionaryService.convertLangKeyToString(component.componentLangKey, languageDictionary),
      suggestedConditions: isIncludedAllSublayers && !!(component.suggestedConditionLangKeys && component.suggestedConditionLangKeys.length)
        ? component.suggestedConditionLangKeys.map(condition => this.langDictionaryService.convertLangKeyToString(condition, languageDictionary))
        : []
    } as ComponentPieceModel
  }

  private convertToConditionPieceData(component: ComponentModel, conditionIndex: number, languageDictionary: EvirLanguageDictionary): ConditionPieceModel {
    const condition = component.suggestedConditionLangKeys[conditionIndex]
    return {
      name: this.langDictionaryService.convertLangKeyToString(condition, languageDictionary)
    }
  }

  private formatFormDataPieceHelper(formDataPiece: FormDataPieceModel, langDict: EvirLanguageDictionary): FormDataPieceModel {
    return {
      ...formDataPiece,
      fieldNameLangKey: this.langDictionaryService.getLangKeyByString(formDataPiece.fieldNameLabelKey, langDict.languageStrings, LANGUAGE_CONFIG_TYPES.FORM_DATA, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]),
      hintLangKey: this.langDictionaryService.getLangKeyByString(formDataPiece.hintKey, langDict.languageStrings, LANGUAGE_CONFIG_TYPES.FORM_DATA, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]),
      ...(isValidArray(formDataPiece.select) && { selectLangKeys: formDataPiece.select.map(option => this.langDictionaryService.getLangKeyByString(option, langDict.languageStrings, LANGUAGE_CONFIG_TYPES.FORM_DATA, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])) }),
    }
  }
}
