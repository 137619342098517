import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import {
  AddPremadeFieldToFormLibrary,
  CopyInspectionTypeDetailRow,
  DropToInspectionTypeDetail,
  GetTemplateLibraryPieces,
  RemoveInspectionTypeDetailRow,
  ToggleInspectionAdditionalInformation,
  transformToInspectionTypeFormData,
} from 'src/app/app.state'
import { Constants, FORM_LIBRARY_DROP_TYPE, MENU_OPTION_KEY, NOTIFICATION_TYPES } from 'src/app/constants/internationalized-constants-en'
import { InspectionType, InspectionTypeFormData, unmodified } from 'src/app/views/inspection-type/inspection-type.component'
import { AddPieceDialogComponent } from '../add-piece-dialog/add-piece-dialog.component'
import { DialogComponent } from '../dialog/dialog.component'
import { InspectionDetailRowCheckboxEditDialogComponent } from '../inspection-detail-row-checkbox-edit-dialog/inspection-detail-row-checkbox-edit-dialog.component'
import { InspectionDetailRowDropdownEditDialogComponent } from '../inspection-detail-row-dropdown-edit-dialog/inspection-detail-row-dropdown-edit-dialog.component'
import { InspectionDetailRowPhotoEditDialogComponent } from '../inspection-detail-row-photo-edit-dialog/inspection-detail-row-photo-edit-dialog.component'
import { InspectionDetailRowEditDialogComponent } from '../inspection-detail-row-edit-dialog/inspection-detail-row-edit-dialog.component'
import { MenuOption } from '../menu/menu.component'
import { NotificationSnackbarService } from '../notification-snackbar/services/notification-snackbar.service'
import { FormDataModel } from '../tree/models/tree.model'

export interface InspectionRowEditDialogData {
  inspectionTypeIndex: number
  formDataIndex: number
  row: InspectionTypeFormData
  // This property to mark the action dispatched to the store as edit or drag and drop
  isUpdate: boolean
}
interface FormDataWithUnit extends InspectionTypeFormData {
  availableUnits: string[]
  selectedUnit: string
}

interface InspectionTypeWithUnit extends InspectionType {
  formData: FormDataWithUnit[]
}

export const MENU_INSPECTION_TYPE_DETAIL_ROW = {
  Edit: { text: MENU_OPTION_KEY.EDIT },
  Copy: { text: MENU_OPTION_KEY.COPY },
  // ECT2-1326: Set disable true to temporarily disable this option
  'Add to library': { text: MENU_OPTION_KEY.ADD_TO_LIBRARY, disable: true },
  Delete: { text: MENU_OPTION_KEY.DELETE }
}

export const INSPECTION_DETAIL_TYPE = {
  TEXT: 'text',
  NUMBERIC: 'int',
  CHECKBOX: 'bool',
  DROPDOWN: 'select',
  FLOAT: 'float',
  PHOTO: 'photo',
}

@Component({
  selector: 'app-inspection-type-detail',
  templateUrl: './inspection-type-detail.component.html',
  styleUrls: ['./inspection-type-detail.component.scss'],
})
export class InspectionTypeDetailComponent implements OnInit, OnChanges {
  @Input() inspectionType: InspectionType = null
  @Input() index: number
  @Input() isDraggingFormLibraryField: boolean
  @Input() isAccessGranted: boolean = false
  @Input() maxImageCount: number

  transformedInspectionType: InspectionTypeWithUnit

  private adminOnlyOptions: MenuOption[] = [
    MENU_INSPECTION_TYPE_DETAIL_ROW.Copy,
    MENU_INSPECTION_TYPE_DETAIL_ROW['Add to library']
  ]

  get menuInspectionTypeDetailRow(): MenuOption[] {
    return Object.values(MENU_INSPECTION_TYPE_DETAIL_ROW).filter(menuItem => this.isAccessGranted || !this.adminOnlyOptions.map(item => item.text).includes(menuItem.text))
  }

  get inspectionFormData(): FormDataWithUnit[] {
    if (this.transformedInspectionType && this.transformedInspectionType.formData && this.transformedInspectionType.formData.length) {
      return this.transformedInspectionType.formData
    }

    return []
  }

  get isLastItemCheckbox(): boolean {
    if (this.inspectionFormData.length > 0) {
      return (this.inspectionFormData[this.inspectionFormData.length - 1] || {}).inspectionDetailType === 'bool'
    } else {
      return false
    }
  }

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackbarService: NotificationSnackbarService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inspectionType && (changes.inspectionType.currentValue !== changes.inspectionType.previousValue)) {
      this.transformedInspectionType = this.convertInspectionType(changes.inspectionType.currentValue)
    }
  }

  get photoLimit() {
    return `(0/${this.maxImageCount})`
  }

  async handleContextMenuSelected(menuItem: string, selectedIndex: number) {
    switch (menuItem) {
      case MENU_INSPECTION_TYPE_DETAIL_ROW.Edit.text: {
        if (await unmodified.isInspectionValueUnmodified(this.inspectionType, this.dialog, this.translateService)) {
          return
        }

        const inspectionDetailType = this.inspectionType.formData[selectedIndex].inspectionDetailType
        const editDialog = this.getEditDialog(inspectionDetailType)
        this.dialog.open(editDialog, {
          data: {
            row: this.inspectionType.formData[selectedIndex],
            formDataIndex: selectedIndex,
            inspectionTypeIndex: this.index,
            isUpdate: true,
          }
        })
        break
      }

      case MENU_INSPECTION_TYPE_DETAIL_ROW.Delete.text: {
        if (await unmodified.isInspectionValueUnmodified(this.inspectionType, this.dialog, this.translateService)) {
          return
        }

        this.openConfirmDialog(selectedIndex, () => this.deleteDetailRow(selectedIndex))
        break
      }

      case MENU_INSPECTION_TYPE_DETAIL_ROW.Copy.text:
        this.store.dispatch(new CopyInspectionTypeDetailRow(this.inspectionType.formData[selectedIndex]))
        break

      case MENU_INSPECTION_TYPE_DETAIL_ROW['Add to library'].text:
        const inspectionTypeDetailRow = this.inspectionType.formData[selectedIndex]
        this.openAddPremadeFieldDialog(inspectionTypeDetailRow)
        break

      default: {
        break
      }
    }
  }

  openConfirmDialog(index: number, successCallback: () => void) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => { dialogRef.close() },
        onAccept: () => {
          successCallback()
          dialogRef.close()
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('OK'),
        title: this.translateService.instant('INSPECTION_FORM_DELETE_CONFIRM_TITLE', { name: this.inspectionType.formData[index].fieldNameLabel }),
        message: ''
      },
    })
  }

  deleteDetailRow<T>(index: number): Observable<T> {
    return this.store.dispatch(new RemoveInspectionTypeDetailRow(index))
  }

  getEditDialog(inspectionDetailType: string): any {
    switch (inspectionDetailType) {
      case INSPECTION_DETAIL_TYPE.TEXT:
      case INSPECTION_DETAIL_TYPE.NUMBERIC:
      case INSPECTION_DETAIL_TYPE.FLOAT:
        return InspectionDetailRowEditDialogComponent
      case INSPECTION_DETAIL_TYPE.CHECKBOX:
        return InspectionDetailRowCheckboxEditDialogComponent
      case INSPECTION_DETAIL_TYPE.DROPDOWN:
        return InspectionDetailRowDropdownEditDialogComponent
      case INSPECTION_DETAIL_TYPE.PHOTO:
        return InspectionDetailRowPhotoEditDialogComponent
      default:
        return
    }
  }

  async drop(event: CdkDragDrop<FormDataModel[]>) {
    // Ignore drop outside of the inspection type detail row list
    if (!(event && event.isPointerOverContainer)) {
      return
    }

    if (await unmodified.isInspectionValueUnmodified(this.inspectionType, this.dialog, this.translateService)) {
      return
    }

    const rowData = event && event.item && event.item.data && event.item.data.dropValue
      ? transformToInspectionTypeFormData(event.item.data.dropValue)
      : null
    // Return if row data doesn't exist or empty
    if (!(rowData && Object.keys(rowData).length > 0)) {
      return
    }

    // Return if drop type is invalid
    if (!(event.item.data.dropType && Object.values(FORM_LIBRARY_DROP_TYPE).includes(event.item.data.dropType))) {
      return
    }

    // Handle drop for premade field
    if (event.item.data.dropType === FORM_LIBRARY_DROP_TYPE.PREMADE_FIELD) {
      this.store.dispatch(new DropToInspectionTypeDetail(
        this.index,
        event.currentIndex,
        rowData,
      ))
      return
    }

    const editDialog = this.getEditDialog(rowData.inspectionDetailType)
    // Return if there is no dialog for this inspection detail type
    if (!editDialog) {
      return
    }

    this.dialog.open(editDialog, {
      data: {
        row: rowData,
        formDataIndex: event.currentIndex,
        inspectionTypeIndex: this.index,
        isUpdate: false,
      },
    })
  }

  onCheckboxClicked(key: string) {
    this.store.dispatch(new ToggleInspectionAdditionalInformation(this.index, key))
  }

  openAddPremadeFieldDialog(inspectionTypeDetailRow: InspectionTypeFormData) {
    this.dialog.open(AddPieceDialogComponent, {
      data: {
        name: inspectionTypeDetailRow.fieldNameLabel,
        hasNodeChildren: null,
        isPremadeField: true,
        title: this.translateService.instant('INSPECTION_PIECE_ADD_TITLE'),
      },
      panelClass: 'add-premade-field-dialog',
    }).afterClosed().pipe(filter(data => !!data))
      .subscribe(result => {
        const { name, isAdminViewOnly } = result
        this.handleAddPremadeField(name, isAdminViewOnly, inspectionTypeDetailRow)
      })
  }

  private handleAddPremadeField(premadeFieldName: string, isAdminViewOnly: boolean, inspectionTypeDetailRow: InspectionTypeFormData) {
    this.store.dispatch(new AddPremadeFieldToFormLibrary(premadeFieldName, isAdminViewOnly, inspectionTypeDetailRow)).subscribe(
      value => { },
      error => {
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('INSPECTION_PIECE_ADD_FAIL_MSG', { name: premadeFieldName }),
          NOTIFICATION_TYPES.FAILED
        )
      },
      () => {
        this.store.dispatch(new GetTemplateLibraryPieces())
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('INSPECTION_PIECE_ADD_SUCCESS_MSG', { name: premadeFieldName }),
          NOTIFICATION_TYPES.SUCCESS, null, Constants.snackbar.duration,
        )
      }
    )
  }

  private convertInspectionType(input: InspectionType): InspectionTypeWithUnit {
    if (!input) { return null }
    return {
      ...input,
      formData: (input.formData || []).map(this.convertInspectionTypeFormData.bind(this))
    }
  }

  private convertInspectionTypeFormData(formData: InspectionTypeFormData): FormDataWithUnit {
    const availableUnits = Array.isArray(formData.inspectionDetailUnitConversionPair)
      ? formData.inspectionDetailUnitConversionPair
      : []

    return {
      ...formData,
      availableUnits: availableUnits || null,
      selectedUnit: availableUnits ? availableUnits[0] : null,
    }
  }
}
