<div
  fxLayout="row" fxLayoutGap="25px"
  [class]="defaultContainerClasses.concat(containerClasses)"
  [style.maxHeight.px]="maxHeight || ''"
  [style.minHeight.px]="minHeight || ''"
  [style.maxWidth.px]="maxWidth || 1925"
>

  <div fxFlex="{{ leftColumnFlex || 25 }}" [class]="defaultLeftColumnClasses.concat(leftColumnClasses)" fxLayout="row">
    <ng-content select="[left]" ></ng-content>
  </div>

  <div fxFlex [class]="defaultCenterColumnClasses.concat(centerColumnClasses)" fxLayout="row">
    <ng-content select="[center]" ></ng-content>
  </div>

  <div fxFlex="{{ rightColumnFlex || 25 }}" [class]="defaultRightColumnClasses.concat(rightColumnClasses)" fxLayout="row">
    <ng-content select="[right]" ></ng-content>
  </div>

</div>
