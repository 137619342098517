import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PortalModule } from '@angular/cdk/portal'

import { TooltipComponent } from './components/tooltip.component'
import { TooltipDirective } from './directives/tooltip.directive'

@NgModule({
  declarations: [
    TooltipComponent,
    TooltipDirective,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    PortalModule,
  ],
  exports: [
    TooltipComponent,
    TooltipDirective,
  ]
})
export class TooltipModule { }
