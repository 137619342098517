<div class='error-page' fxLayout='row' fxLayoutAlign='space-between start'>
    <div class='page-text' fxLayout='column' fxLayoutGap='16px'>
  
      <div class='error-header'>
        404 - {{ 'NOT_FOUND' | translate }}
      </div>
  
    </div>
  
    <div class='logo' fxFlex>
      <a href='https://www.zonarsystems.com/'>
        <img src='./../../../assets/Zonar_logo.svg'>
      </a>
    </div>
  </div>
  