import { Injectable } from '@angular/core'
import { xml2json } from 'xml-js'

import { LANGUAGE_CONFIG_TYPES, TAG_TYPE_OPTION_VALUE } from 'src/app/constants/internationalized-constants-en'
import { Asset, EvirLanguageDictionary, LegacyConfigurationDataModel, TranslationObject, ZoneLayoutModel } from '../../models/tree.model'
import { LanguageDictionaryHandlingService } from '../language-dictionary-handling/language-dictionary-handling.service'

@Injectable({
  providedIn: 'root'
})
export class XmlConverterService {

  constructor(private langDictionaryService: LanguageDictionaryHandlingService) { }

  convertLegacyXMLToConfig(
    legacyConfigDataXml: string, dataConfiguration: ZoneLayoutModel[],
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ): ZoneLayoutModel[] {
    const legacyConfigDataJson: LegacyConfigurationDataModel = this.convertXMLToJson(legacyConfigDataXml)
    this.ensureAssetIsArray(legacyConfigDataJson)
    const newZoneLayouts = this.convertLegacyJsonToConfig(legacyConfigDataJson, translationObject, evirLanguageDictionary)
    return [...newZoneLayouts, ...dataConfiguration]
  }

  private convertXMLToJson<T>(xml: string): T {
    try {
      const legacyConfigsToConvert = xml2json(xml, { compact: true })
      return JSON.parse(legacyConfigsToConvert)
    } catch {
      console.error(`Error parsing legacy config data XML`)
    }
  }

  private convertLegacyJsonToConfig(
    legacyConfigDataJson: LegacyConfigurationDataModel,
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ): ZoneLayoutModel[] {
    const newZoneLayouts = this.createNewZoneLayout(legacyConfigDataJson)
    this.addConfigNameInLanguageDictionary(legacyConfigDataJson, newZoneLayouts, translationObject, evirLanguageDictionary)
    this.convertConfigZoneLayoutFromtoXml(legacyConfigDataJson, newZoneLayouts, translationObject, evirLanguageDictionary)
    return newZoneLayouts
  }

  // create new zone layout / zone layout for each 'Asset' node in the xml configs
  private createNewZoneLayout(legacyConfig: LegacyConfigurationDataModel) {

    return legacyConfig.dbconfig.Config.Asset.map(() => {

      // ECT2-739: EVIR api doesn't allow assetViewId as empty value
      // New zone layout without assetViewId and assetViewGrid for successful test/deploy
      // create new zone layout
      const newZoneLayout = {
        assetType: ['vehicle', 'standard'],
        components: [],
        configZones: [],
        zoneLayoutLangKey: '',
        ect2Data: { data: 'Object containing data not passed to the mobile apps.' },
        borderConfigImport: true
      }

      return newZoneLayout

    })
  }

  // add the (config / zone layout) names to the language dictionary
  private addConfigNameInLanguageDictionary(
    legacyConfigData: LegacyConfigurationDataModel, newZoneLayouts: ZoneLayoutModel[],
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ) {

    let firstElement: Asset

    const configFistName = this.humanize(this.getXMLAttribute(legacyConfigData.dbconfig.Config, 'display'))

    if (legacyConfigData.dbconfig.Config.Asset.length === 1) {
      const zoneLayoutLanguageKey = this.langDictionaryService.getOrCreateLangKey(configFistName, translationObject, evirLanguageDictionary, LANGUAGE_CONFIG_TYPES.CONFIGURATION)
      newZoneLayouts[0].zoneLayoutLangKey = zoneLayoutLanguageKey
    } else {
      firstElement = legacyConfigData.dbconfig.Config.Asset.shift()
      const configSecondName = legacyConfigData.dbconfig.Config.Asset.map((name) => {
        return this.humanize(this.getXMLAttribute(name, 'subtype'))
      })

      legacyConfigData.dbconfig.Config.Asset.unshift(firstElement)

      const configNames = [configFistName, ...configSecondName]
      configNames.forEach((name, i) => {
        const zoneLayoutLanguageKey = this.langDictionaryService.getOrCreateLangKey(name, translationObject, evirLanguageDictionary, LANGUAGE_CONFIG_TYPES.CONFIGURATION)
        newZoneLayouts[i].zoneLayoutLangKey = zoneLayoutLanguageKey
      })
    }
  }

  private convertConfigZoneLayoutFromtoXml(
    legacyConfigPackage: LegacyConfigurationDataModel, newZoneLayouts: ZoneLayoutModel[],
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ) {
    legacyConfigPackage.dbconfig.Config.Asset.forEach((assetNode, index) => {

      // create new zones
      const newZones = this.createNewZones(assetNode, index, newZoneLayouts, translationObject, evirLanguageDictionary)

      // update the relevant zone layout's list of zones
      newZoneLayouts[index].configZones = newZones
    })
  }

  private createNewZones(
    assetNode: any, index: number, newZoneLayouts: ZoneLayoutModel[],
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ) {

    return assetNode.Zone.map(legacyZone => {
      // ECT2-739: EVIR api doesn't allow assetViewLocation if assetViewId is empty
      // New zone without assetViewLocation for successful test/deploy
      const newZone = {
        tagTypeLangKey: this.langDictionaryService.getOrCreateLangKey(TAG_TYPE_OPTION_VALUE.ZONE, translationObject, evirLanguageDictionary, LANGUAGE_CONFIG_TYPES.ZONE),
        tagNumber: 1,
        tagLangKey: '',
        tagComponents: [], // integers
        zoneInspectionTypes: [], // slated for removal
      }

      // add the zone name to the language dictionary
      const zoneNameKey = this.langDictionaryService.getOrCreateLangKey(this.humanize(this.getXMLAttribute(legacyZone, 'name')), translationObject, evirLanguageDictionary, LANGUAGE_CONFIG_TYPES.ZONE)

      // fill in the new zone info
      newZone.tagLangKey = zoneNameKey
      newZone.tagNumber = this.getXMLAttribute(legacyZone, 'tag_id') ? + this.getXMLAttribute(legacyZone, 'tag_id') : 0

      // for all the old components in this config
      newZone.tagComponents = this.createtagComponents(legacyZone, index, newZoneLayouts, translationObject, evirLanguageDictionary)

      return newZone

    })
  }

  private createtagComponents(
    legacyZone: any, index: number, newZoneLayouts: ZoneLayoutModel[],
    translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary
  ) {
    return legacyZone.Component.map(legacyComponent => {
      const newComponent = {
        maxSeverity: 255,
        minSeverity: 0,
        componentLangKey: '',
        suggestedConditionLangKeys: [],// STRING_KEYs
      }

      // add the component name to the language dictionary
      const componentNameKey = this.langDictionaryService.getOrCreateLangKey(
        this.humanize(this.getXMLAttribute(legacyComponent, 'name')),
        translationObject,
        evirLanguageDictionary,
        LANGUAGE_CONFIG_TYPES.COMPONENT
      )

      newComponent.componentLangKey = componentNameKey

      // the xml converter doesn't use an array for a single value
      if (this.dataTypeOf(legacyComponent.Condition) === 'object') {
        legacyComponent.Condition = [legacyComponent.Condition]
      }

      // for all the old conditions in the old component
      newComponent.suggestedConditionLangKeys = this.createSuggestedConditions(legacyComponent, translationObject, evirLanguageDictionary)

      // todo: this needs to check to see if a component already exists
      // update the zone layout's list of components, and get the index from the length of the array returned from push()
      const componentIndex = newZoneLayouts[index].components.push(newComponent) - 1

      return componentIndex
    })

  }

  private createSuggestedConditions(legacyComponent: any, translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary): string[] {
    return legacyComponent.Condition.map(legacyCondition => {

      // add the condition name to the language dictionary
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const conditionNameKey = this.langDictionaryService.getOrCreateLangKey(this.humanize(legacyCondition['_text']), translationObject, evirLanguageDictionary, LANGUAGE_CONFIG_TYPES.CONDITION)

      // intentional string version of the integer
      return conditionNameKey
    })
  }

  private getXMLAttribute(target: any, key: string): string {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!target['_attributes']) {
      return ''
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return target['_attributes'][key]
  }

  // the xml converter doesn't use an array for a single value
  private ensureAssetIsArray(legacyConfigPackage: any) {
    if (this.dataTypeOf(legacyConfigPackage.dbconfig.Config.Asset) === 'object') {
      return legacyConfigPackage.dbconfig.Config.Asset = [legacyConfigPackage.dbconfig.Config.Asset]
    }
  }

  private dataTypeOf(value) {
    if (typeof (value) === 'number') {
      return 'number'
    }
    if (typeof (value) === 'string') {
      return 'string'
    }
    if (typeof (value) === 'symbol') {
      return 'symbol'
    }
    if (typeof (value) === 'boolean') {
      return 'boolean'
    }
    if (typeof (value) === 'undefined') {
      return 'undefined'
    }
    if (typeof (value) === 'function') {
      return 'function'
    }
    if (value === null) {
      return 'null'
    }
    if (typeof (value) === 'object') {
      if (Array.isArray(value)) {
        return 'array'
      }
      return 'object'
    }
  }

  /**
   * Convert uppercase string to lowercase string and uppercase first character of the first word
   * @param str e.g. 'AIR LINES'
   * @returns  e.g. Air lines
   */
  private humanize(str: string) {
    return str
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b[A-Z][a-z]+\b/g, (word: string) => {
        return word.toLowerCase()
      })
      .replace(/^[a-z]/g, (first: string) => {
        return first.toUpperCase()
      })
  }
}
