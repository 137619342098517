
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { Router } from '@angular/router'

export interface TabLink {
  label: string
  link?: string
}

@Component({
  selector: 'app-navigation-tab',
  templateUrl: './navigation-tab.component.html',
  styleUrls: ['./navigation-tab.component.scss']
})

export class NavigationTabComponent implements OnChanges {
  @Input() tabLinks: TabLink[] = []
  @Output() tabIndex: EventEmitter<number> = new EventEmitter<number>()
  activeTabLinkIndex: number

  constructor(private router: Router) { }
  /**
   * Re-navigate configuration tab when at inspection-types tab and select company 
   */
  ngOnChanges(): void{
    const temp = this.tabLinks.findIndex(x => this.router.url.includes(x.link))
    this.activeTabLinkIndex = temp
  }

  onSwitchTab(index, tabLink) {
    this.activeTabLinkIndex = index
    this.tabIndex.emit(index)
  }

}
