import { Routes } from '@angular/router'

import { PermissionsGuard } from '@zonar-ui/auth'

import { NoPermissionGuard } from 'src/app/shared/ect-auth/guards/no-permission.guard'

import { CompaniesComponent } from '../../companies/companies.component'
import { CONFIGURATIONS_ROUTES } from '../../configuration-layout/routes/configuration-layout.routes'
import { NoPermissionComponent } from '../../no-permission/no-permission.component'

export const DEFAULT_ROUTES: Routes = [
  { path: '',   redirectTo: 'companies', pathMatch: 'full' },
  { path: 'companies/:companyId', component: CompaniesComponent, children: CONFIGURATIONS_ROUTES, canActivate: [PermissionsGuard] },
  { path: 'no-permission', component: NoPermissionComponent, canActivate:[NoPermissionGuard] },
]
