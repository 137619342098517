<ng-container [formGroup]="formGroup">

  <div fxLayout="column">

    <ng-container *ngIf="isDecimal">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{'DECIMAL_PLACES_LABEL' | translate}}</mat-label>
        <mat-select formControlName="inspectionDetailMaxDecimal">
          <mat-option *ngFor="let item of maxDecimal" [value]="item.maxDecimal">
            {{ item.maxDecimal }} <span class="decimal-value">({{item.value}})</span>
          </mat-option>
          <mat-select-trigger>
            {{ formGroup.controls.inspectionDetailMaxDecimal.value }} <span class="decimal-value">({{getMaxDecimalDisplayValue()}})</span>
          </mat-select-trigger>
        </mat-select>
        <mat-error *ngIf="formGroup.controls.inspectionDetailMaxDecimal.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngTemplateOutlet="
        unitType;
        context: {
          $implicit: availableUnitType || [],
          formControl: formGroup.controls.unitTypes
        }"> </ng-container>

    <mat-slide-toggle class="slide-toggle-box" *ngIf="formGroup.controls.unitTypes.value" formControlName="allowUserToChoose">{{'ALLOW_CHOOSE_NAME' | translate}}</mat-slide-toggle>
    <mat-slide-toggle  formControlName="required">{{'REQUIRED_FIELD_NAME' | translate}}</mat-slide-toggle>
  </div>

</ng-container>

<ng-template #unitType let-unitDescription let-unitTypeControl="formControl">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{'UNIT_TYPES_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="unitTypeControl">
      <mat-option [value]="''"> {{'SIMPLE_NUMBER_OPTION' | translate}} </mat-option>
      <mat-option *ngFor="let item of unitDescription" [value]="item.key">
      <!-- `uppercase` used to transform to an i18n key.
        Example: ('time' | uppercase) return 'TIME'. Then,
                 ('TIME' | translate) return 'Time'

        Because `item.measurement` is reflected to a property API model `inspectionDetailMeasurementType`,
        so we dont want to use `translate.instant(i18n)` on .ts file. Just `translate` on .html file
      -->
       {{ item.measurement | uppercase | translate }}
       (
        {{ item.unitText[0] }} <sup *ngIf="item.exponent > 1">{{ item.exponent }}</sup> {{item.unitText[1] ? ', ' + item.unitText[1] : ''}}
        <sup *ngIf="item.exponent > 1 && item.unitText[1]">{{ item.exponent }}</sup>
       )
      </mat-option>

    </mat-select>
    <mat-error *ngIf="unitTypeControl.hasError('required')">
      {{ 'FIELD_REQUIRED_ERROR_MSG' | translate}}
    </mat-error>
    <mat-error *ngIf="unitTypeControl.hasError('arrayLengthEqual')">
      Unit types must have {{ unitTypeControl.errors.arrayLengthEqual }} items
    </mat-error>
  </mat-form-field>
</ng-template>
