<div class="navigation-tab">
    <nav mat-tab-nav-bar>
        <a mat-tab-link
           *ngFor='let tabLink of tabLinks; let i = index'
           [active]='activeTabLinkIndex === i'
           [routerLink]='tabLink.link'
           (click)='onSwitchTab(i,tabLink)'>
            {{tabLink.label}}
        </a>
    </nav>
</div>