import { Component, Inject } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { internationalizedValues } from 'src/app/constants/internationalized-constants-en'
import { EditDialogDataModel } from 'src/app/views/companies/model'

import { BaseEditDialogComponent } from '../base-edit-dialog/base-edit-dialog.component'
import { NUMBER_REGEX, ERROR_MESSAGES } from '../../constants/internationalized-constants-en'
import { FormGeneratorService, FormType } from '../tree/services/form-generator/form-generator.service'


@Component({
  selector: 'app-component-edit-dialog',
  templateUrl: './component-edit-dialog.component.html',
  styleUrls: [
    '../base-edit-dialog/base-edit-dialog.component.scss',
    './component-edit-dialog.component.scss'
  ]
})
export class ComponentEditDialogComponent extends BaseEditDialogComponent {

  numberRegex = NUMBER_REGEX

  SEVERITY_REQUIRED_MESSAGE = ERROR_MESSAGES.GENERIC_FIELD_REQUIRED
  SEVERITY_NUMBER_MESSAGE = ERROR_MESSAGES.INVALID_POSITIVE_INTEGER
  SEVERITY_RANGE_MESSAGE = ERROR_MESSAGES.NUMBER_LIMIT_8BIT

  SEVERITY_MINMAX_KEY = 'severityMinMax'
  SEVERITY_MINMAX_MESSAGE = 'Severity max value must be greater than min value'

  FORM_KEY_MAP = {
    NAME: 'name',
    SEVERITY: {
      MIN: 'severityMin',
      MAX: 'severityMax',
    }
  }

  formType: FormType = 'component'
  /* Temporary to set hide of severity fields. Need to re-display in the future */
  isHideSeverityFields: boolean = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditDialogDataModel,
    public dialogRef: MatDialogRef<ComponentEditDialogComponent>,
    formGenerator: FormGeneratorService) {
    super(data, dialogRef, formGenerator)
    this.title = internationalizedValues.EDIT_COMPONENT_TITLE
  }

  handlePostInit() {
    if (this.isHideSeverityFields) {
      this.severityMinValue = 0
      this.severityMaxValue = 255
    }
  }

  get controlName(): AbstractControl {
    return this.getControlByName(this.FORM_KEY_MAP.NAME)
  }

  get componentName(): string {
    return this.getControlValueByName(this.FORM_KEY_MAP.NAME)
  }

  set componentName(input: string) {
    this.setControlValueByName(this.FORM_KEY_MAP.NAME, input)
  }

  get controlSeverityMax(): AbstractControl {
    return this.getControlByName(this.FORM_KEY_MAP.SEVERITY.MAX)
  }

  get severityMaxValue(): number {
    return this.getControlValueByName(this.FORM_KEY_MAP.SEVERITY.MAX)
  }

  set severityMaxValue(input: number) {
    this.setControlValueByName(this.FORM_KEY_MAP.SEVERITY.MAX, input)
  }

  get controlSeverityMin(): AbstractControl {
    return this.getControlByName(this.FORM_KEY_MAP.SEVERITY.MIN)
  }

  get severityMinValue(): number {
    return this.getControlValueByName(this.FORM_KEY_MAP.SEVERITY.MIN)
  }

  set severityMinValue(input: number) {
    this.setControlValueByName(this.FORM_KEY_MAP.SEVERITY.MIN, input)
  }

}
