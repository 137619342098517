import { AbstractControl, ValidatorFn } from '@angular/forms'

import { NAME_REGEX } from 'src/app/constants/internationalized-constants-en'

export function lastAssetTypeValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const assetTypeValue: string[] = control.value
    const duplicateAssetTypes = ['vehicle', 'standard']
    if (!assetTypeValue || assetTypeValue.length < 3) {
      return null
    }

    if (assetTypeValue[2] === '') {
      return { emptyLastAssetType: true }
    }

    if (duplicateAssetTypes.includes(assetTypeValue[2].toLowerCase())) {
      return { duplicateLastAssetType: true }
    }

    if (!assetTypeValue[2].match(new RegExp(NAME_REGEX))) {
      return { invalidLastAssetType: true }
    }

    return null
  }
}
