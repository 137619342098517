<div class="inspection-detail-wrapper" fxLayout="column" fxLayoutGap="16px">

  <div class="main-details"
    [ngClass]="{ 'last-item-checkbox': isLastItemCheckbox,'empty-list' : inspectionFormData.length <= 0 }"
    id="inspection-type-detail-row-list"
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <ng-container *ngIf="inspectionFormData.length <= 0" [ngTemplateOutlet]="empty" ></ng-container>
    <div *ngFor="let item of inspectionFormData; let i = index;"
      class="detail-box"
      [ngClass]="item.inspectionDetailType"
      cdkDrag
      [cdkDragDisabled]="true">
      <ng-container *ngTemplateOutlet="
        item.inspectionDetailType === 'bool' ? checkboxRow : detailRow;
        context: { $implicit: item, index: i }
        " ></ng-container>
    </div>
  </div>

  <div class="additional-information">
    <h1 [innerHTML]="'INFO_ADDITIONAL' | translate"></h1>

    <ng-container
      *ngTemplateOutlet="generalCheckBoxRow; context: { $implicit: { name: ('PRE_POPULATE_CHECKBOX_NAME' | translate), checked: transformedInspectionType?.prepopulateDefects, key: 'prepopulateDefects', description: ('PRE_POPULATE_TOOLTIP_DESCRIPTION' | translate) } }">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="generalCheckBoxRow; context: { $implicit: { name: ('ORDER_FORCE_CHECKBOX_NAME' | translate), checked: transformedInspectionType?.enforceOrder, key: 'enforceOrder', description: ('ORDER_FORCE_TOOLTIP_DESCRIPTION' | translate) } }">
    </ng-container>
    <ng-container *ngIf="transformedInspectionType?.zoneless">
      <ng-container
        *ngTemplateOutlet="generalCheckBoxRow; context: { $implicit: { name: ('ZONELESS_CHECKBOX_NAME' | translate), checked: transformedInspectionType?.zoneless, key: 'zoneless', disabled: true } }">
      </ng-container>
    </ng-container>
    <ng-container
      *ngTemplateOutlet="generalCheckBoxRow; context: { $implicit: { name: ('ASSOCIATE_DRIVER_CHECKBOX_NAME' | translate), checked: transformedInspectionType?.associateDriver, key: 'associateDriver', description: ('ASSOCIATE_DRIVER_TOOLTIP_DESCRIPTION' | translate) } }">
    </ng-container>

  </div>
</div>

<ng-template #detailRow let-formDataRow let-index="index">
  <div class="detail-row">
    <div class="detail-cell">
      <!-- <mat-form-field appearance="outline" floatLabel='always'>
        <input matInput [readonly]="true" class="field-name" placeholder="" [value]="formDataRow.fieldNameLabel"
          maxlength="100">
      </mat-form-field> -->
      <ng-container *ngTemplateOutlet="
      formDataRow.inspectionDetailType === 'select' ? detailSelectRow : formDataRow.inspectionDetailType === 'photo' ? photoRow : commonRow;
        context: { $implicit: formDataRow }
        "></ng-container>
    </div>

    <div class="detail-cell">
      <ng-container *ngIf="formDataRow.inspectionDetailType === 'photo'">
        <div class="photo-amount">
          <p class="photo-amount-label">{{ photoLimit }}</p>
          <mat-icon class="material-icons-outlined camera-icon">photo_camera</mat-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="formDataRow.availableUnits && formDataRow.availableUnits.length > 1">
        <app-unit-switcher [units]="formDataRow.availableUnits" [default]="formDataRow.selectedUnit">
        </app-unit-switcher>
      </ng-container>
    </div>

    <div class="detail-cell">
      <app-menu [menuOptions]="menuInspectionTypeDetailRow" (clickOption)="handleContextMenuSelected($event, index)">
      </app-menu>
    </div>

  </div>

  <div class="detail-row">
    <div class="detail-cell form-description">
      {{ formDataRow.hint }}
    </div>

    <div class="detail-cell">
    </div>

    <div class="detail-cell">
    </div>

  </div>
</ng-template>

<ng-template #checkboxRow let-formDataRow let-index="index">
  <div class="detail-row checkbox-row">
    <div class="detail-cell">
      <mat-checkbox [disableRipple]="true" (click)="$event.preventDefault()" [checked]="formDataRow.default">
        {{ formDataRow.fieldNameLabel }}
      </mat-checkbox>
    </div>

    <div class="detail-cell">
    </div>

    <div class="detail-cell">
      <app-menu [menuOptions]="menuInspectionTypeDetailRow" (clickOption)="handleContextMenuSelected($event, index)">
      </app-menu>
    </div>

  </div>
</ng-template>

<ng-template #generalCheckBoxRow let-data >
  <div class="detail-row">
    <div class="detail-cell checkbox">
      <mat-checkbox
        [disableRipple]="true"
        [checked]="data.checked"
        appThrottleClick
        (throttledClick)="!data.disabled && onCheckboxClicked(data.key)"
        [disabled]="data.disabled"
        [attr.data-testid]="data.key"
        class="additional-information-checkbox"
      >
        {{ data.name }}
      </mat-checkbox>
      <mat-icon *ngIf="data.description" class="tooltip-icon" [tooltip]="data.description">info_outline</mat-icon>
    </div>

    <div class="detail-cell">
    </div>

    <div class="detail-cell">
    </div>

  </div>
</ng-template>

<ng-template #photoRow let-formDataRow>
  <p class="photo-title">{{ formDataRow.fieldNameLabel | uppercase }} <span *ngIf="formDataRow.required" class="required-icon"></span></p>
</ng-template>

<ng-template #detailSelectRow let-formDataRow>
  <mat-form-field appearance="outline" floatLabel='always' *ngIf="formDataRow.select && formDataRow.select.length">
    <mat-select (change)="$event.preventDefault()" [placeholder]="formDataRow.fieldNameLabel" class="read-only-field">
      <mat-option *ngFor="let option of formDataRow.select">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #commonRow let-formDataRow>
  <mat-form-field appearance="outline" floatLabel='always'>
    <input matInput [readonly]="true" class="field-name" placeholder="" [value]="formDataRow.fieldNameLabel"
      maxlength="100">
  </mat-form-field>
</ng-template>

<ng-template #empty>
  <div class="no-form-detail-message" [ngClass]="{'hide-content': isDraggingFormLibraryField}">
    {{'EMPTY_FORM_DATA_TEXT' | translate}}
  </div>
</ng-template>
