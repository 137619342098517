import { Routes } from '@angular/router'
import { PermissionsGuard } from '@zonar-ui/auth'

import { ConfigurationsComponent } from '../../configurations/configurations.component'
import { InspectionTypeComponent } from '../../inspection-type/inspection-type.component'

export const CONFIGURATIONS_ROUTES: Routes = [
  { path: '',   redirectTo: 'configurations', pathMatch: 'full' },
  { path: 'configurations', component: ConfigurationsComponent, canActivate: [PermissionsGuard] },
  { path: 'inspection-types', component: InspectionTypeComponent, canActivate: [PermissionsGuard] },
]
