import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { TreeFlatNode } from 'src/app/components/tree/models/tree.model'
import { NODE_LEVELS, TAG_TYPE_OPTIONS } from 'src/app/constants/internationalized-constants-en'

@Pipe({
  name: 'addZoneTag'
})
export class AddZoneTagPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(node: TreeFlatNode): string {
    if (node.level !== NODE_LEVELS.ZONE_LEVEL) {
      return ''
    }

    const tagType = TAG_TYPE_OPTIONS.find(option => option.id === node.value.tagType)
    const tagTypeValue = this.translateService.instant(tagType ? tagType.displayName : 'TAG_TYPE_OPTIONS.ZONE').slice(0,1)
    return ` (${tagTypeValue}${node.value.tagNumber})`
  }

}
