import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-confirm-reflect-dialog',
  templateUrl: './confirm-reflect-dialog.component.html',
  styleUrls: ['./confirm-reflect-dialog.component.scss']
})
export class ConfirmReflectDialogComponent {

  onClose
  onApplyAll
  onApplyJustThisOne
  cancelText: string
  applyJustThisOneText: string
  applyAllText: string
  title: string
  confirmMessage: string


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ConfirmReflectDialogComponent>) {
    if (data) {
      this.onClose = data.onClose || this.dialogRef.close
      this.onApplyAll = data.onApplyAll
      this.onApplyJustThisOne = data.onApplyJustThisOne
      this.cancelText = data.cancelText
      this.applyAllText = data.applyAllText
      this.applyJustThisOneText = data.applyJustThisOneText
      this.title = data.title
      this.confirmMessage = data.message
    }
  }

  handleCancel() {
    this.onClose()
  }

  handleApplyAll() {
    this.onApplyAll()
  }

  handleApplyJustThisOne() {
    this.onApplyJustThisOne()
  }
}
