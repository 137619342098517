<zui-sidenav
  class="sidenav-component"
  [sidenavParams]="SIDENAV_PARAMS"
  [footer]="sidenavFooter"
  (emitSelectedCompany)="switchToSelectedCompany($event)"
>
  <div class="sidenav-content-container">
    <div class="page-content">
      <div class="spinner" *ngIf="loading; else routerOutlet">
        <mat-spinner [diameter]="44"></mat-spinner>
      </div>

      <ng-template #routerOutlet>
        <router-outlet></router-outlet>
      </ng-template>
    </div>
    <zui-footer-component class="footer" *ngIf="!loading"></zui-footer-component>
  </div>
</zui-sidenav>
