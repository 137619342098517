<div class="grid-zone-container">

  <app-svg-converter #svgBackground
    *ngIf="svgSource"
    [svgSource]="svgSource"
    (backgroundLoaded)="handleAfterSVGLoaded($event)">
  </app-svg-converter>
  <ng-container *ngIf="backgroundWidth && backgroundHeight">
    <div class="zone-box-container"
      [style.width.px]="backgroundWidth"
      [style.height.px]="backgroundHeight"
      cdkDropListGroup>
      <div *ngFor="let item of items;let i=index"
        class="box-item"
        [ngClass]="{'dragging':isDragging}"
        [style.width.px]="calculateZoneBoxWidth(item)"
        [style.height.px]="zoneBoxHeight"
        cdkDropList
        (mousedown)="onDragStart(item)"
        (mouseup)="onDragEnd()"
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="{item:item,index:i}"
        (cdkDropListDropped)="drop($event)">
        <div class="inner-content"
          cdkDrag
          [cdkDragDisabled]="!item.active"
          (cdkDragEnded)="onDragEnd()">
          <div class="box-placeholder"
            *cdkDragPlaceholder></div>
          <ng-container *ngIf="item.active">
            <div class="box"
              [style.width.px]="zoneBoxWidth"
              [style.height.px]="zoneBoxHeight"
              (mouseenter)="onMouseEnter(item)"
              (mouseleave)="onMouseOut()">
              <div class="box-active"
                [matTooltip]="item.configZoneName"
                [matTooltipClass]="customTooltipClasses"
                [ngClass]="{'box-duplicate': item.isDuplicate}"
                [style.width.px]="zoneBoxActiveSize"
                [style.height.px]="zoneBoxActiveSize">
                  <div class="box-name"
                    [style.height.px]="zoneBoxActiveSize"
                    [style.width.px]="zoneBoxActiveSize"
                    [style.lineHeight.px]="zoneBoxActiveSize">{{item.isDuplicate ? 'M' : item.name.slice(1)}}</div>
                  <div class="multiple-box-name"
                    *ngIf="item.isDuplicate">{{('MULTIPLE' | translate) + ' (' + item.name + ')'}}</div>
              </div>
             
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
