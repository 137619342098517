import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { equalsIgnoreCase } from 'src/app/utils/utils'

export class InspectionTypeLabelEditDataModel {
  inspectionTypeNames?: string[]
  inspectionTypeName?: string
  title?: string
}

export class InspectionTypeLabelErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

export function duplicateInspectionTypeNameValidator(inspectionTypeNameList: string[]): ValidatorFn {
  return (control: AbstractControl) => {
    const name: string = control.value
    return inspectionTypeNameList && equalsIgnoreCase(inspectionTypeNameList, name) ? { duplicateInspectionTypeName: true } : null
  }
}

@Component({
  selector: 'app-inspection-type-label-edit-dialog',
  templateUrl: './inspection-type-label-edit-dialog.component.html',
  styleUrls: ['./inspection-type-label-edit-dialog.component.scss']
})
export class InspectionTypeLabelEditDialogComponent implements OnInit {
  editForm: FormGroup

  matcher = new InspectionTypeLabelErrorStateMatcher()

  title: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InspectionTypeLabelEditDataModel,
    public dialogRef: MatDialogRef<InspectionTypeLabelEditDialogComponent>
  ) {
    if (data) {
      if (data.title) {
        this.title = data.title
      }

      this.editForm = new FormGroup({
        label: new FormControl(data.inspectionTypeName, [
          Validators.required,
          Validators.pattern(NAME_REGEX),
          duplicateInspectionTypeNameValidator(data.inspectionTypeNames)
        ])
      })
    }
  }

  ngOnInit(): void { }

  handleApply() {
    if (this.editForm.invalid) {
      return
    }

    this.dialogRef.close(this.editForm.value)
  }
}
