import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Select, Store } from '@ngxs/store'
import { Observable, Subscription } from 'rxjs'

import { AppState, SetErrorType } from 'src/app/app.state'
import { ErrorInformationModel } from 'src/app/views/companies/model'

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  @Select(AppState.getErrorInformation) errorInformation$: Observable<ErrorInformationModel>

  errorHeader: string
  errorSubHeader: string
  contactSubheader: string
  actionButtonText: string
  actionButtonLink: string
  errorTechnicalInformation = []
  defaultLink: string = '/'
  isDisabled: boolean = false
  subscription: Subscription

  constructor(
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.errorInformation$.subscribe(errorInformation => {
      if (!errorInformation) {
        this.router.navigate([this.defaultLink])
        return
      }
      this.errorHeader = this.translateService.instant(errorInformation.errorMessages.ERROR_HEADER)
      this.errorSubHeader = this.translateService.instant(errorInformation.errorMessages.ERROR_SUBHEADER)
      this.contactSubheader = errorInformation.errorMessages.ERROR_CONTACT_US
        ? this.translateService.instant(errorInformation.errorMessages.ERROR_CONTACT_US)
        : null
      this.actionButtonText = this.translateService.instant(errorInformation.errorMessages.ERROR_ACTION_BUTTON_TEXT)
      this.actionButtonLink = errorInformation.errorMessages.ERROR_ACTION_BUTTON_LINK
        ? this.translateService.instant(errorInformation.errorMessages.ERROR_ACTION_BUTTON_LINK)
        : this.defaultLink
      this.errorTechnicalInformation = [
        { label: this.translateService.instant('SUPPORT_PHONE'), content: errorInformation.errorMessages.SUPPORT_PHONE },
        { label: this.translateService.instant('SUPPORT_EMAIL'), content: errorInformation.errorMessages.SUPPORT_EMAIL },
        ...errorInformation.errorTechnicalDetail
          .map(item => item.label === 'Url'
            ? { ...item, content: `"${item.content}"` }
            : item
          )
      ]
    })
  }

  resetErrorStatus() {
    this.isDisabled = true
    this.store.dispatch(new SetErrorType(null))
    setTimeout(() => {
      this.isDisabled = false
    }, 2000)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
