<ng-container *ngIf="isDisplayEditConfigurationDialog">
  <div mat-dialog-title
    fxLayout='row'
    fxLayoutAlign='space-between start'>
    <div class="dialog-title">{{ 'CONFIG_EDIT_DIALOG_TITLE' | translate }}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
      (click)="handleCancel()">close</mat-icon>
  </div>
  <mat-dialog-content class="node-edit-content"
    [ngClass]="{'hide-asset-view-grid': isAssetViewGridHidden}">
    <form class="condition-edit-form"
      [formGroup]="inputForm">
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input matInput
          class="field-name"
          placeholder="{{'NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
          [errorStateMatcher]="matcher"
          maxlength="100">
        <mat-error *ngIf="inputForm.controls.name.hasError('pattern')">
          {{'INVALID_CHARS_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          {{'NAME_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
    </form>
    <div>
      <mat-form-field class="asset-view-image-field"
        appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'IMAGE_OPTION' | translate}}</mat-label>
        <mat-select #assetViewIdSelectBox
          focused="false"
          [value]="inputForm.controls.assetViewId.value || ''"
          (valueChange)="onChange($event)">
          <div *ngIf="isAccessGranted$ | async" value="default-id" class="mat-option" (click)="openUploadDialog()">{{'UPLOAD_NEW_IMAGE_OPTION' | translate}}</div>

          <ng-container *ngFor="let option of defaultOptions">
            <mat-option *ngIf="(inputForm.controls.assetViewId.value && option.id !== '') || (!inputForm.controls.assetViewId.value && option.id !== removeCurrent)"
            [value]="option.id">{{ option.name | translate }}</mat-option>
          </ng-container>

          <mat-divider></mat-divider>
          <mat-option value="default-selected-id" *ngIf="fileUploaded !== null ">{{fileUploaded.assetViewName}}</mat-option>
          <mat-option *ngFor="let option of assetViewOptions$ | async"
            [value]="option.assetViewId">{{option.assetViewName | capitalizeFirstLetter}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="asset-type-content">
      <app-asset-type-input [formControl]="inputForm.controls.assetType"
        (assetTypeChipStatusChange)="handleChipAssetTypeStatusChange($event)"
        [validStatus]="getValidAssetTypeStatus()"></app-asset-type-input>
      <mat-error *ngIf="inputForm.controls.assetType.hasError('required')">
        {{'ASSET_TYPES_REQUIRED_ERROR_MSG' | translate}}
      </mat-error>
      <mat-error class="invalid-input"
        *ngIf="inputForm.controls.assetType.hasError('invalidInput')">
        {{'INVALID_CHARS_ERROR_MSG' | translate}}
      </mat-error>
      <mat-error *ngIf="inputForm.controls.assetType.hasError('duplicated')">
        {{'ASSET_TYPES_DUPLICATED_ERROR_MSG' | translate}}
      </mat-error>
      <mat-error *ngIf="inputForm.controls.assetType.hasError('duplicateLastAssetType')">
        {{'ASSET_TYPE_DUPLICATED_ERROR_MSG' | translate}}
      </mat-error>
      <mat-error *ngIf="inputForm.controls.assetType.hasError('emptyLastAssetType')">
        {{'ASSET_TYPE_REQUIRED_ERROR_MSG' | translate}}
      </mat-error>
      <mat-error *ngIf="inputForm.controls.assetType.hasError('invalidLastAssetType')">
        {{'INVALID_CHARS_ERROR_MSG' | translate}}
      </mat-error>
    </div>
    <div *ngIf="!isAssetViewGridHidden">
      <app-chips #assetViewGridChips
        [disabledChipList]="!inputForm.controls.assetViewId.value || inputForm.controls.assetViewId.value === removeCurrent"
        [chipList]="inputForm.controls.assetViewGrid.value"
        chipTitle="Asset map view grid size"
        [ngClass]="{'erorr-chips':inputForm.controls.assetViewGrid.invalid}"
        [regex]="regexNumber"
        placeholder='Please enter asset map view grid size'
        [minChips]="minAssetViewGrid"
        [maxChips]="maxAssetViewGrid"
        [chipType]="CHIP_TYPE.NUMBER"
        (valueChange)="handleChangeChip($event,'assetViewGrid')"
        [chipsStatus]="inputForm.controls.assetViewGrid.valid"
        (inputStatusChange)="handleChipAssetViewGridTypeStatusChange($event,assetViewGridChips)"></app-chips>
      <mat-error class="invalid-input"
        *ngIf="!inputForm.controls.assetViewGrid.hasError('required') && inputForm.controls.assetViewGrid.hasError('invalidMinLengthChips')">
        {{ASSET_MAP_VIEW_COORDINATES_LENGTH_MESSAGE}}
      </mat-error>
      <mat-error class="invalid-input"
        *ngIf="inputForm.controls.assetViewGrid.hasError('required')">
        {{ASSET_MAP_VIEW_COORDINATES_REQUIRED_MESSAGE}}
      </mat-error>
      <mat-error class="invalid-input"
        *ngIf="inputForm.controls.assetViewGrid.hasError('invalidInput')">
        {{INVALID_NUMBER_MESSAGE}}
      </mat-error>
      <mat-error class="invalid-input"
        *ngIf="inputForm.controls.assetViewGrid.hasError('invalidAssetViewGrid')">
        {{INVALID_ASSET_MAP_VIEW_COORDINATES_MESSAGE}}
      </mat-error>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      mat-dialog-close
      class="primary-button"
      (click)="handleCancel()">{{'CANCEL' | translate}}</button>
    <button mat-button
      [disabled]="!inputForm.valid"
      class="special-button"
      (click)="inputForm.controls.assetViewId.value === removeCurrent ? openRemoveImageDialog() : handleAccept()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</ng-container>

<input class="config-file-upload-dialog"
#fileUpload
type="file"
(change)="chooseFile($event)"
accept=".svg"/>
