import { Component, OnInit } from '@angular/core'
import { Select } from '@ngxs/store'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AppState } from 'src/app/app.state'
import { equalsIgnoreCase } from 'src/app/utils/utils'
import { TemplateLibraryPieceModel } from '../template-library/models/template-library.model'
import { EvirLanguageDictionary } from '../tree/models/tree.model'
import { TemplateLibraryPieceConvertingService } from '../template-library/services/template-library-piece-converting/template-library-piece-converting.service'

@Component({
  selector: 'app-add-inspection-type-dialog',
  templateUrl: './add-inspection-type-dialog.component.html',
  styleUrls: ['./add-inspection-type-dialog.component.scss']
})
export class AddInspectionTypeDialogComponent implements OnInit {
  @Select(AppState.getInspectionTypeLibraryPieces) _inspectionTypeLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  @Select(AppState.getLanguageDictionary) languageDictionary$: Observable<EvirLanguageDictionary>
  @Select(AppState.getInspectionDetailLangKeys) inspectionDetailLangKeys$: Observable<string[]>

  inspectionTypeLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  selectedInspectionType: TemplateLibraryPieceModel

  constructor(
    public pieceConvertingService: TemplateLibraryPieceConvertingService,
  ) {
    this.inspectionTypeLibraryPieces$ = combineLatest([this._inspectionTypeLibraryPieces$, this.languageDictionary$]).pipe(
      map(([inspectionTypeLibraryPieces, langDict]) => this.pieceConvertingService.formatInspectionTypePieces(inspectionTypeLibraryPieces, langDict)),
    )
  }

  ngOnInit(): void { }
}
