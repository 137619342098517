import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MIN_ASSET_VIEW_LOCATION_LENGTH, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { convertLangKeyToString } from 'src/app/utils/utils'
import { ConfigZoneModel, EvirLanguageDictionary, ZoneLayoutModel } from '../../../models/tree.model'
import { assetViewLocationValidator } from '../validators/asset-view'
import { maxInputNumber, minLengthValidator } from '../validators/number'
import { FormGroupGenerator, FormType } from './interface'

export class ZoneFormGroup implements FormGroupGenerator {
  generateFor: FormType = 'zone'

  generateFormGroup(formBuilder: FormBuilder, forModal: boolean): FormGroup {
    let controlConfigs: Record<string, any> = {}
    let crossFieldValidators: ValidatorFn[] = []

    controlConfigs = {
      ...controlConfigs,
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(100),
      ]),
      assetViewLocation: new FormControl(''),
      assetViewGrid: [0, 0],
      assetViewId: '',
    }

    controlConfigs = {
      ...controlConfigs,
      tagType: new FormControl('', [Validators.required]),
      tagNumber: new FormControl('', [
        maxInputNumber(100, 'invalidMaxValue'),
        Validators.required,
      ]),
      zoneInspectionTypes: new FormControl('', [
        Validators.required,
      ]),
    }

    crossFieldValidators.push(assetViewLocationValidator(MIN_ASSET_VIEW_LOCATION_LENGTH))

    return formBuilder.group(controlConfigs, {
      validators: crossFieldValidators,
    })
  }

  transformToValidateableFormat(rawConfig: ConfigZoneModel, dictionary: EvirLanguageDictionary, extra: ZoneLayoutModel) {
    return {
      tagType: convertLangKeyToString(rawConfig.tagTypeLangKey, dictionary),
      // There a bug that when the user submit empty tag number (by manually edit HTML)
      // the final result is NaN, since Angular treat NaN as a valid number (seriously!),
      // we need to address this in the logic.
      // TO-DO: find the root cause of setting the final value to NaN
      tagNumber: isNaN(rawConfig.tagNumber) ? null : rawConfig.tagNumber,
      zoneInspectionTypes: rawConfig.zoneInspectionTypes,
      assetViewLocation: rawConfig.assetViewLocation,
      name: convertLangKeyToString(rawConfig.tagLangKey, dictionary),
      assetViewGrid: extra.assetViewGrid,
      assetViewId: extra.assetViewId,
    }
  }
}
