import { Component, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, FormGroup } from '@angular/forms'
import { Subject } from 'rxjs'
import { delay, map, takeUntil } from 'rxjs/operators'
import { ERROR_MESSAGES } from 'src/app/constants/internationalized-constants-en'

export interface InspectionFormControlComponent<F, T> {
  createFormGroup(): FormGroup
  transformData(val: F): T
  transformFormData(val: T): Partial<F>
}

type ExternalFnCallBack<T> = (value: T) => void

@Component({
  template: '',
})
export abstract class BaseInspectionFormControlComponent<F = any, T = any>
  implements ControlValueAccessor, OnInit, OnDestroy, InspectionFormControlComponent<F, T>
{
  formGroup: FormGroup

  commonErrorMessages = {
    required: ERROR_MESSAGES.GENERIC_FIELD_REQUIRED,
    positiveNumber: ERROR_MESSAGES.INVALID_POSITIVE_INTEGER,
    numberLimit8Bit: ERROR_MESSAGES.NUMBER_LIMIT_8BIT,
    numberWithRequiredCheck: (isRequired: boolean) => isRequired ? 'REQUIRED_RANGE_ERROR_MSG' : 'NON_REQUIRED_RANGE_ERROR_MSG',
  }

  private destroy$: Subject<boolean> = new Subject<boolean>()

  onChange: ExternalFnCallBack<T> = () => { }
  onTouch: ExternalFnCallBack<T> = () => { }

  ngOnInit(): void {
    this.formGroup = this.createFormGroup()
    this.formGroup.markAllAsTouched()
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        delay(0),
        map<F, T>(this.transformData.bind(this)),
      )
      .subscribe(value => {
        const emitValue = this.formGroup.invalid ? {} as T : value
        this.notify(emitValue)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  writeValue(obj: T): void {
    this.formGroup.patchValue(this.transformFormData(obj))
  }

  registerOnChange(fn: ExternalFnCallBack<T>): void {
    this.onChange = fn
  }

  registerOnTouched(fn: ExternalFnCallBack<T>): void {
    this.onTouch = fn
  }

  notify(obj: T) {
    this.onChange(obj)
    this.onTouch(obj)
  }

  createFormGroup(): FormGroup {
    throw new Error('Method not implemented.')
  }

  transformData(val: F): T {
    throw new Error('Method not implemented.')
  }

  transformFormData(val: T): Partial<F> {
    throw new Error('Method not implemented.')
  }
}
