import { Directive, HostListener, Input } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Directive({
  selector: '[appToastMessage]'
})
export class ToastMessageDirective {

  @Input() toastMessage = ''
  @Input() displayTime = 2000

  constructor(private _snackBar: MatSnackBar) { }

  @HostListener('click') onclick() {
    this._snackBar.open(this.toastMessage, '', {
      duration: this.displayTime,
    })
  }

}
