import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Store, Select } from '@ngxs/store'
import { Observable } from 'rxjs'

import { AppState, SetErrorInformation } from 'src/app/app.state'
import {
  ERROR_STATUS_CODES,
  httpPageNotFoundConstants,
  defaultErrorConstants,
  httpServerErrorConstants,
  httpMaintenanceConstants,
  httpNoPermissionConstants
} from 'src/app/constants/internationalized-constants-en'
import { ErrorInformationModel } from 'src/app/views/companies/model/index'

const errorPageUrl = 'server-error'

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  @Select(AppState.getErrorType) isHttpError$: Observable<boolean>

  isHttpError: boolean

  constructor(
    private router: Router,
    private store: Store,
    private ngZone: NgZone,
    private translateService: TranslateService,
  ) {
    this.isHttpError$.subscribe(status => {
      this.isHttpError = status
    })
  }

  handleError(error: HttpErrorResponse) {
    const errorInformation = this.buildErrorInformation(error)
    this.dispatchToErrorPage(errorInformation)
  }

  buildErrorInformation(rawError: HttpErrorResponse): ErrorInformationModel {
    const errorInformation: ErrorInformationModel = {
      errorMessages: null,
      errorTechnicalDetail: null
    }
    if (this.isHttpError) {
      errorInformation.errorTechnicalDetail = [
        { label: this.translateService.instant('ERROR'), content: rawError.statusText, },
        { label: 'Url', content: rawError.url, },
        { label: this.translateService.instant('STATUS'), content: rawError.status.toString(), },
      ]
      errorInformation.errorMessages = this.buildErrorMessage(rawError.status)
      return errorInformation
    } else {
      // TODO: Handle client errors
      console.log(rawError)
    }
  }

  dispatchToErrorPage(errorData: ErrorInformationModel) {
    if (!errorData) {
      return
    }
    this.store.dispatch(new SetErrorInformation(errorData))
    this.ngZone.run(() => {
      this.router.navigate([errorPageUrl])
    })
  }

  private buildErrorMessage(errorStatus: number): Record<string, string> {
    let returnValue = {}
    switch (errorStatus) {
      case ERROR_STATUS_CODES.NO_PERMISSION:
        returnValue = httpNoPermissionConstants
        break
      case ERROR_STATUS_CODES.NOT_FOUND_ERROR:
        returnValue = httpPageNotFoundConstants
        break
      case ERROR_STATUS_CODES.SERVER_ERROR:
        returnValue = httpServerErrorConstants
        break
      case ERROR_STATUS_CODES.MAINTENANCE_ERROR:
        returnValue = httpMaintenanceConstants
        break
      default:
        break
    }
    return {
      ...defaultErrorConstants,
      ...returnValue,
    }
  }
}
