export * from './component-form-group'
export * from './condition-form-group'
export * from './config-form-group'
export * from './zone-form-group'

import { FormGroupGenerator, FormType } from './interface'
import { ComponentFormGroup } from './component-form-group'
import { ConditionFormGroup } from './condition-form-group'
import { ConfigFormGroup } from './config-form-group'
import { ZoneFormGroup } from './zone-form-group'

const GENERATORS: FormGroupGenerator[] = [
  new ConfigFormGroup(),
  new ZoneFormGroup(),
  new ComponentFormGroup(),
  new ConditionFormGroup(),
]

export const DEFAULT_GENERATORS = GENERATORS[3] // Condition form is the most simple one, suitable for default

type Mapper = Record<FormType, FormGroupGenerator>

export function getFormGroupFactory(): Mapper {
  return GENERATORS.reduce((acc, current) => ({
    ...acc,
    [current.generateFor]: current,
  }), {} as Mapper)
}
