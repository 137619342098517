import { Component, forwardRef, Input } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms'

import { isValidArray } from 'src/app/utils/utils'
import { BaseInspectionFormControlComponent } from '../base-inspection-form-control'
import { DECIMAL_OPTIONS, UnitDescription, UNIT_TYPE } from './mapper'

export interface InspectionDetailNumeric {
  inspectionDetailMeasurementType?: string
  required: boolean
  inspectionDetailUnitConversionPair?: string[]
  // ECT2-813: Comment some logic related to inspectionDetailUnitType and allowUserToChoose
  // because it make the deployment fail
  // inspectionDetailUnitType: string
  // allowUserToChoose: boolean
  inspectionDetailMaxDecimal?: number
}

interface TextFieldForm {
  measurementType: string
  unitTypes: string
  required: boolean
  allowUserToChoose: boolean
  inspectionDetailMaxDecimal?: number
}

const arrayLengthEqual = (num: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  return control.value.length !== num ? { arrayLengthEqual: num } : null
}

@Component({
  selector: 'app-inspection-row-numberic',
  templateUrl: './inspection-row-numberic.component.html',
  styleUrls: ['./inspection-row-numberic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InspectionRowNumbericComponent),
      multi: true,
    },
  ],
})
export class InspectionRowNumbericComponent
  extends BaseInspectionFormControlComponent<TextFieldForm, InspectionDetailNumeric> {
  @Input() isDecimal: boolean = false
  unitType = UNIT_TYPE
  maxDecimal = DECIMAL_OPTIONS

  get availableUnitType(): UnitDescription[] {
    return this.unitType
  }

  createFormGroup(): UntypedFormGroup {
    const form = new UntypedFormGroup({
      unitTypes: new FormControl([]),
      required: new FormControl(false),
      allowUserToChoose: new FormControl(false),
    })
    if (this.isDecimal) {
      form.addControl('inspectionDetailMaxDecimal', new FormControl(this.maxDecimal[1].maxDecimal, {
        validators: [
          Validators.required
        ]
      }))
    }
    return form
  }

  transformData(val: TextFieldForm): InspectionDetailNumeric {
    const selectedUnitType = this.availableUnitType.find(x => x.key === val.unitTypes)
    let part = {}
    if (this.isDecimal) {
      part = {
        inspectionDetailMaxDecimal: val.inspectionDetailMaxDecimal || null
      }
    }
    if (selectedUnitType) { /** Simple Number */
      part = {
        ...part,
        inspectionDetailMeasurementType: selectedUnitType.measurement || '',
        inspectionDetailUnitConversionPair: selectedUnitType.value || [],
      }
    }

    return {
      // inspectionDetailUnitType: selectedUnitType && selectedUnitType.value[0] ? selectedUnitType.value[0] : '',
      required: val.required,
      // allowUserToChoose: val.allowUserToChoose,
      ...part
    }
  }

  transformFormData(val: InspectionDetailNumeric): Partial<TextFieldForm> {
    let part = {}
    if (isValidArray(val.inspectionDetailUnitConversionPair)) {
      part = {
        unitTypes: val.inspectionDetailUnitConversionPair.length > 1
          ? `${val.inspectionDetailUnitConversionPair[0]}${val.inspectionDetailUnitConversionPair[1]}`
          : `${val.inspectionDetailUnitConversionPair[0]}`,
      }
    }
    if (this.isDecimal) {
      part = {
        ...part,
        inspectionDetailMaxDecimal: val.inspectionDetailMaxDecimal || this.maxDecimal[1].maxDecimal
      }
    }
    return {
      unitTypes: '',
      required: !!val.required,
      // allowUserToChoose: !!val.allowUserToChoose,
      allowUserToChoose: false,
      ...part
    }
  }

  getMaxDecimalDisplayValue() {
    const selectedDecimal = this.maxDecimal.find(obj => obj.maxDecimal === this.formGroup.controls.inspectionDetailMaxDecimal.value)
    return selectedDecimal.value
  }
}
