import { Component, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'

import { Link } from '@zonar-ui/core'

import { environment } from 'src/environments/environment'
import { REGION_BRAND } from 'src/app/constants/internationalized-constants-en'
import { FOOTER_LINKS } from 'src/app/constants/url-constants'
import { GetCompanies } from 'src/app/app.state'

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  headerTitle = 'Inspection Builder'
  logoSelector = REGION_BRAND[environment.region]
  region = environment.region
  copyRightText = environment.copyRightText
  links: Link[] = FOOTER_LINKS[this.region].map(link => {
    const [key] = Object.keys(link)
    return {
      url: link[key][this.translateService.currentLang] || link[key],
      title: this.translateService.instant(key.toUpperCase()),
    }
  })

  constructor(
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new GetCompanies())
  }

}
