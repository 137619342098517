import { Component, forwardRef } from '@angular/core'
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { take } from 'rxjs/operators'
import { POSITIVE_NUMBER_REGEX } from 'src/app/constants/internationalized-constants-en'
import { lessThan } from '../../tree/services/form-generator/validators/number'
import { BaseInspectionFormControlComponent } from '../base-inspection-form-control'

export interface InspectionDetailTextField {
  maximum: number
  minimum: number
  required: boolean
}

interface TextFieldForm {
  minLength: number
  maxLength: number
  required: boolean
}

@Component({
  selector: 'app-inspection-row-text',
  templateUrl: './inspection-row-text.component.html',
  styleUrls: ['./inspection-row-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InspectionRowTextComponent),
      multi: true,
    },
  ],
})
export class InspectionRowTextComponent extends BaseInspectionFormControlComponent<
  TextFieldForm,
  InspectionDetailTextField
> {
  createFormGroup(): FormGroup {
    const maxMinGenerator = [Validators.max(60), this.minWithRequiredValidatorFactory()]
    const form = new FormGroup(
      {
        textType: new FormControl('single'), // single | multi
        minLength: new FormControl('', {
          validators: [
            Validators.required,
            Validators.pattern(POSITIVE_NUMBER_REGEX),
            ...maxMinGenerator,
          ],
        }),
        maxLength: new FormControl('', {
          validators: [
            Validators.required,
            Validators.pattern(POSITIVE_NUMBER_REGEX),
            ...maxMinGenerator,
          ],
        }),
        required: new FormControl(false),
      },
      {
        validators: [lessThan('minLength', 'maxLength', 'lengthMinMax')],
      }
    )

    return form
  }

  transformData(val: TextFieldForm): InspectionDetailTextField {
    return {
      minimum: val.minLength,
      maximum: val.maxLength,
      required: !!val.required,
    }
  }

  transformFormData(val: InspectionDetailTextField): Partial<TextFieldForm> {
    return {
      minLength: val.minimum,
      maxLength: val.maximum,
      required: !!val.required,
    }
  }

  handleRequireChanged() {
    // Because of how Angular FormControl work, only changes on its own value
    // trigger validation check. If we change `required` field without any intervention,
    // the validation logic of `minLength` will not be run. This block of code use a trick to
    // make the controls recognize that they need to validate again.
    this.formGroup.valueChanges.pipe(take(1)).subscribe((changes) => {
      const refreshNumberControl = (key: string) => {
        const value = changes[key]
        this.formGroup.patchValue({ [key]: null })
        this.formGroup.patchValue({ [key]: value })
      }

      refreshNumberControl('minLength')
      refreshNumberControl('maxLength')
    })
  }

  // We can use cross validators, but the problem is if we really want the error to show
  // in the section of the child control, it will be not possible, since child control has
  // no error, only parent control has.
  //
  // For example: {
  //   formGroup: {  --> cross validations make .hasError to return true
  //     minLength: {...} --> has no error
  //   }
  // }
  // This block of code create a custom validator in child control that has the ability
  // to access parent control
  private minWithRequiredValidatorFactory(): ValidatorFn {
    return (control) => {
      const canGetRequireValue = control.parent && control.parent.value
      const isRequired = canGetRequireValue ? !!control.parent.value.required : false
      const min = isRequired ? 1 : 0

      /** The below section is copied directly from Angular source code of Validators.min */
      const isEmptyInputValue = (inputValue) =>
        // eslint-disable-next-line eqeqeq
        inputValue != null && typeof inputValue.length === 'number'
      if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
        return null // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value)
      return !isNaN(value) && value < min
        ? { min: { min: min, actual: control.value } }
        : null
    }
  }
}
