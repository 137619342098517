import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-three-column-layout',
  templateUrl: './three-column-layout.component.html',
  styleUrls: ['./three-column-layout.component.scss'],
})
export class ThreeColumnLayoutComponent implements OnInit {
  @Input() maxHeight: number = null
  @Input() minHeight: number = null
  @Input() maxWidth: number = null

  @Input() containerClasses: string[] = []
  @Input() leftColumnClasses: string[] = []
  @Input() centerColumnClasses: string[] = []
  @Input() rightColumnClasses: string[] = []

  @Input() leftColumnFlex = 25
  @Input() rightColumnFlex = 25

  defaultContainerClasses: string[] = ['three-column-layout']
  defaultLeftColumnClasses: string[] = ['column', 'left']
  defaultCenterColumnClasses: string[] = ['column', 'center']
  defaultRightColumnClasses: string[] = ['column', 'right']

  constructor() {}

  ngOnInit(): void {}
}
