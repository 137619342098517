import { Clipboard } from '@angular/cdk/clipboard'
import { Directive, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[appSupportCopy]'
})
export class SupportCopyDirective {

  @Input() dataCopy = ''

  constructor(private _clipboard: Clipboard) { }

  @HostListener('click') onclick() {
    this._clipboard.copy(this.dataCopy)
  }
}
