import { Injectable } from '@angular/core'
import { ComponentPieceModel, ConditionPieceModel, ConfigurationPieceModel, FormDataPieceModel, InspectionDetailPieceModel, TemplateLibraryPieceModel, TemplateLibraryStandardTreeModel, ZonePieceModel } from 'src/app/components/template-library/models/template-library.model'
import { TemplateLibraryKey, TEMPLATE_NAME_KEY_LIST, TEMPLATE_TYPES_VALUE, LANGUAGE_CONFIG_TYPES, LANGUAGE_CODE_BY_LANGUAGE_ID, LANGUAGE_IDS } from 'src/app/constants/internationalized-constants-en'

import {
  ComponentModel,
  DataConfigurationModel,
  EvirLanguageDictionary,
  ReflectCountModel,
  TreeDataType,
  TreeNode,
  ZoneLayoutModel
} from '../../models/tree.model'
import { LanguageDictionaryHandlingService, currentLanguageCode } from '../language-dictionary-handling/language-dictionary-handling.service'
import { isValidArray } from 'src/app/utils/utils'

type PieceData = (ConfigurationPieceModel | ZonePieceModel | ComponentPieceModel | ConditionPieceModel | FormDataPieceModel | InspectionDetailPieceModel) & {
  id: string,
  pieceName: string,
}

@Injectable({
  providedIn: 'root'
})
export class TreeBuildingService {

  constructor(private langDictionaryService: LanguageDictionaryHandlingService) { }

  buildTree(data: DataConfigurationModel[], level: number, parentId: string = '0', nodeType = TreeDataType.CONFIGURATION): TreeNode[] {
    return data.reduce<TreeNode[]>((accumulator, rawNode, index) => {
      const node = new TreeNode()
      node.id = `${parentId}/${index}`
      node.name = rawNode.name
      node.value = rawNode.value
      node.reflectCount = rawNode.reflectCount
      node.nodeType = nodeType
      if (rawNode.data) {
        node.children = this.buildTree(rawNode.data, level + 1, node.id, nodeType)
      }
      return accumulator.concat(node)
    }, [])
  }

  buildPieceTree(data: TemplateLibraryStandardTreeModel[], level: number, parentId: string = '0', nodeType = TreeDataType.CONFIGURATION): TreeNode[] {
    return data.map((item, index) => {
      const node = new TreeNode()
      node.id = `${parentId}/${index}`
      node.name = item.name
      node.displayName = item.pieceName
      node.value = item.value
      node.nodeType = nodeType
      if (item.data) {
        node.children = this.buildPieceTree(item.data, level + 1, node.id, nodeType)
      }
      return node
    })
  }

  getConfigurations(configurations: ZoneLayoutModel[], languageDictionary: EvirLanguageDictionary): any[] {
    if (!configurations || !configurations.length) {
      return []
    }

    if (!languageDictionary) {
      return []
    }

    const result = configurations.map(config => {
      const componentReflectCounts: ReflectCountModel[] = (config.components || []).map((item, index) => {
        return {
          index: index,
          count: 0
        }
      })
      const configName = this.langDictionaryService.convertLangKeyToString(
        config.zoneLayoutLangKey, languageDictionary)
      const zones = config.configZones.map(zone => {
        const zoneName = this.langDictionaryService.convertLangKeyToString(
          zone.tagLangKey, languageDictionary)
        const components = zone.tagComponents.map((componentIndex) => {
          const componentReflectCount = componentReflectCounts.find(x => x.index === componentIndex)
          const componentConfiguration = config.components[componentIndex] || {} as ComponentModel
          const componentName = this.langDictionaryService.convertLangKeyToString(componentConfiguration.componentLangKey, languageDictionary)
          if (componentReflectCount) { componentReflectCount.count++ }
          const conditions = (componentConfiguration.suggestedConditionLangKeys || []).map((condition, conditionIndex) => {
            return {
              name: this.langDictionaryService.convertLangKeyToString(
                condition, languageDictionary),
              data: null,
              value: {
                [conditionIndex]: condition
              },
              reflectCount: componentReflectCount
            }
          })
          return {
            name: componentName,
            data: conditions,
            reflectCount: componentReflectCount,
            value: {
              index: componentIndex,
              nameKey: componentConfiguration.componentLangKey,
              severityMax: componentConfiguration.maxSeverity,
              severityMin: componentConfiguration.minSeverity,
            }
          }
        })
        return {
          name: zoneName,
          data: components,
          value: {
            tagLangKey: zone.tagLangKey,
            tagType: this.langDictionaryService.convertLangKeyToString(zone.tagTypeLangKey, languageDictionary),
            tagNumber: zone.tagNumber,
            zoneInspectionTypes: zone.zoneInspectionTypes ? zone.zoneInspectionTypes : [],
            assetViewLocation: zone.assetViewLocation,
          }
        }
      })
      return {
        name: configName,
        data: zones,
        value: {
          assetType: config.assetType,
          components: config.components,
          zoneLayoutLangKey: config.zoneLayoutLangKey,
          assetViewGrid: config.assetViewGrid,
          assetViewId: config.assetViewId,
          ect2Data: config.ect2Data,
          borderConfigImport: config.borderConfigImport
        }
      }
    })
    return result
  }

  findNodeSiblings(nodes: Array<TreeNode>, id: string): Array<TreeNode> {
    let result, subResult
    nodes.forEach((item) => {
      if (item.id === id) {
        result = nodes
      } else if (item.children) {
        subResult = this.findNodeSiblings(item.children, id)
        if (subResult) { result = subResult }
      }
    })
    return result
  }

  buildTreeByLevel(data: TemplateLibraryPieceModel[], level: TEMPLATE_TYPES_VALUE): TemplateLibraryStandardTreeModel[] {
    return this.buildStandardTemplateLibraryTree(level, data)
  }

  buildStandardTemplateLibraryTree(templateType: TEMPLATE_TYPES_VALUE, templateLibrary: TemplateLibraryPieceModel[]): TemplateLibraryStandardTreeModel[] {
    return templateLibrary.map(template => {
      const pieceData = {
        ...template.piece,
        id: template.id,
        pieceName: template.name,
      }
      return this.buildPiece(pieceData, templateType)
    })
  }

  buildPiece(piece: PieceData, templateType: TEMPLATE_TYPES_VALUE): TemplateLibraryStandardTreeModel {
    const templateLibraryKey = TemplateLibraryKey[templateType]
    const newPiece = new TemplateLibraryStandardTreeModel()
    newPiece.name = this.getTemplateLibraryName(piece, templateType)
    newPiece.pieceName = piece.pieceName
    newPiece.value = piece
    if (piece[templateLibraryKey.pieceKey]) {
      newPiece.data = piece[templateLibraryKey.pieceKey].map(value => this.buildPiece(value, templateLibraryKey.nextKey))
    }
    return newPiece
  }

  getTemplateLibraryName(templateLibrary: ConfigurationPieceModel | ZonePieceModel | ComponentPieceModel | ConditionPieceModel | FormDataPieceModel | InspectionDetailPieceModel, templateType: TEMPLATE_TYPES_VALUE): string {
    const templateLibraryKey = TEMPLATE_NAME_KEY_LIST[templateType]
    return templateLibrary[templateLibraryKey] || templateLibrary
  }

  getFormattedPieces(rawTemplateLibraryPieces: TemplateLibraryPieceModel[], languageDictionary: EvirLanguageDictionary): TemplateLibraryPieceModel[] {
    return rawTemplateLibraryPieces.map(rawPiece => {
      if (rawPiece.pieceType !== TEMPLATE_TYPES_VALUE.CONFIGURATION) {
        return rawPiece
      }

      const isBlankPiece = rawPiece.id === 'blank-configuration-piece'
      const config = rawPiece.piece as unknown as ConfigurationPieceModel
      const convertKeyToName = (key: string, langDict: EvirLanguageDictionary, langCode: string, defaultName: string): string => {
        return this.langDictionaryService.convertLangKeyToString(key, langDict, langCode)
          || this.langDictionaryService.convertLangKeyToString(key, langDict, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
          || defaultName
      }
      if (isBlankPiece) {
        config.nameLangKey = this.langDictionaryService.getLangKeyByString(config.zoneLayoutLanguageKey, languageDictionary.languageStrings, LANGUAGE_CONFIG_TYPES.CONFIGURATION, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
      }
      const configname = convertKeyToName(config.nameLangKey, languageDictionary, currentLanguageCode, config.zoneLayoutLanguageKey)
      const zones = config.configZones.map(zone => {
        if (isBlankPiece) {
          zone.tagLangKey = this.langDictionaryService.getLangKeyByString(zone.tagNameKey, languageDictionary.languageStrings, LANGUAGE_CONFIG_TYPES.ZONE, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
        }
        const zoneName = convertKeyToName(zone.tagLangKey, languageDictionary, currentLanguageCode, zone.tagNameKey)
        const components = zone.tagComponents.map(component => {
          if (isBlankPiece) {
            component.componentLangKey = this.langDictionaryService.getLangKeyByString(component.componentNameKey, languageDictionary.languageStrings, LANGUAGE_CONFIG_TYPES.COMPONENT, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
            component.suggestedConditionLangKeys = component.suggestedConditions.map(condition => this.langDictionaryService.getLangKeyByString(condition, languageDictionary.languageStrings, LANGUAGE_CONFIG_TYPES.CONDITION, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]))
          }
          const componentName = convertKeyToName(component.componentLangKey, languageDictionary, currentLanguageCode, component.componentNameKey)
          const conditions = (component.suggestedConditionLangKeys || []).map((condition, index) => convertKeyToName(condition, languageDictionary, currentLanguageCode, component.suggestedConditions[index]))

          return {
            ...component,
            componentNameKey: componentName,
            suggestedConditions: isValidArray(conditions) ? conditions : (component.suggestedConditions || []),
          }
        })

        return {
          ...zone,
          tagNameKey: zoneName,
          tagComponents: components,
        }
      })

      return {
        ...rawPiece,
        piece: {
          ...rawPiece.piece,
          zoneLayoutLanguageKey: configname,
          configZones: zones,
        }
      }
    })
  }
}
