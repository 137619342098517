import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogComponent } from '../dialog/dialog.component'

@Component({
  selector: 'app-inform-dialog',
  templateUrl: './inform-dialog.component.html',
  styleUrls: ['./inform-dialog.component.scss']
})
export class InformDialogComponent {
  title: string
  message: string
  acceptText: string
  cancelText: string
  toastMessage: string

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<DialogComponent>) {
    if (data) {
      this.acceptText = data.acceptText
      this.cancelText = data.cancelText
      this.toastMessage = data.toastMessage
      this.title = data.title
      this.message = data.message
    }

  }

  handleCancel() {
    this.dialogRef.close()
  }
}
