import { O } from '@angular/cdk/keycodes'
import { Component, Input } from '@angular/core'
import { Observable, of} from 'rxjs' 
import { catchError, last, map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{
  
 isLoading: Observable<boolean>  
  /**
   * Key value used to trigger Spinner 
   */
  @Input() set shouldLoading(obs$: Observable<any>){
    if(obs$){
      this.isLoading = obs$.pipe(
        last(),
        map(()=> false),
        startWith(true),
        catchError(() => of(false)), 
      ) 
    }
  }
  
  constructor() { } 

}
