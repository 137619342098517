import {
  AbstractControl,
  ValidatorFn,
} from '@angular/forms'

function isNumber(value: any): boolean {
  return typeof value === 'number'
}

export function maxLengthValidator(maxLength: number, errorKey: string): ValidatorFn {
  return (control: AbstractControl) => {
    const controlValue = control.value
    return controlValue && controlValue.length > maxLength ? { [errorKey]: true } : null
  }
}

export function minLengthValidator(minLength: number, errorKey: string): ValidatorFn {
  return (control: AbstractControl) => {
    const controlValue = control.value
    return controlValue && controlValue.length < minLength ? { [errorKey]: true } : null
  }
}

export function maxInputNumber(maxValue: number, errorKey): ValidatorFn {
  return (control: AbstractControl) => {
    const controlValue = control.value
    return controlValue > maxValue ? { [errorKey]: true } : null
  }
}

export function lessThan(lesserName: string, greaterName: string, errorKey: string): ValidatorFn {
  return (control: AbstractControl) => {
    const testValues = [
      control.value[lesserName],
      control.value[greaterName],
    ]

    if (testValues.some(value => !isNumber(value))) {
      return null
    }

    const [lesser, greater] = testValues

    return greater <= lesser ? { [errorKey]: true } : null
  }
}
