import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { EditDialogDataModel } from 'src/app/views/companies/model'
import { BaseEditDialogComponent } from '../base-edit-dialog/base-edit-dialog.component'
import { FormGeneratorService, FormType } from '../tree/services/form-generator/form-generator.service'

@Component({
  selector: 'app-condition-edit-dialog',
  templateUrl: './condition-edit-dialog.component.html',
  styleUrls: [
    '../base-edit-dialog/base-edit-dialog.component.scss',
    './condition-edit-dialog.component.scss'
  ]
})
export class ConditionEditDialogComponent extends BaseEditDialogComponent {
  formType: FormType = 'condition'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditDialogDataModel,
    public dialogRef: MatDialogRef<ConditionEditDialogComponent>,
    formGenerator: FormGeneratorService) {
    super(data, dialogRef, formGenerator)
  }

}
