<div class="dialog-wrapper">
  <div mat-dialog-title>
    <div class="dialog-title">{{'ADD_INSPECTION_TYPE_TITLE' | translate}}</div>
    <mat-icon class="close-icon"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <mat-form-field appearance="outline"
      [style.width.%]="100">
      <mat-label>{{'INSPECTION_TYPE' | translate}}*</mat-label>
      <mat-select panelClass="inspection-type-select"
        disableOptionCentering="true"
        placeholder="{{'INSPECTION_TYPE' | translate}}"
        (valueChange)="selectedInspectionType=$event">
        <mat-option *ngFor="let inspectionTypePiece of inspectionTypeLibraryPieces$ | async"
          [disabled]="(inspectionDetailLangKeys$ | async).includes(inspectionTypePiece.piece.inspectionDetailLangKey)"
          [value]="inspectionTypePiece">{{inspectionTypePiece.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!selectedInspectionType"
      [mat-dialog-close]="selectedInspectionType">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</div>
