import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Select, Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable, combineLatest, of } from 'rxjs'
import { filter, startWith, last, map, catchError } from 'rxjs/operators'

import { AppState, DeleteFormLibraryPiece, GetTemplateLibraryPieces, UpdatePiece } from 'src/app/app.state'
import { Constants, ERROR_MESSAGES, FORM_LIBRARY_DROP_TYPE, i18nParams, MENU_OPTION_KEY, NOTIFICATION_TYPES } from 'src/app/constants/internationalized-constants-en'
import { equalsIgnoreCase, trimAll } from 'src/app/utils/utils'
import { DialogComponent } from '../dialog/dialog.component'
import { MenuOption } from '../menu/menu.component'
import { NotificationSnackbarService } from '../notification-snackbar/services/notification-snackbar.service'
import { PieceEditDialogComponent } from '../piece-edit-dialog/piece-edit-dialog.component'
import { TemplateLibraryPieceModel } from '../template-library/models/template-library.model'
import { CheckDuplicatePieceNameService } from '../template-library/services/check-duplicate-piece-name/check-duplicate-piece-name.service'
import { EvirLanguageDictionary } from '../tree/models/tree.model'
import { TemplateLibraryPieceConvertingService } from '../template-library/services/template-library-piece-converting/template-library-piece-converting.service'

@Component({
  selector: 'app-form-library',
  templateUrl: './form-library.component.html',
  styleUrls: ['./form-library.component.scss']
})
export class FormLibraryComponent implements OnInit {
  @Select(AppState.getFormLibraryPieces) _formLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  @Select(AppState.getBlankFormLibraryPieces) _blankFormLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  @Select(AppState.getLanguageDictionary) languageDictionary$: Observable<EvirLanguageDictionary>
  @Select(AppState.getAccessStatus) isAccessGranted$: Observable<boolean>

  @Output() draggedFormField = new EventEmitter<boolean>()

  NO_PREMADE_FIELD_DATA_MESSAGE = ERROR_MESSAGES.NO_PREMADE_FIELD_DATA
  menuOptions: MenuOption[] = [
    // ECT2-1326: Set disable true to temporarily disable this option
    { text: MENU_OPTION_KEY.EDIT, disable: true },
    { text: MENU_OPTION_KEY.DELETE },
  ]
  formLibraryDropType = FORM_LIBRARY_DROP_TYPE
  draggedDestination = 'inspection-type-detail-row-list'

  blankFormLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  formLibraryPieces$: Observable<TemplateLibraryPieceModel[]>

  public shouldLoading$: Observable<any> = of(false)

  constructor(
    public store: Store,
    public dialog: MatDialog,
    public snackbarService: NotificationSnackbarService,
    public pieceNameService: CheckDuplicatePieceNameService,
    public pieceConvertingService: TemplateLibraryPieceConvertingService,
    public translateService: TranslateService,
  ) {
    this.blankFormLibraryPieces$ = this._blankFormLibraryPieces$.pipe(
      map(blankPieces => blankPieces.map(blankPiece => ({ ...blankPiece, name: this.translateService.instant(blankPiece.name) }))),
    )

    this.formLibraryPieces$ = combineLatest([this._formLibraryPieces$, this.languageDictionary$]).pipe(
      map(([formLibraryPieces, langDict]) => this.pieceConvertingService.formatPremadeFieldPieces(formLibraryPieces, langDict)),
    )
  }

  ngOnInit(): void { }

  handleOptionSelected(menuOption: string, formPiece: TemplateLibraryPieceModel) {
    switch (menuOption) {
      case MENU_OPTION_KEY.DELETE:
        this.openDeleteConfirmDialog(formPiece)
        break

      case MENU_OPTION_KEY.EDIT:
        this.openPremadeFieldEditDialog(formPiece)
        break

      default:
        break
    }
  }

  openDeleteConfirmDialog(formPiece: TemplateLibraryPieceModel) {
    this.dialog.open(DialogComponent, {
      data: {
        onAccept: () => {
          this.deleteFormLibraryPiece(formPiece)
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('YES'),
        title: this.translateService.instant('INSPECTION_PIECE_DELETE_CONFIRM_TITLE', { name: formPiece.name }),
      },
      panelClass: 'delete-piece-dialog'
    })
  }

  deleteFormLibraryPiece(formPiece: TemplateLibraryPieceModel) {
    this.store.dispatch(new DeleteFormLibraryPiece(formPiece.id)).subscribe(
      value => { },
      error => {
        const detailedError = {
          'Error': [`${this.translateService.instant('ERROR_CODE')}: ${error.status}`]
        }
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('INSPECTION_PIECE_DELETE_FAIL_MSG', { name: formPiece.name }),
          NOTIFICATION_TYPES.FAILED,
          detailedError
        )
      },
      () => {
        this.pieceNameService.removePieceName(formPiece.name, formPiece.id)
        this.shouldLoading$ = this.store.dispatch(new GetTemplateLibraryPieces()).pipe(
          last(),
          map(() => false),
          startWith(true),
          catchError(() => of(false))
        )
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('INSPECTION_PIECE_DELETE_SUCCESS_MSG', i18nParams.FORMAT_PIECE_NAME(formPiece.name)),
          NOTIFICATION_TYPES.SUCCESS,
          null,
          Constants.snackbar.duration
        )
      }
    )
  }

  openPremadeFieldEditDialog(premadeField: TemplateLibraryPieceModel) {
    const oldPremadeFieldName = premadeField.name
    this.dialog.open(PieceEditDialogComponent, {
      data: {
        editPiece: premadeField,
        title: this.translateService.instant('INSPECTION_PIECE_EDIT_TITLE'),
      },
      panelClass: 'piece-edit-dialog',
    }).afterClosed().pipe(filter(data => !!data))
      .subscribe(([id, updatePremadeField, isAdmin]) => this.updatePremadeField(id, updatePremadeField, oldPremadeFieldName, isAdmin))
  }

  private updatePremadeField(id: string, premadeField: TemplateLibraryPieceModel, oldPremadeFieldName: string, isAdmin: boolean) {
    premadeField.name = trimAll(premadeField.name)
    this.store.dispatch(new UpdatePiece(premadeField, id, isAdmin)).subscribe(
      value => { },
      error => {
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('PIECE_EDIT_FAIL_MSG', { name: premadeField.name }),
          NOTIFICATION_TYPES.FAILED
        )
      },
      () => {
        if (!equalsIgnoreCase(oldPremadeFieldName, premadeField.name)) {
          this.pieceNameService.removePieceName(oldPremadeFieldName, id)
          this.pieceNameService.addPieceName(premadeField.name, id)
        }
        this.shouldLoading$ = this.store.dispatch(new GetTemplateLibraryPieces()).pipe(
          last(),
          map(() => false),
          startWith(true),
          catchError(() => of(false))
        )
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('INSPECTION_PIECE_EDIT_SUCCESS_MSG', { name: premadeField.name }),
          NOTIFICATION_TYPES.SUCCESS, null, Constants.snackbar.duration
        )
      }
    )
  }
}
