import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { convertLangKeyToString } from 'src/app/utils/utils'
import { EvirLanguageDictionary } from '../../../models/tree.model'
import { FormGroupGenerator, FormType } from './interface'

export class ConditionFormGroup implements FormGroupGenerator {
  generateFor: FormType = 'condition'
  generateFormGroup(formBuilder: FormBuilder, forModal: boolean): FormGroup {
    let controlConfigs: Record<string, any> = {}

    controlConfigs = {
      ...controlConfigs,
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(100),
      ]),
    }

    return formBuilder.group(controlConfigs)
  }

  transformToValidateableFormat(rawConfig: string, dictionary: EvirLanguageDictionary) {
    return {
      name: convertLangKeyToString(rawConfig, dictionary)
    }
  }
}
