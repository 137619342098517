<div class="error-page" fxLayout="row" fxLayoutAlign="center center">
  <div class="page-text" fxLayout="column" >

    <div class="error-header">
      {{ errorHeader }}
    </div>
    <div class="error-subheader">
      {{ errorSubHeader }}
      <br />
      {{ contactSubheader }}
    </div>

    <a class="action-button-error" *ngIf="actionButtonText" [routerLink]="actionButtonLink"
      (click)="resetErrorStatus()" [ngClass]="{'disabled-action-button': isDisabled}">
      {{ actionButtonText }}
    </a>

    <!-- support technical information -->
    <ul class="technical-information">
      <li *ngFor="let errorInfo of errorTechnicalInformation">
        <ng-template *ngTemplateOutlet="supportInformationItem; context: { $implicit: { label: errorInfo.label, value: errorInfo.content }}" ></ng-template>
      </li>
    </ul>

    <div class='logo' >
      <img src='./../../../assets/zonar-logo-white.png'>
    </div>

  </div>
</div>

<ng-template #supportInformationItem let-option>
  <label>{{ option.label }}: </label>
  <span>{{ option.value }}</span>
</ng-template>
