import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { SpinnerDirective } from './spinner.directive'

@NgModule({
  imports: [BrowserModule, CommonModule, MatProgressSpinnerModule],
  declarations: [SpinnerDirective],
  exports: [SpinnerDirective, MatProgressSpinnerModule],
})
export class SpinnerModule {}
