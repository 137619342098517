import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-unit-switcher',
  templateUrl: './unit-switcher.component.html',
  styleUrls: ['./unit-switcher.component.scss'],
})
export class UnitSwitcherComponent implements OnInit, OnChanges {
  @Input() units: [string, string] = ['mi', 'km']
  @Input() default: string = 'mi'

  public selected: string = 'mi'

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.default) {
      const selectIndex = this.units.indexOf(changes.default.currentValue)
      this.selected = selectIndex > -1 ? this.units[selectIndex] : ''
    }
  }

  ngOnInit(): void {}
}
