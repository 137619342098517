import { Injectable } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

import {
  ComponentModel,
  ConfigurationErrorModel,
  ConfigZoneModel,
  EvirLanguageDictionary,
  ZoneLayoutModel,
} from '../../models/tree.model'
import { FormGeneratorService } from '../form-generator/form-generator.service'
import {
  ComponentFormGroup,
  ConditionFormGroup,
  ConfigFormGroup,
  ZoneFormGroup,
} from '../form-generator/form-groups'
import { FormGroupGenerator, FormType } from '../form-generator/form-groups/interface'

interface AllValidationError {
  label: string
  level: FormType
  controlName: string
  errorName: string
  errorValue: any
  configName?: string
  zoneName?: string
  componentName?: string
}

interface FormGroupControl {
  [key: string]: AbstractControl
}

/**
 * Recursively fetch all the errors inside a form group.
 *
 * @param formGroup The target form group.
 * @param label A string that will be applied as a label for all the error items.
 * @param level A string that will be applied as a level for all the error items.
 * @return array containing all error item.
 */
function getAllFormErrors(
  formGroup: FormGroup,
  label: string,
  level: FormType
): AllValidationError[] {
  // Get errors from the source form group first, because there might be some
  // errors of the cross field validations and they can be put inside the
  // form group object
  let errors: AllValidationError[] = getFormError(formGroup, 'group', label, level)

  const controls: FormGroupControl = formGroup.controls
  Object.keys(controls).forEach((key) => {
    const control = controls[key]
    if (control instanceof FormGroup) {
      errors = errors.concat(getAllFormErrors(control, label, level))
    }
    errors = errors.concat(getFormError(controls[key], key, label, level))
  })

  return errors
}

/**
 * Fetch all errors from a AbstractControl.
 *
 * @param control The target control containing the errors.
 * @param controlName The name of control.
 * @param label A string that will be applied as a label for all error items.
 * @param level A string that will be applied as a level for all error items.
 * @return array of error items.
 */
function getFormError(
  control: AbstractControl,
  controlName: string,
  label: string,
  level: FormType
): AllValidationError[] {
  const errors: AllValidationError[] = []

  const controlErrors: ValidationErrors = control.errors
  if (controlErrors !== null) {
    // In general, we want to remove some errors when they overlapped
    // with 'required', because an empty number may have more than one error,
    // for example ['required', 'min', 'customError'], we should
    // only show 'required' error in this case.
    const filteredErrors = controlErrors.required
      ? { required: controlErrors.required }
      : controlErrors

    Object.keys(filteredErrors).forEach((keyError) => {
      errors.push({
        label: label,
        level: level,
        controlName: controlName,
        errorName: keyError,
        errorValue: controlErrors[keyError],
      })
    })
  }

  return errors
}

const MAPPER_FROM_FORM_ERROR_TO_ERROR_TYPE = {
  required: 'REQUIRED_FIELDS',
  invalidMinLengthChips: 'ITEM_NUMBER',
  invalidMaxLengthChips: 'ITEM_NUMBER',
  invalidAssetViewLocationLength: 'ITEM_NUMBER',
  invalidMaxValue: 'INVALID_NUMBER_VALUE',
  invalidMinValue: 'INVALID_NUMBER_VALUE',
  max: 'INVALID_NUMBER_VALUE',
  min: 'INVALID_NUMBER_VALUE',
  invalidAssetViewLocation: 'INVALID_NUMBER_VALUE',
  severityMinMax: 'INVALID_NUMBER_VALUE',
  pattern: 'INVALID_FORMAT',
}

/**
 * Wrap all logic to support validation for full JSON configuraton.
 */
@Injectable({
  providedIn: 'root'
})
export class FullConfigurationValidatorService {
  constructor(
    private formGenerator: FormGeneratorService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) { }

  /**
   * Validate against a full configuration.
   *
   * @param configuration The target full configuration.
   * @param dictionary Dictionary object that support reversing name key to actual name in string
   * @return Array of configuration error
   */
  validate(
    configuration: ZoneLayoutModel[],
    dictionary: EvirLanguageDictionary
  ): ConfigurationErrorModel[] {
    const result: AllValidationError[][] = []
    // For each configuration, the order of validating should be in the following:
    // config (zone layout) -> zone -> component -> condition
    // Errors also follow this order, for example the errors for zone should appear
    // before the errors for component
    for (const config of configuration) {
      result.push(this.validateZoneLayout(config, dictionary))
    }

    return result
      .map((item) => {
        // we can't using bind to make it short, because typescript `bind`
        // will change the function type to any, which is not good for `map` and `filter`
        return this.transformFormErrorToConfigurationError(item)
      })
      .filter((item) => item !== null)
  }

  /**
   * Validate zone layout (configuration) level and all its inner structure.
   *
   * @param zoneLayout The target zone layout.
   * @param dictionary Dictionary object that support reversing name key to actual name in string.
   * @return Array of error item.
   */
  validateZoneLayout(
    zoneLayout: ZoneLayoutModel,
    dictionary: EvirLanguageDictionary
  ): AllValidationError[] {
    // validate for zonelayout level
    const [generator, formGroup] =
      this.getGeneratorAndFormGroup<ConfigFormGroup>('configuration')
    const transformedData = generator.transformToValidateableFormat(
      zoneLayout,
      dictionary
    )

    let result = this.getCurrentFormErrors(formGroup, transformedData, 'configuration')
    for (const zone of zoneLayout.configZones) {
      result = result.concat(this.validateZone(zoneLayout, zone, dictionary))
    }
    return result.map(item => ({ ...item, configName: transformedData.name, }))
  }

  /**
   * Validate zone level and all its inner structure.
   *
   * @param parentConfiguration The parent configuration. We need it for validating view locations.
   * @param configZone The target zone.
   * @param dictionary Dictionary object that support reversing name key to actual name in string.
   * @return Array of error item.
   */
  validateZone(
    parentConfiguration: ZoneLayoutModel,
    configZone: ConfigZoneModel,
    dictionary: EvirLanguageDictionary
  ): AllValidationError[] {
    const [generator, formGroup] = this.getGeneratorAndFormGroup<ZoneFormGroup>('zone')
    const transformedData = generator.transformToValidateableFormat(
      configZone,
      dictionary,
      parentConfiguration
    )

    let result = this.getCurrentFormErrors(formGroup, transformedData, 'zone')
    for (const componentIndex of configZone.tagComponents) {
      result = result.concat(
        this.validateComponent(parentConfiguration.components[componentIndex], dictionary)
      )
    }
    return result.map(item => ({ ...item, zoneName: transformedData.name, }))
  }

  /**
   * Validate component and all its inner structure.
   *
   * @param component The target component.
   * @param dictionary Dictionary object that support reversing name key to actual name in string.
   * @return Array of error item.
   */
  validateComponent(
    component: ComponentModel,
    dictionary: EvirLanguageDictionary
  ): AllValidationError[] {
    const [generator, formGroup] = this.getGeneratorAndFormGroup<ComponentFormGroup>('component')
    const transformedData = generator.transformToValidateableFormat(component, dictionary)

    let result = this.getCurrentFormErrors(formGroup, transformedData, 'component')
    for (const conditionKey of component.suggestedConditionLangKeys) {
      result = result.concat(this.validateCondition(conditionKey, dictionary))
    }

    return result.map(item => ({ ...item, componentName: transformedData.name, }))
  }

  /**
   * Validate condition level.
   *
   * @param conditionKey The condition key matching a name in dictionary.
   * @param dictionary Dictionary object that support reversing name key to actual name in string.
   * @return Array of error item.
   */
  validateCondition(
    conditionKey: string,
    dictionary: EvirLanguageDictionary
  ): AllValidationError[] {
    const [generator, formGroup] = this.getGeneratorAndFormGroup<ConditionFormGroup>('condition')
    const transformedData = generator.transformToValidateableFormat(conditionKey, dictionary)
    return this.getCurrentFormErrors(formGroup, transformedData, 'condition')
  }

  private getGeneratorAndFormGroup<T extends FormGroupGenerator>(
    formType: FormType
  ): [T, FormGroup] {
    const generator = this.formGenerator.getFormGenerator(formType) as T
    const formGroup = generator.generateFormGroup(this.formBuilder, false)
    return [generator, formGroup]
  }

  private getCurrentFormErrors(
    formGroup: FormGroup,
    data: any,
    formType: FormType
  ): AllValidationError[] {
    formGroup.patchValue(data)
    formGroup.updateValueAndValidity()
    return getAllFormErrors(formGroup, data.name, formType)
  }

  private transformFormErrorToConfigurationError(
    formError: AllValidationError[]
  ): ConfigurationErrorModel {
    if (formError.length <= 0) {
      return null
    }

    const createErrorMessageFunc = this.createErrorMessage.bind(this)

    return {
      configurationName: formError[0].configName,

      // Loop through all errors and group them together by error type
      // For example for a list of [required, max, min, max] (roughtly presented of real list)
      // will be group like this:
      // {
      //   required: [{..}],
      //   max: [{..}, {..}],
      //   min: [{..}]
      // }
      configurationErrorList: formError.reduce((acc, current) => {
        const key = this.getConfigurationErrorType(current)
        if (key) {
          const onlyUnique = (value, index, self) => self.indexOf(value) === index
          acc[key] = [
            ...(acc[key] || []),
            createErrorMessageFunc(current)
          ].filter(onlyUnique) // errors that already reported should not appear again
        }

        return acc
      }, {}),
    }
  }

  private createErrorMessage(error: AllValidationError): string {
    if (error.level === 'condition' || error.level === 'component') {
      let hierarchy = [error.configName, error.zoneName, error.componentName]
      if (error.level === 'component') { hierarchy = hierarchy.slice(0, -1) }
      const hierarchyDescription = hierarchy
        .map(item => item || '')
        .join(' - ')
      return `${this.translateService.instant(error.level.toUpperCase())} '${error.label}' [${hierarchyDescription}]`
    }
    return `${this.translateService.instant(error.level.toUpperCase())} '${error.label}'`
  }

  private getConfigurationErrorType(error: AllValidationError): string {
    return MAPPER_FROM_FORM_ERROR_TO_ERROR_TYPE[error.errorName] || ''
  }
}
