<div class="dialog-wrapper">
  <div mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="space-between start">
    <div class="dialog-title">
      <span>{{'SELECT_LABEL' | translate}}</span>
      <span class="dropdown-sub-title">{{'DROPDOWN_LABEL' | translate}}</span>
    </div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form [formGroup]="dropdownEditForm">
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'LABEL_LABEL' | translate}}</mat-label>
        <input matInput
          class="label-field"
          placeholder="{{'LABEL_PLACEHOLDER' | translate}}"
          formControlName="label"
          maxlength="20"
          [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="dropdownEditForm.controls.label.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="dropdownEditForm.controls.label.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'HELPER_TEXT_LABEL' | translate}}</mat-label>
        <input matInput
          class="helper-text-field"
          placeholder="{{'HELPER_TEXT_PLACEHOLDER' | translate}}"
          formControlName="helper"
          maxlength="40"
          [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="dropdownEditForm.controls.helper.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="dropdownEditForm.controls.helper.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- Choice list template -->
      <div class="choice-list-title">{{'CHOICES_NAME' | translate}}</div>
      <div class="choice-list-content"
        cdkDropList
        (cdkDropListDropped)="drop($event)">
          <div formArrayName="choices"
          class="choice-box"
          cdkDrag
          *ngFor="let choice of choiceList.controls; let i = index">
          <div [formGroupName]="i" [ngClass]="{
            'oneline-error': choice.controls.name.hasError('required'),
            'twoline-error': choice.controls.name.hasError('pattern') }">
            <button class="drop-choice-button"
            (mouseenter)="onMouseEnter($event.target)"
            (mouseleave)="onMouseOut($event.target)">
              <div class="drop-icon"></div>
            </button>
            <mat-form-field class="choice-item"
              appearance="outline">
              <input matInput
                formControlName="name"
                maxlength="128"
                [errorStateMatcher]="matcher"/>
              <mat-error *ngIf="choice.controls.name.hasError('required')">
                {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
              </mat-error>
              <mat-error *ngIf="choice.controls.name.hasError('pattern')">
                {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
              </mat-error>
            </mat-form-field>
            <button class="remove-choice-button"
              [disabled]="choiceList.controls && choiceList.controls.length <= minNumberOfChoices"
              (click)="removeChoice(i)">
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button class="add-choice-button"
              (click)="addChoice('', i)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- End of choice list template -->

      <mat-slide-toggle class="required-toggle"
        formControlName="required"
        [style.width.%]="100">{{'REQUIRED_FIELD_NAME' | translate}}</mat-slide-toggle>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!dropdownEditForm.valid"
      (click)="handleApply()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</div>
