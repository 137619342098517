<app-card [containerClasses]="isActive ? ['active'] : []" fxFlex
  [hasContextMenu]="true"
  [contextMenuItems]="menuItems"
  [cardTitle]="inspection.name"
  [isInspectionType]="true"
  (clickedItem)="handleClickContextMenuItem($event)"
>
  <ng-container card-title >{{ inspection.name }}</ng-container>
  <ng-container card-body >{{ inspection.description }}</ng-container>
</app-card>
