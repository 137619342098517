import { Ect2Data } from 'src/app/app.state'

export class TreeNode {
  id: string
  children: TreeNode[]
  name: string
  displayName?: string
  value?: any
  reflectCount?: ReflectCountModel
  nodeType?: TreeDataType
}

/** Flat node with expandable and level information */
export class TreeFlatNode {
  constructor(
    public expandable: boolean,
    public name: string,
    public level: number,
    public value: any,
    public id: string,
    public nodeType: TreeDataType,
    public reflectCount?: ReflectCountModel,
    // Define the node's sibling index in the nodes of the same level and the same parent
    public siblingIndex?: number,
    public displayName?: string
  ) {}
}

export class DataConfigurationModel {
  name: string
  data: DataConfigurationModel[]
  value?: any
  reflectCount?: ReflectCountModel
}

export class ReflectCountModel {
  index: number
  count: number
}

export const DataConfigurationKeyName = {
  0: {
    keyValue: 'configZones',
    keyId: 'zoneLayoutLanguageKey',
  },
  1: {
    keyValue: 'tagComponents',
    keyId: 'tagNameKey',
  },
  2: {
    keyValue: 'tagComponents',
    keyId: 'tagNameKey',
  },
  3: {
    keyValue: 'componentNameKey',
    keyId: 'suggestedConditions',
  },
}

export enum TreeDataType {
  CONFIGURATION = 'configuration',
  TEMPLATE_LIBRARY = 'template-library',
}

export class SettingModel {
  active: boolean
  allowCustomDefectDescription: boolean
  allowTimeCardInspection: boolean
  allowUserAssets: boolean
  companyId: string
  created: string
  defaultZoneLayoutId: string
  id: string
  imageRetention: number
  inspectionRetention: number
  maxDefectImageCount: number
  maxTotalImageCount: number
  propagateDefects: boolean
  requireCriticalDefectImage: boolean
  requireUnverifiedZoneReason: boolean
  userToCoreAssociationLevel: string
  version: string
}

export class ConfigurationModel {
  id?: string
  zoneLayouts: ZoneLayoutModel[]
  inspectionDetails: InspectionDetailModel[]
  companyId: string
  ect2Data?: Ect2Data
  configType: string
  draft: boolean
  translationObject?: TranslationObject
  evirLanguageDictionary?: EvirLanguageDictionary
  languageCodes: string[]
  created: string
  version: string
  maxDefectImageCount?: number
}

export class ZoneLayoutModel {
  assetType: string[]
  components: ComponentModel[]
  configZones: ConfigZoneModel[]
  zoneLayoutLangKey: string
  assetViewGrid?: number[]
  assetViewId?: string
  ect2Data?: Ect2Data
  borderConfigImport?: boolean
}

export class ComponentModel {
  maxSeverity: number
  minSeverity: number
  componentLangKey: string
  suggestedConditionLangKeys: string[]
}

export class ConfigZoneModel {
  tagTypeLangKey: string
  tagNumber: number
  tagLangKey: string
  tagComponents: number[]
  zoneInspectionTypes: string[]
  assetViewLocation?: number[]
}

export class InspectionDetailModel {
  formData?: FormDataModel[]
  enforceOrder: boolean
  legallyMandated: boolean
  prepopulateDefects: boolean
  requiresReview: boolean
  zoneless: boolean
  associateDriver: boolean
  inspectionDetailLangKey: string
  inspectionDescriptionLangKey: string
  inspectionType: string
  ect2Data: Ect2Data
}

export class FormDataModel {
  regex?: string
  default?: any
  selectLangKeys?: string[]
  hintLangKey: string
  maximum?: number
  minimum?: number
  required: boolean
  fieldNameLangKey: string
  inspectionDetailType: string
  inspectionDetailCategory: string
  inspectionDetailMeasurementType?: string
  inspectionDetailUnitConversionPair?: string[]
  inspectionDetailMaxDecimal?: number
}

export class ConfigTemplateLargeModel {
  companyName: number
  date: string
  updated: string
  version: string
  code: string
  zoneLayouts: ConfigurationModel
}

export class ConfigTemplateSmallModel {
  companyName: number
  date: string
  updated: string
  version: string
  code: string
  zoneLayouts: ConfigurationModel
}

export class InstallerExportModel {
  code: string
  companyName: string
  configName: string
  date: string
  version?: string
  updated?: string
  zoneLayouts: InstallerExportZoneLayoutModel[]
  /**
   * ECT2-1138: The labels like "COMPANY", "LAST UPDATED",... in template export file
   * required to i18n translate based on browser locale.
   */
  evirMobileHeaderLabel: string
  companyLabel: string
  exportedDateLabel: string
  lastUpdatedLabel: string
  versionLabel: string
  accountCodeLabel: string
  configNameLabel: string
  assetTypesLabel: string
  zoneNameLabel: string
  zoneNumberLabel: string
  componentNameLabel: string
  conditionNameLabel: string
}

export class TransformedInstallerExportZoneLayoutModel {
  zoneLayouts: InstallerExportZoneLayoutModel[]
}

export class InstallerExportZoneLayoutModel {
  assetType: any[]
  configZones: InstallerExportConfigZoneModel[]
  zoneLayoutLanguageKey: string
}

export class InstallerExportConfigZoneModel {
  tagComponents: InstallerExportComponentModel[]
  tagNameKey: string
  tagNumber: number
}

export class InstallerExportComponentModel {
  componentNameKey: string
  suggestedConditions: any[]
}

export class SelectedConfigurationModel {
  index: number
}

export class ConfigurationValidationModel {
  configName: string
  assetType: string[]
  totalOfStandardZones: number
  zones: ZoneValidationModel[]
}

export class ZoneValidationModel {
  zoneName: string
  totalOfComponents: number
  components: ComponentValidationModel[]
}

export class ComponentValidationModel {
  componentName: string
  totalOfConditions: number
}

export class ConfigurationErrorModel {
  configurationName: string
  configurationErrorList: Record<string, string[]>
}

export class LegacyConfigurationDataModel {
  dbconfig: DataConfig
}

export class DataConfig {
  Config: Config
}

export class Config {
  certificationInfoText: string
  certificationGreen: string
  certificationYellow: string
  certificationRed: string
  AssetValue: string
  Asset: Asset[]
  titles: Title
}

export class Asset {
  Zone: Zone[]
}

export class Zone {
  Component: Component[]
}

export class Component {
  Condition: [
    {
      _text: string
    }
  ]
}
export class Title {
  zones: string
  zone: string
  components: string
  component: string
  conditions: string
  condition: string
  noneFound: string
}

export class ZoneNameModel {
  parentIndex: number
  zoneName: string
}

export class AssetViewModel {
  assetViewData: string
  assetViewDefaults: AssetViewDefaultModel
  assetViewId: string
  assetViewName: string
  proprietary: boolean
}

export class AssetViewDefaultModel {
  assetViewGrid: number[]
  assetViewLocations: any[]
}

export class CopiedConfigModel {
  copiedCompanyId: string
  nodeCopiedData: ZoneLayoutModel
  originalCopyNodeId: string
  nodeCopyEvirLanguageDictionary: EvirLanguageDictionary
  copiedInspectionByNameMapping: Record<string, InspectionDetailModel>
}

export class AssetViewUploadModel {
  assetViewId?: string
  companyId?: string
  assetViewName: string
  proprietary: boolean
  assetViewData: string
  assetViewDefaults?: {
    assetViewGrid: number[]
    assetViewLocations: number[][]
  }
}

export class TranslationObject {
  [key: string]: {
    translations: Record<string, string>
    configType: string
    context?: string
  }
}

export class TranslationObjectValue {
  translations: Record<string, string>
  configType: string
  context?: string
}

export class EvirLanguageDictionary {
  languageStrings: TranslationObject
  legacyLanguageStrings: {
    [key: string]: Record<string, string>
  }
}

export class RawLanguageDictionary {
  evirLanguageDictionary: EvirLanguageDictionary
  translationObject: TranslationObject
}
