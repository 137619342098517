<app-three-column-layout [rightColumnFlex]="38">
  <ng-container left>
    <app-asset-zone-map></app-asset-zone-map>
  </ng-container>
  <ng-container center>
    <app-tree></app-tree>
  </ng-container>
  <ng-container right>
    <app-template-library></app-template-library>
  </ng-container>
</app-three-column-layout>
