<ng-container>
  <div mat-dialog-title>
    <div class="dialog-title">{{ data.title }}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close" (click)="this.closeDialog()">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="edit-content">
    <form class="piece-edit-form" [formGroup]="editForm">
      <mat-form-field appearance="outline" floatLabel="always" [style.width.%]="100">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input
          matInput
          class="field-name"
          placeholder="{{'NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
          maxlength="100"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="editForm.controls.name.hasError('required')">
          {{ 'NAME_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="editForm.controls.name.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="editForm.controls.name.hasError('duplicatePieceName')">
          {{ 'NAME_DUPLICATED_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle class="admin-view-only-toggle" [style.width.%]="100" formControlName="isAdmin">{{'ADMIN_ONLY_CHECKBOX_NAME' | translate}}</mat-slide-toggle>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="primary-button" (click)="this.closeDialog()">{{'CANCEL' | translate}}</button>
    <button
      mat-button
      class="special-button"
      (click)="onAcceptBtnClicked()"
      [disabled]="!editForm.valid"
    >
      {{'APPLY' | translate}}
    </button>
  </mat-dialog-actions>
</ng-container>
