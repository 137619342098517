import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

  /**
   * Capitalize input
   *
   * @param rawString input string
   * @param [reserveTail=false] determine if we should keep case of those characters after the first one.
   * For example: mPrivate -> MPrivate if it's true, otherwise mPrivate -> Mprivate
   * @return transformed string
   */
  transform(rawString: string, reserveTail = false): string {
    if (!rawString) {
      return ''
    }

    const tail = reserveTail ? rawString.substr(1) : rawString.substr(1).toLowerCase()
    return rawString.charAt(0).toUpperCase() + tail
  }

}
