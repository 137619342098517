import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

/**
 * Throttle click directive to avoid multiple clicks at the same time
 */
@Directive({
  selector: '[appThrottleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {

  /**
   * Key value used to set the throttle time
   */
  @Input() throttleTime: number = 1000
  /**
   * Emit event when the first click event is in throttle time
   */
  @Output() throttledClick = new EventEmitter()

  private clicks = new Subject<MouseEvent>()
  private subscription: Subscription

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      throttleTime(this.throttleTime)
    ).subscribe((event: MouseEvent) => this.emitThrottledClick(event))
  }

  emitThrottledClick(event: MouseEvent) {
    // Handle to emit the throttled click
    this.throttledClick.emit(event)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    // Prevent action when click event occurs
    // Feed new click event to subject
    event.preventDefault()
    event.stopPropagation()
    this.clicks.next(event)
  }

}
