export const permissions = {
  defaultRedirectUrl: '/no-permission',
  defaultPermPrefix: 'ect2',
  routes: [
    ['/companies', 'read:company'],
    ['/companies/inspection-types', 'read:company'],
    ['/companies/configurations', 'read:company'],
    ['/companies/:companyId', 'read:company', { companyId: 'companyId' }],
    ['/companies/:companyId/configurations', 'read:company', { companyId: 'companyId' }],
    ['/companies/:companyId/inspection-types', 'read:company', { companyId: 'companyId' }],
  ],
}
