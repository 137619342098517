import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-configuration-layout',
  templateUrl: './configuration-layout.component.html',
  styleUrls: ['./configuration-layout.component.scss']
})
export class ConfigurationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
