import { FormGroup, ValidatorFn } from '@angular/forms'

export function assetViewGridValidator(validLength: number): ValidatorFn {
  return (form: FormGroup) => {
    const assetViewGridFormControl = form.controls.assetViewGrid
    const assetViewGridValue: number[] = assetViewGridFormControl.value || []
    const hasAssetViewId = Boolean(form.value.assetViewId)

    if (!hasAssetViewId) {
      assetViewGridFormControl.setErrors(null)
      return null
    }

    if (assetViewGridValue.length === 0) {
      assetViewGridFormControl.setErrors({ required: true })
      return null
    }

    if (assetViewGridValue.length < validLength) {
      assetViewGridFormControl.setErrors({ invalidMinLengthChips: true })
      return null
    }

    if (assetViewGridValue.length === validLength && !assetViewGridValue.every(value => value > 0)) {
      assetViewGridFormControl.setErrors({ invalidAssetViewGrid: true })
      return null
    }

    assetViewGridFormControl.setErrors(null)
    return null
  }
}

export function assetViewLocationValidator(validLength: number): ValidatorFn {
  return (form: FormGroup) => {
    const assetViewLocationFormControl = form.controls.assetViewLocation
    const assetViewLocationValue: number[] = assetViewLocationFormControl.value || []
    const hasAssetViewId = Boolean(form.value.assetViewId)
    const assetViewGridValue: number[] = form.value.assetViewGrid || []

    if (!hasAssetViewId) {
      assetViewLocationFormControl.setErrors(null)
      return null
    }

    if (assetViewLocationValue.length === 0) {
      assetViewLocationFormControl.setErrors({ required: true })
      return null
    }

    if (assetViewLocationValue.length < validLength) {
      assetViewLocationFormControl.setErrors({ invalidAssetViewLocationLength: true })
      return null
    }

    if (assetViewGridValue.length < validLength) {
      assetViewLocationFormControl.setErrors(null)
      return null
    }

    if (assetViewGridValue.length === assetViewLocationValue.length
      && !assetViewGridValue.every((value, index) => assetViewLocationValue[index] < value)) {
      assetViewLocationFormControl.setErrors({ invalidAssetViewLocation: true })
      return null
    }

    assetViewLocationFormControl.setErrors(null)
    return null
  }
}
