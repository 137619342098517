import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subject } from 'rxjs'

import { MAX_ASSET_TYPE, MIN_ASSET_TYPE } from 'src/app/constants/internationalized-constants-en'
import { environment } from 'src/environments/environment'
import { ChipsComponent } from '../chips/chips.component'

@Component({
  selector: 'app-asset-type-input',
  templateUrl: './asset-type-input.component.html',
  styleUrls: ['./asset-type-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssetTypeInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AssetTypeInputComponent,
      multi: true,
    },
  ]
})
export class AssetTypeInputComponent implements ControlValueAccessor, OnInit {
  
  @Input() validStatus: Record<string, boolean>
  @Output() assetTypeChipStatusChange = new EventEmitter()

  inputAssetType: string
  assetTypeValue: string[] = []
  disabled = false
  region = environment.region
  minAssetType = MIN_ASSET_TYPE
  maxAssetType = MAX_ASSET_TYPE

  private assetTypeValueChanges = new Subject<string[]>()
  private touches = new Subject()
  private validates = new Subject()

  constructor() { }

  ngOnInit(): void { }

  registerOnChange(fn): void {
    this.assetTypeValueChanges.subscribe(fn)
  }

  registerOnTouched(fn): void {
    this.touches.subscribe(fn)
  }

  registerOnValidatorChange(fn): void {
    this.validates.subscribe(fn)
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  writeValue(assetTypeValue: string[]): void {
    this.assetTypeValue = assetTypeValue || []
    this.inputAssetType = this.assetTypeValue[2]
    this.validates.next(true)
  }

  validate() {
    return this.region === 'EU' && (!this.assetTypeValue || this.assetTypeValue.length < 3)
      ? { emptyLastAssetType: true }
      : null
  }

  updateAssetTypeValue(newValue: string) {
    const baseAssetTypeValue = this.region === 'EU' ? ['vehicle', 'standard'] : this.assetTypeValue.slice(0, 2)
    const newAssetTypeValue = typeof(newValue) === 'string'
      ? [...baseAssetTypeValue, ...[newValue]]
      : newValue
    this.assetTypeValue = newAssetTypeValue
    this.inputAssetType = newAssetTypeValue[2]
    this.assetTypeValueChanges.next(newAssetTypeValue)
  }

  handleChangeAssetTypeChip(status: boolean, chips: ChipsComponent) {
    this.assetTypeChipStatusChange.emit({
      status: status,
      chipInputValue: chips.chipInputValue,
    })
  }
}
