import { Injectable } from '@angular/core'

import {
  CONFIGURATION_ERROR_TYPES,
  MAX_NUMBER_COMPONENTS,
  MAX_TOTAL_STANDARD_ZONES,
  MIN_NUMBER_COMPONENTS,
  MIN_NUMBER_CONDITIONS,
  MIN_NUMBER_ZONES,
  STANDARD_ZONE_TAG_TYPES,
} from 'src/app/constants/internationalized-constants-en'
import {
  ComponentModel,
  ComponentValidationModel,
  ConfigurationErrorModel,
  ConfigurationValidationModel,
  ConfigZoneModel,
  EvirLanguageDictionary,
  ZoneLayoutModel,
  ZoneValidationModel,
} from '../../models/tree.model'
import { LanguageDictionaryHandlingService } from '../language-dictionary-handling/language-dictionary-handling.service'
import { FullConfigurationValidatorService } from './full-configuration-validator.service'
import { getAssetTypeErrors } from './util/get-assettype-errors'

@Injectable({
  providedIn: 'root',
})
export class DataConfigurationValidationService {
  constructor(
    private langDictionaryService: LanguageDictionaryHandlingService,
    private fullConfigurationValidator: FullConfigurationValidatorService
  ) {}

  validateFullForm(
    dataConfiguration: ZoneLayoutModel[],
    languageDictionary: EvirLanguageDictionary
  ): ConfigurationErrorModel[] {
    if (dataConfiguration === null || dataConfiguration === undefined) {
      return []
    }

    return this.fullConfigurationValidator.validate(dataConfiguration, languageDictionary)
  }

  validateDataConfigurationError(
    dataConfiguration: ZoneLayoutModel[],
    languageDictionary: EvirLanguageDictionary
  ): ConfigurationErrorModel[] {
    let dataConfigurationError = this.validateFullForm(
      dataConfiguration,
      languageDictionary
    )
    const transformDataConfiguration: ConfigurationValidationModel[] =
      this.transformDataConfigurationForValidation(dataConfiguration, languageDictionary)

    if (!(transformDataConfiguration && transformDataConfiguration.length)) {
      return []
    }

    transformDataConfiguration.forEach((config) => {
      const configErrorList: Record<string, string[]> = {}

      // Validation for the minimum number of zones in a config
      if (config.zones.length < MIN_NUMBER_ZONES) {
        this.addNewErrorToErrorList(
          configErrorList,
          CONFIGURATION_ERROR_TYPES.LIMIT_NUMBER_OF_ZONES,
          [config.configName]
        )
      }

      // Validation for the total of standard zones in a config
      if (config.totalOfStandardZones > MAX_TOTAL_STANDARD_ZONES) {
        this.addNewErrorToErrorList(
          configErrorList,
          CONFIGURATION_ERROR_TYPES.TOTAL_OF_STANDARD_ZONES,
          [config.configName]
        )
      }

      this.validateConfigurationErrorWithDataZone(
        configErrorList,
        config.configName,
        config.zones
      )

      if (Object.keys(configErrorList).length) {
        dataConfigurationError.push({
          configurationName: config.configName,
          configurationErrorList: configErrorList,
        })
      }
    })

    // validate that asset types are unique
    const errors: ConfigurationErrorModel[] = getAssetTypeErrors(transformDataConfiguration)

    dataConfigurationError = [...dataConfigurationError, ...errors]

    return dataConfigurationError
  }

  private validateConfigurationErrorWithDataZone(
    configErrorList: Record<string, string[]>,
    configName: string,
    zoneList: ZoneValidationModel[]
  ) {
    if (!(zoneList && zoneList.length)) {
      return
    }

    const zoneErrorList: string[] = []
    let allComponentErrorList: string[] = []
    zoneList.forEach((zone) => {
      // Validation for limit the number of components in a zone
      if (
        zone.totalOfComponents < MIN_NUMBER_COMPONENTS ||
        zone.totalOfComponents > MAX_NUMBER_COMPONENTS
      ) {
        zoneErrorList.push(`[${configName} - ${zone.zoneName}]`)
      }
      const componentErrorListInZone = this.validateConfigurationErrorWithDataComponent(
        configName,
        zone.zoneName,
        zone.components
      )
      allComponentErrorList = [...allComponentErrorList, ...componentErrorListInZone]
    })

    if (allComponentErrorList.length) {
      this.addNewErrorToErrorList(
        configErrorList,
        CONFIGURATION_ERROR_TYPES.LIMIT_NUMBER_OF_CONDITIONS,
        allComponentErrorList
      )
    }

    if (zoneErrorList.length) {
      this.addNewErrorToErrorList(
        configErrorList,
        CONFIGURATION_ERROR_TYPES.LIMIT_NUMBER_OF_COMPONENTS,
        [...new Set(zoneErrorList)]
      )
    }
  }

  private validateConfigurationErrorWithDataComponent(
    configName: string,
    zoneName: string,
    componentList: ComponentValidationModel[]
  ): string[] {
    if (!(componentList && componentList.length)) {
      return []
    }

    const componentErrorList: string[] = []
    componentList.forEach((component) => {
      // Validation for limit the number of conditions in a component
      if (
        component.totalOfConditions < MIN_NUMBER_CONDITIONS ||
        component.totalOfConditions > MAX_NUMBER_COMPONENTS
      ) {
        componentErrorList.push(
          `[${configName} - ${zoneName} - ${component.componentName}]`
        )
      }
    })

    return [...new Set(componentErrorList)]
  }

  private addNewErrorToErrorList(
    errorList: Record<string, string[]>,
    newErrorKey: string,
    newErrorValue: string[]
  ) {
    if (!errorList || !newErrorKey || !(newErrorValue && newErrorValue.length)) {
      return
    }

    errorList[newErrorKey] = newErrorValue
  }

  private transformDataConfigurationForValidation(
    configurationList: ZoneLayoutModel[],
    languageDictionary: EvirLanguageDictionary
  ): ConfigurationValidationModel[] {
    if (!(configurationList && configurationList.length) || !languageDictionary) {
      return []
    }

    return configurationList.map((config) => {
      const configName = this.langDictionaryService.convertLangKeyToString(
        config.zoneLayoutLangKey,
        languageDictionary
      )
      const totalOfStandardZones = config.configZones.filter((zone) =>
        STANDARD_ZONE_TAG_TYPES.includes(
          this.langDictionaryService.convertLangKeyToString(
            zone.tagTypeLangKey,
            languageDictionary
          )
        )
      ).length
      const zones = this.transformDataZoneForValidation(
        config.configZones,
        config.components,
        languageDictionary
      )
      return {
        configName: configName,
        totalOfStandardZones: totalOfStandardZones,
        zones: zones,
        assetType: config.assetType
      }
    })
  }

  private transformDataZoneForValidation(
    zoneList: ConfigZoneModel[],
    componentList: ComponentModel[],
    languageDictionary: EvirLanguageDictionary
  ): ZoneValidationModel[] {
    return zoneList.map((zone) => {
      const zoneName = this.langDictionaryService.convertLangKeyToString(
        zone.tagLangKey,
        languageDictionary
      )
      const components = this.transformDataComponentForValidation(
        zone.tagComponents,
        componentList,
        languageDictionary
      )
      return {
        zoneName: zoneName,
        totalOfComponents: zone.tagComponents.length,
        components: components,
      }
    })
  }

  private transformDataComponentForValidation(
    tagComponentList: number[],
    componentList: ComponentModel[],
    languageDictionary: EvirLanguageDictionary
  ): ComponentValidationModel[] {
    return tagComponentList.map((tagComponent) => {
      const componentName = this.langDictionaryService.convertLangKeyToString(
        componentList[tagComponent].componentLangKey,
        languageDictionary
      )
      return {
        componentName: componentName,
        totalOfConditions: componentList[tagComponent].suggestedConditionLangKeys.length,
      }
    })
  }
}
