import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms'

import {
  NAME_REGEX,
  NUMBER_REGEX,
} from 'src/app/constants/internationalized-constants-en'
import { convertLangKeyToString } from 'src/app/utils/utils'
import { ComponentModel, EvirLanguageDictionary } from '../../../models/tree.model'

import { lessThan } from '../validators/number'
import { FormGroupGenerator, FormType } from './interface'

export class ComponentFormGroup implements FormGroupGenerator {
  generateFor: FormType = 'component'

  generateFormGroup(formBuilder: FormBuilder, forModal: boolean): FormGroup {
    let controlConfigs: Record<string, any> = {}
    let crossFieldValidators: ValidatorFn[] = []

    controlConfigs = {
      ...controlConfigs,
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_REGEX),
        Validators.maxLength(100),
      ]),
    }

    controlConfigs = {
      ...controlConfigs,
      severityMin: new FormControl('', [
        Validators.required,
        Validators.pattern(NUMBER_REGEX),
        Validators.max(255),
        Validators.min(0),
      ]),
      severityMax: new FormControl('', [
        Validators.required,
        Validators.pattern(NUMBER_REGEX),
        Validators.max(255),
        Validators.min(0),
      ]),
    }

    crossFieldValidators.push(
      lessThan('severityMin', 'severityMax', 'severityMinMax')
    )

    return formBuilder.group(controlConfigs, {
      validators: crossFieldValidators,
    })
  }

  transformToValidateableFormat(rawConfig: ComponentModel, dictionary: EvirLanguageDictionary) {
    return {
      severityMin: rawConfig.minSeverity,
      severityMax: rawConfig.maxSeverity,
      name: convertLangKeyToString(rawConfig.componentLangKey, dictionary)
    }
  }
}
