import { moveItemInArray } from '@angular/cdk/drag-drop'
import { Injectable } from '@angular/core'
import { remove, cloneDeep } from 'lodash'

import { Ect2Data } from 'src/app/app.state'
import {
  CONFIRM_DIALOG_TYPE,
  EXPANDED_STATES_OPTIONS,
  LANGUAGE_CONFIG_TYPES,
  NODE_LEVELS,
  NODE_LEVELS_INDEX
} from 'src/app/constants/internationalized-constants-en'
import { equalsIgnoreCase, getLocationWithLimit } from 'src/app/utils/utils'
import {
  ComponentModel,
  ConfigurationModel,
  ConfigZoneModel,
  EvirLanguageDictionary,
  InspectionDetailModel,
  RawLanguageDictionary,
  SelectedConfigurationModel,
  TranslationObject,
  TreeFlatNode,
  TreeNode,
  ZoneLayoutModel
} from '../../models/tree.model'
import { LanguageDictionaryHandlingService } from '../language-dictionary-handling/language-dictionary-handling.service'

@Injectable({
  providedIn: 'root'
})
export class DataConfigurationHandlingService {

  constructor(public langDictionaryService: LanguageDictionaryHandlingService) { }

  createNewNodeToRawData(zoneLayouts: ZoneLayoutModel[], node: TreeNode) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    let configIndex, zoneIndex, componentIndex, conditionIndex,
      tagComponentIndex, component, tagComponents, nodeClone, indexNewData

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    componentIndex = parentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    conditionIndex = parentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        break

      case NODE_LEVELS.ZONE_LEVEL:
        break

      case NODE_LEVELS.COMPONENT_LEVEL:
        tagComponents = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents
        tagComponentIndex = tagComponents[componentIndex]
        component = zoneLayouts[configIndex].components[tagComponentIndex]
        nodeClone = cloneDeep(component)
        zoneLayouts[configIndex].components.push(nodeClone)
        indexNewData = zoneLayouts[configIndex].components.indexOf(nodeClone)
        tagComponents[componentIndex] = indexNewData
        node.value.index = indexNewData
        break

      case NODE_LEVELS.CONDITION_LEVEL:
        tagComponents = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents
        tagComponentIndex = tagComponents[componentIndex]
        component = zoneLayouts[configIndex].components[tagComponentIndex]

        nodeClone = cloneDeep(component)
        zoneLayouts[configIndex].components.push(nodeClone)
        indexNewData = zoneLayouts[configIndex].components.indexOf(nodeClone)
        tagComponents[componentIndex] = indexNewData
        break

      default:
        break
    }
  }

  updateRawData(zoneLayouts: ZoneLayoutModel[], node: TreeNode, translationObject: TranslationObject, evirLangDictionary: EvirLanguageDictionary) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    let configIndex, zoneIndex, componentIndex, conditionIndex,
      configZone, zoneLayout, component, tagComponentIndex

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    componentIndex = parentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    conditionIndex = parentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:

        zoneLayout = zoneLayouts[configIndex]

        zoneLayout.assetType = node.value.assetType
        zoneLayout.zoneLayoutLangKey = node.value.id
        // ECT2-739: EVIR api doesn't allow assetViewId as empty value
        // We will remove assetViewId and assetViewGrid if assetViewId is empty for successful test/deploy
        if (!node.value.assetViewId) {
          delete zoneLayout.assetViewId
          delete zoneLayout.assetViewGrid
        } else {
          zoneLayout.assetViewId = node.value.assetViewId
          zoneLayout.assetViewGrid = node.value.assetViewGrid
        }
        break

      case NODE_LEVELS.ZONE_LEVEL:

        configZone = zoneLayouts[configIndex].configZones[zoneIndex]

        configZone.tagLangKey = node.value.id
        configZone.assetViewLocation = node.value.assetViewLocation
        configZone.tagNumber = node.value.tagNumber
        configZone.tagTypeLangKey = this.langDictionaryService.getOrCreateLangKey(node.value.tagType, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.ZONE)
        configZone.zoneInspectionTypes = node.value.zoneInspectionTypes
        if (node.value.assetViewLocation && node.value.assetViewLocation.length) {
          configZone.assetViewLocation = node.value.assetViewLocation
        } else {
          delete configZone.assetViewLocation
        }
        break

      case NODE_LEVELS.COMPONENT_LEVEL:

        const componentConfigIndex = node.value && node.value.index
        const componentConfig = zoneLayouts[configIndex].components[componentConfigIndex]

        componentConfig.componentLangKey = node.value.id
        componentConfig.maxSeverity = node.value.severityMax
        componentConfig.minSeverity = node.value.severityMin
        break

      case NODE_LEVELS.CONDITION_LEVEL:

        tagComponentIndex = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[componentIndex]
        component = zoneLayouts[configIndex].components[tagComponentIndex]

        component.suggestedConditionLangKeys[conditionIndex] = node.value.id
        break

      default:
        break
    }
  }

  deleteDataConfiguration(zoneLayouts: ZoneLayoutModel[], node: TreeNode, type?: CONFIRM_DIALOG_TYPE) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    let configIndex, zoneIndex, componentIndex, conditionIndex,
      configZones, components, conditions

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    componentIndex = parentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    conditionIndex = parentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        remove(zoneLayouts, (item, index) => index === configIndex)
        break

      case NODE_LEVELS.ZONE_LEVEL:
        configZones = zoneLayouts[configIndex].configZones
        remove(configZones, (item, index) => index === zoneIndex)
        break

      case NODE_LEVELS.COMPONENT_LEVEL:
        components = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents
        remove(components, (item, index) => index === componentIndex)
        break

      case NODE_LEVELS.CONDITION_LEVEL:
        const component = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[componentIndex]
        conditions = zoneLayouts[configIndex].components[component].suggestedConditionLangKeys
        remove(conditions, (item, index) => index === conditionIndex)
        break

      default:
        break
    }
  }

  reorderDataConfiguration(zoneLayouts: ZoneLayoutModel[], node: TreeNode, newIndex: number) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    let configIndex, zoneIndex, componentIndex, conditionIndex,
      configZones, components, conditions

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    componentIndex = parentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    conditionIndex = parentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        moveItemInArray(zoneLayouts, configIndex, newIndex)
        break

      case NODE_LEVELS.ZONE_LEVEL:
        moveItemInArray(zoneLayouts[configIndex].configZones, zoneIndex, newIndex)
        break

      case NODE_LEVELS.COMPONENT_LEVEL:
        components = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents
        moveItemInArray(components, componentIndex, newIndex)
        break

      case NODE_LEVELS.CONDITION_LEVEL:
        const component = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[componentIndex]
        conditions = zoneLayouts[configIndex].components[component].suggestedConditionLangKeys
        moveItemInArray(conditions, conditionIndex, newIndex)
        break

      default:
        break
    }
  }

  cloneDataConfiguration(zoneLayouts: ZoneLayoutModel[], node: TreeFlatNode, selectedConfig?: SelectedConfigurationModel) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    let configIndex: number, zoneIndex: number, tagComponentIndex: number, componentIndex: number, conditionIndex: number,
      newTagComponent: number, cloneDataIndex: number, cloneData

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    tagComponentIndex = parentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    conditionIndex = parentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (node.level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        cloneDataIndex = configIndex + 1
        if (selectedConfig) {
          const selectedConfigIndex = selectedConfig.index
          selectedConfig.index = cloneDataIndex <= selectedConfigIndex ? selectedConfigIndex + 1 : selectedConfigIndex
        }

        cloneData = cloneDeep(zoneLayouts[configIndex])
        zoneLayouts.splice(cloneDataIndex, 0, cloneData)
        break

      case NODE_LEVELS.ZONE_LEVEL:
        cloneDataIndex = zoneIndex + 1

        cloneData = cloneDeep(zoneLayouts[configIndex].configZones[zoneIndex])
        zoneLayouts[configIndex].configZones.splice(cloneDataIndex, 0, cloneData)
        break

      case NODE_LEVELS.COMPONENT_LEVEL:
        componentIndex = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[tagComponentIndex]
        cloneDataIndex = tagComponentIndex + 1

        cloneData = cloneDeep(zoneLayouts[configIndex].components[componentIndex])
        zoneLayouts[configIndex].components.push(cloneData)
        newTagComponent = zoneLayouts[configIndex].components.indexOf(cloneData)
        zoneLayouts[configIndex].configZones[zoneIndex].tagComponents.splice(cloneDataIndex, 0, newTagComponent)
        break

      case NODE_LEVELS.CONDITION_LEVEL:
        componentIndex = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[tagComponentIndex]
        cloneDataIndex = conditionIndex + 1

        const newComponent: ComponentModel = cloneDeep(zoneLayouts[configIndex].components[componentIndex])
        cloneData = newComponent.suggestedConditionLangKeys[conditionIndex]
        newComponent.suggestedConditionLangKeys.splice(cloneDataIndex, 0, cloneData)
        zoneLayouts[configIndex].components.push(newComponent)
        newTagComponent = zoneLayouts[configIndex].components.indexOf(newComponent)
        zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[tagComponentIndex] = newTagComponent
        break

      default:
        break
    }
  }

  convertTreeDataToJson(node: TreeNode, translationObject: TranslationObject): ZoneLayoutModel {
    let configZones
    let tagComponents
    let components
    if (node) {
      components = node.value.components
      configZones = node.children.map(configZonesChild => {
        if (configZonesChild.children) {
          tagComponents = configZonesChild.children.map(tagComponent => {
            if (typeof (tagComponent.value) === 'number') {
              return tagComponent.value
            }

            return tagComponent.value.index
          })
        }
        const { tagType, ...rest } = configZonesChild.value
        return {
          ...rest,
          tagComponents: tagComponents,
          tagTypeLangKey: this.langDictionaryService.getLangKeyByString(tagType, translationObject, LANGUAGE_CONFIG_TYPES.ZONE)
        }
      })
    }
    delete node.value.id
    const result = {
      ...node.value,
      configZones: configZones,
    }
    return result
  }

  convertAllTreeDataToJson(
    configurations: TreeNode[], inspectionDetails: InspectionDetailModel[], rawLanguageDictionary: RawLanguageDictionary, companyId: string,
    globalEct2Data: Ect2Data, globalConfigType: string, isDraft: boolean, translationObject: TranslationObject, languageCodes: string[], version: string, createdDate: string
  ): ConfigurationModel {
    const zoneLayouts = configurations.map(rawConfiguration => this.convertTreeDataToJson(rawConfiguration, translationObject))
    const result = {
      zoneLayouts: zoneLayouts,
      inspectionDetails: inspectionDetails,
      languageCodes: languageCodes,
      ...rawLanguageDictionary,
      companyId: companyId,
      ect2Data: globalEct2Data,
      configType: globalConfigType,
      draft: isDraft,
      version: version,
      created: createdDate,
    }

    return result
  }

  getNewExpandedStateWithOption(
    expandedState: string, node: TreeFlatNode, option: EXPANDED_STATES_OPTIONS,
    destinationNode?: TreeFlatNode, numberNewConfig?: number
  ): string {
    const getNodeLevelIndex = (expandOption) => {
      if (expandOption === EXPANDED_STATES_OPTIONS.IMPORT_LEGACY) {
        return 1
      }
      return expandOption === EXPANDED_STATES_OPTIONS.COPY_PASTE ? node.level + 2 : node.level + 1
    }
    const nodeLevelIndex = getNodeLevelIndex(option)
    const increaseN = (addend: number) => (value) => Number(value) + addend
    const increaseOne = increaseN(1)
    const reduceOne = increaseN(-1)
    const updateMatchingNodeLevel = (callback) => (value, index) =>
      index === nodeLevelIndex ? callback(value) : Number(value)

    switch (option) {
      case EXPANDED_STATES_OPTIONS.CLONE_BELOW:
        return expandedState.split('/')
          .map(updateMatchingNodeLevel(increaseOne)).join('/')

      case EXPANDED_STATES_OPTIONS.COPY_PASTE:
        return expandedState.split('/')
          .map(updateMatchingNodeLevel(increaseOne)).join('/')

      case EXPANDED_STATES_OPTIONS.DELETE:
        return expandedState.split('/')
          .map(updateMatchingNodeLevel(reduceOne)).join('/')

      case EXPANDED_STATES_OPTIONS.DRAG_DROP:
        const nodeIndexes = node.id.split('/')
        const destinationNodeIndexes = destinationNode.id.split('/')
        const expandedStateIndexes = expandedState.split('/')

        if (expandedState.includes(node.id) && expandedStateIndexes[nodeLevelIndex] === nodeIndexes[nodeLevelIndex]) {
          const newIndex = Number(destinationNodeIndexes[nodeLevelIndex]) - Number(nodeIndexes[nodeLevelIndex])
          return expandedStateIndexes.map(updateMatchingNodeLevel(increaseN(newIndex))).join('/')
        }

        const isDroppedUp = Number(nodeIndexes[nodeLevelIndex]) > Number(destinationNodeIndexes[nodeLevelIndex])
        const updateCallback = isDroppedUp ? increaseOne : reduceOne
        return expandedStateIndexes.map(updateMatchingNodeLevel(updateCallback)).join('/')

      case EXPANDED_STATES_OPTIONS.IMPORT_LEGACY:
        return expandedState.split('/')
          .map(updateMatchingNodeLevel(increaseN(numberNewConfig))).join('/')

      default:
        return
    }
  }

  filterExpandedStatesWithLevel(nodeLevel: number, nodeIndexes: number[], expandedStates: string[]): string[] {
    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        return expandedStates

      case NODE_LEVELS.ZONE_LEVEL:
        return expandedStates.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          return nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] === expandedStateIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL]
        })

      case NODE_LEVELS.COMPONENT_LEVEL:
        return expandedStates.filter(expandedState => {
          const expandedStateIndexes = expandedState.split('/').map(x => Number(x))
          return nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] === expandedStateIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] &&
            nodeIndexes[NODE_LEVELS_INDEX.ZONE_LEVEL] === expandedStateIndexes[NODE_LEVELS_INDEX.ZONE_LEVEL]
        })

      case NODE_LEVELS.CONDITION_LEVEL:
      default:
        return []
    }
  }

  getNewAssetViewLocation(assetViewLocation: number[], newAssetViewGrid: number[]): number[] {
    if (newAssetViewGrid && !assetViewLocation) {
      return [0, 0]
    }

    const newAssetViewLocation = assetViewLocation.map((value, index) => getLocationWithLimit(index, value, newAssetViewGrid))
    return !assetViewLocation.every((value, index) => value === newAssetViewLocation[index]) ? newAssetViewLocation : null
  }

  getNodeLevel(nodeId: string) {
    const parentsIndex: number[] = nodeId.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    return nodeLevel
  }

  isLastConditionNode(allNodes: TreeNode[], conditionNode: TreeFlatNode) {
    if (conditionNode.level !== NODE_LEVELS.CONDITION_LEVEL) {
      return false
    }

    const [_first, configIndex, zoneIndex, componentIndex]: number[] = conditionNode.id.split('/').map(x => Number(x))
    return allNodes[configIndex].children[zoneIndex].children[componentIndex].children.length <= 1
  }

  updateChildNodeId(nodeId: string, newId: number, level: NODE_LEVELS) {
    const parentsIndex: number[] = nodeId.split('/').map(x => Number(x))
    const nodeLevel = parentsIndex.length - 2
    let newNodeId = nodeId
    if (nodeLevel >= level) {
      // NODE_LEVELS_INDEX = NODE_LEVEL + 1
      parentsIndex[level + 1] = newId
      newNodeId = parentsIndex.reduce((accumulator, value, index) => {
        return index === 0 ? accumulator + value : accumulator + '/' + value
      }, '')
    }
    return newNodeId
  }

  deleteBorderDataConfigImport(configurationData: ZoneLayoutModel[]) {
    if (Array.isArray(configurationData)) {
      configurationData.forEach((config) => {
        if (config.borderConfigImport) {
          delete config.borderConfigImport
        }
      })
    }
  }

  checkInspectionTypeReferenced(inspectionDetail: InspectionDetailModel, dataConfig: ZoneLayoutModel[], dictionary: EvirLanguageDictionary) {
    const result = []
    dataConfig.forEach(config => {
      const inspectionName = this.langDictionaryService.convertLangKeyToString(inspectionDetail.inspectionDetailLangKey, dictionary)
      const isUsed = config.configZones.some(zone => zone.zoneInspectionTypes && equalsIgnoreCase(zone.zoneInspectionTypes, inspectionName))

      if (isUsed) {
        const configName = this.langDictionaryService.convertLangKeyToString(config.zoneLayoutLangKey, dictionary)
        result.push(configName)
      }
    })
    return result
  }

  getMissingInspectionTypes(inspectionTypeNames: string[], copiedInspectionTypeNames: string[], copiedConfig: ZoneLayoutModel, originalCopyNodeId?: string) {
    const parentsIndex: number[] = originalCopyNodeId ? originalCopyNodeId.split('/').map(x => Number(x)) : []
    const nodeLevel = parentsIndex.length - 2
    const missingInspectionTypes = []
    switch (nodeLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        copiedConfig.configZones.forEach(copiedZone => {
          if (copiedZone.zoneInspectionTypes) {
            copiedZone.zoneInspectionTypes.forEach(inspectionType => {
              if (!equalsIgnoreCase(missingInspectionTypes, inspectionType) && equalsIgnoreCase(copiedInspectionTypeNames, inspectionType) && !equalsIgnoreCase(inspectionTypeNames, inspectionType)) {
                missingInspectionTypes.push(inspectionType)
              }
            })
          }
        })
        return missingInspectionTypes
      case NODE_LEVELS.ZONE_LEVEL:
        const zoneIndex = parentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
        if (copiedConfig.configZones[zoneIndex].zoneInspectionTypes) {
          copiedConfig.configZones[zoneIndex].zoneInspectionTypes.forEach(inspectionType => {
            if (!equalsIgnoreCase(missingInspectionTypes, inspectionType) && equalsIgnoreCase(copiedInspectionTypeNames, inspectionType) && !equalsIgnoreCase(inspectionTypeNames, inspectionType)) {
              missingInspectionTypes.push(inspectionType)
            }
          })
        }
        return missingInspectionTypes
      default:
        return []
    }
  }

  updateZoneInspectionType(configurationList: ZoneLayoutModel[], oldZoneInspectionType: string, newZoneInspectionType: string): void {
    for (const configuration of (configurationList || [])) {
      for (const zone of (configuration.configZones || [])) {
        const zoneInspectionTypeIndex = zone.zoneInspectionTypes.indexOf(oldZoneInspectionType)
        if (zoneInspectionTypeIndex > -1) {
          zone.zoneInspectionTypes[zoneInspectionTypeIndex] = newZoneInspectionType
        }
      }
    }
  }
}
