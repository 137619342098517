<ng-container>
  <div mat-dialog-title
    fxLayout='row'
    fxLayoutAlign='space-between start'>
    <div class="dialog-title">{{'ZONE_EDIT_DIALOG_TITLE' | translate}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
      (click)="handleCancel()">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="node-edit-content">
    <form class="zone-edit-form"
      [formGroup]="inputForm">
      <mat-form-field appearance="outline"
        class="edit-field"
        [style.width.%]="100">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input matInput
          class="field-name"
          placeholder="{{'NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
          [errorStateMatcher]="matcher"
          maxlength="100">
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          {{'NAME_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('pattern')">
          {{'INVALID_CHARS_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline"
        class="edit-field tag-type"
        [style.width.px]="140">
        <mat-label>{{'TAG_TYPE_LABEL' | translate}}</mat-label>
        <mat-select focused="false"
          [formControl]="inputForm.controls.tagType">
          <mat-option *ngFor="let option of tagTypeOption"
            [value]="option.id">{{option.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline"
        class="edit-field tag-number"
        [style.width.px]="150">
        <mat-label>{{'TAG_NUMERIC_LABEL' | translate}}</mat-label>
        <input matInput
          class="field-tag-number"
          placeholder="{{'TAG_NUMBER_PLACEHOLDER' | translate}}"
          formControlName="tagNumber"
          [errorStateMatcher]="matcher"
          [pattern]="numberPattern">
        <mat-error *ngIf="inputForm.controls.tagNumber.hasError('required')">
          {{'TAG_NUMBER_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.tagNumber.hasError('pattern')">
          {{'INVALID_POSITIVE_NUMBER_ERROR_MSG' | translate}}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.tagNumber.hasError('invalidMaxValue')">
          {{'TAG_NUMBER_LIMIT_ERROR_MSG' | translate}}
        </mat-error>
      </mat-form-field>

      <div>
        <app-select [options]="inspectionTypeOptions"
          [selectedOptions]="inputForm.controls.zoneInspectionTypes.value"
          [multiple]="true"
          (optionsChanged)="handleChangeValue($event,'zoneInspectionTypes')"
          title="{{'INSPECTION_TYPE' | translate}}"
          placeholder="{{'INSPECTION_TYPE_PLACEHOLDER' | translate}}"
          [ngClass]="{'select-error': !inputForm.controls.zoneInspectionTypes.valid}"></app-select>
        <mat-error *ngIf="inputForm.controls.zoneInspectionTypes.hasError('required')">
          {{'INSPECTION_TYPES_REQUIRED_ERROR_MSG' | translate}}
        </mat-error>
      </div>

      <div *ngIf="isHiddenAssetViewLocation">
        <app-chips #assetViewLocationChips
          [chipList]="inputForm.controls.assetViewLocation.value"
          chipTitle="{{ 'ASSET_LOCATIONS' | translate }}"
          placeholder="{{ 'ASSET_LOCATION_PLACEHOLDER' | translate }}"
          [chipType]="CHIP_TYPE.NUMBER"
          [regex]="numberPattern"
          [minChips]="minAssetViewLocation"
          [maxChips]="maxAssetViewLocation"
          [maxLengthNumber]="maxLengthNumber"
          (valueChange)="handleChangeValue($event,'assetViewLocation')"
          [ngClass]="{'error-chips':inputForm.controls.assetViewLocation.invalid}"
          (inputStatusChange)="handleChipAssetViewLocationStatusChange($event,assetViewLocationChips)"
          [chipsStatus]="inputForm.controls.assetViewLocation.valid">
        </app-chips>
        <mat-error *ngIf="!inputForm.controls.assetViewLocation.hasError('required') && inputForm.controls.assetViewLocation.hasError('invalidAssetViewLocationLength')">
          {{ 'ASSET_VIEW_LOCATION_LENGTH_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error class="invalid-input" *ngIf="inputForm.controls.assetViewLocation.hasError('required')">
          {{ 'ASSET_VIEW_LOCATION_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="inputForm.controls.assetViewLocation.hasError('invalidInput')">
          {{ 'INVALID_POSITIVE_NUMBER_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error class="invalid-input" *ngIf="inputForm.controls.assetViewLocation.hasError('invalidAssetViewLocation')">
          {{INVALID_ASSET_VIEW_LOCATION_MESSAGE}}
        </mat-error>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      mat-dialog-close
      class="primary-button"
      (click)="handleCancel()">{{'CANCEL' | translate}}</button>
    <button mat-button
      [disabled]="!inputForm.valid"
      class="special-button"
      (click)="handleAccept()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</ng-container>
