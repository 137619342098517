<ng-container *ngIf="permBuilder$ | async as permBuilder">
  <div class="group-button" *ngFor="let btn of buttonState.states[buttonState.currentState].buttonList">
    <button mat-button
      class="{{btn.className}}"
      *zonarPermission="permBuilder(btn.permission)"
      appThrottleClick
      [disabled]="btn.checkDisabledButton(btn.buttonName)"
      (throttledClick)="btn.executeFunc(btn.buttonName)"
      [ngClass]="{'spinner-loading':checkLoadingSpinnerApplied(btn.buttonName)}">
      <ng-container *appLoadingSpinner="checkLoadingSpinnerApplied(btn.buttonName);">
        {{ btn.buttonName | translate }}
      </ng-container>
    </button>
  </div>
</ng-container>
