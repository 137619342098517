<div fxLayout="row" fxLayoutGap="8px">
  <div>
    <mat-icon [ngClass]="notificationType === notificationTypes.SUCCESS ? 'check-circle-outline-icon' : 'error-outline-icon'">
      {{notificationType === notificationTypes.SUCCESS ? 'check_circle_outline' : 'error_outline'}}
    </mat-icon>
  </div>
  <div class="message">
    <div class="notification-message" [matTooltip]="notificationMessage" [ngClass]="{
      'no-detail-error-message': detailedErrorMessages.length === 0
    }">
      {{notificationMessage}}
    </div>
    <ng-container *ngIf="detailedErrorMessages.length > 0">
      <div class="detailed-error-message" [matTooltip]="detailedErrorMessages[0] | capitalizeFirstLetter">
        {{detailedErrorMessages[0] | capitalizeFirstLetter}}
      </div>
    </ng-container>
  </div>
  <div *ngIf="notificationType !== notificationTypes.SUCCESS">
    <mat-icon class="close-icon" (click)="dismissNotificationSnackbar()">close</mat-icon>
  </div>
</div>
