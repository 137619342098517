import { Injectable } from '@angular/core'
import Docxtemplater from 'docxtemplater'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'

import { ConfigurationPieceModel } from 'src/app/components/template-library/models/template-library.model'
import { INSTALLER_EXPORT_TYPE, NODE_LEVELS_INDEX } from 'src/app/constants/internationalized-constants-en'
import {
  EvirLanguageDictionary,
  InstallerExportModel,
  TransformedInstallerExportZoneLayoutModel,
  TreeFlatNode,
  ZoneLayoutModel
} from '../../models/tree.model'
import { LanguageDictionaryHandlingService } from '../language-dictionary-handling/language-dictionary-handling.service'

@Injectable({
  providedIn: 'root'
})
export class ExportDataConfigurationService {

  constructor(
    private langDictionaryService: LanguageDictionaryHandlingService,
    private translateService: TranslateService,
  ) { }

  loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback)
  }

  generateDocxFile(configData: InstallerExportModel, exportType: INSTALLER_EXPORT_TYPE) {
    const templatePath = exportType === INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_LARGE ? 'configs-template-lrg.docx' : 'configs-template-sml.docx'
    const fileName = `${exportType === INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_LARGE
      ? this.translateService.instant('EXPORT_TEMPLATE.FULL_EXPORT_CONFIG_FILENAME')
      : this.translateService.instant('EXPORT_TEMPLATE.ZONE_INSTALL_LOCATION_FILENAME')} - ${configData.companyName} - ${configData.configName} - ${configData.date}`

    this.loadFile(`./../../../../assets/export-template/${templatePath}`, (error, content) => {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater().loadZip(zip)
      const tempData = {
        ...configData
      }
      doc.setData(tempData)
      try {
        doc.render()
      } catch (error) {
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map((e) => {
              return e.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "valueA" is unopened'
        }
        throw error
      }
      const output = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }) // Output the document using Data-URI

      saveAs(output, fileName + '.docx')
    })
  }

  convertInstallerExportAllData(zoneLayouts: ZoneLayoutModel[], languageDictionary: EvirLanguageDictionary): TransformedInstallerExportZoneLayoutModel {
    const result = zoneLayouts.map(config => {
      const configName = this.langDictionaryService.convertLangKeyToString(config.zoneLayoutLangKey, languageDictionary)
      const assetType = config.assetType.map(value => ({ name: value }))

      const configZones = config.configZones.map(zone => {
        const zoneName = this.langDictionaryService.convertLangKeyToString(zone.tagLangKey, languageDictionary)
        const components = zone.tagComponents.map((componentIndex) => {
          const componentName = this.langDictionaryService.convertLangKeyToString(config.components[componentIndex].componentLangKey, languageDictionary)
          const componentConfiguration = config.components[componentIndex]
          const conditions = (componentConfiguration.suggestedConditionLangKeys || []).map((condition) => {
            return {
              name: this.langDictionaryService.convertLangKeyToString(condition, languageDictionary)
            }
          })
          return {
            componentNameKey: componentName,
            suggestedConditions: conditions
          }
        })
        return {
          tagComponents: components,
          tagNameKey: zoneName,
          tagNumber: zone.tagNumber,
        }
      })
      return {
        assetType: assetType,
        configZones: configZones,
        zoneLayoutLanguageKey: configName
      }
    })

    return { zoneLayouts: result }
  }

  convertInstallerExportOneData(zoneLayouts: ZoneLayoutModel[], languageDictionary: EvirLanguageDictionary, node: TreeFlatNode): TransformedInstallerExportZoneLayoutModel {
    const allData = this.convertInstallerExportAllData(zoneLayouts, languageDictionary)
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    const configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    const result = allData.zoneLayouts[configIndex]
    return {
      zoneLayouts: [result]
    }
  }

  convertPieceDataToExport(pieceData: ConfigurationPieceModel): TransformedInstallerExportZoneLayoutModel {
    const configName = pieceData.zoneLayoutLanguageKey
    const assetType = pieceData.assetType.map(value => ({ name: value }))
    const configZones = pieceData.configZones.map(zone => {
      const zoneName = zone.tagNameKey
      const components = zone.tagComponents.map(component => {
        const componentName = component.componentNameKey
        const conditions = component.suggestedConditions.map(condition => ({ name: condition }))
        return {
          componentNameKey: componentName,
          suggestedConditions: conditions
        }
      })
      return {
        tagComponents: components,
        tagNameKey: zoneName,
        tagNumber: zone.tagNumber,
      }
    })
    const config = {
      assetType: assetType,
      configZones: configZones,
      zoneLayoutLanguageKey: configName
    }
    return { zoneLayouts: [config] }
  }
}
