import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Select, Store } from '@ngxs/store'
import { Observable, Subject, of } from 'rxjs'
import { catchError, debounceTime, take, takeUntil, last, map, startWith } from 'rxjs/operators'

import { AppState, GetTemplateLibraryPieces, UpdateFilterPieceType, UpdatePieceSearchText, UpdatePieceSortOption } from 'src/app/app.state'
import { ERROR_MESSAGES, TEMPLATE_LIBRARY_SORT_OPTIONS, TEMPLATE_TYPES_VALUE, TEMPLATE_TYPE_OPTIONS } from 'src/app/constants/internationalized-constants-en'
import { MenuOption } from '../menu/menu.component'
import { TemplateLibraryPieceModel } from './models/template-library.model'
import { EvirLanguageDictionary } from '../tree/models/tree.model'

@Component({
  selector: 'app-template-library',
  templateUrl: './template-library.component.html',
  styleUrls: ['./template-library.component.scss']
})
export class TemplateLibraryComponent implements OnInit, OnDestroy {
  @Select(AppState.getFilteredPieces) templateLibraryPieces$: Observable<TemplateLibraryPieceModel[]>
  @Select(AppState.getFilterPieceType) filterPieceType$: Observable<TEMPLATE_TYPES_VALUE>
  @Select(AppState.getPieceSearchText) pieceSearchText$: Observable<string>
  @Select(AppState.getBlankPiece) blankPiece$: Observable<TemplateLibraryPieceModel[]>
  @Select(AppState.getAccessStatus) isAccessGranted$: Observable<boolean>
  @Select(AppState.getLanguageDictionary) languageDictionary$: Observable<EvirLanguageDictionary>

  templateTypeOptions = TEMPLATE_TYPE_OPTIONS
  templateLibraryErrorMessage = ERROR_MESSAGES.NO_TEMPLATE_LIBRARY_DATA
  sortOptions: MenuOption[] = [
    { text: TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_DATE_ADDED },
    { text: TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_ASCENDING_ALPHABET },
    { text: TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_DESCENDING_ALPHABET }
  ]
  searchControl = new FormControl()

  private destroy$: Subject<boolean> = new Subject<boolean>()
  public shouldLoading$: Observable<any> = of(false)

  constructor(public store: Store) {
    this.pieceSearchText$.pipe(take(1)).subscribe(value => this.searchControl.setValue(value))
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe(searchText => this.store.dispatch(new UpdatePieceSearchText(searchText)))
  }

  updateFilterPieceType(newFilterPieceType: string) {
    this.store.dispatch(new UpdateFilterPieceType(newFilterPieceType))
    this.store.dispatch(new UpdatePieceSortOption(TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_DATE_ADDED))
    this.store.dispatch(new UpdatePieceSearchText(''))
  }

  updatePieceSortOption(newPieceSortOption: string) {
    this.store.dispatch(new UpdatePieceSortOption(newPieceSortOption))
  }

  clearSearch() {
    this.searchControl.setValue('')
  }

  reloadTemplateLibraryPieces() {
    this.shouldLoading$ = this.store.dispatch(new GetTemplateLibraryPieces()).pipe(
      last(),
      map(() => false),
      startWith(true),
      catchError(() => of(false))
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
