import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { round } from 'lodash'
import { DEFAULT_WIDTH_SVG } from 'src/app/constants/internationalized-constants-en'

@Component({
  selector: 'app-svg-converter',
  templateUrl: './svg-converter.component.html',
  styleUrls: ['./svg-converter.component.scss']
})
export class SvgConverterComponent implements AfterViewInit {

  @Input() svgSource: string
  @Output() backgroundLoaded = new EventEmitter()

  elementId = 'svg-convert-wrapper'
  assetZoneMapBlockId = 'asset-zone-map-block'
  backgroundHeight: number
  backgroundWidth: number
  originalHeight: number
  originalWidth: number
  hasInit = false
  defaultWidthSVG = DEFAULT_WIDTH_SVG

  constructor() { }

  ngAfterViewInit() {
    this.buildSVG()
  }

  buildSVG() {
    const doc = new DOMParser().parseFromString(
      this.svgSource,
      'application/xml'
    )
    const el = document.getElementById(this.elementId)
    const assetZoneMapBlockEl = document.getElementById(this.assetZoneMapBlockId)

    if (!this.hasInit) {
      el.appendChild(el.ownerDocument.importNode(doc.documentElement, true))
      this.originalHeight = el.offsetHeight
      this.originalWidth = el.offsetWidth
    }

    const svgElement = el.firstElementChild as any
    // 100px is padding and margin width and 14px is scroll width
    const maxWidth = assetZoneMapBlockEl ? assetZoneMapBlockEl.offsetWidth - 40 - 14 : this.defaultWidthSVG
    let ratio = 1

    if (svgElement && maxWidth < this.originalWidth) {
      ratio = round(this.originalWidth / maxWidth, 4)
      svgElement.style.width = maxWidth + 'px'
      svgElement.style.height = this.originalHeight / ratio + 'px'
    } else if (svgElement && maxWidth > this.originalWidth) {
      ratio = round(maxWidth / this.originalWidth, 4)
      svgElement.style.width = maxWidth + 'px'
      svgElement.style.height = this.originalHeight * ratio + 'px'
    }
    this.backgroundHeight = el.offsetHeight
    this.backgroundWidth = el.offsetWidth
    this.hasInit = true
    this.backgroundLoaded.emit(true)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.buildSVG()
  }

}
