import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngxs/store'

import { DropToInspectionTypeDetail, UpdateInspectionFormData } from 'src/app/app.state'
import { MIN_NUMBER_OF_CHOICES, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { InspectionTypeFormData } from 'src/app/views/inspection-type/inspection-type.component'
import { InspectionRowEditDialogData } from '../inspection-type-detail/inspection-type-detail.component'

export class DropdownFieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

export interface InspectionDetailDropdownField {
  fieldNameLabel: string
  hint: string
  select: string[]
  required: boolean
}

export interface DropdownFieldForm {
  label: string
  helper: string
  choices: any[]
  required: boolean
}

@Component({
  selector: 'app-inspection-detail-row-dropdown-edit-dialog',
  templateUrl: './inspection-detail-row-dropdown-edit-dialog.component.html',
  styleUrls: ['./inspection-detail-row-dropdown-edit-dialog.component.scss']
})
export class InspectionDetailRowDropdownEditDialogComponent implements OnInit {
  dropdownEditForm: FormGroup

  minNumberOfChoices = MIN_NUMBER_OF_CHOICES

  matcher = new DropdownFieldErrorStateMatcher()

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InspectionRowEditDialogData,
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: MatDialogRef<InspectionDetailRowDropdownEditDialogComponent>
  ) {
    if (this.data) {
      const formData = this.transformFormData(this.data.row)
      this.dropdownEditForm = this.formBuilder.group({
        label: new FormControl(formData.label, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(20),
          ]
        }),
        helper: new FormControl(formData.helper, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(40),
          ]
        }),
        choices: new FormArray([]),
        required: new FormControl(formData.required),
      })
      this.prepareChoices(formData.choices)
    }
  }

  ngOnInit(): void { }

  onMouseEnter(target: HTMLDivElement) {
    target.closest('.choice-box').classList.add('cdk-drag-preview')
  }

  onMouseOut(target: HTMLDivElement) {
    target.closest('.choice-box').classList.remove('cdk-drag-preview')
  }

  get choiceList() {
    return this.dropdownEditForm.controls.choices as FormArray
  }

  handleApply() {
    if (this.dropdownEditForm.invalid) {
      return
    }

    const formValue = this.dropdownEditForm.value
    const updateValue = {
      ...this.data.row,
      ...this.transformData(formValue),
      fieldNameLangKey: '',
      hintLangKey: '',
      selectLangKeys: [],
    }

    const action = this.data.isUpdate ? UpdateInspectionFormData : DropToInspectionTypeDetail
    this.store.dispatch(new action(
      this.data.inspectionTypeIndex,
      this.data.formDataIndex,
      updateValue,
    ))
    this.dialogRef.close()
  }

  transformFormData(val: InspectionTypeFormData): DropdownFieldForm {
    return {
      label: val.fieldNameLabel,
      helper: val.hint,
      choices: val.select,
      required: val.required,
    }
  }

  transformData(val: DropdownFieldForm): InspectionDetailDropdownField {
    return {
      fieldNameLabel: val.label,
      hint: val.helper,
      select: val.choices.map(choice => choice.name),
      required: val.required,
    }
  }

  prepareChoices(choiceList: string[]) {
    const padArray = (target: string[], targetLength: number, padValue: string): string[] => {
      const currentLength = !target ? 0 : target.length
      if (currentLength >= targetLength) {
        return target
      }
      return [
        ...(target || []),
        ...Array(targetLength - currentLength).fill(padValue)
      ]
    }

    for (const choice of padArray(choiceList, this.minNumberOfChoices, '')) {
      this.addChoice(choice)
    }
  }

  removeChoice(choiceIndex: number) {
    this.choiceList.removeAt(choiceIndex)
  }

  addChoice(choice: string = '', choiceIndex: number = null) {
    const createNewChoice = (choiceName: string) => this.formBuilder.group({
      name: new FormControl(choiceName, {
        validators: [
          Validators.required,
          Validators.pattern(NAME_REGEX),
        ]
      })
    })
    const newChoiceIndex = !choiceIndex && choiceIndex !== 0 ? choiceIndex : choiceIndex + 1

    if (newChoiceIndex) {
      this.choiceList.insert(newChoiceIndex, createNewChoice(choice))
    } else {
      this.choiceList.push(createNewChoice(choice))
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    // Ignore drop if event is invalid
    if (!event) {
      return
    }

    // Ignore drop outside of the choice list
    if (!event.isPointerOverContainer) {
      return
    }

    // Ignore drop the choice in the same position
    if (event.previousIndex === event.currentIndex) {
      return
    }

    const choiceList = this.choiceList.getRawValue()
    moveItemInArray(choiceList, event.previousIndex, event.currentIndex)
    this.dropdownEditForm.controls.choices.patchValue(choiceList)
  }
}
