import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Select, Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { AppState, CreateNewInspectionType, UpdateInspectionType } from 'src/app/app.state'
import { INSPECTION_TYPE_CATEGORY_LIST, INSPECTION_TYPE_MODAL_TYPE, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { equalsIgnoreCase } from 'src/app/utils/utils'
import { InspectionType } from 'src/app/views/inspection-type/inspection-type.component'
import { InspectionTypeDescriptionDialogComponent } from '../inspection-type-description-dialog/inspection-type-description-dialog.component'

export class InspectionFormFieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

export interface InspectionTypeEditDataModel {
  modalType: INSPECTION_TYPE_MODAL_TYPE,
  inspectionIndex: number,
  inspectionType: InspectionType,
}

export function duplicateInspectionTypeNameValidator(inspectionTypeNames: string[]): ValidatorFn {
  return (control: AbstractControl) => {
    const name: string = control.value
    return equalsIgnoreCase(inspectionTypeNames, name) ? { duplicateInspectionTypeName: true } : null
  }
}

function validInspectionCategoryValidator(inspectionCategoryList: string[]): ValidatorFn {
  return (control: AbstractControl) => !equalsIgnoreCase(inspectionCategoryList, control.value)
    ? { required: true }
    : null
}

@Component({
  selector: 'app-inspection-type-edit-dialog',
  templateUrl: './inspection-type-edit-dialog.component.html',
  styleUrls: ['./inspection-type-edit-dialog.component.scss']
})
export class InspectionTypeEditDialogComponent implements OnInit {
  @Select(AppState.getInspectionTypeNames) inspectionTypeNames$: Observable<string[]>

  inspectionTypeEditTitle: string
  inspectionTypeEditForm: FormGroup
  inspectionTypeNames: string[]
  editMode = false

  matcher = new InspectionFormFieldErrorStateMatcher()

  categoryOptions = INSPECTION_TYPE_CATEGORY_LIST.map(categoryOption => ({
    ...categoryOption,
    name: this.translateService.instant(categoryOption.name),
    description: this.translateService.instant(categoryOption.description),
  }))
  private inspectionIndex: number

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InspectionTypeEditDataModel,
    private store: Store,
    private dialogRef: MatDialogRef<InspectionTypeEditDialogComponent>,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {
    this.inspectionTypeNames$.pipe(take(1))
      .subscribe(inspectionTypeNames => this.inspectionTypeNames = inspectionTypeNames)

    if (data) {
      this.editMode = data.modalType === INSPECTION_TYPE_MODAL_TYPE.EDIT
      this.inspectionTypeEditTitle = this.editMode
        ? this.translateService.instant('EDIT_NAME_TITLE', { name: data.inspectionType.name })
        : this.translateService.instant('NEW_INSPECTION_TYPE_TITLE')

      // Prepare inspection type names without including the edited inspection type name
      const remainInspectionTypeNames = this.inspectionTypeNames.filter((_, index) => index !== data.inspectionIndex)
      const categoryOptionValues = this.categoryOptions.map(option => option.value)

      this.inspectionTypeEditForm = new FormGroup({
        label: new FormControl(data.inspectionType.name, [
          Validators.required,
          Validators.pattern(NAME_REGEX),
          duplicateInspectionTypeNameValidator(remainInspectionTypeNames)
        ]),
        description: new FormControl(data.inspectionType.description, [
          Validators.required,
          Validators.pattern(NAME_REGEX),
        ]),
        category: new FormControl(data.inspectionType.category, [
          Validators.required,
          validInspectionCategoryValidator(categoryOptionValues)
        ]),
        zoneless: new FormControl(data.inspectionType.zoneless)
      })
      this.inspectionIndex = data.inspectionIndex
    }
  }

  ngOnInit(): void { }

  handleApply() {
    if (this.inspectionTypeEditForm.invalid) {
      return
    }

    this.dialogRef.close()

    const { label, description, zoneless, category } = this.inspectionTypeEditForm.value

    const payload = {
      label,
      description,
      zoneless,
      category,
    }

    if (this.editMode) {
      this.store.dispatch(new UpdateInspectionType(this.inspectionIndex, payload))
    } else {
      this.store.dispatch(new CreateNewInspectionType(payload))
    }

  }

  openCategoryDescriptionDialog() {
    this.dialog.open(InspectionTypeDescriptionDialogComponent)
  }
}
