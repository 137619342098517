import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { combineLatest, Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'

import { AppState } from 'src/app/app.state'
import { BUTTON_TYPES_KEY } from 'src/app/constants/internationalized-constants-en'

import { ZoneLayoutModel } from '../tree/models/tree.model'

type PermBuilder = (_: string) => string

const PERM = {
  updateConfig: 'write:company',
  deployConfig: 'write:company',
}

const buildPermQuery = (companyId: string): PermBuilder => (perm: string) => {
  const applicationId = '' // default to applicationId
  const appPrefix = '' // default to defaultPermPrefix
  const divisionid = ''
  return `${applicationId}:${companyId}:${divisionid}:${appPrefix}:${perm}`
}

@Component({
  selector: 'app-update-config-group-button',
  templateUrl: './update-config-group-button.component.html',
  styleUrls: ['./update-config-group-button.component.scss']
})
export class UpdateConfigGroupButtonComponent implements OnInit, OnDestroy {

  @Select(AppState.getDataConfiguration) dataConfiguration$: Observable<ZoneLayoutModel[]>
  @Select(AppState.getConfigurationChangedStatus) configurationChangedStatus$: Observable<boolean>
  @Select(AppState.getWorkInProgressStatus) workInProgressStatus$: Observable<boolean>
  @Select(AppState.getDraftStatus) draftStatus$: Observable<boolean>
  @Select(AppState.getUndoableStatus) isUndoable$: Observable<boolean>

  @Input() isValidatedConfiguration: boolean
  @Input() isUpdatingConfiguration: boolean
  @Input() isSaving: boolean
  @Input() isTesting: boolean
  @Input() isDeploying: boolean
  @Output() shouldSaveConfiguration = new EventEmitter()
  @Output() shouldPublishConfiguration = new EventEmitter()
  @Output() shouldUndoConfiguration = new EventEmitter()
  @Output() shouldTestConfiguration = new EventEmitter()
  haveConfiguration: boolean
  isConfigurationChanged: boolean
  isWorkInProgress: boolean
  isDraft: boolean
  isUndoable: boolean

  permBuilder$: Observable<PermBuilder>

  buttonState = {
    currentState: 0,
    states: [
      {
        buttonList: [
          {
            buttonName: BUTTON_TYPES_KEY.SAVE,
            className: 'save-button special-button',
            checkDisabledButton: (btnName: BUTTON_TYPES_KEY) => this.checkDisabledButton(btnName),
            executeFunc: (event) => this.handleClickButtonOfFirstState(event),
            permission: PERM.updateConfig,
          },
          {
            buttonName: BUTTON_TYPES_KEY.UNDO,
            className: 'undo-button primary-button',
            checkDisabledButton: (btnName: BUTTON_TYPES_KEY) => this.checkDisabledButton(btnName),
            executeFunc: (event) => this.handleClickButtonOfFirstState(event),
            permission: PERM.updateConfig,
          }
        ]
      },
      {
        buttonList: [
          {
            buttonName: BUTTON_TYPES_KEY.DEPLOY,
            className: 'deploy-button primary-button',
            checkDisabledButton: (btnName: BUTTON_TYPES_KEY) => this.checkDisabledButton(btnName),
            executeFunc: (event) => this.handleClickButtonOfSecondState(event),
            permission: PERM.deployConfig,
          },
          {
            buttonName: BUTTON_TYPES_KEY.TEST,
            className: 'test-button primary-button',
            checkDisabledButton: (btnName: BUTTON_TYPES_KEY) => this.checkDisabledButton(btnName),
            executeFunc: (event) => this.handleClickButtonOfSecondState(event),
            permission: PERM.deployConfig,
          }
        ]
      }
    ]
  }

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private store: Store, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.permBuilder$ = this.route.paramMap.pipe(
      map(params => params.get('companyId')),
      map(buildPermQuery)
    )

    combineLatest([
      this.dataConfiguration$,
      this.configurationChangedStatus$,
      this.workInProgressStatus$,
      this.draftStatus$,
      this.isUndoable$
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([dataConfiguration, configurationChangedStatus, workInProgressStatus, draftStatus, isUndoable]) => {

        this.haveConfiguration = dataConfiguration && dataConfiguration.length !== 0 ? true : false
        this.isConfigurationChanged = configurationChangedStatus
        this.isWorkInProgress = workInProgressStatus
        this.isDraft = draftStatus
        this.buttonState.currentState = this.isWorkInProgress && !this.isConfigurationChanged ? 1 : 0
        this.isUndoable = isUndoable
      })
  }

  checkDisabledButton(buttonType: BUTTON_TYPES_KEY): boolean {
    switch (buttonType) {
      case BUTTON_TYPES_KEY.UNDO:
        return this.isUpdatingConfiguration || !this.isUndoable

      case BUTTON_TYPES_KEY.SAVE:
        return !this.haveConfiguration || this.isUpdatingConfiguration || !this.isConfigurationChanged || this.isValidatedConfiguration

      case BUTTON_TYPES_KEY.TEST:
      // ECT2-1332: Add true to temporarily disable this button
      return true || this.isUpdatingConfiguration

      case BUTTON_TYPES_KEY.DEPLOY:
        return this.isUpdatingConfiguration

      default:
        return
    }
  }

  checkLoadingSpinnerApplied(buttonType: BUTTON_TYPES_KEY) {
    switch (buttonType) {
      case BUTTON_TYPES_KEY.SAVE:
        return this.isSaving

      case BUTTON_TYPES_KEY.TEST:
        return this.isTesting

      case BUTTON_TYPES_KEY.DEPLOY:
        return this.isDeploying

      default:
        return false
    }
  }

  handleClickButtonOfFirstState(event: BUTTON_TYPES_KEY) {
    if (event === BUTTON_TYPES_KEY.SAVE) {
      this.shouldSaveConfiguration.emit(true)
    }

    if (event === BUTTON_TYPES_KEY.UNDO) {
      this.shouldUndoConfiguration.emit(true)
    }
  }

  handleClickButtonOfSecondState(event: BUTTON_TYPES_KEY) {
    if (event === BUTTON_TYPES_KEY.DEPLOY) {
      this.shouldPublishConfiguration.emit(true)
    }
    if (event === BUTTON_TYPES_KEY.TEST) {
      this.shouldTestConfiguration.emit(true)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

}
