
const NA_FOOTER_LINKS = [
  {
    support: 'https://support.zonarsystems.net/hc/en-us',
  },
  {
    terms_and_conditions: 'https://support.zonarsystems.net/hc/en-us/articles/360038970832-EVIR-Mobile-Terms-of-Use',
  },
  {
    data_disclosure: 'https://support.zonarsystems.net/hc/en-us/articles/360038968272-EVIR-Mobile-Privacy-Policy',
  },
]
export const FOOTER_LINKS = {
  NA: NA_FOOTER_LINKS,
}
