import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngxs/store'

import { DropToInspectionTypeDetail, UpdateInspectionFormData } from 'src/app/app.state'
import { NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { InspectionTypeFormData } from 'src/app/views/inspection-type/inspection-type.component'
import { InspectionRowEditDialogData } from '../inspection-type-detail/inspection-type-detail.component'

export class LabelErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

export interface InspectionDetailCheckboxField {
  fieldNameLabel: string
  hint: string
  default: boolean
  required: boolean
}

export interface CheckboxFieldForm {
  label: string
  helper: string
  default: boolean
  required: boolean
}

@Component({
  selector: 'app-inspection-detail-row-checkbox-edit-dialog',
  templateUrl: './inspection-detail-row-checkbox-edit-dialog.component.html',
  styleUrls: ['./inspection-detail-row-checkbox-edit-dialog.component.scss']
})
export class InspectionDetailRowCheckboxEditDialogComponent implements OnInit {
  checkboxEditForm: FormGroup

  matcher = new LabelErrorStateMatcher()

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InspectionRowEditDialogData,
    private store: Store,
    private dialogRef: MatDialogRef<InspectionDetailRowCheckboxEditDialogComponent>
  ) {
    if (this.data) {
      const formData = this.transformFormData(this.data.row)
      this.checkboxEditForm = new FormGroup({
        label: new FormControl(formData.label, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(20),
          ]
        }),
        helper: new FormControl(formData.helper, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(40),
          ]
        }),
        default: new FormControl(formData.default),
        required: new FormControl(formData.required),
      })
    }
  }

  ngOnInit(): void { }

  handleApply() {
    if (this.checkboxEditForm.invalid) {
      return
    }

    const formValue = this.checkboxEditForm.value
    const updateValue = {
      ...this.data.row,
      ...this.transformData(formValue),
      fieldNameLangKey: '',
      hintLangKey: '',
    }

    const action = this.data.isUpdate ? UpdateInspectionFormData : DropToInspectionTypeDetail
    this.store.dispatch(new action(
      this.data.inspectionTypeIndex,
      this.data.formDataIndex,
      updateValue,
    ))
    this.dialogRef.close()
  }

  transformFormData(val: InspectionTypeFormData): CheckboxFieldForm {
    return {
      label: val.fieldNameLabel,
      helper: val.hint,
      default: val.default || false,
      required: val.required,
    }
  }

  transformData(val: CheckboxFieldForm): InspectionDetailCheckboxField {
    return {
      fieldNameLabel: val.label,
      hint: val.helper,
      default: val.default,
      required: val.required,
    }
  }
}
