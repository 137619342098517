import { ConfigurationValidationModel } from '../../../models/tree.model'

export function createAssetTypeMap(
  transformDataConfiguration: ConfigurationValidationModel[]
) {
  const assetTypeMap: Record<string, ConfigurationValidationModel[]> = {}

  // iterate the assetIds and create a map that uses the asset type as the key.
  // Create an array of asset for each type.   If there is more than one asset
  // for a key, then it represents a violation
  const makeAssetTypeKey = (assetId: string[]) => {
    return JSON.stringify(assetId)
  }

  transformDataConfiguration.forEach((config) => {
    const key = makeAssetTypeKey(config.assetType)
    const configs = assetTypeMap[key] ?? []

    configs.push(config)

    assetTypeMap[key] = configs
  })

  return assetTypeMap
}
