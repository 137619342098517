<div *ngIf="region === 'EU'; then inputTemplate else chipTemplate"></div>

<ng-template #chipTemplate>
  <app-chips #assetTypeChips
    [chipList]="assetTypeValue"
    chipTitle="{{'ASSET_TYPES' | translate}}"
    placeholder="{{'ASSET_TYPES_PLACEHOLDER' | translate}}"
    [minChips]="minAssetType"
    [maxChips]="maxAssetType"
    (valueChange)="updateAssetTypeValue($event)"
    [ngClass]="{'erorr-chips': !validStatus.isValidAssetTypeValue}"
    (inputStatusChange)="handleChangeAssetTypeChip($event, assetTypeChips)"
    [chipsStatus]="validStatus.isValidAssetTypeValue">
  </app-chips>
</ng-template>

<ng-template #inputTemplate>
  <mat-form-field class="asset-type-field"
    [class.mat-form-field-invalid]="!validStatus.isValidLastAssetType"
    appearance="outline"
    [style.width.%]="100">
    <mat-label>{{'ASSET_TYPE_LABEL' | translate}}</mat-label>
    <input matInput
      placeholder="{{'ASSET_TYPE_PLACEHOLDER' | translate}}"
      [ngModel]="inputAssetType"
      (ngModelChange)="updateAssetTypeValue($event)"
      maxlength="100"/>
  </mat-form-field>
  <mat-hint *ngIf="validStatus.isValidLastAssetType">{{'INVALID_LAST_ASSET_TYPE_ERROR_MSG' | translate}}</mat-hint>
</ng-template>
