import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Select, Store } from '@ngxs/store'
import { isEqual, union, intersection } from 'lodash'
import { combineLatest, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'

import { AppState, HoverAssetZoneMap, SetFilterInspectionTypes, UpdateAssetZoneMapPosition } from 'src/app/app.state'
import { ERROR_MESSAGES, PLACEHOLDER } from 'src/app/constants/internationalized-constants-en'
import { UpdateAssetZoneDataModel, ZoneBoxModel } from 'src/app/models'
import { EvirLanguageDictionary, SelectedConfigurationModel, ZoneLayoutModel } from '../tree/models/tree.model'
import { SvgHandlingService } from '../tree/services/svg-handling/svg-handling.service'
import { LanguageDictionaryHandlingService } from '../tree/services/language-dictionary-handling/language-dictionary-handling.service'

@Component({
  selector: 'app-asset-zone-map',
  templateUrl: './asset-zone-map.component.html',
  styleUrls: ['./asset-zone-map.component.scss']
})
export class AssetZoneMapComponent implements OnDestroy {

  @Select(AppState.getSelectedConfiguration) selectedConfiguration$: Observable<SelectedConfigurationModel>
  @Select(AppState.getAssetViewBackground) assetViewBackground$: Observable<string>
  @Select(AppState.getDataConfiguration) dataConfiguration$: Observable<ZoneLayoutModel[]>
  @Select(AppState.getLanguageDictionary) languageDictionary$: Observable<EvirLanguageDictionary>
  @Select(AppState.getInspectionTypeNames) inspectionTypesOptions$: Observable<string[]>

  assetZoneName: string
  selectedZoneMap: ZoneLayoutModel
  assetViewBackground: string
  isNoDataAvailable = false
  loading = false
  languageDictionary: EvirLanguageDictionary
  inspectionTypeOptions: string[] = []
  selectedInspectionTypesOption: string
  inputForm: FormGroup = new FormBuilder().group({})
  validInspectionTypes: string[] = []
  selectedConfiguration: SelectedConfigurationModel
  dataConfiguration: ZoneLayoutModel[]

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    protected langDictionaryService: LanguageDictionaryHandlingService,
    private svgHandlingService: SvgHandlingService,
  ) {
    const inspectionTypeOptionsControl = new FormControl([])
    this.inputForm.addControl('inspectionTypes', inspectionTypeOptionsControl)
    combineLatest([
      this.selectedConfiguration$,
      this.assetViewBackground$,
      this.dataConfiguration$,
      this.languageDictionary$.pipe(
        distinctUntilChanged((prevLangDict, currLangDict) => isEqual(prevLangDict, currLangDict)),
      ),
      this.inspectionTypesOptions$.pipe(
        distinctUntilChanged((prevInspectionTypes, currInspectionTypes) => isEqual(prevInspectionTypes, currInspectionTypes)),
      ),
    ]).pipe(takeUntil(this.destroy$)).subscribe(([configuration, assetView, dataConfiguration, languageDictionary, inspectionTypeOptions]) => {
      this.loading = true
      this.assetViewBackground = assetView === null || this.svgHandlingService.isSvgFile(assetView) ? assetView : ''

      if (assetView === '') {
        this.loading = false
      }

      if (dataConfiguration && !dataConfiguration.length) {
        this.isNoDataAvailable = true
        this.loading = false
        return
      }

      if (!configuration || !dataConfiguration || !languageDictionary || !inspectionTypeOptions) {
        return
      }

      const isResetFilter = !this.selectedConfiguration || this.selectedConfiguration.index !== configuration.index || dataConfiguration.length !== this.dataConfiguration.length
      this.selectedConfiguration = configuration
      this.dataConfiguration = dataConfiguration
      this.validInspectionTypes = []
      this.selectedZoneMap = dataConfiguration[configuration.index]
      this.languageDictionary = languageDictionary
      this.isNoDataAvailable = false

      if (this.selectedZoneMap) {
        this.assetZoneName = langDictionaryService.convertLangKeyToString(
          this.selectedZoneMap.zoneLayoutLangKey, this.languageDictionary)
        this.selectedZoneMap.configZones.forEach(zone => {
          const inspectionTypes = zone.zoneInspectionTypes ? zone.zoneInspectionTypes : []
          this.validInspectionTypes = union(inspectionTypes, this.validInspectionTypes)
        })
      }

      if (!isEqual(this.validInspectionTypes, this.inputForm.controls.inspectionTypes.value)) {
        const newInspectionTypes = intersection(this.inputForm.controls.inspectionTypes.value, this.validInspectionTypes)
        this.inputForm.controls.inspectionTypes.setValue(newInspectionTypes)
        this.handleChangeInspectionTypes(newInspectionTypes)
      }

      if (isResetFilter) {
        this.inputForm.controls.inspectionTypes.setValue(this.validInspectionTypes)
        this.handleChangeInspectionTypes(this.validInspectionTypes)
      }

      this.inspectionTypeOptions = inspectionTypeOptions
      this.inspectionTypeOptions = intersection(this.inspectionTypeOptions, this.validInspectionTypes)
      this.store.dispatch(new SetFilterInspectionTypes(this.inputForm.controls.inspectionTypes.value))
    })
  }

  handlePreparingData(status: boolean) {
    this.loading = status
    this.cdRef.detectChanges()
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
    this.store.dispatch(new SetFilterInspectionTypes([]))
  }

  handleDropValue(data: UpdateAssetZoneDataModel) {
    this.store.dispatch(new UpdateAssetZoneMapPosition(data))
  }

  handleHoverAssetZoneBox(data: ZoneBoxModel) {
    this.store.dispatch(new HoverAssetZoneMap(data))
  }

  handleChangeInspectionTypes(value) {
    this.selectedInspectionTypesOption = value
    this.store.dispatch(new SetFilterInspectionTypes(value))
  }
}
