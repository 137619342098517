import { SelectionModel } from '@angular/cdk/collections'
import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { isEqual } from 'lodash'
import { Observable, of as observableOf } from 'rxjs'
import { filter } from 'rxjs/operators'

import { DeleteTemplateLibraryPiece, InstallerExportDataConfiguration, UpdatePiece } from 'src/app/app.state'
import { Constants, INSTALLER_EXPORT_TYPE, MENU_OPTION_KEY, NOTIFICATION_TYPES, TemplateLibraryKey, TEMPLATE_TYPES_VALUE, i18nParams } from 'src/app/constants/internationalized-constants-en'
import { equalsIgnoreCase, trimAll } from 'src/app/utils/utils'
import { DialogComponent } from '../../dialog/dialog.component'
import { MenuOption } from '../../menu/menu.component'
import { NotificationSnackbarService } from '../../notification-snackbar/services/notification-snackbar.service'
import { PieceEditDialogComponent, PieceEditDialogDataModel } from '../../piece-edit-dialog/piece-edit-dialog.component'
import { TemplateLibraryPieceModel } from '../../template-library/models/template-library.model'
import { CheckDuplicatePieceNameService } from '../../template-library/services/check-duplicate-piece-name/check-duplicate-piece-name.service'
import { EvirLanguageDictionary, TreeDataType, TreeFlatNode, TreeNode, ZoneNameModel } from '../../tree/models/tree.model'
import { TreeBuildingService } from '../../tree/services/tree-building/tree-building.service'

@Component({
  selector: 'app-template-library-tree',
  templateUrl: './template-library-tree.component.html',
  styleUrls: ['./template-library-tree.component.scss']
})
export class TemplateLibraryTreeComponent {

  @Input() set selectedTemplateType(templateType: TEMPLATE_TYPES_VALUE) {
    if (templateType && !isEqual(templateType, this.templateLibraryType)) {
      this.templateLibraryType = templateType
      this.displayMenuOptions = this.menuOptions.filter(menuItem => this.templateLibraryType === TEMPLATE_TYPES_VALUE.CONFIGURATION
        || !this.configOnlyOptions.map(item => item.text).includes(menuItem.text))
      this.createResourceAndRebuildTree(this.templateLibrary, this.templateLibraryType, this._languageDictionary)
    }
  }
  @Input() set templateLibraryData(data) {
    if (data && !isEqual(data, this.templateLibrary)) {
      this.templateLibrary = data
      this.createResourceAndRebuildTree(this.templateLibrary, this.templateLibraryType, this._languageDictionary)
    }
  }
  @Input() set languageDictionary(langDict: EvirLanguageDictionary) {
    if (!langDict) {
      return
    }

    this._languageDictionary = langDict
    this.createResourceAndRebuildTree(this.templateLibrary, this.templateLibraryType, this._languageDictionary)
  }
  @Input() isMenuHidden: boolean = false
  @Output() shouldReloadPieces = new EventEmitter()

  templateLibrary: TemplateLibraryPieceModel[]
  templateLibraryType: TEMPLATE_TYPES_VALUE
  _languageDictionary: EvirLanguageDictionary
  treeControl: FlatTreeControl<TreeFlatNode>
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true)
  dragging = false
  expandTimeout: any
  expandDelay = 1000
  tooltipDisabledStatus: boolean = true
  tooltipDelayTime = 3000
  zoneNameList: ZoneNameModel[] = []
  menuOptions: MenuOption[] = [
    // ECT2-1326: Set disable true to temporarily disable this option
    { text: MENU_OPTION_KEY.EDIT, disable: true },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_FULL },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL },
    { text: MENU_OPTION_KEY.DELETE },
  ]
  configOnlyOptions: MenuOption[] = [
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_FULL },
    { text: MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL },
  ]
  displayMenuOptions: MenuOption[] = []

  constructor(
    private treeBuildingService: TreeBuildingService,
    public store: Store,
    public dialog: MatDialog,
    public snackbarService: NotificationSnackbarService,
    public pieceNameService: CheckDuplicatePieceNameService,
    private translateService: TranslateService,
  ) { }

  createResourceAndRebuildTree(templateLibrary: TemplateLibraryPieceModel[], templateLibraryType: TEMPLATE_TYPES_VALUE, languageDictionary: EvirLanguageDictionary) {
    if (templateLibrary && templateLibraryType && languageDictionary) {
      this.expansionModel.clear()
      const startLevel = TemplateLibraryKey[templateLibraryType].startIndex
      const formattedPieces = this.treeBuildingService.getFormattedPieces(templateLibrary, languageDictionary)
      const treeData = this.treeBuildingService.buildTreeByLevel(formattedPieces, templateLibraryType)
      const data = this.treeBuildingService.buildPieceTree(treeData, 0, startLevel, TreeDataType.TEMPLATE_LIBRARY)
      const transformer = (node: TreeNode, level: number) => {
        const nodeSiblingIndex = node.id.split('/').pop()
        return new TreeFlatNode(!!node.children, node.name, level, node.value, node.id, node.nodeType, undefined, Number(nodeSiblingIndex), node.displayName)
      }
      const getLevel = (node: TreeFlatNode) => node.level
      const isExpandable = (node: TreeFlatNode) => node.expandable
      const getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children)
      this.treeFlattener = new MatTreeFlattener(transformer, getLevel, isExpandable, getChildren)
      this.treeControl = new FlatTreeControl<TreeFlatNode>(getLevel, isExpandable)
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

      this.rebuildTreeForData(data)
    }
  }

  rebuildTreeForData(data: any) {
    this.dataSource.data = data
    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.id === id)
      this.treeControl.expand(node)
    })
  }

  hasChild = (_index: number, _nodeData: TreeFlatNode) => {
    const nodeSiblings = this.treeBuildingService.findNodeSiblings(this.dataSource.data, _nodeData.id)
    let node = nodeSiblings.find(x => x.id === _nodeData.id)
    if (!node || !node.children || !node.children.length) {
      _nodeData.expandable = false
      return false
    }
    return _nodeData.expandable
  }

  isDisabledTooltip(nodeId: string): boolean {
    if (!nodeId) {
      return true
    }

    const selectors = [`piece-name ${nodeId}`, `piece-zone-tag ${nodeId}`, `piece-zone-inspection-types ${nodeId}`]

    const nodeContentWidth = selectors
      .map(document.getElementById.bind(document))
      .filter(item => !!item)
      .map((item: HTMLElement) => item.offsetWidth)
      .reduce((acc, current) => acc += current, 0)
    const maxNodeContentWidth = document.getElementById(nodeId)
      ? document.getElementById(nodeId).offsetWidth
      : 0
    return nodeContentWidth < maxNodeContentWidth
  }

  handleClickOption(option: string, node: TreeFlatNode) {
    switch (option) {
      case MENU_OPTION_KEY.EXPORT_CONFIG_FULL:
        this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_LARGE, true, node))
        break
      case MENU_OPTION_KEY.EXPORT_CONFIG_INSTALL:
        this.store.dispatch(new InstallerExportDataConfiguration(INSTALLER_EXPORT_TYPE.INSTALLER_EXPORT_SHORT, true, node))
        break
      case MENU_OPTION_KEY.DELETE:
        this.openDeleteConfirmDialog(node)
        break
      case MENU_OPTION_KEY.EDIT:
        this.openEditDialog(node)
        break
      default:
        break
    }
  }

  openDeleteConfirmDialog(node: TreeFlatNode) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        onClose: () => { dialogRef.close() },
        onAccept: () => {
          this.deleteTemplateLibraryPiece(node)
          dialogRef.close()
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('YES'),
        title: this.translateService.instant('CONFIG_PIECE_DELETE_CONFIRM_TITLE'),
        message: `(${node.displayName})`
      },
      panelClass: 'delete-piece-dialog'
    })
  }

  deleteTemplateLibraryPiece(node: TreeFlatNode) {
    this.store.dispatch(new DeleteTemplateLibraryPiece(node.value.id)).subscribe(
      value => { },
      error => {
        const detailedError = {
          'Error': [`${this.translateService.instant('ERROR_CODE')}: ${error.status}`]
        }
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('CONFIG_PIECE_DELETE_FAIL_MSG'),
          NOTIFICATION_TYPES.FAILED,
          detailedError
        )
      },
      () => {
        this.pieceNameService.removePieceName(node.displayName, node.value.id)
        this.shouldReloadPieces.emit(true)
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('CONFIG_PIECE_DELETE_SUCCESS_MSG', { name: node.displayName }),
          NOTIFICATION_TYPES.SUCCESS,
          null,
          Constants.snackbar.duration
        )
      }
    )
  }

  openEditDialog(node: TreeFlatNode) {
    const editPiece = this.templateLibrary.find(item => item.id === node.value.id)
    const oldPieceName = editPiece.name
    this.dialog.open<PieceEditDialogComponent, PieceEditDialogDataModel>(PieceEditDialogComponent, {
      data: {
        editPiece: editPiece,
        title: this.translateService.instant('CONFIG_PIECE_EDIT_TITLE'),
      },
      panelClass: 'piece-edit-dialog',
    }).afterClosed().pipe(
      filter(res => !!res)
    ).subscribe(([id, piece, isAdmin]) => this.updatePiece(id, piece, oldPieceName, isAdmin))
  }

  private updatePiece(id: string, piece: TemplateLibraryPieceModel, oldPieceName: string, isAdmin: boolean) {
    piece.name = trimAll(piece.name)
    this.store.dispatch(new UpdatePiece(piece, id, isAdmin)).subscribe(
      () => {
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('CONFIG_PIECE_EDIT_SUCCESS_MSG', { name: piece.name }),
          NOTIFICATION_TYPES.SUCCESS,
          null,
          Constants.snackbar.duration
        )
      },
      () => {
        this.snackbarService.openNotificationSnackbar(
          this.translateService.instant('PIECE_EDIT_FAIL_MSG', { name: piece.name }),
          NOTIFICATION_TYPES.FAILED
        )
      },
      () => {
        if (!equalsIgnoreCase(oldPieceName, piece.name)) {
          this.pieceNameService.removePieceName(oldPieceName, id)
          this.pieceNameService.addPieceName(piece.name, id)
        }
        this.shouldReloadPieces.emit(true)
      }
    )
  }
}
