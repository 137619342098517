<app-three-column-layout>

  <ng-container left>
    <ng-container
      *ngTemplateOutlet="cardTemplate; context: { $implicit: ('INSPECTIONS_TITLE' | translate), bodyTemplate: inspectionTypeCol,
        menuItems: copiedInspectionType ? menuInspectionType.concat(pasteOption) : menuInspectionType,
        handleClickContextMenu: handleClickInspectionTypeColumnContextMenu.bind(this), containerClasses: ['root', 'column-left'] }" >
    </ng-container>
  </ng-container>

  <ng-container center>
    <ng-container
      *ngTemplateOutlet="cardTemplate; context: { $implicit: (inspectionTypeList$ | async)[currentInspectionTypeIndex]?.name, bodyTemplate: inspectionTypeDetailCol,
        menuItems: (copiedInspectionDetailRow$ | async) && (selectedInspectionTypeIndex$ | async) !== null ? menuInspectionTypeDetail : [], handleClickContextMenu: handleClickInspectionDetailColumnContextMenu.bind(this), containerClasses: ['height-adjust', 'root', 'column-center'] }">
    </ng-container>
  </ng-container>

  <ng-container right>
    <ng-container *ngTemplateOutlet="cardTemplate; context: { $implicit: ('FORM_LIBRARY_TITLE' | translate), bodyTemplate: formLibraryCol, containerClasses: ['column-right'] }"></ng-container>
  </ng-container>

</app-three-column-layout>

<ng-template #cardTemplate let-cardTitle let-bodyTemplate="bodyTemplate" let-menuItems="menuItems" let-handleClickContextMenu="handleClickContextMenu" let-containerClasses="containerClasses">
  <app-card [style.height]="'650px'" [contextMenuItems]="menuItems" fxFlex (clickedItem)="handleClickContextMenu($event)" [containerClasses]="containerClasses">
    <ng-container card-title>{{ cardTitle }}</ng-container>
    <ng-container card-body>
      <ng-container *ngTemplateOutlet="bodyTemplate || toBeDefined"></ng-container>
    </ng-container>
  </app-card>
</ng-template>

<ng-template #toBeDefined>
  <span>TBD</span>
</ng-template>

<ng-template #inspectionTypeCol>
  <div fxLayout="column" fxLayoutGap="15px">
    <ng-container *ngFor="let item of inspectionTypeList$ | async; index as i">
      <ng-container *ngTemplateOutlet="inspectionCard; context: { $implicit: item, index: i }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #inspectionCard let-inspection let-index="index">
  <app-inspection-type-card (click)="selectInspectionType(index)" [inspection]="inspection"
    [isAccessGranted]="isAccessGranted$ | async" [isActive]="index === currentInspectionTypeIndex"
    (click)="handleClickContextMenu($event, index, inspection)">
  </app-inspection-type-card>
</ng-template>

<ng-template #inspectionTypeDetailCol>
  <app-inspection-type-detail [isAccessGranted]="isAccessGranted$ | async" [inspectionType]="seletectedInspectionType"
    [index]="currentInspectionTypeIndex" [isDraggingFormLibraryField]="isDraggingFormLibraryField"
    [maxImageCount]="maxImageCount$ | async"></app-inspection-type-detail>
</ng-template>

<ng-template #formLibraryCol>
  <app-form-library (draggedFormField)="isDraggingFormLibraryField=$event"></app-form-library>
</ng-template>
