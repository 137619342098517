import { CONFIGURATION_ERROR_TYPES } from 'src/app/constants/internationalized-constants-en'
import {
  ConfigurationErrorModel,
  ConfigurationValidationModel,
} from '../../../models/tree.model'
import { createAssetTypeMap } from './create-assettype-map'

export function getAssetTypeErrors(
  transformDataConfiguration: ConfigurationValidationModel[]
) {
  const configErrorList: Record<string, string[]> = {}

  const errors: ConfigurationErrorModel[] = []

  const assetTypeMap = createAssetTypeMap(transformDataConfiguration)

  Object.keys(assetTypeMap).forEach((key) => {
    const configs = assetTypeMap[key]

    if (configs.length > 1) {
      configs.forEach((config) => {
        errors.push({
          configurationName: config.configName,
          configurationErrorList: {
            [CONFIGURATION_ERROR_TYPES.DUPLICATE_ASSET_TYPE]: [key],
          },
        })
      })
    }
  })

  return errors
}
