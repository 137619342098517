import { AddInspectionTypeDialogComponent } from './components/add-inspection-type-dialog/add-inspection-type-dialog.component'
import { AddPieceDialogComponent } from './components/add-piece-dialog/add-piece-dialog.component'
import { AddZoneInspectionTypesPipe } from './pipes/add-zone-inspection-types/add-zone-inspection-types.pipe'
import { AddZoneTagPipe } from './pipes/add-zone-tag/add-zone-tag.pipe'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { AppState, SetAccessStatus } from './app.state'
import { AssetTypeInputComponent } from './components/asset-type-input/asset-type-input.component'
import { AssetZoneMapComponent } from './components/asset-zone-map/asset-zone-map.component'
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter/capitalize-first-letter.pipe'
import { CardComponent } from './components/card/card.component'
import { CheckboxComponent } from './components/checkbox/checkbox.component'
import { ChipsComponent } from './components/chips/chips.component'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { CompaniesComponent } from './views/companies/companies.component'
import { CompanySelectorComponent } from './components/company-selector/company-selector.component'
import { ComponentEditDialogComponent } from './components/component-edit-dialog/component-edit-dialog.component'
import { ConditionEditDialogComponent } from './components/condition-edit-dialog/condition-edit-dialog.component'
import { ConfigLevelEditDialogComponent } from './components/config-level-edit-dialog/config-level-edit-dialog.component'
import { ConfigurationLayoutComponent } from './views/configuration-layout/configuration-layout.component'
import { ConfigurationsComponent } from './views/configurations/configurations.component'
import { ConfirmReflectDialogComponent } from './components/confirm-reflect-dialog/confirm-reflect-dialog.component'
import { ConfirmReflectDirective } from './directives/confirm-reflect/confirm-reflect.directive'
import { DefaultLayoutComponent } from './views/default-layout/default-layout.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { EctAuthModule } from './shared/ect-auth/ect-auth.module'
import { environment } from 'src/environments/environment'
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component'
import { ErrorHandlerInterceptor } from './interceptors/error-handler/error-handler.interceptor'
import { ErrorPageComponent } from './components/error-page/error-page.component'
import { FeatureFlagsModule } from './shared/feature-flag/feature-flags.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormLibraryComponent } from './components/form-library/form-library.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { getActionTypeFromInstance, NgxsModule } from '@ngxs/store'
import { GridZoneBoxComponent } from './components/grid-zone-box/grid-zone-box.component'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { InformDialogComponent } from './components/inform-dialog/inform-dialog.component'
import { InspectionDetailRowCheckboxEditDialogComponent } from './components/inspection-detail-row-checkbox-edit-dialog/inspection-detail-row-checkbox-edit-dialog.component'
import { InspectionDetailRowDropdownEditDialogComponent } from './components/inspection-detail-row-dropdown-edit-dialog/inspection-detail-row-dropdown-edit-dialog.component'
import { InspectionDetailRowEditDialogComponent } from './components/inspection-detail-row-edit-dialog/inspection-detail-row-edit-dialog.component'
import { InspectionDetailRowPhotoEditDialogComponent } from './components/inspection-detail-row-photo-edit-dialog/inspection-detail-row-photo-edit-dialog.component'
import { InspectionRowNumbericComponent } from './components/inspection-detail-row-edit-dialog/inspection-row-numberic/inspection-row-numberic.component'
import { InspectionRowTextComponent } from './components/inspection-detail-row-edit-dialog/inspection-row-text/inspection-row-text.component'
import { InspectionTypeCardComponent } from './components/inspection-type-card/inspection-type-card.component'
import { InspectionTypeComponent } from './views/inspection-type/inspection-type.component'
import { InspectionTypeDescriptionDialogComponent } from './components/inspection-type-description-dialog/inspection-type-description-dialog.component'
import { InspectionTypeDetailComponent } from './components/inspection-type-detail/inspection-type-detail.component'
import { InspectionTypeEditDialogComponent } from './components/inspection-type-edit-dialog/inspection-type-edit-dialog.component'
import { InspectionTypeLabelEditDialogComponent } from './components/inspection-type-label-edit-dialog/inspection-type-label-edit-dialog.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTreeModule } from '@angular/material/tree'
import { MenuComponent } from './components/menu/menu.component'
import { NavigationTabComponent } from './components/navigation-tab/navigation-tab.component'
import { NgModule } from '@angular/core'
import { NgxsHistoryPluginModule } from './modules/ngxs-history-plugin/ngxs-history-plugin.module'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NoPermissionComponent } from './views/no-permission/no-permission.component'
import { NotFoundComponent } from './views/not-found/not-found.component'
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component'
import { PieceEditDialogComponent } from './components/piece-edit-dialog/piece-edit-dialog.component'
import { RadioButtonComponent } from './components/radio-button/radio-button.component'
import { SelectComponent } from './components/select/select.component'
import { SidenavModule as ZonarUiSidenavModule } from '@zonar-ui/sidenav'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { SpinnerModule } from './directives/spinner/spinner.module'
import { SupportCopyDirective } from './directives/support-copy/support-copy.directive'
import { SvgConverterComponent } from './components/svg-converter/svg-converter.component'
import { TemplateLibraryComponent } from './components/template-library/template-library.component'
import { TemplateLibraryTreeComponent } from './components/template-library-tree/template-library-tree/template-library-tree.component'
import { ThreeColumnLayoutComponent } from './components/layouts/three-column-layout/three-column-layout.component'
import { ThrottleClickDirective } from './directives/throttle-click/throttle-click.directive'
import { ToastMessageDirective } from './directives/toast-message/toast-message.directive'
import { TooltipModule } from './shared/tooltip/tooltip.module'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule, ZonarUiI18nModule } from '@zonar-ui/i18n'
import { TreeComponent } from './components/tree/tree.component'
import { UnitSwitcherComponent } from './components/unit-switcher/unit-switcher.component'
import { UpdateConfigGroupButtonComponent } from './components/update-config-group-button/update-config-group-button.component'
import { ZonarUICoreModule } from '@zonar-ui/core'
import { ZonarUiFooterModule } from '@zonar-ui/footer'
import { ZonarUIMaterialModule } from '@zonar-ui/material'
import { ZoneLevelEditDialogComponent } from './components/zone-level-edit-dialog/zone-level-edit-dialog.component'
import {
  APP_VERSION,
  CURRENT_VERSION,
  APP_VERSION_STRING,
  constructVersionString,
} from 'src/app/version'
import {
  ZonarOwnerIdInterceptor,
  ZonarUiAuthModule,
  ZonarUiPermissionsModule,
} from '@zonar-ui/auth'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locates/', '.json')
}

@NgModule({
  declarations: [
    AddInspectionTypeDialogComponent,
    AddPieceDialogComponent,
    AddZoneInspectionTypesPipe,
    AddZoneTagPipe,
    AppComponent,
    AssetTypeInputComponent,
    AssetZoneMapComponent,
    AutocompleteComponent,
    CapitalizeFirstLetterPipe,
    CardComponent,
    CheckboxComponent,
    ChipsComponent,
    CompaniesComponent,
    CompanySelectorComponent,
    ComponentEditDialogComponent,
    ConditionEditDialogComponent,
    ConfigLevelEditDialogComponent,
    ConfigurationLayoutComponent,
    ConfigurationsComponent,
    ConfirmReflectDialogComponent,
    ConfirmReflectDirective,
    DefaultLayoutComponent,
    DialogComponent,
    ErrorDialogComponent,
    ErrorPageComponent,
    FormLibraryComponent,
    GridZoneBoxComponent,
    InformDialogComponent,
    InspectionDetailRowCheckboxEditDialogComponent,
    InspectionDetailRowDropdownEditDialogComponent,
    InspectionDetailRowEditDialogComponent,
    InspectionDetailRowPhotoEditDialogComponent,
    InspectionRowNumbericComponent,
    InspectionRowTextComponent,
    InspectionTypeCardComponent,
    InspectionTypeComponent,
    InspectionTypeDescriptionDialogComponent,
    InspectionTypeDetailComponent,
    InspectionTypeEditDialogComponent,
    InspectionTypeLabelEditDialogComponent,
    MenuComponent,
    NavigationTabComponent,
    NoPermissionComponent,
    NotFoundComponent,
    NotificationSnackbarComponent,
    PieceEditDialogComponent,
    RadioButtonComponent,
    SelectComponent,
    SpinnerComponent,
    SupportCopyDirective,
    SvgConverterComponent,
    TemplateLibraryComponent,
    TemplateLibraryTreeComponent,
    ThreeColumnLayoutComponent,
    ThrottleClickDirective,
    ToastMessageDirective,
    TreeComponent,
    UnitSwitcherComponent,
    UpdateConfigGroupButtonComponent,
    ZoneLevelEditDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    DragDropModule,
    EctAuthModule.forRoot(),
    FeatureFlagsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatTreeModule,
    NgxsHistoryPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      filter: (action) => getActionTypeFromInstance(action) !== SetAccessStatus.type,
    }),
    NgxsModule.forRoot([AppState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ReactiveFormsModule,
    SpinnerModule,
    TooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ZonarUiAuthModule,
    ZonarUICoreModule,
    ZonarUiFooterModule,
    ZonarUiI18nModule,
    ZonarUIMaterialModule,
    ZonarUIMaterialModule,
    ZonarUiPermissionsModule,
    ZonarUiSidenavModule,
  ],
  providers: [
    { provide: APP_VERSION, useValue: CURRENT_VERSION },
    {
      provide: APP_VERSION_STRING,
      useFactory: constructVersionString,
      deps: [APP_VERSION, 'appEnvironment'],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    /** IB needs zonar-ownerid-interceptor to recognized it's routes */
    {
      provide: 'options',
      useValue: {
        zonarOwnerIdInterceptor: {
          requiresZonarOwnerId: [environment.configApi, environment.evirApi],
        },
        permissionsDataStoreEffects: {
          selectDefaultCompany: true,
        },
      },
    },
    /** I18n token */
    { provide: 'supportedLanguages', useValue: environment.i18n.supportedLanguages },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: environment.i18n.useBrowserLanguage },
    /** pendo token */
    { provide: 'pendoParams', useValue: environment.pendo },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
