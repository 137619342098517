export interface UnitDescription {
  value: string[]
  exponent: number
  measurement: string
  unitText: string[]
  key?: string
}

const unitDescriptionFactory = (firstUnitName: string, secondUnitName: string, exponent: number, measurement: string): UnitDescription => ({
  value: [`${firstUnitName}${exponent > 1 ? exponent : ''}`, `${secondUnitName}${exponent > 1 ? exponent : ''}`],
  exponent: exponent,
  measurement: measurement,
  unitText: [firstUnitName, secondUnitName],
  key: `${firstUnitName}${exponent > 1 ? exponent : ''}${secondUnitName}${exponent > 1 ? exponent : ''}`
})

const singleUnitDescriptionFactory = (unitName: string, exponent: number, measurement: string): UnitDescription => ({
  value: [`${unitName}${exponent > 1 ? exponent : ''}`],
  exponent: exponent,
  measurement: measurement,
  unitText: [unitName],
  key: `${unitName}${exponent > 1 ? exponent : ''}`
})

export const UNIT_TYPE = [
  unitDescriptionFactory('ft', 'm', 2, 'area'),
  unitDescriptionFactory('yd', 'm', 2, 'area'),
  unitDescriptionFactory('ft', 'm', 3, 'volume'),
  unitDescriptionFactory('yd', 'm', 3, 'volume'),
  unitDescriptionFactory('USD', 'CAD', 1, 'currency'),
  unitDescriptionFactory('USD', 'EUR', 1, 'currency'),
  unitDescriptionFactory('USD', 'MXN', 1, 'currency'),
  unitDescriptionFactory('USD', 'GBP', 1, 'currency'),
  unitDescriptionFactory('in', 'cm', 1, 'distance'),
  unitDescriptionFactory('ft', 'cm', 1, 'distance'),
  unitDescriptionFactory('yd', 'm', 1, 'distance'),
  unitDescriptionFactory('mi', 'km', 1, 'distance'),
  unitDescriptionFactory('floz', 'ml', 1, 'fluid volume'),
  unitDescriptionFactory('pt', 'l', 1, 'fluid volume'),
  unitDescriptionFactory('qt', 'l', 1, 'fluid volume'),
  unitDescriptionFactory('gal', 'l', 1, 'fluid volume'),
  unitDescriptionFactory('mph', 'kph', 1, 'velocity'),
  singleUnitDescriptionFactory('psi', 1, 'pressure'),
  unitDescriptionFactory('psi', 'pa', 1, 'pressure'),
  unitDescriptionFactory('psi', 'kPa', 1, 'pressure'),
  unitDescriptionFactory('F', 'C', 1, 'temperature'),
  unitDescriptionFactory('oz', 'g', 1, 'mass'),
  unitDescriptionFactory('lb', 'kg', 1, 'mass'),
  singleUnitDescriptionFactory('t', 1, 'mass'),
  singleUnitDescriptionFactory('sec', 1, 'time'),
  singleUnitDescriptionFactory('min', 1, 'time'),
  singleUnitDescriptionFactory('hrs', 1, 'time'),
]

export const DECIMAL_OPTIONS = [
  {
    maxDecimal: 1,
    value: '.1'
  },
  {
    maxDecimal: 2,
    value: '.01'
  },
  {
    maxDecimal: 3,
    value: '.001'
  },
]
