import { Injectable, Injector } from '@angular/core'
import { NgxsNextPluginFn, NgxsPlugin, Store } from '@ngxs/store'
import { cloneDeep } from 'lodash'

import { AppState } from 'src/app/app.state'
import { HistoryStackService } from '../services/history-stack.service'
import { DisableUndoFeature, EnableUndoFeature, UndoConfigurationChange } from '../actions'

// Based on
// https://github.com/ngxs/store/blob/e6a0b2b50ae707ea430891a6a795d944c9a3946b/packages/store/src/utils/utils.ts#L5
function getActionTypeFromInstance(actionInstance: any): string | undefined {
  if (actionInstance.constructor && actionInstance.constructor.type) {
    return actionInstance.constructor.type
  }

  return actionInstance.type
}
@Injectable({
  providedIn: 'root',
})

export class TrackConfigurationEditHistoryPlugin implements NgxsPlugin {

  constructor(
    private stackService: HistoryStackService,
    private injector: Injector, // we cannot inject the Store inside a plugin
  ) { }

  /**
   * Intercept action handler and try to store the snapshot to history stack
   * 
   * @param state current state
   * @param action intercepted action
   * @param next handler for to pass to next plugins or action handler
   */
  public handle(state: any, action: any, next: NgxsNextPluginFn) {
    const store = this.injector.get<Store>(Store)

    if (this.isActionUndoable(action)) {
      // Store the current snapshot to stack so we can restore it latere
      // if we could, we should use store.snapshot instead
      this.stackService.push(cloneDeep(state))
      store.dispatch(new EnableUndoFeature())
    }

    if (this.isAction(action, UndoConfigurationChange)) {
      this.restoreState(store)
    }

    return next(state, action)
  }

  private restoreState(store: Store) {
    if (this.stackService.size() > 0) {
      const state = this.stackService.pop()
      const latestPieces = store.selectSnapshot(AppState.getTemplateLibraryPieces)
      state.app.templateLibraryPieces = latestPieces
      store.reset(state)
      if (this.stackService.size() <= 0) {
        store.dispatch(new DisableUndoFeature())
      }
    }
  }

  private isAction(action: any, actionClass: { type: string }): boolean {
    return getActionTypeFromInstance(action) === actionClass.type
  }

  private isActionUndoable(action: { isUndoable?: boolean }): boolean {
    return action.isUndoable
  }
}
