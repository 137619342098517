<mat-form-field class="app-chip-list"
  appearance="outline"
  [style.width.px]="300"
  [ngClass]="{'error-chip-list': !chipsStatus}">
  <mat-label>{{chipTitle}}</mat-label>
  <mat-chip-list #matChipList
    [disabled]="disabledChipList"
    ariaOrientation='horizontal'>
    <ng-container *ngIf="chipType === CHIP_TYPE.STRING">
      <div class="input-wrapper">
        <input #chipString
          class="chip-input"
          *ngIf="!maxChips || chipList.length < maxChips"
          [placeholder]="placeholder"
          [matChipInputFor]="matChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (keydown)="handleStatusChange(chipString)"
          (matChipInputTokenEnd)="add($event)"
          maxlength="50"
          [pattern]="regex">
      </div>
    </ng-container>
    <ng-container *ngIf="chipType === CHIP_TYPE.NUMBER">
      <div class="input-wrapper">
        <input #chipNumber
          class="chip-input"
          *ngIf="!maxChips || chipList.length < maxChips"
          [placeholder]="placeholder"
          [matChipInputFor]="matChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (keydown)="handleStatusChange(chipNumber)"
          (matChipInputTokenEnd)="add($event)"
          [attr.maxLength]="maxLengthNumber"
          [pattern]="regex">
      </div>
    </ng-container>
    <mat-chip *ngFor="let chip of chipList; let i = index"
      [selectable]="selectable"
      [title]="chip"
      [removable]="removable"
      (removed)="remove(i)">
      <div class="chip-content">
        {{chip}}
      </div>
      <mat-icon matChipRemove
        *ngIf="removable && !chipInputValue">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</mat-form-field>
