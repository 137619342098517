<div class="dialog-wrapper">
  <div mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="space-between start">
    <div class="dialog-title">{{'CHECKBOX' | translate}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form [formGroup]="checkboxEditForm">
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'LABEL_LABEL' | translate}}</mat-label>
        <input matInput
          class="label-field"
          placeholder="{{'LABEL_PLACEHOLDER' | translate}}"
          formControlName="label"
          maxlength="20"
          [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="checkboxEditForm.controls.label.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="checkboxEditForm.controls.label.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'HELPER_TEXT_LABEL' | translate}}</mat-label>
        <input matInput
          class="helper-text-field"
          placeholder="{{'HELPER_TEXT_PLACEHOLDER' | translate}}"
          formControlName="helper"
          maxlength="40"
          [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="checkboxEditForm.controls.helper.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="checkboxEditForm.controls.helper.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle class="default-toggle"
        formControlName="default"
        [style.width.%]="100">{{'DEFAUL_CHECKED_NAME' | translate}}</mat-slide-toggle>
      <mat-slide-toggle class="required-toggle"
        formControlName="required"
        [style.width.%]="100">{{'REQUIRED_FIELD_NAME' | translate}}</mat-slide-toggle>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!checkboxEditForm.valid"
      (click)="handleApply()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</div>
