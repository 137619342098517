<div class="dialog-wrapper" [ngClass]="{'two-fields-hidden': isHideSeverityFields}">
  <div mat-dialog-title
       fxLayout='row'
       fxLayoutAlign='space-between start'>
    <div class="dialog-title">{{'COMPONENT_EDIT_DIALOG_TITLE' | translate}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
              (click)="handleCancel()">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="node-edit-content">

    <form class="condition-edit-form"
          fxLayout='column'
          gdAlignColumns="start stretch"
          [formGroup]="inputForm">

      <mat-form-field appearance="outline">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input matInput
               class="field-name"
               placeholder="{{'NAME_PLACEHOLDER' | translate}}"
               [formControlName]="FORM_KEY_MAP.NAME"
               [errorStateMatcher]="matcher"
               maxlength="100">

        <mat-error>
          <!-- Wrap with mat-error because mat-form-field require mat-error to be direct child of it-->
          <ng-template *ngTemplateOutlet="nameErrorMessages; context: {$implicit: controlName}"></ng-template>
        </mat-error>

      </mat-form-field>

      <div *ngIf="!isHideSeverityFields" fxLayout='row wrap'
           fxLayoutGap="20px">
        <mat-form-field appearance="outline"
                        floatLabel='always'
                        class="inline-field">
          <mat-label>Min severity</mat-label>
          <input matInput
                 type="number"
                 class="field-name"
                 placeholder="Please enter min severity"
                 [formControlName]="FORM_KEY_MAP.SEVERITY.MIN"
                 [errorStateMatcher]="matcher"
                 maxlength="3">

          <mat-error>
            <!-- Wrap with mat-error because mat-form-field require mat-error to be direct child of it-->
            <ng-template *ngTemplateOutlet="numberErrorMessages; context: {$implicit: controlSeverityMin}">
            </ng-template>
          </mat-error>

        </mat-form-field>
        <mat-form-field appearance="outline"
                        floatLabel='always'
                        class="inline-field">
          <mat-label>Max severity</mat-label>
          <input matInput
                 type="number"
                 class="field-name"
                 placeholder="Please enter max severity"
                 [formControlName]="FORM_KEY_MAP.SEVERITY.MAX"
                 [errorStateMatcher]="matcher"
                 maxlength="3">

          <mat-error>
            <!-- Wrap with mat-error because mat-form-field require mat-error to be direct child of it-->
            <ng-template *ngTemplateOutlet="numberErrorMessages; context: {$implicit: controlSeverityMax}">
            </ng-template>
          </mat-error>

        </mat-form-field>

      </div>

      <mat-error class="cross-field-error"
                 *ngIf="controlSeverityMax.valid && controlSeverityMin.valid && inputForm.hasError(SEVERITY_MINMAX_KEY)">
        Errors:<br>
        {{SEVERITY_MINMAX_MESSAGE}}
      </mat-error>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="custom-dialog-actions">
    <button mat-button
            class="primary-button"
            (click)="handleCancel()">{{'CANCEL' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid || !(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="primary-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_ALL)">{{'APPLY_ALL' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid"
            *ngIf="(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="special-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_JUST_THIS_ONE)">{{'APPLY' | translate}}</button>
    <button mat-button
            [disabled]="!inputForm.valid"
            *ngIf="!(editNode.reflectCount && editNode.reflectCount.count > 1)"
            class="special-button"
            (click)="beforeAccept(CONFIRM_REFLECT_ACTION.APPLY_ALL)">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>

</div>

<!-- Reusable template contains a list of error message for name input -->
<ng-template #nameErrorMessages
             let-control>
  <mat-error *ngIf="control.hasError('pattern')">
    {{'INVALID_CHARS_ERROR_MSG' | translate}}
  </mat-error>
  <mat-error *ngIf="control.hasError('required')">
    {{'FIELD_REQUIRED_ERROR_MSG' | translate}}
  </mat-error>
</ng-template>

<!-- Reusable template contains a list of error message for severity input -->
<ng-template #numberErrorMessages
             let-control>
  <mat-error *ngIf="control.touched && control.hasError('required')">
    {{SEVERITY_REQUIRED_MESSAGE}}
  </mat-error>
  <mat-error *ngIf="control.touched && control.hasError('pattern')">
    {{SEVERITY_NUMBER_MESSAGE}}
  </mat-error>
  <mat-error
             *ngIf="control.touched && !control.hasError('pattern') && (control.hasError('max') || control.hasError('min'))">
    {{SEVERITY_RANGE_MESSAGE}}
  </mat-error>
</ng-template>