import {
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
} from '@angular/cdk/drag-drop'
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'

import {
  GRID_VIEW_POSITION_INDEX,
  IS_GRID_VIEW_HIDDEN,
  TAG_TYPE_OPTIONS
} from 'src/app/constants/internationalized-constants-en'
import { ZoneBoxModel } from 'src/app/models'
import { SvgConverterComponent } from '../svg-converter/svg-converter.component'
import { EvirLanguageDictionary, ZoneLayoutModel } from '../tree/models/tree.model'
import { LanguageDictionaryHandlingService } from '../tree/services/language-dictionary-handling/language-dictionary-handling.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-grid-zone-box',
  templateUrl: './grid-zone-box.component.html',
  styleUrls: ['./grid-zone-box.component.scss'],
})
export class GridZoneBoxComponent {

  @ViewChild('svgBackground') svgBackground: SvgConverterComponent
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>
  @ViewChild(CdkDropList) placeholder: CdkDropList

  @Input() svgSource: string
  @Input() languageDictionary: EvirLanguageDictionary
  @Input() set zoneMapData(data: ZoneLayoutModel) {
    if (data && data.assetViewGrid && data.assetViewGrid.length === 2) {
      this._zoneData = data
      this.zoneMapWidth = data.assetViewGrid[GRID_VIEW_POSITION_INDEX.POSITION_X]
      this.zoneMapHeight = data.assetViewGrid[GRID_VIEW_POSITION_INDEX.POSITION_Y]
      this.rebuildGridZone()
    }
  }
  @Input() set inspectionTypes(data: string[]) {
    if (data) {
      this.selectedInspectionTypes = data ? data : []
      this.rebuildGridZone()
    }
  }
  @Output() preparingData = new EventEmitter()
  @Output() dropValue = new EventEmitter()
  @Output() hoveringBox = new EventEmitter()
  @Output() componentInit = new EventEmitter()

  items: Array<ZoneBoxModel> = []
  target: CdkDropList
  targetIndex: number
  source: any
  sourceIndex: number

  backgroundWidth: number = 196
  zoneMapWidth: number = 7
  backgroundHeight: number
  zoneMapHeight: number

  selectedInspectionTypes: string[]
  _zoneData: ZoneLayoutModel

  MAX_ZONE_BOX_SIZE: number = 28
  MAX_ZONE_DOT_SIZE: number = 24

  zoneBoxHeight: number = this.MAX_ZONE_BOX_SIZE
  zoneBoxWidth: number = this.MAX_ZONE_BOX_SIZE
  zoneBoxActiveSize: number = this.MAX_ZONE_DOT_SIZE

  isDragging: boolean = false
  isHovering: boolean = false
  customTooltipClasses: string[] = ['multi-line-tooltip', 'custom-position-tooltip']

  constructor(
    private langDictionaryService: LanguageDictionaryHandlingService,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  /** ECT2-806: Currently the grid being used (behind the map view image) is based on what numbers have been entered within the edit modal for that config.
   *  We remove those input fields and make the grid size more fixed.
   *  Fixed width, dynamic rows based on image loaded. */
  get isAssetViewGridHidden(): boolean {
    return IS_GRID_VIEW_HIDDEN
  }

  // prepare for drag and drop feature
  drop(event: CdkDragDrop<any>) {
    if (event &&
      event.isPointerOverContainer &&
      event.container &&
      event.container.data &&
      event.previousContainer &&
      event.previousContainer.data) {

      const newValue = this.items[event.container.data.index]
      const oldValue = this.items[event.previousContainer.data.index]

      this.dropValue.emit({ newValue: newValue, oldValue: oldValue })
      this.items[event.previousContainer.data.index] = event.container.data.item
      this.items[event.container.data.index] = event.previousContainer.data.item
    }
  }

  onDragStart(zoneBox: ZoneBoxModel) {
    this.isDragging = zoneBox.active
  }

  onDragEnd() {
    this.isDragging = false
    if (!this.isHovering) {
      this.hoveringBox.emit(null)
    }
  }


  rebuildGridZone() {
    setTimeout(() => {
      if (this.svgBackground && this._zoneData && this.svgSource && this.selectedInspectionTypes) {

        this.items = []

        if (this.isAssetViewGridHidden) {
          this.backgroundHeight = this.svgBackground.backgroundHeight

          const calculatedZoneBoxHeight = Math.ceil(this.backgroundHeight / this.zoneMapHeight)
          this.zoneBoxHeight = Math.min(calculatedZoneBoxHeight, this.zoneBoxHeight)
          this.zoneBoxActiveSize = Math.min(this.zoneBoxHeight, this.zoneBoxActiveSize)

        } else {
          this.backgroundWidth = this.svgBackground.backgroundWidth
          this.backgroundHeight = this.svgBackground.backgroundHeight
          this.zoneBoxHeight = this.backgroundHeight / this.zoneMapHeight
          this.zoneBoxWidth = this.backgroundWidth / this.zoneMapWidth

          if (this.zoneBoxHeight > this.zoneBoxWidth) {
            this.zoneBoxActiveSize = this.zoneBoxWidth > 24 ? 24 : this.zoneBoxWidth
          } else {
            this.zoneBoxActiveSize = this.zoneBoxHeight > 24 ? 24 : this.zoneBoxHeight
          }
        }

        for (let i = 0; i < this.zoneMapHeight * this.zoneMapWidth; i++) {
          const positionY = Math.floor(i / this.zoneMapWidth)
          const positionX = i % this.zoneMapWidth
          this.items.push(
            {
              positionX: positionX,
              positionY: positionY,
              active: false,
              isDuplicate: false,
            } as ZoneBoxModel)
        }

        this._zoneData.configZones.forEach(zone => {
          const positionX = zone.assetViewLocation[GRID_VIEW_POSITION_INDEX.POSITION_X]
          const positionY = zone.assetViewLocation[GRID_VIEW_POSITION_INDEX.POSITION_Y]
          const gridIndex = positionY * this.zoneMapWidth + positionX
          const tagTypeId = this.langDictionaryService.convertLangKeyToString(zone.tagTypeLangKey, this.languageDictionary)
          const tagType = TAG_TYPE_OPTIONS.find(option => option.id === tagTypeId)
          const tagTypeName = this.translateService.instant(tagType ? tagType.displayName : 'TAG_TYPE_OPTIONS.ZONE').slice(0,1)
          const zoneName = `${this.langDictionaryService.convertLangKeyToString(zone.tagLangKey, this.languageDictionary)} (${tagTypeName}${zone.tagNumber})`

          if (positionX >= this.zoneMapWidth || positionY >= this.zoneMapHeight) {
            return
          }

          if (zone.zoneInspectionTypes && zone.zoneInspectionTypes.findIndex(x => this.selectedInspectionTypes.includes(x)) > -1) {
            if (this.items[gridIndex] && this.items[gridIndex].active) {
              this.items[gridIndex].isDuplicate = true
              this.items[gridIndex].name += `, ${tagTypeName}${zone.tagNumber}`
              this.items[gridIndex].configZoneName += `,\n${zoneName}`
            } else {
              this.items[gridIndex] = {
                positionX: positionX,
                positionY: positionY,
                active: true,
                name: `${tagTypeName}${zone.tagNumber}`,
                configZoneName: zoneName,
              } as ZoneBoxModel
            }
          }
        })

        this.preparingData.emit(false)
      }
    }, 200)
  }


  onMouseOut() {
    if (!this.isDragging) {
      this.hoveringBox.emit(null)
    }
    this.isHovering = false
  }

  onMouseEnter(boxItem: ZoneBoxModel) {
    if (!this.isDragging) {
      this.hoveringBox.emit(boxItem)
    }
    this.isHovering = true
  }

  handleAfterSVGLoaded(status: boolean) {
    this.rebuildGridZone()
    this.cdRef.detectChanges()
  }

  calculateZoneBoxWidth(zoneBoxItem: ZoneBoxModel): number {
    if (this.isAssetViewGridHidden) {
      return this.zoneBoxWidth
    }

    // Decrease the width of the zone box item if it's the last zone box item in row
    // to avoid the last zone box item being moved to the next row.
    return zoneBoxItem.positionX < this.zoneMapWidth - 1
      ? this.zoneBoxWidth
      : this.zoneBoxWidth - 1
  }
}
