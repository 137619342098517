<div class="form-library">
  <div class="blank-form-field-list"
    cdkDropList
    [cdkDropListSortingDisabled]="true"
    cdkDropListConnectedTo="inspection-type-detail-row-list">
    <ng-container *ngFor="let blankFormPiece of blankFormLibraryPieces$ | async; let isLastFormField = last; let i = index">
      <div class="blank-form-field"
        [ngClass]="{'last-form-field': isLastFormField}"
        cdkDrag
        (cdkDragEntered)="draggedFormField.emit($event.container.id === draggedDestination)"
        (cdkDragEnded)="draggedFormField.emit(false)"
        [cdkDragData]="{ dropType: formLibraryDropType.CUSTOM_FIELD, dropValue: blankFormPiece.piece, rawData: blankFormPiece, rawDataIndex: i }">
        <div class="drop-zone">
          <span>{{blankFormPiece.name}}</span>
        </div>
        <div class="custom-placeholder" *cdkDragPlaceholder [ngClass]="{'last-form-field': isLastFormField}">
          <div class="drop-zone">
            <span>{{blankFormPiece.name}}</span>
          </div>
      </div>
      </div>
    </ng-container>
  </div>
  <div class="premade-field-title">{{'PREMDADE_FIELD_TITLE' | translate}}</div>
  <div *ngIf="(formLibraryPieces$ | async).length > 0; then premadeFieldTemplate else noPremadeFieldTemplate"></div>
</div>

<ng-template #premadeFieldTemplate>
  <ng-container *appLoadingSpinner="shouldLoading$ | async; diameter: 100">
    <div class="premade-field-list"
      cdkDropList
      cdkDropListConnectedTo="inspection-type-detail-row-list">
      <ng-container *ngFor="let formPiece of formLibraryPieces$ | async; let isLastPremadeField = last">
        <div class="premade-field"
          [ngClass]="{'last-premade-field': isLastPremadeField}"
          cdkDrag
          (cdkDragEntered)="draggedFormField.emit($event.container.id === draggedDestination)"
          (cdkDragEnded)="draggedFormField.emit(false)"
          [cdkDragData]="{ dropType: formLibraryDropType.PREMADE_FIELD, dropValue: formPiece.piece }">
          <div class="drop-zone"
            fxLayout="row"
            fxLayoutAlign="space-between start">
            <span class="truncate-text">{{formPiece.piece.fieldNameLabelKey}}</span>
            <app-menu *ngIf="isAccessGranted$ | async" [menuOptions]="menuOptions" (clickOption)="handleOptionSelected($event, formPiece)"></app-menu>
          </div>
          <div class="custom-placeholder" *cdkDragPlaceholder [ngClass]="{'last-form-field': isLastPremadeField}">
            <div class="drop-zone">
              <span class="truncate-text">{{formPiece.piece.fieldNameLabelKey}}</span>
            </div>
        </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #noPremadeFieldTemplate>
  <div class="no-premade-field-data">{{'EMPTY_PREMADE_FIELD_DATA' | translate}}</div>
</ng-template>
