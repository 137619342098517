<!-- Asset configuration card -->
<app-card [containerClasses]="['data-configuration', 'root']"
  [contextMenuItems]="dataSource && dataSource.data && dataSource.data.length && !isErrorData ? getMenuOption(NODE_LEVELS.ALL_CONFIG_LEVEL) : []"
  (clickedItem)="handleSelectOption($event, null)">
  <ng-container card-title>{{'ASSET_CONFIGURATIONS_TITLE' | translate}}</ng-container>
  <ng-container card-body>
    <ng-container *ngIf="dataSource && dataSource.data && dataSource.data.length && !isErrorData; then treeTemplate"></ng-container>
    <ng-container *ngIf="isErrorData; then errorTemplate"></ng-container>
  </ng-container>
</app-card>
<!-- End of asset configuration card -->

<!-- Native upload file <input>, hidden by default -->
<input class="file-upload-dialog"
  #fileInput
  type="file"
  multiple
  (change)="chooseFile($event)"
  accept=".xml"/>
<!-- End of upload file <input> -->

<!-- Templates section -->
<ng-template #treeTemplate>
  <mat-tree class="tree-list thin-scrollbar"
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    id="configuration-tree"
    cdkDropListConnectedTo="template-library-tree"
    (cdkDropListDropped)="drop($event)">
    <mat-tree-node *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      cdkDrag [cdkDragData]="node"
      class="app-tree-box"
      [ngClass]="{'node-parent':node.level === 0, 'tree-box':true, 'active-node':isActiveNode(node), 'hovering-node':isHoveringAssetZoneMapBox(node), 'borderConfig-import': node.value.borderConfigImport && node.level === 0}">
      <button mat-icon-button disabled></button>
      <div class="node-content truncate-text"
        [attr.id]="node.id"
        matTooltip="{{node.name + (node | addZoneTag) + (node | addZoneInspectionTypes : zoneNameList)}}"
        [matTooltipDisabled]="tooltipDisabledStatus"
        [matTooltipShowDelay]="tooltipDelayTime"
        (mouseover)="tooltipDisabledStatus=isDisabledTooltip(node.id)"
        (click)="handleSelectNode(node)">
        <span [attr.id]="'name ' + node.id">{{node.name}}</span>
        <span [attr.id]="'zone-tag ' + node.id">{{node | addZoneTag}}</span>
        <span class="zone-inspection-type-content"
          [attr.id]="'zone-inspection-types ' + node.id">{{node | addZoneInspectionTypes : zoneNameList}}</span>
      </div>
      <app-menu [menuOptions]="getMenuOption(node.level, node)"
        (clickOption)="handleSelectOption($event, node)"></app-menu>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      cdkDrag [cdkDragData]="node"
      class="app-tree-box"
      [ngClass]="{'node-parent':node.level === 0, 'tree-box':true, 'active-node':isActiveNode(node), 'hovering-node':isHoveringAssetZoneMapBox(node), 'borderConfig-import': node.value.borderConfigImport && node.level === 0}">
      <button mat-icon-button
        matTreeNodeToggle
        [disableRipple]="true"
        (click)="expansionModel.toggle(node.id);setExpandedStatesToStore()"
        [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <div class="node-content truncate-text"
        [attr.id]="node.id"
        matTooltip="{{node.name + (node | addZoneTag) + (node | addZoneInspectionTypes : zoneNameList)}}"
        [matTooltipDisabled]="tooltipDisabledStatus"
        [matTooltipShowDelay]="tooltipDelayTime"
        (mouseover)="tooltipDisabledStatus=isDisabledTooltip(node.id)"
        (click)="handleSelectNode(node)">
        <span [attr.id]="'name ' + node.id">{{node.name}}</span>
        <span [attr.id]="'zone-tag ' + node.id">{{node | addZoneTag}}</span>
        <span class="zone-inspection-type-content"
          [attr.id]="'zone-inspection-types ' + node.id">{{node | addZoneInspectionTypes : zoneNameList}}</span>
      </div>
      <app-menu [menuOptions]="getMenuOption(node.level, node)"
        (clickOption)="handleSelectOption($event, node)"></app-menu>
    </mat-tree-node>
  </mat-tree>
</ng-template>

<ng-template #errorTemplate>
  <div class="default-error">
    {{'ERROR_ASSET_CONFIGURATION' | translate}}
  </div>
</ng-template>
<!-- End of template section -->
