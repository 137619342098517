import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { Store } from '@ngxs/store'

import { InspectionTypeFormData } from 'src/app/views/inspection-type/inspection-type.component'
import { DropToInspectionTypeDetail, UpdateInspectionFormData } from 'src/app/app.state'
import { NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { InspectionRowEditDialogData } from '../inspection-type-detail/inspection-type-detail.component'

export class PhotoFieldErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid
  }
}

export interface InspectionDetailPhotoField {
  fieldNameLabel: string
  hint: string
  required: boolean
}

export interface PhotoFieldForm {
  label: string
  hint: string
  required: boolean
}

@Component({
  selector: 'app-inspection-detail-row-photo-edit-dialog',
  templateUrl: './inspection-detail-row-photo-edit-dialog.component.html',
  styleUrls: ['./inspection-detail-row-photo-edit-dialog.component.scss'],
})
export class InspectionDetailRowPhotoEditDialogComponent {
  matcher = new PhotoFieldErrorStateMatcher()
  photoEditForm: FormGroup

  FORM_KEY_MAP = {
    LABEL: 'label',
    HINT: 'hint',
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InspectionRowEditDialogData,
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: MatDialogRef<InspectionDetailRowPhotoEditDialogComponent>,
  ) {
    if (this.data) {
      const formData = this.transformFormData(this.data.row)
      this.photoEditForm = this.formBuilder.group({
        label: new FormControl(formData.label, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(20),
          ],
        }),
        hint: new FormControl(formData.hint, {
          validators: [
            Validators.required,
            Validators.pattern(NAME_REGEX),
            Validators.maxLength(60),
          ],
        }),
        required: new FormControl(formData.required),
      })
    }
  }

  handleApply() {
    if (this.photoEditForm.invalid) {
      return
    }

    const formValue = this.photoEditForm.value
    const updateValue = {
      ...this.data.row,
      ...this.transformData(formValue),
      fieldNameLangKey: '',
      hintLangKey: '',
    }

    const action = this.data.isUpdate ? UpdateInspectionFormData : DropToInspectionTypeDetail

    this.store.dispatch(new action(this.data.inspectionTypeIndex, this.data.formDataIndex, updateValue))

    this.dialogRef.close()
  }

  transformFormData(val: InspectionTypeFormData): PhotoFieldForm {
    return {
      label: val.fieldNameLabel,
      hint: val.hint,
      required: !!val.required,
    }
  }

  transformData(val: PhotoFieldForm): InspectionDetailPhotoField {
    return {
      fieldNameLabel: val.label,
      hint: val.hint,
      required: !!val.required,
    }
  }

  get controlLabel(): AbstractControl {
    return this.getControlByName(this.FORM_KEY_MAP.LABEL)
  }

  get controlHint(): AbstractControl {
    return this.getControlByName(this.FORM_KEY_MAP.HINT)
  }

  private getControlByName(controlName: string): AbstractControl {
    return this.photoEditForm.get(controlName)
  }
}
