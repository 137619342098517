<div mat-dialog-title
  fxLayout='row'
  fxLayoutAlign='space-between start'>
  <div class="dialog-title">{{title}}</div>
  <mat-icon class="mat-icon-rtl-mirror icon-close"
    (click)="handleCancel()">close</mat-icon>
</div>
<mat-dialog-content class="dialog-content thin-scrollbar">
  <div class="inform-content">
    <pre>{{message}}</pre>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button
    appToastMessage
    appSupportCopy
    [dataCopy]="message"
    [toastMessage]="toastMessage"
    class="special-button">{{acceptText}}</button>
  <button mat-raised-button
    class="special-button"
    mat-dialog-close
    (click)="handleCancel()">{{cancelText}}</button>
</mat-dialog-actions>
