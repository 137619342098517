import {
  ApplicationRef,
  Directive,
  HostListener,
  Input,
  ViewContainerRef,
  Injector,
  ComponentFactoryResolver,
  AfterViewInit,
  ElementRef,
} from '@angular/core'
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal'

import { TooltipComponent } from '../components/tooltip.component'

/**
 * @description
 * Tooltip directive can be added in any HTML element to show a tooltip by
 * adding the `tooltip` attribute and passing the tooltip text as value.
 *
 * @example
 * ```html
 * <!-- Example with a button element -->
 * <button tooltip="Tooltip text">Button Text</button>
 * <!-- Example with a mat-icon component -->
 * <mat-icon tooltip="Tooltip text">info</mat-icon>
 * ```
 */
@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements AfterViewInit {
  @HostListener('mouseenter') onMouseEnter() {
    if (!this.templatePortal.isAttached) {
      this.tooltipPortalHost.attach(this.templatePortal)
    }
    this.updatePosition()
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.tooltipPortalHost.detach()
    this.removePosition()
  }

  @Input('tooltip') tooltipText: string

  private tooltipPortalHost: DomPortalOutlet
  private templatePortal: TemplatePortal<any>

  constructor(
    private element: ElementRef<HTMLElement>,
    private injector: Injector,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.updatePosition = this.updatePosition.bind(this)
  }

  ngAfterViewInit() {
    this.createContainerTemplate()
      ;['scroll', 'resize'].forEach((event) =>
        globalThis.addEventListener(event, this.updatePosition)
      )
  }

  private createContainerTemplate() {
    this.tooltipPortalHost = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    )

    const tooltipComponent = this.viewContainerRef.createComponent(TooltipComponent)
    tooltipComponent.changeDetectorRef.detectChanges()

    this.templatePortal = new TemplatePortal(
      tooltipComponent.instance.tooltip,
      this.viewContainerRef,
      {
        $implicit: this.tooltipText,
      }
    )
  }

  private updatePosition() {
    const { scrollY, scrollX } = globalThis
    const { top, left, width, height } =
      this.element.nativeElement.getBoundingClientRect()
    if (!this.templatePortal.isAttached) {
      Object.entries({
        '--tooltip-top': `${top + scrollY}px`,
        '--tooltip-left': `${left + scrollX}px`,
        '--tooltip-width': `${width}px`,
        '--tooltip-height': `${height}px`,
      }).forEach(([property, value]) => document.body.style.setProperty(property, value))
    }
  }

  private removePosition() {
    ;['--tooltip-top', '--tooltip-left', '--tooltip-width', '--tooltip-height'].forEach(
      (property) => document.body.style.removeProperty(property)
    )
  }
}
