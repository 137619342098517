<mat-menu #menu="matMenu"
  class="app-menu"
  xPosition="before">
  <button class="menu-option"
    mat-menu-item
    *ngFor="let menuOption of _menuOptions"
    [ngClass]="{'disabled': menuOption.disable}"
    [disableRipple]="menuOption.disable"
    [matTooltip]="menuOption.tooltipMessage || null"
    [matTooltipClass]="['custom-menu-option-tooltip']"
    (click)="!menuOption.disable && _onClickOption(menuOption.text)">
    <span [innerHTML]="menuOption.text | translate"></span>
  </button>
</mat-menu>
<i class="material-icons more_horiz"
  mat-button
  [matMenuTriggerFor]="menu">more_horiz</i>
