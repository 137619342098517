<ng-container>
  <div mat-dialog-title>
    <div class="dialog-title">{{addPieceTitle}}</div>
    <mat-icon class="mat-icon-rtl-mirror icon-close"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form class="add-piece-form" [formGroup]="addPieceForm">
      <mat-form-field appearance="outline"
        floatLabel="always"
        [style.width.%]="100">
        <mat-label>{{'NAME_LABEL' | translate}}</mat-label>
        <input matInput
          class="name-field"
          placeholder="{{'NAME_PLACEHOLDER' | translate}}"
          formControlName="name"
          [errorStateMatcher]="matcher"
          maxlength="100"/>
          <mat-error *ngIf="addPieceForm.controls.name.hasError('required')">
            {{ 'NAME_REQUIRED_ERROR_MSG' | translate }}
          </mat-error>
          <mat-error *ngIf="addPieceForm.controls.name.hasError('pattern')">
            {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
          </mat-error>
          <mat-error *ngIf="addPieceForm.controls.name.hasError('duplicatePieceName')">
            {{ 'NAME_DUPLICATED_ERROR_MSG' | translate }}
          </mat-error>
        <mat-icon *ngIf="!addPieceForm.valid"
          class="mat-icon-rtl-mirror error-icon"
          matSuffix>error_outline</mat-icon>
      </mat-form-field>
      <mat-slide-toggle *ngIf="!isPremadeField && false"
        class="include-all-sublayers-toggle"
        formControlName="isIncludedAllSublayers"
        [disabled]="!hasNodeChildren"
        [style.width.%]="100">Include all sublayers</mat-slide-toggle>
      <mat-slide-toggle class="admin-view-only-toggle"
        formControlName="isAdminViewOnly"
        [style.width.%]="100">{{'ADMIN_ONLY_CHECKBOX_NAME' | translate}}</mat-slide-toggle>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!addPieceForm.valid"
      (click)="handleApply()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</ng-container>
