import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ERROR_MESSAGES, NAME_REGEX } from 'src/app/constants/internationalized-constants-en'
import { TemplateLibraryPieceModel } from '../template-library/models/template-library.model'
import { CheckDuplicatePieceNameService, checkDuplicatePieceNameValidator } from '../template-library/services/check-duplicate-piece-name/check-duplicate-piece-name.service'

export class PieceNameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && control.invalid
  }
}

export class PieceEditDialogDataModel {
  editPiece: TemplateLibraryPieceModel
  title: string
}

@Component({
  selector: 'app-piece-edit-dialog',
  templateUrl: './piece-edit-dialog.component.html',
  styleUrls: ['./piece-edit-dialog.component.scss'],
})
export class PieceEditDialogComponent implements OnInit {
  NAME_REQUIRED_MESSAGE = ERROR_MESSAGES.NAME_REQUIRED
  INVALID_CHARACTERS_MESSAGE = ERROR_MESSAGES.INVALID_CHARACTERS
  DUPLICATE_PIECE_NAME_MESSAGE = ERROR_MESSAGES.DUPLICATE_PIECE_NAME

  editForm: FormGroup
  matcher = new PieceNameErrorStateMatcher()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PieceEditDialogDataModel,
    public dialogRef: MatDialogRef<PieceEditDialogComponent>,
    private pieceNameService: CheckDuplicatePieceNameService,
  ) {

    if (data) {
      this.editForm = new FormGroup({
        id: new FormControl(''),
        name: new FormControl('', [
          Validators.required,
          Validators.pattern(NAME_REGEX),
        ], checkDuplicatePieceNameValidator(this.pieceNameService, data.editPiece.name)),
        isAdmin: new FormControl(false),
      })
      this.editForm.patchValue({ ...data.editPiece, isAdmin: data.editPiece.admin })
    }
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close()
  }

  onAcceptBtnClicked() {
    if (this.editForm.invalid) {
      return
    }

    const [id, piece, isAdmin] = this.prepareEditData()
    this.dialogRef.close([id, piece, isAdmin])
  }

  private prepareEditData(): [string, TemplateLibraryPieceModel, boolean] {
    const editFields = ['piece', 'pieceType', 'name']
    const filterFields = (source, fields) =>
      fields.reduce((acc, curr) => ({ ...acc, [curr]: source[curr] }), {})
    const patchedPiece = {
      ...this.data.editPiece,
      ...this.editForm.value,
    }
    return [this.data.editPiece.id, filterFields(patchedPiece, editFields), this.editForm.value.isAdmin]
  }
}
