<ng-container [formGroup]="formGroup">
  <div
      fxLayout="column"
      fxLayoutAlign="space-between start">

    <div class="min-max-group"
      fxLayout="row"
      fxLayoutAlign="space-between start">
      <mat-form-field appearance="outline"
                      class="inline-field">
        <mat-label>{{'MIN_CHARS_LABEL' | translate}}</mat-label>
        <input matInput
                type="number"
                class="field-name"
                formControlName="minLength">
        <mat-error>
          <ng-container *ngTemplateOutlet="numberErrorMessageGroups; context: { $implicit: formGroup.controls.minLength, parentForm: formGroup }"></ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"
                      class="inline-field">
        <mat-label>{{'MAX_CHARS_LABEL' | translate}}</mat-label>
        <input matInput
                type="number"
                class="field-name"
                formControlName="maxLength">
        <mat-error>
          <ng-container *ngTemplateOutlet="numberErrorMessageGroups; context: { $implicit: formGroup.controls.maxLength, parentForm: formGroup }"></ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <mat-slide-toggle formControlName="required" (click)="handleRequireChanged()">{{'REQUIRED_FIELD_NAME' | translate}}</mat-slide-toggle>
    <mat-error *ngIf="formGroup.hasError('lengthMinMax')">{{ 'INVALID_MIN_MAX_NUMBER_ERROR_MSG' | translate }}</mat-error>
  </div>

</ng-container>

<ng-template #numberErrorMessageGroups let-numberControl let-parentForm="parentForm" >
  <mat-error *ngIf="numberControl.hasError('required')">
    {{'FIELD_REQUIRED_ERROR_MSG' | translate}}
  </mat-error>

  <mat-error *ngIf="numberControl.hasError('pattern')">
    {{'INVALID_POSITIVE_NUMBER_ERROR_MSG' | translate}}
  </mat-error>

  <mat-error *ngIf="numberControl.hasError('max') && !numberControl.hasError('pattern')">
    {{ commonErrorMessages.numberWithRequiredCheck(parentForm.value.required) | translate }}
  </mat-error>

  <mat-error *ngIf="numberControl.hasError('min') && !numberControl.hasError('pattern')">
    {{ commonErrorMessages.numberWithRequiredCheck(parentForm.value.required) | translate }}
  </mat-error>
</ng-template>
