import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core'
import { cloneDeep, isEqual } from 'lodash'

export class MenuOption {
  text: string
  disable?: boolean
  tooltipMessage?: string
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {
  @Input() menuOptions: MenuOption[] = []
  @Output() clickOption = new EventEmitter()
  _menuOptions: MenuOption[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuOptions && !isEqual(changes.menuOptions.currentValue, changes.menuOptions.previousValue)) {
      this._menuOptions = cloneDeep(changes.menuOptions.currentValue)
    }
  }

  constructor() { }

  _onClickOption(option: string) {
    this.clickOption.emit(option)
  }

}
