import { Injectable } from '@angular/core'
import { AbstractControl, AsyncValidatorFn } from '@angular/forms'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { toIgnoreCase } from 'src/app/utils/utils'

import { TemplateLibraryPieceModel } from '../../models/template-library.model'

export function checkDuplicatePieceNameValidator(service: CheckDuplicatePieceNameService, editPieceName?: string): AsyncValidatorFn {
  return (control: AbstractControl) => {
    const duplicationThreshold = editPieceName && editPieceName === control.value ? 1 : 0
    return service.isPieceNameExists(control.value, duplicationThreshold).pipe(
      map(isPieceNameDuplicated => isPieceNameDuplicated ? { duplicatePieceName: true } : null)
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class CheckDuplicatePieceNameService {

  private pieceNameHashMap: Record<string, string[]> = {}
  // This property indicates whether the piece name hash map is preloaded
  private isPreloaded: boolean = false

  constructor() { }

  preloadPieceNames(pieceList: TemplateLibraryPieceModel[], shouldReset = true) {
    if (shouldReset) {
      this.pieceNameHashMap = {}
    }
    for (const piece of pieceList) {
      this.addPieceName(piece.name, piece.id)
    }
    this.isPreloaded = true
  }

  isPieceNameExists(rawPieceName: string, duplicationThreshold: number = 0): Observable<boolean> {
    const pieceName: string = toIgnoreCase(rawPieceName)
    return of(!!(this.pieceNameHashMap[pieceName] && this.pieceNameHashMap[pieceName].length > duplicationThreshold))
  }

  addPieceName(rawPieceName: string, pieceId: string) {
    const pieceName: string = toIgnoreCase(rawPieceName)
    const pieceNameValue = this.pieceNameHashMap[pieceName]
    if (pieceNameValue) {
      pieceNameValue.push(pieceId)
    } else {
      this.pieceNameHashMap[pieceName] = [pieceId]
    }
  }

  removePieceName(rawPieceName: string, pieceId: string) {
    const pieceName: string = toIgnoreCase(rawPieceName)
    const pieceNameValue = this.pieceNameHashMap[pieceName]
    if (pieceNameValue.length <= 1) {
      delete this.pieceNameHashMap[pieceName]
    } else {
      this.pieceNameHashMap[pieceName] = pieceNameValue.filter(id => id !== pieceId)
    }
  }

  isPieceNamesPreloaded(): boolean {
    return this.isPreloaded
  }
}
