<div class="dialog-wrapper">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start">
    <div class="dialog-title">
      <span>{{ 'CAMERA_PHOTOS' | translate }}</span>
    </div>
    <mat-icon class="mat-icon-rtl-mirror icon-close" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form [formGroup]="photoEditForm">
      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-label>{{ 'LABEL_LABEL' | translate }}</mat-label>
        <input
          matInput
          class="label-field"
          placeholder="{{ 'LABEL_PLACEHOLDER' | translate }}"
          formControlName="label"
          maxlength="20"
          [errorStateMatcher]="matcher"/>
        <mat-hint>{{ 'DESCRIPTION_MAXIMUM_CHARS' | translate: { number: 20 } }}</mat-hint>
        <mat-error>
          <ng-template *ngTemplateOutlet="nameErrorMessages; context: {$implicit: controlLabel}"></ng-template>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-label>{{ 'INSTRUCTIONAL_TEXT' | translate }}</mat-label>
        <input
          matInput
          class="helper-text-field"
          placeholder="{{ 'INSTRUCTIONAL_TEXT' | translate }}"
          formControlName="hint"
          maxlength="60"
          [errorStateMatcher]="matcher"/>
        <mat-hint>{{ 'DESCRIPTION_MAXIMUM_CHARS' | translate: { number: 60 } }}</mat-hint>
        <mat-error>
          <ng-template *ngTemplateOutlet="nameErrorMessages; context: {$implicit: controlHint}"></ng-template>
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle
        class="required-toggle"
        formControlName="required"
        [style.width.%]="100"
        >{{ 'REQUIRED_FIELD_NAME' | translate }}</mat-slide-toggle>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button class="primary-button" mat-dialog-close>
      {{ 'CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="special-button"
      [disabled]="!photoEditForm.valid"
      (click)="handleApply()"
    >
      {{ 'APPLY' | translate }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #nameErrorMessages let-control>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('pattern')">
    {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
  </mat-error>
</ng-template>
