import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Select } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

import { AppState } from 'src/app/app.state'
import { CHIP_TYPE, POSITIVE_NUMBER_REGEX, internationalizedValues, TAG_TYPE_OPTION_VALUE, TAG_TYPE_OPTIONS } from 'src/app/constants/internationalized-constants-en'
import { EditDialogDataModel, OptionModel } from 'src/app/views/companies/model'
import { BaseEditDialogComponent } from '../base-edit-dialog/base-edit-dialog.component'
import { ChipsComponent } from '../chips/chips.component'
import { FormGeneratorService, FormType } from '../tree/services/form-generator/form-generator.service'

@Component({
  selector: 'app-zone-level-edit-dialog',
  templateUrl: './zone-level-edit-dialog.component.html',
  styleUrls: [
    '../base-edit-dialog/base-edit-dialog.component.scss',
    './zone-level-edit-dialog.component.scss'
  ]
})
export class ZoneLevelEditDialogComponent extends BaseEditDialogComponent {

  numberPattern = POSITIVE_NUMBER_REGEX
  minAssetViewLocation = 2
  maxAssetViewLocation = 2
  maxLengthNumber = 3
  inspectionTypeOptions = []
  assetViewGrid: number[]
  CHIP_TYPE = CHIP_TYPE
  INVALID_ASSET_VIEW_LOCATION_MESSAGE: string

  tagTypeOption: OptionModel[]

  @Select(AppState.getInspectionTypeNames) inspectionTypesOptions$: Observable<string[]>

  formType: FormType = 'zone'
  customDataKey = ['assetViewGrid', 'assetViewId']
  isHiddenAssetViewLocation: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditDialogDataModel,
    public dialogRef: MatDialogRef<ZoneLevelEditDialogComponent>,
    formGenerator: FormGeneratorService,
    public translateService: TranslateService,
  ) {
    super(data, dialogRef, formGenerator)
    this.title = internationalizedValues.EDIT_ZONE_TITLE
    if (this.data.assetViewGrid) {
      this.assetViewGrid = this.data.assetViewGrid
    }
    this.INVALID_ASSET_VIEW_LOCATION_MESSAGE = this.translateService.instant('INVALID_ASSET_VIEW_LOCATION_ERROR_MSG', { assetViewGrid: this.assetViewGrid })

    this.subscriptions.push(
      this.inspectionTypesOptions$.subscribe(inspectionTypeOptions => {
        if (!inspectionTypeOptions) {
          return
        }
        this.inspectionTypeOptions = inspectionTypeOptions
      })
    )

    this.tagTypeOption = TAG_TYPE_OPTIONS.map(option => ({ ...option, displayName: this.translateService.instant(option.displayName) }))

    if (this.data &&
      this.data.node &&
      this.data.node.value &&
      this.data.node.value.tagType) {

      const isValidTagType = this.tagTypeOption.find(option => option.id === this.data.node.value.tagType)
      if (!isValidTagType) {
        this.data.node.value.tagType = TAG_TYPE_OPTION_VALUE.ZONE
      }
    } else {
      this.data.node.value.tagType = TAG_TYPE_OPTION_VALUE.ZONE
    }
  }

  handlePostInit() {
    if (!this.inputForm.value.assetViewLocation) {
      this.inputForm.controls.assetViewLocation.setValue([])
    }
    this.isHiddenAssetViewLocation = this.inputForm.controls.assetViewLocation.invalid
  }

  handleChipAssetViewLocationStatusChange(status: boolean, chips: ChipsComponent) {
    // Handle to set an error status of Asset View Location when input changes
    if (!status) {
      // Set an invalid input error when Asset View Location isn't in Asset View Grid
      this.setErrorControl('assetViewLocation', 'invalidInput')
    } else if (!this.inputForm.controls.assetViewLocation.value.length && !chips.chipInputValue) {
      // Set a required error when Asset View Location's length is 0 and input value is empty
      this.setErrorControl('assetViewLocation', 'required')
    } else if (
      !chips.chipInputValue &&
      this.inputForm.controls.assetViewLocation.value.length < this.maxAssetViewLocation) {
      // Set an invalid length error when Asset View Location's length is less than 2 and input value is empty
      this.setErrorControl('assetViewLocation', 'invalidAssetViewLocationLength')
    } else if (!chips.chipInputValue &&
      this.inputForm.controls.assetViewLocation.value.length === this.assetViewGrid.length &&
      !this.assetViewGrid.every((value, index) => this.inputForm.controls.assetViewLocation.value[index] < value)) {
      // Set an invalid Asset View Location error when Asset View Location's length is 2 and its value isn't in Asset View Grid
      this.setErrorControl('assetViewLocation', 'invalidAssetViewLocation')
    } else {
      this.inputForm.controls.assetViewLocation.setErrors(null)
    }
  }

  setErrorControl(field: string, errorName: string) {
    this.inputForm.controls[field].setErrors({ [errorName]: true })
  }

  handleChangeValue(valueChange: string[] | number[], field: string) {
    if (this.inputForm.controls[field]) {
      this.inputForm.controls[field].setValue(valueChange)
    }
  }
}
