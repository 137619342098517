import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { Select, Store } from '@ngxs/store'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AppState, SetErrorType } from 'src/app/app.state'
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  @Select(AppState.getErrorType) isHttpError$: Observable<boolean>

  // Store RegExp with URLs to accept it's pass although API called was unsuccessful
  ignorePatterns: RegExp[] = [
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/core\/(v1beta2|v1)\/userprofiles\?(\S+)?embed=roles(\S+)?/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/evir\/(v1beta2|v1)\/asset-views/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/evir\/(v1beta2|v1)\/lang-dict\?companyId=\S+/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/ib-api\/(v1beta2|v1)\/configs/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/ib-api\/(v1beta2|v1)\/translations\/\S+/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/ib-api\/(v1beta2|v1)\/tl-pieces/,
    /https:\/\/api(-[a-z]+)?\.zonarsystems\.net\/core\/(v1beta2|v1)\/companies\/\S+\/legacy-account-codes/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/cvi-api\/(v1beta2|v1)\/asset-views/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/cvi-api\/(v1beta2|v1)\/lang-dict\?companyId=\S+/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/ib-api\/(v1beta2|v1)\/configs/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/ib-api\/(v1beta2|v1)\/translations\/\S+/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/ib-api\/(v1beta2|v1)\/tl-pieces/,
    /https:\/\/api\.[a-z]+\.continental-mobility-services\.com\/core\/(v1beta2|v1)\/companies\/\S+\/legacy-account-codes/,
  ]

  // 'https://evir-api.dev.zonarsystems.net/api/v1'
  isHttpError: boolean

  constructor(private errorHandlerService: ErrorHandlerService, private store: Store) {
    this.isHttpError$.subscribe(status => {
      this.isHttpError = status
    })
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.ignorePatterns.every(pattern => !pattern.test(request.urlWithParams))) {
      return next.handle(request).pipe(
        catchError(error => {
          // Only handle error when this error is HttpErrorResponse and no server error is catched before
          if (error instanceof HttpErrorResponse && !this.isHttpError) {
            this.store.dispatch(new SetErrorType(true))
            this.errorHandlerService.handleError(error)
          }
          return throwError(error)
        })
      )
    }

    return next.handle(request)
  }
}
