import { Component } from '@angular/core'

import { INSPECTION_TYPE_CATEGORY_LIST } from 'src/app/constants/internationalized-constants-en'

@Component({
  selector: 'app-inspection-type-description-dialog',
  templateUrl: './inspection-type-description-dialog.component.html',
  styleUrls: ['./inspection-type-description-dialog.component.scss'],
})
export class InspectionTypeDescriptionDialogComponent {

  inspectionTypeDescriptions = INSPECTION_TYPE_CATEGORY_LIST

  constructor() { }
}
