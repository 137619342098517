<div class="dialog-wrapper">
  <div mat-dialog-title>
    <div class="dialog-title">{{ title }}</div>
    <mat-icon class="close-icon"
      mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content class="dialog-content">
    <form [formGroup]="editForm">
      <mat-form-field appearance="outline"
        [style.width.%]="100">
        <mat-label>{{'LABEL_LABEL' | translate}}</mat-label>
        <input matInput
          formControlName="label"
          [errorStateMatcher]="matcher"
          maxlength="32"/>
        <mat-error *ngIf="editForm.controls.label.hasError('required')">
          {{ 'FIELD_REQUIRED_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="editForm.controls.label.hasError('pattern')">
          {{ 'INVALID_CHARS_ERROR_MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="editForm.controls.label.hasError('duplicateInspectionTypeName')">
          {{ 'NAME_DUPLICATED_ERROR_MSG' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="primary-button"
      mat-dialog-close>{{'CANCEL' | translate}}</button>
    <button mat-button
      class="special-button"
      [disabled]="!editForm.valid"
      (click)="handleApply()">{{'APPLY' | translate}}</button>
  </mat-dialog-actions>
</div>
