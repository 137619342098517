<!-- Template library card -->
<app-card [containerClasses]="['template-library-container']"
  [contextMenuItems]="sortOptions"
  (clickedItem)="updatePieceSortOption($event)">
  <ng-container card-title>{{'TEMPLATE_LIBRARY' | translate}}</ng-container>
  <ng-container card-body>
    <ng-container *ngTemplateOutlet="templateLibraryTemplate"></ng-container>
  </ng-container>
</app-card>
<!-- End of Template library card -->

<!-- Templates section -->
<ng-template #templateLibraryTemplate>
  <mat-form-field *ngIf="false" class="template-type-field"
    appearance="outline"
    floatLabel="always"
    [style.width.px]="195">
    <mat-label>Template Type</mat-label>
    <mat-select focused="false"
      [value]="filterPieceType$ | async"
      (valueChange)="updateFilterPieceType($event);clearSearch()">
      <mat-option *ngFor="let option of templateTypeOptions"
        [value]="option">{{option | capitalizeFirstLetter}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="search-field"
    appearance="outline"
    [style.width.%]="100">
    <input matInput
      type="text"
      placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
      [formControl]="searchControl">
    <mat-icon matPrefix class="mat-icon-rtl-mirror search-icon">search</mat-icon>
    <mat-icon *ngIf="searchControl.value.length > 0"
      matSuffix
      class="mat-icon-rtl-mirror close-icon"
      (click)="clearSearch()">close</mat-icon>
  </mat-form-field>
  <ng-container *ngIf="(templateLibraryPieces$ | async)?.length > 0 || (blankPiece$ | async).length > 0; then piecesTemplate else errorTemplate"></ng-container>
</ng-template>

<ng-template #piecesTemplate>
  <ng-container *appLoadingSpinner="shouldLoading$ | async; diameter: 100">
    <app-template-library-tree [templateLibraryData]="(blankPiece$ | async).concat((templateLibraryPieces$ | async))"
      [selectedTemplateType]="filterPieceType$ | async"
      [languageDictionary]="languageDictionary$ | async"
      [isMenuHidden]="!(isAccessGranted$ | async)"
      (shouldReloadPieces)="reloadTemplateLibraryPieces()"></app-template-library-tree>
  </ng-container>
</ng-template>

<ng-template #errorTemplate>
  <div class="template-library-error">{{templateLibraryErrorMessage}}</div>
</ng-template>
<!-- End of templates section -->
