import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ErrorPageComponent } from './components/error-page/error-page.component'
import { DefaultLayoutComponent } from './views/default-layout/default-layout.component'
import { DEFAULT_ROUTES } from './views/default-layout/routes/default-layout.routes'
import { NotFoundComponent } from './views/not-found/not-found.component'
import { PermissionsGuard } from '@zonar-ui/auth'
import { AuthGuard } from '@auth0/auth0-angular'

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: DEFAULT_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: 'server-error',
    component: ErrorPageComponent,
    canActivate: [PermissionsGuard],
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
