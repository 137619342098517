import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Select, Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, Observable, Subject } from 'rxjs'
import { filter, map, takeUntil, tap } from 'rxjs/operators'

import { AddInspectionType, AppState, DeleteInspectionDetail, SelectInspectionType, CloneBelowInspectionTypes, PasteInspectionTypeDetailRow, CopyInspectionType, PasteInspectionType, CopiedInspectionTypeModel } from 'src/app/app.state'
import { AddInspectionTypeDialogComponent } from 'src/app/components/add-inspection-type-dialog/add-inspection-type-dialog.component'
import { DialogComponent } from 'src/app/components/dialog/dialog.component'
import { MENU_INSPECTION_TYPE_ITEM } from 'src/app/components/inspection-type-card/inspection-type-card.component'
import { InspectionTypeEditDialogComponent } from 'src/app/components/inspection-type-edit-dialog/inspection-type-edit-dialog.component'
import { InspectionTypeLabelEditDialogComponent } from 'src/app/components/inspection-type-label-edit-dialog/inspection-type-label-edit-dialog.component'
import { MenuOption } from 'src/app/components/menu/menu.component'
import { NotificationSnackbarService } from 'src/app/components/notification-snackbar/services/notification-snackbar.service'
import { InspectionDetailPieceModel, TemplateLibraryPieceModel } from 'src/app/components/template-library/models/template-library.model'
import {
  EvirLanguageDictionary,
  FormDataModel,
  InspectionDetailModel,
  ZoneLayoutModel,
} from 'src/app/components/tree/models/tree.model'
import { DataConfigurationHandlingService } from 'src/app/components/tree/services/data-configuration-handling/data-configuration-handling.service'
import { LanguageDictionaryHandlingService } from 'src/app/components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import {
  MENU_OPTION_KEY,
  NOTIFICATION_TYPES,
  INSPECTION_TYPE_MODAL_TYPE,
  Constants,
  i18nParams,
} from 'src/app/constants/internationalized-constants-en'

import { equalsIgnoreCase, isValidArray } from 'src/app/utils/utils'

export interface InspectionTypeFormData extends FormDataModel {
  hint: string
  fieldNameLabel: string
  select?: string[]
}

export interface InspectionType {
  id: string
  name: string
  description: string
  formData?: InspectionTypeFormData[]
  enforceOrder: boolean
  legallyMandated: boolean
  prepopulateDefects: boolean
  zoneless: boolean
  associateDriver: boolean
  category: string
}

/**
 * These inspections were under regulatory compliance of Department of Transportation (DOT) or company policy.
 */
const inspectionTypeNameUnderDOT: string[] = [
  'Pre-Trip',
  'Post-Trip',
]

const isDOTInspectionType = (inspectionDetail: InspectionType) => equalsIgnoreCase(inspectionTypeNameUnderDOT, inspectionDetail.name)

const askForModifyInspectionTypeConfirm = (dialog: MatDialog, translateService: TranslateService): Promise<boolean> => new Promise((resolve, _) => {
  dialog.open(DialogComponent, {
    data: {
      onClose: () => { resolve(false) },
      onAccept: () => { resolve(true) },
      cancelText: translateService.instant('CANCEL'),
      acceptText: translateService.instant('OK'),
      title: translateService.instant('WARNING_TITLE'),
      message: translateService.instant('MODIFYING_DOT_INSPECTION_WARNING_MSG'),
    },
    panelClass: 'edit-inspection-type-confirm-dialog',
  })
})

// Creating this const for testing purposes
export const unmodified: any = {}

unmodified.isInspectionValueUnmodified = async (inspectionDetail: InspectionType, dialog: MatDialog, translateService: TranslateService): Promise<boolean> =>
  Promise.resolve(isDOTInspectionType(inspectionDetail) && !(await askForModifyInspectionTypeConfirm(dialog, translateService)))

@Component({
  selector: 'app-inspection-type',
  templateUrl: './inspection-type.component.html',
  styleUrls: ['./inspection-type.component.scss'],
})
export class InspectionTypeComponent implements OnInit, OnDestroy {
  @Select(AppState.getInspectionDetails) inspectionDetails$: Observable<
    InspectionDetailModel[]
  >
  @Select(AppState.getLanguageDictionary) dictionary$: Observable<EvirLanguageDictionary>
  @Select(AppState.getSelectedInspectionTypeIndex) selectedInspectionTypeIndex$: Observable<number>
  @Select(AppState.getDataConfiguration) dataConfiguration$: Observable<ZoneLayoutModel[]>
  @Select(AppState.getCopiedInspectionTypeDetailRow) copiedInspectionDetailRow$: Observable<InspectionTypeFormData>
  @Select(AppState.getCopiedInspectionType) copiedInspectionType$: Observable<CopiedInspectionTypeModel>
  @Select(AppState.getInspectionTypeNames) inspectionTypeNames$: Observable<string[]>
  @Select(AppState.getAccessStatus) isAccessGranted$: Observable<boolean>
  @Select(AppState.getMaxImageCount) maxImageCount$: Observable<number>

  inspectionTypeList$: Observable<InspectionType[]>
  currentInspectionTypeIndex = 0
  seletectedInspectionType: InspectionType
  menuInspectionType: MenuOption[] = [
    { text: MENU_OPTION_KEY.CREATE_NEW },
    { text: MENU_OPTION_KEY.ADD_FROM_LIBRARY },
  ]
  menuInspectionTypeDetail: MenuOption[] = [
    { text: MENU_OPTION_KEY.PASTE }
  ]
  copiedInspectionType: CopiedInspectionTypeModel
  inspectionTypeNames: string[]
  pasteOption: MenuOption[] = [
    { text: MENU_OPTION_KEY.PASTE }
  ]
  isDraggingFormLibraryField: boolean

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private langDictionaryService: LanguageDictionaryHandlingService,
    private store: Store,
    private dataConfigurationHandlingService: DataConfigurationHandlingService,
    private snackbarService: NotificationSnackbarService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.inspectionTypeList$ = combineLatest([
      this.inspectionDetails$,
      this.dictionary$,
      this.selectedInspectionTypeIndex$,
      this.dataConfiguration$
    ]).pipe(
      map(([inspectionDetails, dictionary, selectedIndex, dataConfiguration]) => {
        this.currentInspectionTypeIndex = selectedIndex
        return this.dereferenceInspectionDetails(inspectionDetails, dictionary, dataConfiguration)
      }),
      tap(inspectionTypeList => {
        this.seletectedInspectionType = inspectionTypeList[this.currentInspectionTypeIndex]
      })
    )

    this.copiedInspectionType$.pipe(takeUntil(this.destroy$))
      .subscribe(copiedInspectionType => this.copiedInspectionType = copiedInspectionType)

    this.inspectionTypeNames$.pipe(takeUntil(this.destroy$))
      .subscribe(inspectionTypeNames => this.inspectionTypeNames = inspectionTypeNames)
  }

  selectInspectionType(index: number) {
    if (this.currentInspectionTypeIndex === index) {
      return
    }

    this.store.dispatch(new SelectInspectionType(index))
  }

  private dereferenceInspectionDetails(
    inspectionDetails: InspectionDetailModel[],
    dictionary: EvirLanguageDictionary,
    dataConfiguration: ZoneLayoutModel[]
  ): InspectionType[] {
    if (!Array.isArray(inspectionDetails)) {
      return []
    }

    const convertKeyToName = (key) =>
      this.langDictionaryService.convertLangKeyToString(key, dictionary)

    return inspectionDetails.map((inspection) => ({
      id: inspection.inspectionDetailLangKey,
      name: convertKeyToName(inspection.inspectionDetailLangKey),
      description: convertKeyToName(inspection.inspectionDescriptionLangKey),
      formData: (inspection.formData || []).map(data => {
        const selectField = isValidArray(data.selectLangKeys) ? { select: data.selectLangKeys.map(convertKeyToName) } : {}
        const formDataItem = {
          ...data,
          hint: convertKeyToName(data.hintLangKey),
          fieldNameLabel: convertKeyToName(data.fieldNameLangKey),
          ...selectField,
        }
        return formDataItem
      }),
      enforceOrder: inspection.enforceOrder,
      legallyMandated: inspection.legallyMandated,
      prepopulateDefects: inspection.prepopulateDefects,
      category: inspection.inspectionType,
      zoneless: inspection.zoneless,
      associateDriver: inspection.associateDriver,
      referenced: this.dataConfigurationHandlingService.checkInspectionTypeReferenced(inspection, dataConfiguration, dictionary)
    }))
  }

  async handleClickContextMenu(menuItem: string, inspectionTypeIndex: number, inspectionDetail: any) {
    switch (menuItem) {

      case MENU_INSPECTION_TYPE_ITEM.Delete.text:
        if (await unmodified.isInspectionValueUnmodified(inspectionDetail, this.dialog, this.translateService)) {
          return
        }

        this.openDeleteConfirmDialog(inspectionTypeIndex, inspectionDetail)
        break

      case MENU_INSPECTION_TYPE_ITEM['Clone below'].text:
        const newLabelName = await this.askForNewInspectionName()
        this.store.dispatch(new CloneBelowInspectionTypes(inspectionTypeIndex, newLabelName))
        break

      case MENU_INSPECTION_TYPE_ITEM.Edit.text:
        if (await unmodified.isInspectionValueUnmodified(inspectionDetail, this.dialog, this.translateService)) {
          return
        }

        this.openInspectionTypeEditDialog(INSPECTION_TYPE_MODAL_TYPE.EDIT, inspectionDetail, inspectionTypeIndex)
        break

      case MENU_INSPECTION_TYPE_ITEM.Copy.text:
        this.store.dispatch(new CopyInspectionType(inspectionTypeIndex, inspectionDetail))
        break

      default: {
        break
      }
    }
  }

  openDeleteConfirmDialog(inspectionTypeIndex: number, inspectionDetail: any) {
    this.dialog.open(DialogComponent, {
      data: {
        onAccept: () => {
          this.deleteInspectionType(inspectionTypeIndex, inspectionDetail)
        },
        cancelText: this.translateService.instant('CANCEL'),
        acceptText: this.translateService.instant('YES'),
        title: this.translateService.instant('INSPECTION_DELETE_CONFIRM_TITLE', { name: inspectionDetail.name })
      }
    })
  }

  deleteInspectionType(inspectionTypeIndex: number, inspectionDetail: any) {
    if (isValidArray(inspectionDetail.referenced)) {
      const message = this.translateService.instant('INSPECTION_DELETE_FAIL_MSG', i18nParams.DELETE_INSPECTION_ERROR(inspectionDetail.referenced, this.translateService.currentLang))
      this.snackbarService.openNotificationSnackbar(message, NOTIFICATION_TYPES.FAILED)
    } else {
      this.store.dispatch(new DeleteInspectionDetail(inspectionTypeIndex, inspectionDetail))
    }
  }

  handleClickInspectionTypeColumnContextMenu(menuItem: string) {
    switch (menuItem) {
      case MENU_OPTION_KEY.ADD_FROM_LIBRARY:
        this.openAddInspectionTypeDialog()
        break
      case MENU_OPTION_KEY.CREATE_NEW:
        const inspectionType = {
          id: '',
          name: '',
          description: '',
          formData: [],
          enforceOrder: false,
          legallyMandated: false,
          prepopulateDefects: true,
          zoneless: false,
          associateDriver: true,
          category: ''
        }
        this.openInspectionTypeEditDialog(INSPECTION_TYPE_MODAL_TYPE.CREATE, inspectionType)
        break

      case MENU_OPTION_KEY.PASTE:
        const name = this.copiedInspectionType.inspectionTypeName
        if (equalsIgnoreCase(this.inspectionTypeNames, name)) {
          this.openInspectionTypeLabelEditDialog()
        } else {
          this.store.dispatch(new PasteInspectionType())
        }
        break

      default:
        break
    }
  }

  openAddInspectionTypeDialog() {
    this.dialog.open(AddInspectionTypeDialogComponent).afterClosed()
      .pipe(filter(data => !!data))
      .subscribe((inspectionTypePiece: TemplateLibraryPieceModel) => this.addInspectionType(inspectionTypePiece.piece as InspectionDetailPieceModel))
  }

  addInspectionType(rawInspectionType: InspectionDetailPieceModel) {
    this.store.dispatch(new AddInspectionType(rawInspectionType)).subscribe(
      () => this.snackbarService.openNotificationSnackbar(
        this.translateService.instant('ADD_INSPECTION_TYPE_SUCCESS_MSG'),
        NOTIFICATION_TYPES.SUCCESS,
        null,
        Constants.snackbar.duration
      )
    )
  }

  handleClickInspectionDetailColumnContextMenu(menuItem: string) {
    if (menuItem === MENU_OPTION_KEY.PASTE) {
      this.store.dispatch(new PasteInspectionTypeDetailRow())
    }
  }

  openInspectionTypeEditDialog(modalType: INSPECTION_TYPE_MODAL_TYPE, inspectionType: InspectionType, inspectionIndex?: number) {
    this.dialog.open(InspectionTypeEditDialogComponent, {
      data: {
        inspectionIndex: inspectionIndex,
        inspectionType: inspectionType,
        modalType: modalType
      }
    })
  }

  private openInspectionTypeLabelEditDialog() {
    this.dialog.open(InspectionTypeLabelEditDialogComponent, {
      data: {
        inspectionTypeNames: this.inspectionTypeNames,
        inspectionTypeName: this.copiedInspectionType.inspectionTypeName,
        title: this.translateService.instant('EDIT_INSPECTIONS_LABEL'),
      }
    }).afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(result => this.store.dispatch(new PasteInspectionType(result.label)))
  }

  private askForNewInspectionName(): Promise<string> {
    return new Promise((resolve, _) => {
      this.dialog.open(InspectionTypeLabelEditDialogComponent, {
        data: {
          inspectionTypeNames: this.inspectionTypeNames,
          title: this.translateService.instant('NEW_CLONE_NAME_TITLE'),
        }
      }).afterClosed()
        .pipe(filter(res => !!res))
        .subscribe(result => resolve(result.label))
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
