import { Injectable } from '@angular/core'
import { round } from 'lodash'
import { DEFAULT_WIDTH_SVG } from 'src/app/constants/internationalized-constants-en'

@Injectable({
  providedIn: 'root'
})
export class SvgHandlingService {
  stringPattern = '<\s*svg[^>]*>(.*?)<\s*/\s*svg>'

  constructor() { }

  isSvgFile(file: string) {
    const regex = new RegExp(this.stringPattern, 's')
    return file.match(regex)
  }

  getFileName(fullFileName: string) {
    const fileExtensionStartIndex = fullFileName.lastIndexOf('.')
    return fileExtensionStartIndex ? fullFileName.slice(0, fileExtensionStartIndex) : fullFileName
  }

  getGridSizeFromSVGsource(svgSource: string): Promise<number[]> {
    return new Promise((resolve, reject) => {
      this.getSizeFromSVGsource(svgSource, (height, width) => {
        /**
         * Fixed 7 columns
         * Dynamic rows based on the loading image height. Fixed size of each cell (28px, 28px)
         */
        resolve([7, Math.ceil(height / 28)])
      })
    })
  }

  getSizeFromSVGsource(svgSource: string, callbackFn: (height: number, width: number) => void) {
    const id = 'svg-hidden'
    const el = document.getElementById(id)
    const assetZoneMapBlockEl = document.getElementById('asset-zone-map-block')

    if (!el) {
      console.warn('Cannot get svg-hidden-wrapper element')
      if (typeof callbackFn === 'function') {
        /** 
         * Because we base on 'svg-hidden-wrapper' element to caculate SVG size.
         * In case cann't get that element, then we set default size (196, 196) to pass validation.
         * The actual size will be caculated and update to store after SVG complete loading on Asset Map View column.
         */
        callbackFn(196, 196)
      }
      return
    }

    const doc = new DOMParser().parseFromString(
      svgSource,
      'application/xml'
    )
    const element = el.appendChild(el.ownerDocument.importNode(doc.documentElement, true))
    const originalHeight = el.offsetHeight
    const originalWidth = el.offsetWidth

    const svgElement = el.firstElementChild as any
    // 100px is padding and margin width and 14px is scroll width
    const maxWidth = assetZoneMapBlockEl ? assetZoneMapBlockEl.offsetWidth - 40 - 14 : DEFAULT_WIDTH_SVG
    let ratio = 1

    if (svgElement && maxWidth < originalWidth) {
      ratio = round(originalWidth / maxWidth, 4)
      svgElement.style.width = maxWidth + 'px'
      svgElement.style.height = originalHeight / ratio + 'px'
    } else if (svgElement && maxWidth > originalWidth) {
      ratio = round(maxWidth / originalWidth, 4)
      svgElement.style.width = maxWidth + 'px'
      svgElement.style.height = originalHeight * ratio + 'px'
    }

    setTimeout(() => {
      const height = el.offsetHeight, width = el.offsetWidth
      document.querySelector(`#${id}`).removeChild(element)
      if (typeof callbackFn === 'function') {
        callbackFn(height, width)
      }
    }, 0)
  }
}
