export class EnableUndoFeature {
  static readonly type = '[App] Enable undo feature'
  constructor() { }
}
export class DisableUndoFeature {
  static readonly type = '[App] Disable undo feature'
  constructor() { }
}
export class UndoConfigurationChange {
  static readonly type = '[App] Undo configuration change'
  constructor() { }
}
export class DisableUndoConfigurationChange {
  static readonly type = '[App] Disable Undo configuration change'
  constructor() { }
}
