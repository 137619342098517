<div class="dialog-wrapper inspection-type-description-dialog">
  <div mat-dialog-title>
    <div class="dialog-title"> {{ 'DESCRIPTIONS' | translate }} </div>
  </div>
  <mat-dialog-content>
    <div class="dialog-content">
      <ul>
        <li *ngFor="let item of inspectionTypeDescriptions" >
          <span>{{ item.name | translate }}: </span>{{ item.description | translate }}
        </li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
      class="special-button"
      mat-dialog-close
      >{{ 'OK' | translate }}</button>
  </mat-dialog-actions>
</div>
