import { Injectable } from '@angular/core'
import { unescape } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'

import { LANGUAGE_CODE_BY_LANGUAGE_ID, LANGUAGE_CONFIG_TYPES, LANGUAGE_IDS } from 'src/app/constants/internationalized-constants-en'
import { UndefinedOrNullLangValueError } from 'src/app/shared/error'
import { convertLangKeyToString, trimAll } from 'src/app/utils/utils'
import { EvirLanguageDictionary, TranslationObject, TranslationObjectValue } from '../../models/tree.model'

export let currentLanguageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
export const currentLanguageCode$: BehaviorSubject<string> = new BehaviorSubject<string>(currentLanguageCode)

@Injectable({
  providedIn: 'root'
})
export class LanguageDictionaryHandlingService {

  constructor() {
    currentLanguageCode$.subscribe((langCode: string) => {
      currentLanguageCode = langCode
    })
  }

  convertLangKeyToString(rawLangKey: string, languageDictionary: EvirLanguageDictionary, languageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]): string {
    return convertLangKeyToString(rawLangKey, languageDictionary, languageCode)
  }

  /**
   * Get or create new ID or UUID lang key
   * @param languageString new string
   * @param translationObject translation Object
   * @param evirLanguageDictionary evir language dictionary
   * @returns language key
  */
  getOrCreateLangKey(newStr: string, translationObject: TranslationObject, evirLanguageDictionary: EvirLanguageDictionary, configType: string = LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE, languageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]): string {
    newStr = trimAll(newStr)
    const possibleLangKey = this.getLangKeyByString(newStr, evirLanguageDictionary.languageStrings, configType, languageCode) || this.getLangKeyByString(newStr, translationObject, configType, languageCode)
    return possibleLangKey || this.createNewTranslationsObject(newStr, translationObject, configType, languageCode)
  }

  /**
   * Create new translation object.
   * @param newStrings translation object with new string
   * @param configType type of config
  */
  createNewTranslationsObject(newValue: string | TranslationObjectValue, translationObject: TranslationObject, configType: string = LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE, languageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]): string {
    if (!newValue) {
      throw new UndefinedOrNullLangValueError(`Language value is invalid`)
    }

    const newUUID = uuidv4()
    if (typeof newValue !== 'string') {
      translationObject[newUUID] = newValue
    } else {
      translationObject[newUUID] = {
        translations: {
          [languageCode]: newValue
        },
        configType: configType
      }
    }

    return newUUID
  }

  /**
   * Get UUID by string.
   * @param languageString language string
   * @param translationObject translate object
   * @returns UUID
  */
  public getLangKeyByString(languageString: string, translationObject: TranslationObject, configType: string = LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE, langCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]): string {
    if (!languageString || !translationObject) {
      return ''
    }

    return Object.keys(translationObject).find(langKey => unescape(translationObject[langKey].translations[langCode]) === unescape(languageString)
      && translationObject[langKey].configType === configType)
  }

}
