import { Injectable } from '@angular/core'

/**
 * Simple stack service
 */

@Injectable({
  providedIn: 'root',
})

export class HistoryStackService<T = any> {
  maximumSize = 20

  private stack: T[] = []

  constructor() { }

  size(): number {
    return this.stack.length
  }

  push(item: T): void  {
    this.stack.push(item)
    this.ensureMaximumSize()
  }

  pop(): T {
    return this.stack.pop()
  }

  peek(): T {
    return this.stack[this.size() - 1]
  }

  private ensureMaximumSize() {
    if (this.size() > this.maximumSize) {
      this.stack = this.stack.slice(1) // Remove the oldest element
    }
  }
}
