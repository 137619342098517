import { TEMPLATE_TYPES_VALUE } from 'src/app/constants/internationalized-constants-en'

export class TemplateLibraryPieceModel {
  id: string
  pieceType: TEMPLATE_TYPES_VALUE
  name: string
  admin: boolean
  created: string
  piece: ConfigurationPieceModel | ZonePieceModel | ComponentPieceModel | ConditionPieceModel | FormDataPieceModel | InspectionDetailPieceModel
}

export class ConfigurationPieceModel {
  assetType: string[]
  assetViewGrid: number[]
  assetViewId: string
  configZones: ZonePieceModel[]
  zoneLayoutLanguageKey?: string
  nameLangKey: string
  id?: string
}

export class ZonePieceModel {
  assetViewLocation: number[]
  tagComponents: ComponentPieceModel[]
  tagNameKey?: string
  tagLangKey: string
  tagNumber: number
  tagType: string
  zoneInspectionTypes: string[]
  id?: string
}

export class ComponentPieceModel {
  maxSeverity: number
  minSeverity: number
  componentNameKey?: string
  componentLangKey: string
  suggestedConditions?: string[]
  suggestedConditionLangKeys: string[]
  id?: string
}

export class TemplateLibraryStandardTreeModel {
  name: string // this is the name inside JSON
  pieceName: string // this is piece name
  data: TemplateLibraryStandardTreeModel[]
  value: ConfigurationPieceModel | ZonePieceModel | ComponentPieceModel | ConditionPieceModel | FormDataPieceModel | InspectionDetailPieceModel
}

export class ConditionPieceModel {
  name: string
  id?: string
}

export class FormDataPieceModel {
  regex?: string
  default?: any
  select?: string[]
  selectLangKeys?: string[]
  hintKey?: string
  hintLangKey: string
  maximum?: number
  minimum?: number
  required: boolean
  fieldNameLabelKey?: string
  fieldNameLangKey: string
  inspectionDetailType: string
  inspectionDetailCategory: string
  inspectionDetailMeasurementType?: string
  inspectionDetailUnitConversionPair?: string[]
  inspectionDetailMaxDecimal?: number
}

export class InspectionDetailPieceModel {
  formData?: FormDataPieceModel[]
  enforceOrder: boolean
  legallyMandated: boolean
  prepopulateDefects: boolean
  requiresReview: boolean
  zoneless: boolean
  associateDriver: boolean
  inspectionDetailLanguageKey?: string
  inspectionDetailLangKey: string
  inspectionDescriptionLanguageKey?: string
  inspectionDescriptionLangKey: string
  inspectionType: string
  ect2Data: Record<string, string | boolean>
}

export const blankPieces = [
  {
    id: 'blank-configuration-piece',
    pieceType: TEMPLATE_TYPES_VALUE.CONFIGURATION,
    name: '(Blank Configuration)',
    piece: {
      assetType: ['vehicle', 'standard'],
      configZones: [
        {
          tagComponents: [
            {
              maxSeverity: 255,
              minSeverity: 0,
              componentNameKey: '(Blank Component)',
              componentLangKey: '',
              suggestedConditions: [
                '(Blank Condition)'
              ],
              suggestedConditionLangKeys: [],
            }
          ],
          tagNameKey: '(Blank Zone)',
          tagLangKey: '',
          tagNumber: 0,
          tagType: 'zone',
          zoneInspectionTypes: ['Pre-trip']
        }
      ],
      zoneLayoutLanguageKey: '(Blank Configuration)',
      nameLangKey: '',
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.ZONE,
    name: '(Blank Zone)',
    piece: {
      tagComponents: [
        {
          maxSeverity: 255,
          minSeverity: 0,
          componentNameKey: '(Blank Component)',
          suggestedConditions: [
            '(Blank Condition)'
          ],
        }
      ],
      tagNameKey: '(Blank Zone)',
      tagNumber: 0,
      tagType: 'zone',
      zoneInspectionTypes: ['Pre-trip']
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.COMPONENT,
    name: '(Blank Component)',
    piece: {
      maxSeverity: 255,
      minSeverity: 0,
      componentNameKey: '(Blank Component)',
      suggestedConditions: [
        '(Blank Condition)'
      ],
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.CONDITION,
    name: '(Blank Condition)',
    piece: {
      name: '(Blank Condition)'
    }
  },
  {
    name: 'CHECKBOX',
    pieceType: TEMPLATE_TYPES_VALUE.FORM,
    piece: {
      fieldNameLabelKey: '',
      fieldNameLangKey: '',
      hintKey: '',
      hintLangKey: '',
      inspectionDetailType: 'bool',
      inspectionDetailCategory: 'other',
      required: false,
      default: false
    }
  },
  {
    name: 'DROPDOWN_SELECT_FIELD',
    pieceType: TEMPLATE_TYPES_VALUE.FORM,
    piece: {
      fieldNameLabelKey: '',
      fieldNameLangKey: '',
      hintKey: '',
      hintLangKey: '',
      inspectionDetailType: 'select',
      inspectionDetailCategory: 'other',
      required: false,
      select: [],
      selectLangKeys: [],
    }
  },
  {
    name: 'TEXT_FIELD',
    pieceType: TEMPLATE_TYPES_VALUE.FORM,
    piece: {
      fieldNameLabelKey: '',
      fieldNameLangKey: '',
      hintKey: '',
      hintLangKey: '',
      inspectionDetailType: 'text',
      required: false,
      inspectionDetailCategory: 'other',
      regex: `/[A-Za-z0-9 ''.-@$#]*/`,
      minimum: 0,
      maximum: 60,
    }
  },
  {
    name: 'CAMERA_PHOTOS',
    pieceType: TEMPLATE_TYPES_VALUE.FORM,
    piece: {
      fieldNameLabelKey: '',
      fieldNameLangKey: '',
      hintKey: '',
      hintLangKey: '',
      inspectionDetailCategory: 'other',
      inspectionDetailType: 'photo',
      required: false,
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.INSPECTION_TYPE,
    name: 'Pre-Trip',
    piece: {
      formData: [
        {
          hintKey: 'Value reported on the odometer',
          hintLangKey: '',
          maximum: 1000000000,
          minimum: 0,
          required: true,
          fieldNameLabelKey: 'Odometer',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'odometer',
          inspectionDetailMeasurementType: 'distance',
          inspectionDetailUnitConversionPair: ['mi', 'km'],
        },
        {
          hintKey: 'Engine hours as reported on dash',
          hintLangKey: '',
          maximum: 1000000000,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Engine Hours',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'engineHours',
          inspectionDetailMeasurementType: 'time',
          inspectionDetailUnitConversionPair: ['hrs'],
        },
        {
          hintKey: 'Estimated fuel in tank',
          hintLangKey: '',
          maximum: 500,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Fuel Level',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'fuelVolume',
          inspectionDetailMeasurementType: 'fluidVolume',
          inspectionDetailUnitConversionPair: ['gal', 'l'],
        },
        {
          regex: `/[A-Za-z0-9 ''.-@$#]*/`,
          hintKey: 'Shipping Doc #',
          hintLangKey: '',
          maximum: 50,
          required: false,
          fieldNameLabelKey: 'Shipping Doc #',
          fieldNameLangKey: '',
          inspectionDetailType: 'text',
          inspectionDetailCategory: 'other'
        },
        {
          hintKey: 'Route number',
          hintLangKey: '',
          maximum: 1000,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Route Number',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'other'
        },
        {
          default: false,
          hintKey: 'Do you have hazardous material on board',
          hintLangKey: '',
          required: true,
          fieldNameLabelKey: 'Hazmat',
          fieldNameLangKey: '',
          inspectionDetailType: 'bool',
          inspectionDetailCategory: 'other'
        },
        {
          default: false,
          hintKey: 'Is your vehicle or load over-sized',
          hintLangKey: '',
          required: true,
          fieldNameLabelKey: 'Oversized Vehicle',
          fieldNameLangKey: '',
          inspectionDetailType: 'bool',
          inspectionDetailCategory: 'other'
        }
      ],
      inspectionDetailLanguageKey: 'Pre-Trip',
      inspectionDetailLangKey: '',
      inspectionDescriptionLanguageKey: 'Inspect the vehicle before driving according to DOT and company policy.',
      inspectionDescriptionLangKey: '',
      inspectionType: 'Pre-Trip',
      enforceOrder: true,
      requiresReview: true,
      legallyMandated: true,
      prepopulateDefects: true,
      associateDriver: true,
      zoneless: false,
      ect2Data: {
        data: 'Object containing data not passed to the mobile apps.'
      },
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.INSPECTION_TYPE,
    name: 'Post-Trip',
    piece: {
      formData: [
        {
          hintKey: 'Value reported on the odometer',
          hintLangKey: '',
          maximum: 1000000000,
          minimum: 0,
          required: true,
          fieldNameLabelKey: 'Odometer',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'odometer',
          inspectionDetailMeasurementType: 'distance',
          inspectionDetailUnitConversionPair: ['mi', 'km'],
        },
        {
          hintKey: 'Engine hours as reported on dash',
          hintLangKey: '',
          maximum: 1000000000,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Engine Hours',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'engineHours',
          inspectionDetailMeasurementType: 'time',
          inspectionDetailUnitConversionPair: ['hrs'],
        },
        {
          hintKey: 'Estimated fuel in tank',
          hintLangKey: '',
          maximum: 500,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Fuel Level',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'fuelVolume',
          inspectionDetailMeasurementType: 'fluidVolume',
          inspectionDetailUnitConversionPair: ['gal', 'l'],
        },
        {
          regex: `/[A-Za-z0-9 ''.-@$#]*/`,
          hintKey: 'Trip notes or concerns',
          hintLangKey: '',
          maximum: 500,
          required: false,
          fieldNameLabelKey: 'Post trip notes',
          fieldNameLangKey: '',
          inspectionDetailType: 'text',
          inspectionDetailCategory: 'other'
        }
      ],
      inspectionDetailLanguageKey: 'Post-Trip',
      inspectionDetailLangKey: '',
      inspectionDescriptionLanguageKey: 'Inspect the vehicle after driving according to DOT and company policy.',
      inspectionDescriptionLangKey: '',
      inspectionType: 'Post-Trip',
      enforceOrder: true,
      requiresReview: true,
      legallyMandated: true,
      prepopulateDefects: true,
      zoneless: false,
      associateDriver: true,
      ect2Data: {
        data: 'Object containing data not passed to the mobile apps.'
      },
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.INSPECTION_TYPE,
    name: 'Time Card',
    piece: {
      formData: [
        {
          select: [
            'In',
            'Out',
          ],
          selectLangKeys: [],
          hintKey: 'Punch time card in or out',
          hintLangKey: '',
          required: true,
          fieldNameLabelKey: 'Clock',
          fieldNameLangKey: '',
          inspectionDetailType: 'select',
          inspectionDetailCategory: 'other'
        },
        {
          hintKey: 'Route number',
          hintLangKey: '',
          maximum: 1000,
          minimum: 0,
          required: false,
          fieldNameLabelKey: 'Route Number',
          fieldNameLangKey: '',
          inspectionDetailType: 'int',
          inspectionDetailCategory: 'other'
        },
        {
          select: [
            'Regular',
            'Special',
            'Field Trip',
            'Charter',
            'Athletic'
          ],
          selectLangKeys: [],
          hintKey: 'Route Type',
          hintLangKey: '',
          required: false,
          fieldNameLabelKey: 'Route Type',
          fieldNameLangKey: '',
          inspectionDetailType: 'select',
          inspectionDetailCategory: 'other'
        },
      ],
      inspectionDetailLanguageKey: 'Time Card',
      inspectionDetailLangKey: '',
      inspectionDescriptionLanguageKey: 'Record your time by clocking in or out.',
      inspectionDescriptionLangKey: '',
      inspectionType: 'Time Card',
      enforceOrder: false,
      requiresReview: false,
      legallyMandated: false,
      prepopulateDefects: true,
      zoneless: true,
      associateDriver: true,
      ect2Data: {
        data: 'Object containing data not passed to the mobile apps.'
      },
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.INSPECTION_TYPE,
    name: 'Load Securement',
    piece: {
      inspectionDetailLanguageKey: 'Load Securement',
      inspectionDetailLangKey: '',
      inspectionDescriptionLanguageKey: 'Inspect tarps, straps, and other load safety devices.',
      inspectionDescriptionLangKey: '',
      inspectionType: 'Load Securement',
      enforceOrder: false,
      requiresReview: false,
      legallyMandated: false,
      prepopulateDefects: true,
      associateDriver: true,
      zoneless: false,
      ect2Data: {
        data: 'Object containing data not passed to the mobile apps.'
      },
    }
  },
  {
    pieceType: TEMPLATE_TYPES_VALUE.INSPECTION_TYPE,
    name: 'Child Check',
    piece: {
      inspectionDetailLanguageKey: 'Child Check',
      inspectionDetailLangKey: '',
      inspectionDescriptionLanguageKey: 'Check behind and under seats for any remaining children.',
      inspectionDescriptionLangKey: '',
      inspectionType: 'Child Check',
      enforceOrder: true,
      requiresReview: false,
      legallyMandated: false,
      prepopulateDefects: true,
      zoneless: true,
      associateDriver: true,
      ect2Data: {
        data: 'Object containing data not passed to the mobile apps.'
      },
    }
  },
] as TemplateLibraryPieceModel[]

export class RawTemplateLibraryPieceModel {
  pieceType: TEMPLATE_TYPES_VALUE
  name: string
  piece: ConfigurationPieceModel | ZonePieceModel | ComponentPieceModel | ConditionPieceModel | FormDataPieceModel | InspectionDetailPieceModel
}
